import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Tooltip, alpha } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { deleteTranslation } from 'redux/actions/DBTranslations';

const useStyles = makeStyles(theme => ({
  tableRowRoot: {
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
    },
  },
  tableCellRoot: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    width: '100px',
  },
  placeholder: {},
}));

const TableItem = ({ row, languagesNumber }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleModalIsOpen = () => {
    setModalIsOpen(prev => !prev);
  };
  return (
    <TableRow className={classes.tableRowRoot}>
      <TableCell
        className={[classes.tableCellRoot, classes.placeholder].join(' ')}
        style={{ wordBreak: 'break-all', width: '20%' }}>
        {row.placeholder}
      </TableCell>
      {/* <TableCell className={classes.tableCellRoot} style={{ width: '20%' }}>
        {row.section || '-'}
      </TableCell> */}
      <TableCell className={classes.tableCellRoot} style={{ width: '20%' }}>
        {row?.translationsEn?.translation || '-'}
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ width: '15%' }}>
        {row?.addedByUser?.fullname || '-'}
      </TableCell>
      <TableCell className={classes.tableCellRoot}>{moment(row.created).format('DD.MM.YYYY HH:mm')}</TableCell>
      <TableCell className={classes.tableCellRoot}>{moment(row.updated).format('DD.MM.YYYY HH:mm')}</TableCell>
      <TableCell className={classes.tableCellRoot}>{languagesNumber - row.approveTranslations}</TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Tooltip title="Edit" arrow>
          <IconButton onClick={() => history.push(`db-translations/${row._id}`)}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete" arrow>
          <IconButton onClick={() => setModalIsOpen(true)}>
            <DeleteOutline fontSize="small" />
          </IconButton>
        </Tooltip>
      </TableCell>

      <Dialog
        open={modalIsOpen}
        onClose={handleModalIsOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{`Delete Term ${row.placeholder}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Do you want to delete this term ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalIsOpen} color="primary">
            Cancel
          </Button>
          <Button onClick={() => dispatch(deleteTranslation(row._id))} color="primary" variant="contained" autoFocus>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  );
};

export default TableItem;
