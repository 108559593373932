import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';
import Dashboard from './Pages/Dashboard';
import MatchApp from './Pages/MatchApp';
import MatchDetailApp from './Pages/MatchDetailApp';
import BarApp from './Pages/BarApp';
import Languages from './Pages/Languages';
import Rules from './Pages/Rules';
import Settings from './Pages/Settings';
import Users from './Pages/UsersApp';
import AdminUsers from './Pages/UsersAdmin';
import UserDetail from './Pages/UserDetail';
import CompetitionApp from './Pages/CompetitionApp';
import CompetitionDetailApp from './Pages/CompetitionDetailApp';
import OfficialChampionshipList from './Pages/OfficialChampionshipList';
import OfficialChampionship from './Pages/OfficialChampionship';
import ChangePassword from './Pages/ChangePassword';
import ChallengesApp from './Pages/ChallengesApp';
import ChallengeDetailsApp from './Pages/ChallengeDetailsApp';
import AML from './Pages/AML';
import Odds from './Pages/Odds';
import BetTemplates from './Pages/BetTemplates';
import Ecosystems from './Pages/Ecosystems';
import Ecosystem from './Pages/Ecosystem';
import EcosystemDetail from './Pages/Ecosystem/EcosystemDetail';
import Participants from './Pages/Participants';
import Brackets from './Pages/Brackets';
import CompetitionApp2 from './Pages/Outrights';
import Selections from './Pages/Selections';
import Selection from './Pages/Selection';
import FanClubList from './Pages/FanClubList';
import FanClub from './Pages/FanClub';
import PopupsPage from './Pages/PopupsPage';
import PopupsCreateEdit from './Pages/PopupsPage/PopupsCreateEdit';
import DBTranslationsCreateEdit from './Pages/DBTranslations/DBTranslationsCreateEdit';
import DBTranslationsPage from './Pages/DBTranslations';
import PollsPage from './Pages/Polls';
import PollCreateEditPage from './Pages/Polls/Polls';
import B2BAccount from './Pages/B2BAccount';
import Analytics from './Pages/Analytics';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/dashboard'} />;
  } else if (authUser && location.pathname === '/login') {
    return <Redirect to={'/dashboard'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <RestrictedRoute path="/official-championships" component={OfficialChampionshipList} />
        <RestrictedRoute path="/official-championship/:id" component={OfficialChampionship} />
        <RestrictedRoute path="/official-championship/new" component={OfficialChampionship} />
        <RestrictedRoute path="/competitions/:id" component={CompetitionDetailApp} />
        <RestrictedRoute path="/competitions" component={CompetitionApp} />
        <RestrictedRoute path="/outrights" component={CompetitionApp2} />
        <RestrictedRoute path="/selections" component={Selections} />
        <RestrictedRoute path="/selection/:id" component={Selection} />
        <RestrictedRoute path="/selection/new" component={Selection} />
        <RestrictedRoute path="/fan-clubs" component={FanClubList} />
        <RestrictedRoute path="/fan-club/:id" component={FanClub} />
        <RestrictedRoute path="/fan-club/new" component={FanClub} />
        <RestrictedRoute path="/challenges" component={ChallengesApp} />
        <RestrictedRoute path="/challenge/:id" component={ChallengeDetailsApp} />
        <RestrictedRoute path="/match/:id" component={MatchDetailApp} />
        <RestrictedRoute path="/matches" component={MatchApp} />
        <RestrictedRoute path="/bars" component={BarApp} />
        <RestrictedRoute path="/participants" component={Participants} />
        <RestrictedRoute path="/rules" component={Rules} />
        <RestrictedRoute path="/users" component={Users} />
        <RestrictedRoute path="/admin-users" component={AdminUsers} />
        <RestrictedRoute path="/user/:id" component={UserDetail} />
        <RestrictedRoute path="/translations" component={Languages} />
        <RestrictedRoute path="/settings" component={Settings} />
        <RestrictedRoute path="/aml" component={AML} />
        <RestrictedRoute path="/odds" component={Odds} />
        <RestrictedRoute path="/bet-templates" component={BetTemplates} />
        <RestrictedRoute path="/ecosystems" component={Ecosystems} />
        <RestrictedRoute path="/ecosystem/new" component={Ecosystem} />
        <RestrictedRoute path="/ecosystem/:id" component={EcosystemDetail} />
        <RestrictedRoute path="/brackets" component={Brackets} />
        <RestrictedRoute path="/dashboard" component={Dashboard} />
        <RestrictedRoute path="/change-password" component={ChangePassword} />
        <RestrictedRoute path="/pop-ups/new" component={PopupsCreateEdit} />
        <RestrictedRoute path="/pop-ups/:id" component={PopupsCreateEdit} />
        <RestrictedRoute path="/pop-ups" component={PopupsPage} />
        <RestrictedRoute path="/db-translations/new" component={DBTranslationsCreateEdit} />
        <RestrictedRoute path="/db-translations/:id" component={DBTranslationsCreateEdit} />
        <RestrictedRoute path="/db-translations" component={DBTranslationsPage} />
        <RestrictedRoute path="/polls/new" component={PollCreateEditPage} />
        <RestrictedRoute path="/polls/:id" component={PollCreateEditPage} />
        <RestrictedRoute path="/polls" component={PollsPage} />
        <RestrictedRoute path="/b2b-account" component={B2BAccount} />
        <RestrictedRoute path="/analytics" component={Analytics} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
