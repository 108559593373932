export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';

export const UPDATE_AUTH_USER = 'update_auth_user';
export const UPDATE_LOAD_USER = 'update_load_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'login_google_user_success';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'login_facebook_user_success';
export const SIGNIN_TWITTER_USER_SUCCESS = 'login_twitter_user_success';
export const SIGNIN_GITHUB_USER_SUCCESS = 'login_github_user_SUCCESS';
export const SIGNIN_USER_SUCCESS = 'login_user_success';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';

export const SET_DASHBOARD_DATA = 'set_dashboard_data';
export const ADD_LABEL = 'add_label';
export const GET_LABELS_LIST = 'get_labels_list';
export const TOGGLE_SIDEBAR_COLLAPSED = 'toggle_sidebar_collapsed';
export const SET_FILTER_TYPE = 'set_filter_type';

// Matches
export const GET_MATCHES_LIST = 'get_matches_list';
export const GET_COMPETITIONS_ACTIVE = 'GET_COMPETITIONS_ACTIVE';
export const SET_CURRENT_MATCH = 'set_current_match';
export const CREATE_MATCH = 'create_match';
export const UPDATE_STARRED_STATUS = 'update_starred_status';
export const DELETE_MATCH = 'delete_match';
export const UPDATE_MATCH_LABEL = 'update_match_label';
export const UPDATE_MATCH = 'update_match';
export const GET_MATCH_COUNTS = 'get_match_counts';
export const UPDATE_LABEL_ITEM = 'update_label_item';
export const DELETE_LABEL_ITEM = 'delete_label_item';
export const PATCH_MATCH_PLAYER = 'PATCH_MATCH_PLAYER';
export const SET_MATCH_BROADCAST = 'SET_MATCH_BROADCAST';
export const SET_MATCH_STADIUM = 'SET_MATCH_STADIUM';
export const GET_OVERDUE_CANCELED_MATCHES = 'get_overdue_canceled_matches';
export const CANCEL_MATCH = 'cancel_match';
export const POSTPONE_MATCH = 'postpone_match';
export const SET_SORT_TYPE = 'set_sort_type';

// BARS
export const GET_BARS_LIST = 'get_bars_list';
export const SET_CURRENT_BAR = 'set_current_bar';
export const CREATE_BAR = 'create_bar';
export const UPDATE_BAR_STARRED_STATUS = 'update_bar_starred_status';
export const DELETE_BAR = 'delete_bar';
export const UPDATE_BAR_LABEL = 'update_bar_label';
export const UPDATE_BAR = 'update_bar';
export const GET_BAR_COUNTS = 'get_bar_counts';

// Official Championship
export const SET_OFFICIAL_CHAMPHIONSHIP_CURRENT_USER = 'set_official_championship_current_user';
export const SET_OFFICIAL_CHAMPIONSHIPS_DATA = 'set_official_championships_data';
export const SET_OFFICIAL_CHAMPHIONSHIP_LIST_DATA = 'set_official_championship_list_data';
export const ADD_OFFICIAL_CHAMPHIONSHIP = 'add_official_championship';
export const DELETE_OFFICIAL_CHAMPHIONSHIP = 'delete_official_championship';
export const UPDATE_OFFICIAL_CHAMPHIONSHIP = 'update_official_championship';
export const SET_FILTER_DATA = 'set_filter_data';
export const ADD_OFFICIAL_CHAMPHIONSHIP_LIST = 'add_official_championship_list';
export const UPDATE_OFFICIAL_CHAMPHIONSHIP_LIST = 'update_official_championship_list';
export const DELETE_OFFICIAL_CHAMPHIONSHIP_LIST = 'delete_official_championship_list';
export const SET_OFFICIAL_CHAMPHIONSHIP_DETAIL = 'set_official_championship_detail';
export const SEND_MESSAGE = 'send_message';
export const GET_OFFICIAL_CHAMPIONSHIPS_COUNTS = 'get_official_championships_counts';

//Languages
export const FETCH_ALL_LANGUAGES_INVOKED = 'FETCH_ALL_LANGUAGES_INVOKED';
export const FETCH_ALL_LANGUAGES = 'FETCH_ALL_LANGUAGES';
export const FETCH_ALL_LANGUAGES_ERROR = 'FETCH_ALL_LANGUAGES_ERROR';
export const FETCH_ALL_LANGUAGES_PLATFORMS = 'FETCH_ALL_LANGUAGES_PLATFORMS';
export const FETCH_ALL_LANGUAGES_PLATFORMS_INVOKED = 'FETCH_ALL_LANGUAGES_PLATFORMS_INVOKED';
export const FETCH_ALL_LANGUAGES_PLATFORMS_ERROR = 'FETCH_ALL_LANGUAGES_PLATFORMS_ERROR';
export const GET_LANGUAGE_DATA_INVOKED = 'GET_LANGUAGE_DATA_INVOKED';
export const GET_LANGUAGE_DATA_ERROR = 'GET_LANGUAGE_DATA_ERROR';
export const GET_LANGUAGE_DATA = 'GET_LANGUAGE_DATA';
export const UPDATE_LANGUAGE_DATA = 'UPDATE_LANGUAGE_DATA';
export const CHANGE_LANGUAGE_STATUS = 'CHANGE_LANGUAGE_STATUS';
export const ADD_NEW_LANGUAGE = 'ADD_NEW_LANGUAGE';
export const DELETE_LANGUAGE = 'DELETE_LANGUAGE';

//Competitions
export const GET_COMPETITIONS_DATA = 'GET_COMPETITIONS_DATA';
export const GET_COMPETITIONS_DETAILS = 'GET_COMPETITIONS_DETAILS';
export const SYNC_COMPETITIONS_DATA = 'SYNC_COMPETITIONS_DATA';
export const SYNC_COMPETITIONS_DATA_SW = 'SYNC_COMPETITIONS_DATA_SW';
export const FETCH_COMPETITIONS_DATA = 'SYNC_COMPETITIONS_DATA';
export const GET_COMPETITIONS_TEAMS_DATA = 'GET_COMPETITIONS_TEAMS_DATA';
export const SET_COMPETITIONS_TEAMS_LOGO = 'SET_COMPETITIONS_TEAMS_LOGO';
export const SET_COMPETITIONS_TEAMS_TOP = 'SET_COMPETITIONS_TEAMS_TOP';
export const SET_COMPETITIONS_BROADCAST = 'SET_COMPETITIONS_BROADCAST';
export const FETCH_COMPETITIONS_SEASON = 'FETCH_COMPETITIONS_SEASON';
export const TOP_COMPETITIONS_DATA = 'TOP_COMPETITIONS_DATA';
export const GET_TOP_COMPETITIONS = 'GET_TOP_COMPETITIONS';
export const FETCH_COMPETITIONS_DETAILS = 'FETCH_COMPETITIONS_DETAILS';
export const GET_SINGLE_COMPETITION_ODDS = 'GET_SINGLE_COMPETITION_ODDS';
export const SET_COMPETITION_FILTERS = 'SET_COMPETITION_FILTERS';

//Outrights
export const GET_OUTRIGHTS_DATA = 'GET_OUTRIGHTS_DATA';
export const GET_ALL_OUTRIGHTS_DATA = 'GET_ALL_OUTRIGHTS_DATA';
export const SET_OUTRIGHTS_FILTERS = 'SET_FILTERS';

//Teams
export const GET_TEAMS_DATA = 'GET_TEAMS_DATA';
export const SET_TOP_TEAM = 'SET_TOP_TEAM';
export const GET_MATCH_DETAIL = 'GET_MATCH_DETAIL';
export const GET_MATCH_ODDS = 'GET_MATCH_ODDS';
export const GET_SINGLE_MATCH_ODDS = 'GET_SINGLE_MATCH_ODDS';
export const GET_MATCH_PLAYERS = 'GET_MATCH_PLAYERS';
export const GET_MATCH_EVENTS = 'GET_MATCH_EVENTS';
export const ADD_MATCH_EVENTS = 'ADD_MATCH_EVENTS';
export const REMOVE_MATCH_EVENTS = 'REMOVE_MATCH_EVENTS';
export const PATCH_MATCH_ODDS = 'PATCH_MATCH_ODDS';

//Official Championship
export const ADD_OFFICIAL_CHAMPIONSHIPS = 'ADD_OFFICIAL_CHAMPIONSHIPS';
export const GET_OFFICIAL_CHAMPIONSHIPS = 'GET_OFFICIAL_CHAMPIONSHIPS';
export const DELETE_OFFICIAL_CHAMPIONSHIPS = 'DELETE_OFFICIAL_CHAMPIONSHIPS';
export const PUBLISH_OFFICIAL_CHAMPIONSHIPS = 'PUBLISH_OFFICIAL_CHAMPIONSHIPS';
export const GET_OFFICIAL_CHAMPIONSHIPS_DETAILS = 'GET_OFFICIAL_CHAMPIONSHIPS_DETAILS';
export const PATCH_OFFICIAL_CHAMPIONSHIPS_DETAILS = 'PATCH_OFFICIAL_CHAMPIONSHIPS_DETAILS';
export const GET_OFFICIAL_CHAMPIONSHIPS_MATCHES = 'GET_OFFICIAL_CHAMPIONSHIPS_MATCHES';
export const GET_OFFICIAL_CHAMPIONSHIPS_LANG = 'GET_OFFICIAL_CHAMPIONSHIPS_LANG';
export const SELECT_BET_TEMPLATE = 'SELECT_BET_TEMPLATE';
export const SELECT_BET_TEMPLATE_TYPE = 'SELECT_BET_TEMPLATE_TYPE';
export const SET_CUSTOM_FINAL_BETTING_DATES_OUTRIGHTS = 'SET_CUSTOM_FINAL_BETTING_DATES_OUTRIGHTS';
export const SET_INDIVIDUAL_BET_TEMPLATES = 'SET_INDIVIDUAL_BET_TEMPLATES';

//Selections
export const GET_SELECTIONS = 'GET_SELECTIONS';
export const ADD_SELECTION = 'ADD_SELECTION';
export const GET_SELECTION_DETAILS = 'GET_SELECTION_DETAILS';
export const PATCH_SELECTION = 'PATCH_SELECTION';
export const GET_MATCHES_TEMPLATES = 'GET_MATCHES_TEMPLATES';
export const GET_TEAMS_TEMPLATES = 'GET_TEAMS_TEMPLATES';

//Rules
export const GET_ALL_RULES = 'GET_ALL_RULES';
export const CREATE_NEW_RULE = 'CREATE_NEW_RULE';
export const EDIT_RULE_STATUS = 'EDIT_RULE_STATUS';

//Rules
export const GET_SETTINGS = 'GET_SETTINGS';
export const CREATE_NEW_SETTING = 'CREATE_NEW_SETTING';
export const EDIT_SETTING = 'EDIT_SETTING';

//Users
export const GET_USERS = 'GET_USERS';
export const GET_USERS_ADMIN = 'GET_USERS_ADMIN';
export const GET_AML_USERS = 'GET_AML_USERS';
export const GET_USER_DETAIL = 'GET_USER_DETAIL';
export const GET_USER_DOCUMENTS = 'GET_USER_DOCUMENTS';
export const GET_USER_WALLET = 'GET_USER_WALLET';
export const GET_USER_TRANSACTIONS = 'GET_USER_TRANSACTIONS';
export const PATCH_DOCUMENT_STATUS = 'PATCH_DOCUMENT_STATUS';
export const CREATE_USER = 'CREATE_USER';
export const PATCH_USER = 'PATCH_USER';
export const BLOCK_USER = 'BLOCK_USER';
export const UNBLOCK_USER = 'UNBLOCK_USER';
export const BLOCK_USER_AML = 'BLOCK_USER_AML';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const GET_USERS_PERMISSIONS = 'GET_USERS_PERMISSIONS';
export const POST_USERS_PERMISSIONS = 'POST_USERS_PERMISSIONS';

//Challenges
export const GET_CHALLENGES = 'GET_CHALLENGES';
export const CHALLENGE_DETAILS = 'CHALLENGE_DETAILS';
export const CHALLENGE_TRANSACTIONS = 'CHALLENGE_TRANSACTIONS';
export const CHALLENGE_PARTICIPANTS = 'CHALLENGE_PARTICIPANTS';
export const CHALLENGE_MATCHES = 'CHALLENGE_MATCHES';
export const CHALLENGE_RANK = 'CHALLENGE_RANK';
export const CHALLENGE_OUTRIGHTS = 'CHALLENGE_OUTRIGHTS';
//Odds
export const GET_SPORTS = 'GET_SPORTS';
export const SELECT_SPORT = 'SELECT_SPORT';
export const GET_ODDS = 'GET_ODDS';
export const GET_VISIBLE_ODDS = 'GET_VISIBLE_ODDS';
export const PATCH_ODD_COMMON_NAME = 'PATCH_ODD_COMMON_NAME';

//Bet template
export const BET_TEMPLATE = 'BET_TEMPLATE';

//Ecosystems template
export const ECOSYSTEMS = 'ECOSYSTEMS';
export const GET_ECOSYSTEM_DETAIL = 'GET_ECOSYSTEM_DETAIL';

//FanClubs
export const GET_FANCLUBS = 'GET_FANCLUBS';
export const GET_FANCLUB_DETAILS = 'GET_FANCLUB_DETAILS';
export const ADD_FANCLUB = 'ADD_FANCLUBS';
export const PATCH_FANCLUB = 'PATCH_FANCLUBS';

// POP-UPS
export const GET_POPUP = 'GET_POPUP';
export const GET_ALL_POPUPS = 'GET_ALL_POPUPS';
export const ADD_POPUP = 'ADD_POPUP';
export const PATCH_POPUP = 'PATCH_POPUP';
export const REMOVE_POPUP = 'REMOVE_POPUP';

// POLLS
export const GET_POLL = 'GET_POLL';
export const GET_POLLS = 'GET_POLLS';
export const ADD_POLL = 'ADD_POLL';
export const PATCH_POLL = 'PATCH_POLL';

// DATABASE TRANSLATIONS

export const GET_TRANSLATION = 'GET_TRANSLATION';
export const GET_ALL_TRANSLATIONS = 'GET_ALL_TRANSLATIONS';
export const ADD_TRANSLATION = 'ADD_TRANSLATION';
export const PATCH_TRANSLATION = 'PATCH_TRANSLATION';
export const REMOVE_TRANSLATION = 'REMOVE_TRANSLATION';
export const SET_DB_FILTERS = 'SET_DB_FILTERS';
