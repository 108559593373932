import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { Badge, Box, Button, TablePagination, Tooltip } from '@material-ui/core';
import CmtAvatar from '@coremat/CmtAvatar';

import useStyles from './../index.style';
import SelectModalSingleMatch from '../BetTemplates/SelectModalSingleMatch';

const MatchCell = ({ selectedMatches }) => {
  const classes = useStyles();
  const rowsPerPage = 10;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState({});

  const [filteredMatches, setFilteredMatches] = useState([]);
  const [page, setPage] = useState(0);
  const [slicedMatches, setSlicedMatches] = useState();

  useEffect(() => {
    let filtered = selectedMatches.sort((match1, match2) => {
      if (match1?.game !== undefined)
        return new Date(match2?.game?.scheduledDate).getTime() - new Date(match1?.game?.scheduledDate).getTime();
      else return new Date(match2?.scheduledDate).getTime() - new Date(match1?.scheduledDate).getTime();
    });
    setFilteredMatches(filtered);
  }, [selectedMatches]);

  useEffect(() => {
    setSlicedMatches(filteredMatches?.slice(0, 10));
  }, [filteredMatches]);

  const toggleModal = () => {
    setModalIsOpen(prev => !prev);
  };

  const betSlipButtonHandler = match => {
    setSelectedMatch({ ...match });
    setModalIsOpen(true);
  };

  const handleChangePage = (e, newPage) => {
    setSlicedMatches(filteredMatches?.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage) || {});
    setPage(newPage);
  };

  return (
    <>
      <Box
        style={{
          minHeight: '550px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          marginBottom: '50px',
        }}>
        <Box className={classes.selectedMatchesList}>
          {slicedMatches?.map((match, id) => {
            let copyOfTheMatch = match;
            if (match?.game !== undefined) match = match.game;
            return (
              <Box key={id} className={classes.selectListMatchRow}>
                <Box>
                  <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
                    <Box
                      mr={4}
                      style={{
                        width: '30px',
                        overflow: 'hidden',
                        marginRight: '0',
                        borderRight: '1px solid #fff',
                      }}>
                      <CmtAvatar size={60} src={match.homeTeamEmblemUrl} alt={match.headline} />
                    </Box>
                    <Box mr={4} style={{ width: '30px', overflow: 'hidden', marginRight: '0' }}>
                      <CmtAvatar
                        size={60}
                        style={{ transform: 'translateX(-30px)' }}
                        src={match.awayTeamEmblemUrl}
                        alt={match.headline}
                      />
                    </Box>
                  </Box>
                </Box>
                <Badge
                  badgeContent={
                    copyOfTheMatch?.betsPlaced ? (
                      <Tooltip title="Number of bets" arrow>
                        <span>{copyOfTheMatch?.betsPlaced || 0}</span>
                      </Tooltip>
                    ) : null
                  }
                  color="primary"
                  showZero
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}>
                  <Box style={{ paddingRight: '15px' }}>
                    <Box>
                      <h3 style={{ fontWeight: 'bold' }}>{match.headline}</h3>
                    </Box>
                    <Box>{Moment(match.scheduledDate).format('H:mm - D MMM yyyy')}</Box>
                    <Box>{match.competitionName}</Box>
                  </Box>
                </Badge>
                <Tooltip
                  title={
                    match.status === 'Pending' && !match?.userBets?.length
                      ? 'Change bet template'
                      : 'Bet template is disabled if the match is started or if the match has bets.'
                  }
                  arrow>
                  <span style={{ marginLeft: 'auto' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => betSlipButtonHandler(copyOfTheMatch)}
                      disabled={match.status === 'Pending' && !match?.userBets?.length ? false : true}>
                      BETSLIP
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            );
          })}
        </Box>
        <Box>
          <TablePagination
            component="div"
            style={{ order: '9999', width: '100%' }}
            count={selectedMatches?.length || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[10]}
            rowsPerPage={10}
          />
        </Box>
      </Box>
      <SelectModalSingleMatch selectedMatch={selectedMatch} open={modalIsOpen} onClose={toggleModal} />
    </>
  );
};

export default MatchCell;
