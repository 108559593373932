import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  extractLanguageData,
  fetchAllLanguagesPlatforms,
  fetchAllLanguages,
  changeLanguageStatus,
  addNewLanguage,
  deleteLanguage,
} from 'redux/actions/Languages';
import { map } from 'lodash';

import { Box, TextField, makeStyles, Button } from '@material-ui/core';

import LanguagesList from './LanguagesList/LanguagesList';
import LanguagePublish from './LanguagesList/LanguagePublish';
import { useHistory } from 'react-router';

let timer;
const Languages = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { languageList, languagePlatforms, languages, newLanguageCallback } = useSelector(({ languages }) => languages);
  const [languagePublish, setLanguagePublish] = useState([]);
  const [language, setLanguage] = useState([]);
  const [platform, setPlatform] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [currentLanguageList, setCurrentLanguageList] = useState({});
  const [newLanguage, setNewLanguage] = useState('');
  const [newLanguageCode, setNewLanguageCode] = useState('');
  const [deleteLanguageId, setDeleteLanguageId] = useState(null);
  const [searchText, setSearchText] = useState('');

  const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(4),
    },
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        minWidth: '400px',
      },
    },
    orderFirst: {
      order: 0,
    },
    orderNormal: {
      order: 1,
    },

    borderRight: {
      maxWidth: '120px',
      width: '100%',
      borderRight: '1px solid #000',
      '&:last-of-type': {
        borderRight: 'none',
      },
    },

    inBuildAppCard: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4,
      margin: '15px',
      padding: '25px',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)',
      position: 'relative',
      overflow: 'hidden',
    },
  }));
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    dispatch(deleteLanguage(deleteLanguageId));
    setOpen(false);
  };

  useEffect(() => {
    dispatch(fetchAllLanguagesPlatforms());
    dispatch(fetchAllLanguages());
  }, [dispatch]);
  useEffect(() => {
    if (searchText.length > 0) {
      if (platform === 'mobile') {
        let newList = { translations: {} };
        map(languageList.translations, (value, categoryKey) => {
          if (value.indexOf(searchText) !== -1 || categoryKey.indexOf(searchText) !== -1) {
            newList.translations = {
              ...newList.translations,
              [categoryKey]: value,
            };
          }
        });
        setCurrentLanguageList(newList);
      } else {
        setSearchText('');
        setCurrentLanguageList(languageList);
      }
    } else {
      setCurrentLanguageList(languageList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageList]);

  useEffect(() => {
    setLanguagePublish(languages);
  }, [languages]);

  useEffect(() => {
    setNewLanguage('');
    setNewLanguageCode('');
  }, [newLanguageCallback]);

  const handleChangePlatform = event => {
    setPlatform(event.target.value);
    setCategory('');
    setSelectedCategory('');
    if (language.length > 0) {
      dispatch(extractLanguageData(language, event.target.value));
    }
  };
  const handleChangeLanguages = event => {
    setLanguage(event.target.value);
    if (platform.length > 0) {
      dispatch(extractLanguageData(event.target.value, platform));
    }
  };

  const handleCategory = event => {
    setCategory(event.target.value);
    setSelectedCategory(languageList.translations[event.target.value]);
  };

  const editValue = e => {
    return e;
  };

  const editableMode = e => {
    return e;
  };

  const handleChangeStatus = (id, status) => {
    dispatch(changeLanguageStatus(id, status));
  };

  const addNewLanguageHandler = () => {
    let newLang = {
      name: newLanguage,
      code: newLanguageCode.toLowerCase(),
    };
    dispatch(addNewLanguage(newLang));
  };

  const deleteLanguageHandler = id => {
    // dispatch(deleteLanguage(id));
    setDeleteLanguageId(id);
    setOpen(true);
  };

  const searchKeyHandler = e => {
    setSearchText(e.target.value);
    let valueSearch = e.target.value;
    const delay = e.target.value ? 500 : 0;
    if (platform === 'mobile') {
      clearTimeout(timer);
      timer = setTimeout(() => {
        if (valueSearch.length > 2) {
          let newList = { translations: {} };
          map(languageList.translations, (value, categoryKey) => {
            if (value.indexOf(valueSearch) !== -1 || categoryKey.indexOf(valueSearch) !== -1) {
              newList.translations = {
                ...newList.translations,
                [categoryKey]: value,
              };
            }
          });
          setCurrentLanguageList(newList);
        } else {
          setCurrentLanguageList(languageList);
        }
      }, delay);
    }
  };

  const searchTextList = valueSearch => {
    let newList = { translations: {} };
    map(languageList.translations, (value, categoryKey) => {
      if (value.indexOf(valueSearch) !== -1 || categoryKey.indexOf(valueSearch) !== -1) {
        newList.translations = { ...newList.translations, [categoryKey]: value };
      }
    });
    setCurrentLanguageList(newList);
  };

  return (
    <Box component="div">
      <Box component="div" className={classes.inBuildAppCard}>
        <h2 style={{ margin: '8px 8px 23px 8px' }}>Add Languages</h2>
        <Box style={{ display: 'flex', flexDirection: 'row', margin: '8px' }}>
          <TextField
            id="outlined-basic"
            onChange={e => {
              setNewLanguage(e.target.value);
            }}
            className={classes.textFieldHead}
            label="Language Name"
            variant="outlined"
            style={{ marginRight: '15px' }}
          />
          <TextField
            id="outlined-basic"
            onChange={e => {
              if (e.target.value.length <= 2) {
                setNewLanguageCode(e.target.value);
              }
            }}
            value={newLanguageCode}
            className={classes.textFieldHead}
            label="Code"
            variant="outlined"
            style={{ marginRight: '15px', maxWidth: '100px' }}
          />
          <Button variant="contained" color="primary" component="span" onClick={addNewLanguageHandler}>
            Add
          </Button>
        </Box>
      </Box>
      <Box component="div" className={classes.inBuildAppCard}>
        <h2 style={{ margin: '8px' }}>Publish Languages</h2>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            margin: '8px',
          }}>
          {languages.map((lang, key) => {
            return (
              <LanguagePublish
                deleteLanguage={deleteLanguageHandler}
                languages={languagePublish}
                handleChangeStatus={handleChangeStatus}
                key={key}
                lang={lang}
              />
            );
          })}
        </Box>
      </Box>
      <Box component="div" className={classes.inBuildAppCard}>
        <Button
          style={{ margin: '8px 8px 0 8px' }}
          variant="contained"
          color="primary"
          component="span"
          onClick={() => history.push('/db-translations')}>
          ADD/EDIT TERMS
        </Button>

        {/* <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">Platform</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={platform ? platform : ''}
            onChange={handleChangePlatform}
            label="Platform">
            {languagePlatforms.map(platformKey => {
              return (
                <MenuItem key={platformKey} value={platformKey}>
                  {platformKey.toLowerCase()}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">Language</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={language ? language : ''}
            onChange={handleChangeLanguages}
            label="Language">
            {languages.map(langKey => {
              return (
                <MenuItem key={langKey.code} value={langKey.code}>
                  {langKey.code.toLowerCase()}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {Object.keys(languageList).length > 0 &&
        typeof languageList.translations[Object.keys(languageList.translations)[0]] === 'object' ? (
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">Modules</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={category ? category : ''}
              onChange={handleCategory}
              label="Language">
              {Object.keys(languageList).length > 0
                ? Object.keys(languageList.translations).map(categoryKey => {
                    return (
                      <MenuItem key={categoryKey} value={categoryKey}>
                        {categoryKey}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
          </FormControl>
        ) : null}
        {platform === 'mobile' && (
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              id="outlined-basic"
              onChange={e => {
                searchKeyHandler(e);
              }}
              className={classes.textFieldHead}
              // value={searchText}
              label="Search"
              variant="outlined"
              style={{ marginRight: '15px', background: 'red' }}
              disabled={languageList.length === 0}
            />
          </FormControl>
        )} */}
      </Box>
      {/* <LanguagesList
        editValue={editValue}
        editableMode={editableMode}
        changes={languageList.requiredChanges}
        searchText={searchText}
        searchTextList={searchTextList}
        platform={platform}
        changeLanguages={languages}
        languageList={currentLanguageList}
        language={language}
        category={category}
        selectedCategory={selectedCategory}
      /> */}
      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to delete this Language?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog> */}
    </Box>
  );
};

export default Languages;
