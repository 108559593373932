import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Hidden, IconButton, Table, TableBody, TablePagination, Typography } from '@material-ui/core';
import { toggleExpandSidebar, getOdds, oddTypeCommonName, updateBetTemplates } from 'redux/actions/Odds';
import { getSettings } from 'redux/actions/Settings';
import { Menu } from '@material-ui/icons';
import CmtSearch from '@coremat/CmtSearch';
import AppContext from 'components/contextProvider/AppContextProvider/AppContext';
import { getMatchContainerHeight } from 'constants/AppConstants';
import PerfectScrollbar from 'react-perfect-scrollbar';
import OddCell from './OddsList/OddCell';
import Sidebar from './Sidebar';

import useStyles from './index.style';
import EmptyResult from 'components/Table/EmptyResult';
import TableHeading from './TableHeading/TableHeading';

const Odds = () => {
  const { isSideBarCollapsed, odds, selectedSport, patchCommonName } = useSelector(({ odds }) => odds);
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();
  const classes = useStyles({ height: getMatchContainerHeight(showFooter) + 80 });
  const [selectedSportOdds, setSelectedSportOdds] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortType, setSortType] = useState(null);
  const { settingsList } = useSelector(({ settings }) => settings);
  const [commonNames, setCommonNames] = useState();
  const [commonNamesEvent, setCommonNamesEvent] = useState();

  const fetchOdds = () => {
    dispatch(getOdds(selectedSport._id, page, rowsPerPage, searchQuery, sortType));
  };

  const getSettingsList = () => {
    dispatch(getSettings());
  };

  useEffect(() => {
    if (Object.keys(odds).length !== 0) {
      setSelectedSportOdds(odds.entities);
      setCount(odds.count);
    }
  }, [odds]);

  useEffect(() => {
    if (settingsList !== undefined && settingsList.length > 0) {
      settingsList.forEach(item => {
        if (item.name === 'betTypeCommonNames') {
          setCommonNames(item.value.betTypeCommonNames);
        }
      });
      settingsList.forEach(item => {
        if (item.name === 'eventPartsCommonName') {
          setCommonNamesEvent(item.value.eventPartsCommonNames);
        }
      });
    }
  }, [settingsList]);

  useEffect(() => {
    if (Object.keys(selectedSport).length !== 0) fetchOdds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSport, rowsPerPage, page, sortType]);

  useEffect(() => {
    let timer = setTimeout(() => {
      fetchOdds();
      getSettingsList();
    }, 1500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    setPage(0);
    setRowsPerPage(10);
  }, [selectedSport, sortType]);

  useEffect(() => {
    fetchOdds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchCommonName]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleChangeCommonName = (id, name, type, nameYeppSimple) => {
    dispatch(oddTypeCommonName(id, name, type, nameYeppSimple));
  };

  const handleUpdateBetTemplates = () => {
    dispatch(updateBetTemplates());
  };

  return (
    <Box className={classes.inBuildAppCard}>
      <Box className={classes.inBuildAppHeader}>
        <Box className={classes.inBuildAppHeaderSidebar}>
          <Hidden>
            <IconButton onClick={() => dispatch(toggleExpandSidebar())}>
              <Menu />
            </IconButton>
          </Hidden>
          <Typography className={classes.inBuildAppHeaderTitle} component="div" variant="h1">
            Odds
          </Typography>
        </Box>
        <Box className={classes.inBuildAppHeaderContent}>
          <CmtSearch
            placeholder="Search..."
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            border={false}
          />
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: 20, width: 'max-content' }}
            onClick={handleUpdateBetTemplates}>
            UPDATE BET TEMPLATES
          </Button>
        </Box>
      </Box>
      <Box className={clsx(classes.inBuildAppContainer, isSideBarCollapsed ? 'collapsed' : '')}>
        <Sidebar />
        <Box className={classes.inBuildAppMainContent}>
          <Box className="Cmt-table-responsive">
            <PerfectScrollbar className={classes.perfectScrollbarMatchCon}>
              {selectedSportOdds ? (
                !!selectedSportOdds.length ? (
                  <Table>
                    <TableHeading sortType={sortType} setSortType={setSortType} />
                    <TableBody>
                      {selectedSportOdds.map(odd => (
                        <OddCell
                          key={odd._id}
                          odd={odd}
                          fetchOdds={fetchOdds}
                          handleChangeCommonName={handleChangeCommonName}
                          commonNamesEvent={
                            commonNamesEvent !== undefined && commonNamesEvent.length > 0 ? commonNamesEvent : []
                          }
                          commonNames={commonNames !== undefined && commonNames.length > 0 ? commonNames : []}
                        />
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <EmptyResult />
                )
              ) : null}
            </PerfectScrollbar>
          </Box>
          <TablePagination
            component="div"
            style={{ order: '9999', width: '100%' }}
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Odds;
