import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import axios from 'services/auth/jwt/config';
import { fetchError } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectBetTemplate, selectBetTemplateType, setIndividualBetTemplates } from 'redux/actions/OfficialChampionship';
import { getAvailableSports } from 'redux/actions/Common';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '100%',
    '& input': {
      minWidth: '120px',
    },
  },
  formControlWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  formControlExpandButton: {
    color: '#b8b8b8',
    cursor: 'pointer',
    transition: '100ms',
    '&:hover': {
      color: '#303030',
    },
  },
  expandedSection: {
    marginLeft: '1.5rem',
  },
  error: {
    marginTop: '10px',
    color: 'red',
  },
  formErrors: {
    color: 'red',
    marginLeft: '1rem',
  },
  titleContainer: {
    '& h2': {
      fontSize: '18px',
    },
  },
  sportTitle: {
    marginTop: '10px',
  },
}));

const SelectModalSingleMatch = ({ open, onClose, selectedMatch }) => {
  const { challengeMatches } = useSelector(({ challenges }) => challenges);
  const { individualBetTemplates } = useSelector(({ officialChampionship }) => officialChampionship);
  let { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [betTemplates, setBetTemplates] = useState([]);
  const [betTemplatesForSelectedMatch, setBetTemplatesForSelectedMatch] = useState([]);
  const [selectedMatchBetTemplateId, setSelectedMatchBetTemplateId] = useState([]);
  const [selectedMatchesHistroy, setSelectedMatchesHistory] = useState([]);

  useEffect(() => {
    dispatch(getAvailableSports());
    axios
      .get('/bet-templates/admin')
      .then(response => {
        setBetTemplates(response.data.entities);
      })
      .catch(() => {
        dispatch(fetchError('Something went wrong'));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const betTemplatesForSelectedMatch = betTemplates?.filter(
      template => template.sportId === selectedMatch?.sportId || template.sportId === selectedMatch?.game?.sportId,
    );
    // const selectedChallenge = challengeMatches?.filter(match => match.gameId === selectedMatch._id);

    setBetTemplatesForSelectedMatch(betTemplatesForSelectedMatch);
    // if (selectedChallenge?.length > 0) setSelectedMatchBetTemplateId(selectedChallenge[0].betTemplateId);
    setSelectedMatchBetTemplateId(selectedMatch?.betTemplateId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMatch]);

  const handleClose = () => {
    onClose();
  };

  const onSubmit = () => {
    let match;
    if (selectedMatch?.game) match = selectedMatch?.game;
    else match = selectedMatch;
    if (!selectedMatchesHistroy.find(item => item.gameId === match._id)) {
      setSelectedMatchesHistory(prev => [...prev, { betTemplateId: selectedMatchBetTemplateId, gameId: match._id }]);
    } else if (
      selectedMatchesHistroy.find(item => item.gameId === match._id && item.betTemplateId !== selectedMatchBetTemplateId)
    ) {
      const foundIndex = selectedMatchesHistroy.indexOf(
        selectedMatchesHistroy.find(item => item.gameId === match._id && item.betTemplateId !== selectedMatchBetTemplateId),
      );
      selectedMatchesHistroy[foundIndex].betTemplateId = selectedMatchBetTemplateId;
      setSelectedMatchesHistory(prev => [...prev]);
    } else {
      setSelectedMatchesHistory(prev => [...prev, { betTemplateId: selectedMatchBetTemplateId, gameId: match._id }]);
    }
    onClose();
  };
  // useEffect(() => {
  //   dispatch(selectBetTemplate(selectedMatchesHistroy));
  // }, [selectedMatchesHistroy]);

  useEffect(() => {
    let match;
    if (selectedMatch?.game) match = selectedMatch?.game;
    else match = selectedMatch;
    if (selectedMatchesHistroy?.length > 0 && selectedMatchesHistroy?.find(item => item.gameId === match._id)?.betTemplateId)
      setSelectedMatchBetTemplateId(selectedMatchesHistroy?.find(item => item.gameId === match._id)?.betTemplateId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMatch]);

  const changeBetTemplateValue = e => {
    let match;
    if (selectedMatch?.game) match = selectedMatch?.game;
    else match = selectedMatch;
    setSelectedMatchBetTemplateId(e.target.value);
    individualBetTemplates[match._id] = { betTemplateId: e.target.value, gameId: match._id };
    dispatch(setIndividualBetTemplates({ ...individualBetTemplates }));
  };

  return (
    <Dialog open={open} onClose={handleClose} scroll={'paper'}>
      <DialogTitle className={classes.titleContainer}>
        {selectedMatch?.game?.headline || selectedMatch?.headline} |{' '}
        {selectedMatch?.game?.sportName || selectedMatch?.sportName} | Bet Template
      </DialogTitle>
      <DialogContent dividers={true} style={{ minWidth: '600px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {!selectedMatch ? (
            <>
              <p className={classes.error} style={{ textAlign: 'center' }}>
                Select some matche first.
              </p>
              <p className={classes.error} style={{ textAlign: 'center' }}>
                Required bet templates are derived from the sport of the selected match.
              </p>
            </>
          ) : (
            <div>
              <FormControl style={{ marginBottom: '1.5rem', marginTop: '10px' }} className={classes.formControl}>
                <InputLabel>Bet Template</InputLabel>
                <Select
                  name={selectedMatch._id}
                  defaultValue={selectedMatch.betTemplateId}
                  value={
                    id === 'new' ? individualBetTemplates[selectedMatch?._id]?.betTemplateId : selectedMatchBetTemplateId
                  }
                  onChange={changeBetTemplateValue}>
                  {betTemplatesForSelectedMatch.map(betTemplate => (
                    <MenuItem key={betTemplate._id} value={betTemplate._id}>
                      {betTemplate.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        {errors ? <span className={classes.formErrors}>{errors.betTemplate?.message}</span> : null}
        <div>
          <Button onClick={handleClose} style={{ marginLeft: '15px' }} color="primary">
            Cancel
          </Button>
          <Button
            style={{ marginLeft: '15px' }}
            type="submit"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary">
            {id === 'new' ? 'Select' : 'Update'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default SelectModalSingleMatch;
