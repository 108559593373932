import React from 'react';
import { TableRow, TableCell, Box } from '@material-ui/core';
import useStyles from '../index.style';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

const TableHeading = ({ sortType, setSortType }) => {
  const classes = useStyles();

  const sortHandler = fieldName => {
    if (sortType?.order === -1) {
      setSortType(null);
    } else if (sortType?.field === fieldName) {
      setSortType({
        field: fieldName,
        order: -1,
      });
    } else {
      setSortType({
        field: fieldName,
        order: 1,
      });
    }
  };

  return (
    <TableRow>
      <TableCell className={classes.tableCellRoot}>
        <Box display="flex" alignItems="center" onClick={() => sortHandler('name')}>
          <strong>Name</strong>
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Box display="flex" alignItems="center" onClick={() => sortHandler('country')}>
          <strong>Common name</strong>
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ cursor: 'pointer' }}>
        <Box display="flex" alignItems="center" onClick={() => sortHandler('visible')}>
          <strong>Visible</strong>{' '}
          {sortType?.field === 'visible' ? (
            sortType?.order === -1 ? (
              <ArrowDownward fontSize="small" />
            ) : (
              <ArrowUpward fontSize="small" />
            )
          ) : (
            undefined
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TableHeading;
