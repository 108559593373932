import React, { useContext } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getAppSidebarHeight } from 'constants/AppConstants';
import AppContext from 'components/contextProvider/AppContextProvider/AppContext';
import { withWidth, Box, List, ListItem } from '@material-ui/core';
import CmtList from '@coremat/CmtList';
import ItemCell from './ItemCell';
import useStyles from '../index.style';

const generatedSelection = [
  { id: 1, name: 'Admin', generatedBy: 0 },
  { id: 2, name: 'Script', generatedBy: 1 },
];

const typesSelection = [
  { id: 0, name: 'Normal', type: 0 },
  { id: 1, name: 'Team', type: 1 },
  { id: 2, name: 'Competition', type: 2 },
];

const statusSelection = [
  { id: 0, name: 'Inactive', type: 0 },
  { id: 1, name: 'Active', type: 1 },
  { id: 2, name: 'Finished', type: 2 },
];

const Sidebar = ({
  width,
  setFilterByType,
  setFilterByGeneration,
  filterByType,
  filterByGeneration,
  filterByStatus,
  setFilterByStatus,
}) => {
  const { showFooter } = useContext(AppContext);

  const onTypeSelectionChange = type => {
    const selectedType = typesSelection.find(el => el.name === type);
    setFilterByType(selectedType);
  };

  const onGeneratedSelectionChange = generatedBy => {
    const selectedGeneratedOption = generatedSelection.find(el => el.name === generatedBy);
    setFilterByGeneration(selectedGeneratedOption);
    if (generatedBy === 'Admin') {
      setFilterByType(typesSelection[0]);
    } else {
      setFilterByType(typesSelection[1]);
    }
  };

  const onStatusSelectionChange = type => {
    setFilterByStatus(type);
  };

  const classes = useStyles({
    isCollapsed: false,
    height: getAppSidebarHeight(width, showFooter),
  });

  return (
    <Box className={classes.inBuildAppSidebar}>
      <PerfectScrollbar className={classes.perfectScrollbarMatchSidebar}>
        <ListItem component="div" className={classes.appNavHeaderItem}>
          <Box component="span" className={classes.appNavHeaderItemText}>
            Filter by who
            <br />
            generated selection
          </Box>
        </ListItem>
        <List component="nav" className={classes.appNav}>
          <CmtList
            data={generatedSelection}
            renderRow={(item, index) => (
              <ItemCell
                key={index}
                item={item}
                classes={classes}
                selectedItem={filterByGeneration.name}
                onChange={() => onGeneratedSelectionChange(item.name)}
              />
            )}
          />
        </List>
        <ListItem component="div" className={classes.appNavHeaderItem}>
          <Box component="span" className={classes.appNavHeaderItemText}>
            Filter by type
          </Box>
        </ListItem>
        <List component="nav" className={classes.appNav}>
          <CmtList
            data={typesSelection}
            renderRow={(item, index) => (
              <ItemCell
                key={index}
                item={item}
                classes={classes}
                selectedItem={filterByType.name}
                disabled={
                  (item.name === 'Normal' && filterByGeneration.name === 'Script') ||
                  ((item.name === 'Team' || item.name === 'Competition') && filterByGeneration.name === 'Admin')
                    ? true
                    : false
                }
                onChange={() => onTypeSelectionChange(item.name)}
              />
            )}
          />
        </List>
        <ListItem component="div" className={classes.appNavHeaderItem}>
          <Box component="span" className={classes.appNavHeaderItemText}>
            Filter by status
          </Box>
        </ListItem>
        <List component="nav" className={classes.appNav}>
          <CmtList
            data={statusSelection}
            renderRow={(item, index) => (
              <ItemCell
                key={index}
                item={item}
                classes={classes}
                selectedItem={filterByStatus.name}
                onChange={() => onStatusSelectionChange(item)}
              />
            )}
          />
        </List>
      </PerfectScrollbar>
    </Box>
  );
};

export default withWidth()(Sidebar);
