import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import useStyles from '../index.style';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { fetchAllLanguages } from 'redux/actions/Languages';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getMatchContainerHeight } from 'constants/AppConstants';
import AppContext from 'components/contextProvider/AppContextProvider/AppContext';
import {
  addTranslation,
  createLanguage,
  deleteLanguage,
  getTranslation,
  updateLanguage,
  updateTranslation,
} from 'redux/actions/DBTranslations';

const DBTranslationsDetails = () => {
  const { showFooter } = useContext(AppContext);
  const { id } = useParams();
  const classes = useStyles({ height: getMatchContainerHeight(showFooter) + 80 });
  const dispatch = useDispatch();

  const { languages } = useSelector(({ languages }) => languages);
  const { translation } = useSelector(({ DBTranslations }) => DBTranslations);

  const [termID, setTermID] = useState('');
  const [appSection, setAppSection] = useState('');
  const [details, setDetails] = useState('');
  const [platform, setPlatfrom] = useState(translation?.termDetails?.platform || '');
  const [terms, setTerms] = useState({});
  const [languagesDropdown, setLanguagesDropdown] = useState('none');
  const [insertedLanguages, setInsertedLanguages] = useState(['en']);
  const [publishedLanguages, setPublishedLanguages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState();

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getTranslation(id));
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    dispatch(fetchAllLanguages());
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (languages?.length > 0) {
      setPublishedLanguages(languages.filter(item => item.status === 1));
    }
    // eslint-disable-next-line
  }, [languages]);

  useEffect(() => {
    if (translation?.termDetails && id !== undefined) {
      setTermID(translation.termDetails?.placeholder || '');
      setAppSection(translation.termDetails?.section || '');
      setDetails(translation.termDetails?.details || '');
      setPlatfrom(translation.termDetails?.platform || '');
      let termsFromDB = {};
      // eslint-disable-next-line
      let a = translation.translationsDetails?.forEach(item => {
        termsFromDB[item.languageCode] = {
          text: item.translation,
          id: item.languageId,
          lastUpdateByUser: item.lastUpdateByUser,
          addedByUser: item.addedByUser,
          created: item.created,
          updated: item.updated,
          status: item.status,
          translationId: item._id,
          hasBeenUpdated: 0,
        };
      });
      if (Object.keys(termsFromDB).length > 0) {
        if (Object.keys(termsFromDB).includes('en')) setInsertedLanguages(Object.keys(termsFromDB));
        else setInsertedLanguages([...Object.keys(termsFromDB), 'en']);
      } else setInsertedLanguages(['en']);
      setTerms(termsFromDB);
    } else {
      setTermID('');
      setAppSection('');
      setDetails('');
      setPlatfrom('');
      setTerms({});
      setInsertedLanguages(['en']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translation]);

  const createNotification = message => {
    return NotificationManager.error(message, 'ERROR!', 3000);
  };

  const createTermHandler = () => {
    const canBeSubmitted = validateField();
    if (canBeSubmitted) {
      const body = {
        placeholder: termID,
        platform: platform,
        section: appSection,
        details: details,
      };
      dispatch(addTranslation(body, terms));
    }
  };

  const updateTermHandler = () => {
    const canBeSubmitted = validateField();
    if (canBeSubmitted) {
      let body = {};

      if (
        termID !== translation?.termDetails?.placeholder ||
        platform !== translation?.termDetails?.platform ||
        appSection !== translation?.termDetails?.section ||
        details !== translation?.termDetails?.details
      ) {
        body['placeholder'] = termID;
        body['platform'] = platform;
        body['section'] = appSection;
        body['details'] = details;
        body['termId'] = translation.termDetails._id;
      }

      let englishLanguage = null;
      const updatedLanguages = {};
      const newLanguages = {};
      Object.keys(terms).forEach(key => {
        if (terms[key].id !== undefined && key !== 'en' && terms[key]?.created && terms[key].hasBeenUpdated === 1)
          updatedLanguages[key] = terms[key];
        if (terms[key].id !== undefined && key !== 'en' && !terms[key]?.created && terms[key].hasBeenUpdated === 1)
          newLanguages[key] = terms[key];
        if (terms[key].id !== undefined && key === 'en' && terms[key].hasBeenUpdated === 1)
          englishLanguage = { ...terms[key] };
      });

      if (englishLanguage) {
        const body = {
          translation: englishLanguage.text,
          languageId: englishLanguage.id,
          translationId: englishLanguage.translationId,
        };
        if (!englishLanguage.created) {
          dispatch(createLanguage(translation.termDetails._id, body));
        } else dispatch(updateLanguage(translation.termDetails._id, body));
      }

      if (Object.keys(updatedLanguages).length > 0) {
        Object.keys(updatedLanguages).forEach(key => {
          let body = {
            translation: updatedLanguages[key].text,
            languageId: updatedLanguages[key].id,
            translationId: updatedLanguages[key].translationId,
          };
          dispatch(updateLanguage(translation.termDetails._id, body));
        });
      }

      if (Object.keys(newLanguages).length > 0) {
        Object.keys(newLanguages).forEach(key => {
          let body = {
            translation: newLanguages[key].text,
            languageId: newLanguages[key].id,
          };
          dispatch(createLanguage(translation.termDetails._id, body));
        });
      }
      if (Object.keys(body).length > 0) {
        dispatch(updateTranslation(body));
      } else {
        setTimeout(() => {
          window.location.href = '/db-translations';
        }, 200);
      }
    }
  };

  const validateField = () => {
    let canBeSubmitted = true;
    if (!termID) {
      createNotification('Term ID should not be empty!');
      canBeSubmitted = false;
    }
    if (!appSection) {
      createNotification('App Section should not be empty!');
      canBeSubmitted = false;
    }

    if (!platform) {
      createNotification('Platform should not be empty!');
      canBeSubmitted = false;
    }

    let termsAreEmpty = false;

    Object.keys(terms).forEach(key => {
      if (!terms[key].text) termsAreEmpty = true;
    });

    if (termsAreEmpty === true || insertedLanguages.length !== Object.keys(terms).length) {
      createNotification('Please complete all the languages fields inserted!');
      canBeSubmitted = false;
    }

    return canBeSubmitted;
  };

  const termsHandler = (e, lang) => {
    terms[lang.code] = {
      ...terms[lang.code],
      id: lang._id,
      text: e.target.value,
      translationId: terms[lang.code]?.translationId,
      hasBeenUpdated: 1,
    };
    setTerms({ ...terms });
  };

  const platformHandler = e => {
    setPlatfrom(e.target.value);
  };

  const insertLanguageHandler = () => {
    if (languagesDropdown !== 'none') {
      if (insertedLanguages.includes(languagesDropdown)) {
        const filteredLanguages = insertedLanguages.filter(code => code !== languagesDropdown);
        setInsertedLanguages(filteredLanguages);
        delete terms[languagesDropdown];
        setTerms(prev => prev);
      } else {
        setInsertedLanguages(prev => [...prev, languagesDropdown]);
      }

      setLanguagesDropdown('none');
    }
  };

  const deleteLanguageHandler = lang => {
    const body = {
      translation: terms[lang.code].text,
      languageId: lang._id,
      translationId: terms[lang.code].translationId,
    };
    dispatch(deleteLanguage(translation.termDetails._id, terms[lang.code].translationId, body));
    setTimeout(() => {
      dispatch(getTranslation(id));
    }, 100);
    setModalIsOpen(false);
  };

  const validationHandler = lang => {
    const body = {
      translation: terms[lang.code].text,
      languageId: lang._id,
      translationId: terms[lang.code].translationId,
    };
    dispatch(updateLanguage(translation.termDetails._id, body));
    dispatch(getTranslation(id));
  };

  const modalHandler = item => {
    setItemToDelete(item);
    setModalIsOpen(prev => !prev);
  };

  return (
    <PerfectScrollbar className={classes.perfectScrollbarMatchCon}>
      <Box pr={2}>
        <Box>
          <Box className={classes.row}>
            <TextField
              id="outlined-basic"
              onChange={e => setTermID(e.target.value)}
              value={termID}
              className={classes.textFieldHead}
              label="Term ID"
              variant="outlined"
              required
            />
            <TextField
              id="outlined-basic"
              onChange={e => setAppSection(e.target.value)}
              value={appSection}
              className={classes.textFieldHead}
              label="App Section"
              variant="outlined"
              required
            />
          </Box>

          <Box>
            <TextField
              id="outlined-basic"
              onChange={e => setDetails(e.target.value)}
              multiline={true}
              value={details}
              className={classes.textFieldHead}
              label="Details"
              variant="outlined"
            />
          </Box>
          <Box style={{ marginLeft: '7.5px', marginBottom: '30px', maxWidth: '400px' }}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Platform</InputLabel>
              <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={platformHandler} value={platform}>
                <MenuItem value="mobile">Mobile</MenuItem>
                <MenuItem value="back-end">Back-end</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {id !== undefined && translation?.termDetails && (
            <Box style={{ marginLeft: '7.5px' }}>
              <h2>About</h2>
              <p>
                Created at {moment(translation.termDetails?.created).format('DD.MM.YYYY, HH:mm')} by{' '}
                {translation?.termDetails?.addedByUser?.fullname}, last updated at{' '}
                {moment(translation?.termDetails?.updated).format('DD.MM.YYYY, HH:mm')} by{' '}
                {translation?.termDetails?.lastUpdateByUser?.fullname}.
              </p>
            </Box>
          )}

          <Box
            style={{
              marginLeft: '7.5px',
              marginBottom: '50px',
              marginTop: '40px',
              maxWidth: '400px',
              display: 'flex',
              gap: '20px',
              flexWrap: 'wrap',
            }}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Languages</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={e => setLanguagesDropdown(e.target.value)}
                value={languagesDropdown}>
                <MenuItem value="none">Choose</MenuItem>
                {publishedLanguages.map(item => {
                  return (
                    <MenuItem key={item.code} value={item.code} disabled={item.code === 'en' || terms[item.code]?.created}>
                      {`${item.code.toUpperCase()} - ${item.name}`} {item.status === 0 ? '(Unpublished)' : ''}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={insertLanguageHandler}>
              {!insertedLanguages.includes(languagesDropdown) ? 'ADD' : 'REMOVE'}
            </Button>
          </Box>
          <Box style={{ marginLeft: '7.5px', marginTop: '20px' }}>
            <h2>Languages</h2>
          </Box>
          <Box className={classes.grid}>
            {publishedLanguages.map(item => {
              return (
                insertedLanguages.includes(item.code) && (
                  <Box key={item._id}>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        id="outlined-basic"
                        onChange={e => termsHandler(e, item)}
                        multiline={true}
                        value={terms[item.code]?.text || ''}
                        className={classes.textFieldHead}
                        label={`${item.name} - ${item.code.toUpperCase()} ${
                          item.status === 0 ? '(Unpublished language)' : ''
                        }`}
                        variant="outlined"
                      />
                      {terms[item.code]?.updated ? (
                        <ButtonGroup color="secondary" aria-label="contained primary button group">
                          <Button
                            variant="contained"
                            disabled={item.code === 'en' || terms[item.code]?.status === 1}
                            onClick={() => validationHandler(item)}>
                            Validate
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ paddingTop: '14px', paddingBottom: '14px' }}
                            onClick={() => modalHandler(item)}
                            disabled={item.code === 'en'}>
                            DELETE
                          </Button>
                        </ButtonGroup>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ paddingTop: '14px', paddingBottom: '14px' }}
                          onClick={() => {
                            delete terms[item.code];
                            setTerms(prev => prev);
                            setInsertedLanguages(prev => prev.filter(code => code !== item.code));
                          }}
                          disabled={item.code === 'en'}>
                          REMOVE
                        </Button>
                      )}
                    </Box>
                    {id !== undefined && terms && (
                      <>
                        {terms[item.code]?.status === 0 && terms[item.code].text && (
                          <p style={{ marginLeft: '7.5px', marginTop: '-5px', color: 'red' }}>Status: Needs update</p>
                        )}
                        {terms[item.code]?.text && terms[item.code]?.updated && (
                          <p style={{ marginLeft: '7.5px', marginTop: '-5px' }}>
                            {`Added at ${moment(terms[item.code]?.created).format('DD.MM.YYYY, HH:mm')} by ${terms[item.code]
                              ?.addedByUser?.fullname || 'undefined name'}, Last updated at ${moment(
                              terms[item.code]?.updated,
                            ).format('DD.MM.YYYY, HH:mm')} by ${terms[item.code]?.lastUpdateByUser?.fullname ||
                              'undefined name'}.`}
                          </p>
                        )}
                      </>
                    )}
                  </Box>
                )
              );
            })}
          </Box>
        </Box>
        {id && id !== 'new' ? (
          <Button style={{ margin: '25px 0 ' }} variant="contained" color="primary" onClick={updateTermHandler}>
            Update Translation
          </Button>
        ) : (
          <Button style={{ margin: '25px 0 ' }} variant="contained" color="primary" onClick={createTermHandler}>
            Create Translation
          </Button>
        )}
        <NotificationContainer />
      </Box>
      <Dialog
        open={modalIsOpen}
        onClose={modalHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All your unsaved changes will be lost. Do you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={modalHandler} color="primary">
            Cancel
          </Button>
          <Button onClick={() => deleteLanguageHandler(itemToDelete)} color="primary" variant="contained" autoFocus>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
    </PerfectScrollbar>
  );
};

export default DBTranslationsDetails;
