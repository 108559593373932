import React from 'react';
import { Box } from '@material-ui/core';
import useStyles from './index.style';
import FanClubCell from './FanClubCell';

const ListView = ({ fanClubList }) => {
  const classes = useStyles();
  return (
    <Box className={classes.fanClubsListContainer}>
      {fanClubList !== undefined &&
        fanClubList.map((item, id) => {
          return <FanClubCell funClubData={item} key={id} />;
        })}
    </Box>
  );
};

export default ListView;
