import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, Box } from '@material-ui/core';
import TableHeading from './TableHeading';
import TableItem from './TableItem';

const RecentTable = ({ tableData }) => {
  const [isMotot, setIsMotot] = useState('');

  useEffect(() => {
    setIsMotot(tableData?.[0]?.sportId);
  }, [tableData]);
  return (
    <Box className="Cmt-table-responsive">
      <Table>
        <TableHead>
          <TableHeading sport={isMotot} />
        </TableHead>
        <TableBody>
          {tableData !== undefined && tableData.length > 0
            ? tableData.map(row => <TableItem row={row} key={row?._id} />)
            : null}
        </TableBody>
      </Table>
    </Box>
  );
};

export default RecentTable;
