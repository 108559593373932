import { UPDATE_AUTH_USER, UPDATE_LOAD_USER } from '../../constants/ActionTypes';

const INIT_STATE = {
  authUser: localStorage.getItem('login') ? JSON.parse(localStorage.getItem('login')) : null,
  loadUser: false,
  isB2B: localStorage.getItem('isB2B') ? JSON.parse(localStorage.getItem('isB2B')) : false,
  b2bExternalType: localStorage.getItem('b2bExternalType') ? JSON.parse(localStorage.getItem('b2bExternalType')) : null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH_USER: {
      return {
        ...state,
        authUser: action.payload,
        loadUser: true,
      };
    }
    case UPDATE_LOAD_USER: {
      return {
        ...state,
        loadUser: action.payload,
      };
    }
    case 'UPDATE_B2B_STATUS': {
      return {
        ...state,
        isB2B: action.payload.isB2B,
        b2bExternalType: action.payload.b2bExternalType,
      };
    }
    default:
      return state;
  }
};
