import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CountersSection from './Counters/CountersSection';
import UsersSection from './Users/UsersSection';
import GamesSection from './Games/GamesSection';
import { makeStyles } from '@material-ui/core/styles';
import { fetchAnalyticsCounters, fetchAnalyticsUsers, fetchGames } from '../../../redux/actions/Analytics';

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(7),
  },
}));

const DEFAULT_FILTERS = {
  externalType: null,
  startDate: null,
  endDate: null,
};

const useAnalyticsData = () => {
  const dispatch = useDispatch();
  const { error, counters, users, games } = useSelector(state => state.analytics);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchAnalyticsCounters(DEFAULT_FILTERS));
        await dispatch(fetchAnalyticsUsers(DEFAULT_FILTERS));
        await dispatch(fetchGames({ startDate: null, endDate: null }));
      } catch (err) {
        console.error('Failed to fetch analytics data:', err);
      }
    };
    fetchData();
  }, [dispatch]);

  return { error, counters, users, games };
};

const Analytics = () => {
  const classes = useStyles();
  const { error, counters, users, games } = useAnalyticsData();

  const topRef = useRef(null);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box p={3}>
        <div ref={topRef}></div>
        <Typography variant="h1" gutterBottom className={classes.title}>
          Analytics
        </Typography>
        <React.Fragment>
          <CountersSection counters={counters} />
          <UsersSection users={users} />
          <GamesSection games={games} />
        </React.Fragment>
        {error && <Typography color="error">{String(error)}</Typography>}
      </Box>
    </MuiPickersUtilsProvider>
  );
};

export default Analytics;
