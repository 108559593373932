import React, { useEffect, useState } from 'react';
import { Button, TableRow, TableCell } from '@material-ui/core';

import useStyles from '../index.style';

const Players = ({ matchEvent, deleteEvent }) => {
  const classes = useStyles();
  const [event, setEvent] = useState(matchEvent || {});

  const handleDelete = () => {
    deleteEvent(event._id);
  };
  useEffect(() => {
    setEvent(matchEvent);
  }, [matchEvent]);

  return (
    (event !== undefined) > 0 && (
      <TableRow className={classes.tableRowRoot}>
        <TableCell>
          <span className={classes.mobileOnly}>Team:</span> {event.teamName}
        </TableCell>
        <TableCell>
          <span className={classes.mobileOnly}>Player:</span> {event.playerName}
        </TableCell>
        <TableCell>
          <span className={classes.mobileOnly}>Type:</span> {event.type}
        </TableCell>
        <TableCell>
          <span className={classes.mobileOnly}>Time:</span> {event.matchTime}
        </TableCell>
        <TableCell>
          <span className={classes.mobileOnly}>Extra Time:</span> {event.extraTime === null ? '0' : event.extraTime}
        </TableCell>
        <TableCell>
          <Button variant="contained" color="primary" onClick={handleDelete}>
            Remove
          </Button>
        </TableCell>
      </TableRow>
    )
  );
};

export default Players;
