import { CREATE_NEW_RULE, GET_ALL_RULES, EDIT_RULE_STATUS } from '../../constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';

export const getAllRules = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/alert-rules')
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_ALL_RULES, payload: data.data });
      })
      .catch(error => {});
  };
};

export const addNewRule = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/alert-rules', body)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch(getAllRules());
        dispatch({ type: CREATE_NEW_RULE, payload: data.data });
        dispatch(fetchError('New rule created'));
      })
      .catch(error => {});
  };
};

export const changeRuleStatus = (id, body) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/alert-rules/' + id + '/change-status', body)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch(getAllRules());
        dispatch({ type: EDIT_RULE_STATUS, payload: data.data });
        dispatch(fetchError('Rule status changed'));
      })
      .catch(error => {});
  };
};
