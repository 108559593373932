import React from 'react';
import { Box, Button } from '@material-ui/core';
import useStyles from './index.style';

import { useHistory } from 'react-router-dom';

const FanClubCell = ({ funClubData }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Box className={classes.fanClubsCell}>
      <Box className={classes.fanClubsHead}>
        <h2>{funClubData.name}</h2>
        <Box className={classes.fanClubsActions}>
          <Button
            style={{ margin: '15px' }}
            aria-label="add selection"
            onClick={() => {
              history.push('/fan-club/' + funClubData._id);
            }}
            variant="contained"
            color="primary">
            Add Selection
          </Button>
        </Box>
      </Box>
      <Box className={classes.fanClubsBody}>
        <Box className={classes.fanClubsDate}>
          <span className={classes.label}>
            Top Memebers: <strong>{funClubData.topMembers.length}</strong>
          </span>
        </Box>
      </Box>
    </Box>
  );
};

export default FanClubCell;
