import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRules, addNewRule, changeRuleStatus } from '../../../redux/actions/Rules';
import { fetchAllLanguages } from '../../../redux/actions/Languages';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import RuleDetail from './RuleDetail';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const Languages = () => {
  const dispatch = useDispatch();
  const { ruleList, createRuleResponse } = useSelector(({ rules }) => rules);
  const { languages } = useSelector(({ languages }) => languages);
  const [rules, setRules] = useState([]);
  const [langList, setLangList] = useState([]);
  const [ruleType, setRuleType] = useState(1);

  const [ruleName, setRuleName] = useState('');
  const [periodOfTime, setPeriodOfTime] = useState(2);
  const [push, setPush] = useState(true);
  const [email, setEmail] = useState(true);
  const [emailContent, setEmailContent] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const rulesTypeName = [
    'REGISTERED_AND_NO_BET_PLACED',
    'NO_BET_SINCE_N_DAYS',
    'CHALLENGE_AND_NO_BET_PLACED',
    'FAVOURITE_CLUB_PLAYING',
    'NO_FRIENDS_INVITED_YET',
  ];

  const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(4),
    },
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        minWidth: '400px',
      },
    },
    popupWrapper: {
      top: 0,
      left: 0,
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,0.3)',
    },
    popup: {
      position: 'absolute',
      top: '100px',
      left: '50%',
      maxWidth: '80%',
      maxHeight: 'calc(100vh - 150px)',
      width: '100%',
      background: '#fff',
      transform: 'translateX(-50%)',
      padding: '30px 0 30px 30px',
      borderRadius: '4px',
      display: 'flex',
    },
    fakeBorder: {
      width: '150px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      padding: '4px 10px',
      borderRadius: '4px',
      marginRight: '10px',
      position: 'relative',
      height: '58px',
      '@media screen and (max-width: 768px)': {
        marginTop: '10px',
      },
    },
    label: {
      color: 'rgba(0, 0, 0, 0.6)',
      width: 'auto',
      display: 'inline-block',
      padding: '3px',
      fontSize: '0.75rem',
      textAlign: 'left',
      visibility: 'visible',
      position: 'absolute',
      background: '#fff',
      zIndex: '99',
      top: '-11px',
      left: '10px',
    },
    scrollDivWrapper: {
      position: 'relative',
      width: '100%',
    },
    scrollDiv: {
      paddingRight: '30px',
      maxHeight: '85%',
      display: 'block',
      overflowY: 'scroll',
      paddingBottom: '50px',
      marginBottom: '20px',
      '& .cke_button__image, .cke_button__anchor': {
        display: 'none !important',
      },
      '@media screen and (max-width: 768px)': {
        maxHeight: '75%',
      },
    },
    ruleList: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    ruleItem: {
      maxWidth: '50%',
      width: '100%',
    },
    ruleContainer: {
      '@media screen and (max-width: 768px)': {
        flexDirection: 'column',
        alignItems: 'flex-start !important',
      },
    },
    ruleMargin: {
      margin: theme.spacing(2),
      minWidth: 120,
      '@media screen and (max-width: 768px)': {
        marginTop: '10px',
      },
    },
  }));
  const classes = useStyles();

  const toggleCreate = () => {
    openModal === true ? (document.body.style.overflow = '') : (document.body.style.overflow = 'hidden');
    setOpenModal(!openModal);
  };
  const createNotification = (type, message) => {
    return NotificationManager.error(message, 'ERROR!', 3000, () => {});
  };

  const createRule = () => {
    let validRuleName = true;
    let validEmailContent = true;
    let validPeriod = true;
    if (periodOfTime <= 1) {
      validPeriod = false;
      createNotification('error', 'Period of time should be bigger than 1 day');
    }
    if (ruleName.length === 0) {
      validRuleName = false;
      createNotification('error', 'Rule name field is mandatory');
    }

    emailContent.forEach((item, key) => {
      if (item.content.length === 0) {
        validEmailContent = false;
      }
    });

    if (validEmailContent === false) {
      createNotification('error', 'All rule content fields are mandatory');
      return false;
    }

    if (validRuleName === true && validEmailContent === true && validPeriod === true) {
      let body = {
        name: ruleName,
        periodOfTime: parseInt(periodOfTime),
        ruleType: ruleType,
        timeInAdvance: 0,
        sendOn: {
          pushNotification: push,
          email: email,
        },
        emailContent: emailContent,
      };
      dispatch(addNewRule(body));
    }
  };

  useEffect(() => {
    dispatch(getAllRules());
    dispatch(fetchAllLanguages());
  }, [dispatch]);

  useEffect(() => {
    setRules(ruleList);
  }, [ruleList]);

  useEffect(() => {
    setOpenModal(false);
    document.body.style.overflow = '';
  }, [createRuleResponse]);
  useEffect(() => {
    let langStatus = [];
    let emailContentStructure = [];
    languages.forEach((lang, key) => {
      if (lang.status === 1) {
        if (lang.code === 'en') {
          langStatus.push('gb');
          emailContentStructure.push({
            language: 'gb',
            content: '',
          });
        } else {
          langStatus.push(lang.code);
          emailContentStructure.push({
            language: lang.code,
            content: '',
          });
        }
      }
    });
    setLangList(langStatus);
    setEmailContent(emailContentStructure);
  }, [languages]);

  const changeStatus = (id, status) => {
    let body;
    if (status === 0) {
      body = 1;
    } else {
      body = 0;
    }
    dispatch(changeRuleStatus(id, { status: body }));
  };

  return (
    <Box component="div">
      <Box>
        <Button variant="contained" color="primary" style={{ margin: '15px' }} onClick={toggleCreate}>
          Create New Rule
        </Button>
      </Box>
      <Box className={classes.ruleList}>
        {rules.map((item, key) => {
          return <RuleDetail changeStatus={changeStatus} className={classes.ruleItem} key={key} rule={item} />;
        })}
      </Box>
      <NotificationContainer />
      {openModal === true && (
        <Box className={classes.popupWrapper}>
          <Box className={classes.popup}>
            <Box className={classes.scrollDivWrapper}>
              <h2 style={{ marginBottom: '20px' }}>Add new rule</h2>
              <Box className={classes.scrollDiv}>
                <Box>
                  <TextField
                    id="outlined-basic"
                    style={{ maxWidth: '300px', width: '100%' }}
                    onChange={e => {
                      setRuleName(e.target.value);
                    }}
                    label="Rule Name"
                    variant="outlined"
                  />
                </Box>
                <Box
                  className={classes.ruleContainer}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '20px',
                  }}>
                  <TextField
                    id="outlined-basic"
                    style={{ maxWidth: '170px' }}
                    onChange={e => {
                      setPeriodOfTime(e.target.value);
                    }}
                    value={periodOfTime}
                    type="number"
                    label="Period of Time (days)"
                    variant="outlined"
                  />
                  <FormControl variant="outlined" className={classes.ruleMargin}>
                    <InputLabel id="demo-simple-select-outlined-label">Rule Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={ruleType}
                      onChange={e => setRuleType(e.target.value)}
                      label="Platform">
                      {rulesTypeName.map((platformKey, index) => {
                        return (
                          <MenuItem key={index} value={index}>
                            {platformKey}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Box className={classes.fakeBorder}>
                    <Box className={classes.label}>Push Notification</Box>
                    <p>
                      {' '}
                      <Switch
                        checked={push}
                        onChange={e => {
                          setPush(!push);
                        }}
                        name="checkedA"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    </p>
                  </Box>
                  <Box className={classes.fakeBorder}>
                    <Box className={classes.label}>Send Email</Box>
                    <p>
                      {' '}
                      <Switch
                        checked={email}
                        onChange={e => {
                          setEmail(!email);
                        }}
                        name="checkedA"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    </p>
                  </Box>
                </Box>
                <Box>
                  {langList.map((lang, key) => {
                    return (
                      <Box key={key}>
                        <h3 style={{ margin: '20px 0 10px 0' }}>Rule content for {lang}</h3>
                        <TextareaAutosize
                          aria-label="empty textarea"
                          style={{
                            minHeight: '150px',
                            width: '100%',
                            padding: '15px',
                          }}
                          placeholder="Empty"
                          onChange={e => {
                            let newContent = {};
                            emailContent.forEach((item, keys) => {
                              if (item.language === lang) {
                                newContent = {
                                  language: lang,
                                  content: e.target.value,
                                };
                              }
                            });
                            setEmailContent(emailContent, (emailContent[key] = newContent));
                          }}
                        />
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Button variant="contained" color="primary" style={{ marginRight: '20px' }} onClick={createRule}>
                Create
              </Button>
              <Button variant="contained" color="primary" onClick={toggleCreate}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Languages;
