import React from 'react';
import { useDispatch } from 'react-redux';
import { changePasswordAdmin } from 'redux/actions/Users';
import { Button, Box, makeStyles, TextField, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      minWidth: '400px',
      maxWidth: '400px',
      '@media screen and (max-width: 480px)': {
        maxWidth: '300px',
        minWidth: '300px',
      },
    },
  },
  inBuildAppCard: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    margin: '15px',
    padding: '25px',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)',
    position: 'relative',
    overflow: 'hidden',
    maxWidth: '460px',
    '@media screen and (max-width: 480px)': {
      maxWidth: '360px',
    },
  },
}));

const ChangePassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [repeatNewPassword, setRepeatNewPassword] = React.useState('');
  const [error, setError] = React.useState(false);
  const [matchPassError, setMatchPassError] = React.useState(false);
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showRepeatNewPassword, setShowRepeatNewPassword] = React.useState(false);

  const handleOldPassword = e => {
    setOldPassword(e.target.value);
  };

  const handleNewPassword = e => {
    const value = e.target.value;
    const passwordValid = value.match('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$') !== null;

    setNewPassword(value);
    setError(!passwordValid);

    if (repeatNewPassword) {
      setMatchPassError(value !== repeatNewPassword);
    }
  };

  const handleRepeatNewPassword = e => {
    const value = e.target.value;
    setRepeatNewPassword(value);
    setMatchPassError(value !== newPassword);
  };

  const toggleShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowRepeatNewPassword = () => {
    setShowRepeatNewPassword(!showRepeatNewPassword);
  };

  const submitChangePassword = () => {
    if (!error && !matchPassError && oldPassword && newPassword === repeatNewPassword) {
      dispatch(
        changePasswordAdmin({
          oldPassword: oldPassword,
          password: newPassword,
        }),
      );
    }
  };

  const isSubmitDisabled = !oldPassword || !newPassword || !repeatNewPassword || error || matchPassError;

  return (
    <Box className={classes.root}>
      <Box className={classes.inBuildAppCard}>
        <Box style={{ marginBottom: '20px' }}>
          <h2>Change Password</h2>
        </Box>
        <Box>
          <TextField
            id="old-password"
            style={{ maxWidth: '300px', width: '100%' }}
            onChange={handleOldPassword}
            label="Old Password"
            variant="outlined"
            type={showOldPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleShowOldPassword} edge="end">
                    {showOldPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <TextField
            id="new-password"
            style={{ maxWidth: '300px', width: '100%' }}
            onChange={handleNewPassword}
            label="New Password"
            variant="outlined"
            type={showNewPassword ? 'text' : 'password'}
            error={error}
            helperText={error ? 'At least 8 characters, one uppercase, one lowercase and one number' : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleShowNewPassword} edge="end">
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <TextField
            id="repeat-new-password"
            style={{ maxWidth: '300px', width: '100%' }}
            onChange={handleRepeatNewPassword}
            label="Confirm New Password"
            variant="outlined"
            type={showRepeatNewPassword ? 'text' : 'password'}
            error={matchPassError}
            helperText={matchPassError ? 'New password and confirm password do not match' : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleShowRepeatNewPassword} edge="end">
                    {showRepeatNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            style={{ maxHeight: '40px', marginTop: '10px', marginLeft: '5px' }}
            disabled={isSubmitDisabled}
            onClick={submitChangePassword}>
            Change Password
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;
