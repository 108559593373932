import { GET_POLLS, GET_POLL } from 'constants/ActionTypes';

import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';

export const addPoll = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/user-polls', body)
      .then(() => {
        dispatch(fetchSuccess());
        dispatch(fetchError('POLL CREATED'));
        window.location.href = '/polls';
      })
      .catch(error => {
        if (error.response?.status === 400) {
          dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
        } else if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getPolls = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/user-polls/admin')
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_POLLS, payload: data.data });
      })
      .catch(error => {
        if (error.response?.status === 400) {
          dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
        } else if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getPoll = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`/user-polls/admin/${id}`)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_POLL, payload: data.data });
      })
      .catch(error => {
        if (error.response?.status === 400) {
          dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
        } else if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const updatePoll = (id, body) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .patch(`/user-polls/admin/${id}`, body)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch(fetchError('POLL UPDATED'));
        setTimeout(() => {
          window.location.href = '/polls';
        }, 200);
      })
      .catch(error => {
        if (error.response?.status === 400) {
          dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
        } else if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};
