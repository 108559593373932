import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { getMatchContainerHeight } from 'constants/AppConstants';
import AppContext from 'components/contextProvider/AppContextProvider/AppContext';
import CmtGridView from '@coremat/CmtGridView';
import MatchCell from './MatchCell';
import { Box, TablePagination } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useStyles from '../../index.style';

const ListGridView = ({ width, onShowMatchDetail, onClickEditMatch, matchesData, pagination, setPagination }) => {
  const { showFooter } = useContext(AppContext);
  const classes = useStyles({
    height: getMatchContainerHeight(width, showFooter) + 80,
  });

  const handleChangePage = (event, newPage) => {
    setPagination(prev => {
      return {
        ...prev,
        page: newPage,
      };
    });
  };

  const handleChangeRowsPerPage = event => {
    setPagination({
      page: 0,
      rowsPerPage: event.target.value,
    });
  };

  return (
    <Box px={6} py={{ xs: 8, xl: 10 }} style={{ paddingBottom: '0px' }}>
      <PerfectScrollbar className={classes.perfectScrollbarMatchCon}>
        <CmtGridView
          itemPadding={18}
          responsive={{
            xs: 1,
            sm: 1,
            md: 2,
            lg: 2,
            xl: 3,
          }}
          data={matchesData.matches}
          renderRow={(item, index) => (
            <MatchCell key={index} match={item} onShowMatchDetail={onShowMatchDetail} onClickEditMatch={onClickEditMatch} />
          )}
        />
      </PerfectScrollbar>
      <TablePagination
        component="div"
        style={{ order: '9999', width: '100%' }}
        count={matchesData?.count || 0}
        page={pagination.page}
        onPageChange={handleChangePage}
        rowsPerPage={pagination.rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default ListGridView;

ListGridView.prototype = {
  onShowMatchDetail: PropTypes.func,
  onClickEditMatch: PropTypes.func,
};
