import {
  ADD_LABEL,
  CREATE_MATCH,
  DELETE_MATCH,
  DELETE_LABEL_ITEM,
  GET_MATCH_COUNTS,
  GET_MATCHES_LIST,
  GET_LABELS_LIST,
  SET_CURRENT_MATCH,
  SET_FILTER_TYPE,
  TOGGLE_SIDEBAR_COLLAPSED,
  UPDATE_MATCH,
  UPDATE_MATCH_LABEL,
  UPDATE_LABEL_ITEM,
  UPDATE_STARRED_STATUS,
  SET_TOP_TEAM,
  SELECT_SPORT,
  GET_OVERDUE_CANCELED_MATCHES,
  SET_SORT_TYPE,
} from 'constants/ActionTypes';

const INIT_STATE = {
  isSideBarCollapsed: false,
  labelsList: [],
  filterType: {
    status: 'future',
    competition: '',
    query: '',
  },
  sortType: {
    order: 1,
    field: 'date',
  },
  matchesList: [],
  currentMatch: null,
  totalMatches: null,
  counter: null,
  selectedSport: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR_COLLAPSED: {
      return {
        ...state,
        isSideBarCollapsed: action.payload ? action.payload : !state.isSideBarCollapsed,
      };
    }

    case SELECT_SPORT: {
      return {
        ...state,
        selectedSport: action.payload,
      };
    }

    case SET_FILTER_TYPE: {
      return {
        ...state,
        filterType: action.payload,
      };
    }

    case SET_SORT_TYPE: {
      return {
        ...state,
        sortType: action.payload,
      };
    }

    case SET_TOP_TEAM: {
      return {
        ...state,
        topTeam: action.payload,
      };
    }

    case GET_LABELS_LIST: {
      return { ...state, labelsList: action.payload };
    }

    case GET_OVERDUE_CANCELED_MATCHES: {
      return { ...state, overdueCanceledMatches: action.payload };
    }

    case ADD_LABEL: {
      return {
        ...state,
        labelsList: state.labelsList.concat(action.payload),
      };
    }

    case UPDATE_LABEL_ITEM: {
      return {
        ...state,
        labelsList: state.labelsList.map(item => (item.id === action.payload.id ? action.payload : item)),
      };
    }

    case DELETE_LABEL_ITEM: {
      return {
        ...state,
        labelsList: state.labelsList.filter(item => item.id !== action.payload),
      };
    }

    case GET_MATCHES_LIST: {
      return {
        ...state,
        matchesList: action.payload.folderMatches,
        totalMatches: action.payload.total,
      };
    }

    case SET_CURRENT_MATCH: {
      return {
        ...state,
        currentMatch: action.payload,
      };
    }

    case CREATE_MATCH: {
      let updatedList = state.matchesList;
      let updatedCount = state.totalMatches;
      if (state.filterType.status === action.payload.folder) {
        updatedList = [action.payload, ...updatedList];
        updatedCount = updatedCount + 1;
      }
      return {
        ...state,
        matchesList: updatedList,
        totalMatches: updatedCount,
      };
    }

    case UPDATE_MATCH: {
      return {
        ...state,
        matchesList: state.matchesList.map(item => (item.id === action.payload.id ? action.payload : item)),
      };
    }

    case UPDATE_STARRED_STATUS: {
      const { matchIds, status } = action.payload;
      let updatedList = state.matchesList.map(match => {
        if (matchIds.includes(match.id)) {
          match.starred = status;
          return match;
        }
        return match;
      });
      if (!status && state.filterType.status === 'starred') {
        updatedList = updatedList.filter(item => !matchIds.includes(item.id));
      }
      return {
        ...state,
        matchesList: updatedList,
      };
    }

    case DELETE_MATCH: {
      const updatedList = state.matchesList.filter(match => !action.payload.includes(match.id));
      const updatedCount = state.totalMatches - action.payload.length;
      return {
        ...state,
        matchesList: updatedList,
        totalMatches: updatedCount,
      };
    }

    case UPDATE_MATCH_LABEL: {
      let matchIds = action.payload.map(match => match.id);
      const updatedList = state.matchesList.map(mail => {
        if (matchIds.includes(mail.id)) {
          return action.payload.find(selectedMatch => selectedMatch.id === mail.id);
        } else {
          return mail;
        }
      });
      return {
        ...state,
        matchesList: updatedList,
      };
    }

    case GET_MATCH_COUNTS: {
      return {
        ...state,
        counter: action.payload,
      };
    }

    default:
      return state;
  }
};
