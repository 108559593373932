import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tableCellRoot: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 0,
    paddingBottom: 12,
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      textAlign: 'right',
      paddingRight: 44,
    },
  },
}));

const TableHeading = () => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell className={classes.tableCellRoot} style={{ cursor: 'pointer' }}>
        <Box style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
          <strong style={{ marginRight: '15px' }}>Name</strong>
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ cursor: 'pointer' }}>
        <Box style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
          <strong style={{ marginRight: '15px' }}>Start/End Date</strong>
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ cursor: 'pointer' }}>
        <Box style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
          <strong style={{ marginRight: '15px' }}>Matches</strong>
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Box style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
          <strong style={{ marginRight: '15px' }}>Invitations</strong>
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Box style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
          <strong style={{ marginRight: '15px' }}>Stake</strong>
        </Box>
      </TableCell>

      <TableCell className={classes.tableCellRoot}>
        <Box style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
          <strong style={{ marginRight: '15px' }}>Total Winning</strong>
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Box style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }} />
      </TableCell>
    </TableRow>
  );
};

export default TableHeading;
