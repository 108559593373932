import React, { useState } from 'react';
import { Box, Button, TablePagination, FormControl, InputLabel, Input } from '@material-ui/core';
import CmtAvatar from '@coremat/CmtAvatar';

import SearchIcon from '@material-ui/icons/Search';

import useStyles from './../index.style';

const CountrySearch = props => {
  const [value, setValue] = useState(props.value);
  const submitVal = () => {
    if (value && value.length > 0) {
      props.submit(value);
    } else {
      props.submit('');
    }
  };
  return (
    <FormControl style={{ width: '100%', display: 'block' }}>
      <Box style={{ display: 'inline-block' }}>
        <InputLabel htmlFor="input-with-icon-adornment">Search for Countries</InputLabel>
        <Input
          style={{ marginRight: '20px' }}
          id="input-with-icon-adornment"
          defaultValue={value}
          onChange={e => {
            setValue(e.target.value);
          }}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              submitVal();
            }
          }}
        />
      </Box>
      <Button variant="contained" color="primary" onClick={submitVal} startIcon={<SearchIcon />}>
        Search
      </Button>
    </FormControl>
  );
};

const SelectCountries = ({ country, addFunction, selectedCountries, existingCountries, countriesToCheck }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(country.length);
  const [countryFull, setCountryFull] = useState(country);
  const [countryPagination, setCountryPagination] = useState(countryFull.slice(0, 30));

  const rowsPerPage = 30;

  const handleChangePage = (event, newPage) => {
    setCountryFull(countryFull);
    setCountryPagination(countryFull.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage) || {});
    setPage(newPage);
  };

  const submit = valueSearch => {
    const searchList = [];
    if (valueSearch.length > 0) {
      country.forEach((value, key) => {
        if (valueSearch && value.name.toLowerCase().includes(valueSearch.toLowerCase())) {
          searchList.push(value);
        } else if (valueSearch && value.code.toLowerCase().includes(valueSearch.toLowerCase())) {
          searchList.push(value);
        }
      });
      setCountryFull(searchList);
      setCount(searchList.length);
      setPage(0);
      setCountryPagination(searchList.slice(0, rowsPerPage));
    } else {
      setCountryFull(country);
      setCount(country.length);
      setPage(0);
      setCountryPagination(countryFull.slice(0, rowsPerPage));
    }
  };

  const addHandler = newCountry => {
    const existingCountry = selectedCountries?.filter(country => country.code === newCountry.code);
    if (existingCountry?.length > 0) {
      const filteredCountries = selectedCountries?.filter(country => country.code !== newCountry.code);
      addFunction(filteredCountries);
    } else {
      addFunction(prev => [...prev, newCountry]);
    }
  };

  return (
    <Box className={classes.selectListCountry}>
      <Box style={{ marginBottom: '20px' }}>
        <CountrySearch classes={classes} submit={submit} />
      </Box>
      <Box className={classes.selectListCountryContainer}>
        {countryPagination.map((country, id) => {
          return (
            <Box key={id} className={classes.selectListCountriesRow}>
              <Box>
                <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
                  <Box
                    mr={4}
                    style={{
                      width: '30px',
                      overflow: 'hidden',
                      marginRight: '0',
                      borderRight: '1px solid #fff',
                    }}>
                    <CmtAvatar
                      size={35}
                      src={'http://www.geognos.com/api/en/countries/flag/' + country.code + '.png'}
                      alt={country.code}
                    />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box>
                  <h4 style={{ fontWeight: 'bold' }}>{country.name}</h4>
                </Box>
              </Box>
              <Button
                style={{ marginLeft: 'auto' }}
                variant="contained"
                color="primary"
                onClick={() => addHandler(country)}
                disabled={
                  existingCountries?.filter(item => item.code === country.code)?.length > 0 ||
                  countriesToCheck?.filter(item => item.code === country.code)?.length > 0
                }>
                {selectedCountries?.filter(item => item.code === country.code).length > 0 ? 'REMOVE' : 'ADD'}
              </Button>
            </Box>
          );
        })}
        <TablePagination
          component="div"
          style={{ order: '9999', width: '100%' }}
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[30]}
          rowsPerPage={rowsPerPage}
          // onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default SelectCountries;
