import React, { useEffect, useState } from 'react';
import PlayerDetail from './EventDetail';

const Event = ({ eventsList, deleteEvent }) => {
  const [events, setEvents] = useState(eventsList || {});

  useEffect(() => {
    setEvents(eventsList);
  }, [eventsList]);

  return (
    events !== undefined &&
    events.length > 0 &&
    events.map(events => {
      return <PlayerDetail key={events._id} matchEvent={events} deleteEvent={deleteEvent} />;
    })
  );
};

export default Event;
