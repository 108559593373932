import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import axios from 'services/auth/jwt/config';
import { fetchError } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectBetTemplate, selectBetTemplateType } from 'redux/actions/OfficialChampionship';
import { getAvailableSports } from 'redux/actions/Common';
import { getBetTemplates } from 'redux/actions/BetTemplate';
import { findIndex } from 'lodash';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '100%',
    '& input': {
      minWidth: '120px',
    },
  },
  formControlWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  formControlExpandButton: {
    color: '#b8b8b8',
    cursor: 'pointer',
    transition: '100ms',
    '&:hover': {
      color: '#303030',
    },
  },
  expandedSection: {
    marginLeft: '1.5rem',
  },
  error: {
    marginTop: '10px',
    color: 'red',
  },
  formErrors: {
    color: 'red',
    marginLeft: '1rem',
  },
  titleContainer: {
    '& h2': {
      fontSize: '18px',
    },
  },
  sportTitle: {
    marginTop: '10px',
  },
}));

const SelectModal = ({ open, onClose, betTemplatesData }) => {
  const { betTemplates } = useSelector(({ betTemplates }) => betTemplates);

  let { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { availableSports } = useSelector(({ common }) => common);
  const [betTemplatesValues, setBetTemplatesValues] = useState([]);

  useEffect(() => {
    dispatch(getBetTemplates());
    dispatch(getAvailableSports());
  }, []);

  useEffect(() => {
    setBetTemplatesValues(betTemplatesData);
  }, [betTemplatesData]);

  const handleClose = () => {
    onClose();
  };

  const onSubmit = () => {
    dispatch(selectBetTemplate(betTemplatesValues));
    onClose();
  };

  const changeBetTemplateValue = (e, sport) => {
    if (betTemplatesValues?.length) {
      const index = betTemplatesValues?.findIndex(item => item.sportId === sport._id);
      if (index >= 0) {
        betTemplatesValues[index].betTemplateId = e.target.value;
        setBetTemplatesValues([...betTemplatesValues]);
      } else setBetTemplatesValues(prevState => [...prevState, { betTemplateId: e.target.value, sportId: sport._id }]);
    } else {
      setBetTemplatesValues([{ betTemplateId: e.target.value, sportId: sport._id }]);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} scroll={'paper'}>
      <DialogTitle className={classes.titleContainer}>
        {id === 'new' ? 'Add bet templates' : 'Update bet templates'}
      </DialogTitle>
      <DialogContent dividers={true} style={{ minWidth: '600px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {availableSports?.map(sport => {
            return (
              <div key={sport._id}>
                <h3 className={classes.sportTitle}>{sport.name}</h3>
                <FormControl style={{ marginBottom: '1.5rem', marginTop: '10px' }} className={classes.formControl}>
                  <InputLabel>Bet Template</InputLabel>
                  <Select
                    name={sport.nameLowercase}
                    defaultValue=""
                    value={betTemplatesValues?.filter(item => item.sportId === sport._id)[0]?.betTemplateId}
                    onChange={e => changeBetTemplateValue(e, sport)}>
                    {betTemplates?.entities?.map(
                      betTemplate =>
                        sport._id === betTemplate.sportId && (
                          <MenuItem key={betTemplate._id} value={betTemplate._id}>
                            {betTemplate.name}
                          </MenuItem>
                        ),
                    )}
                  </Select>
                  {errors[sport.nameLowercase] && (
                    <FormLabel className={classes.error}>{errors[sport.nameLowercase].message}</FormLabel>
                  )}
                </FormControl>
              </div>
            );
          })}
        </form>
      </DialogContent>
      <DialogActions>
        {errors ? <span className={classes.formErrors}>{errors.betTemplate?.message}</span> : null}
        <div>
          <Button onClick={handleClose} style={{ marginLeft: '15px' }} color="primary">
            Cancel
          </Button>
          <Button
            style={{ marginLeft: '15px' }}
            type="submit"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary">
            {id === 'new' ? 'Select' : 'Update'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default SelectModal;
