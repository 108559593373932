import React, { useContext, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getAppSidebarHeight } from 'constants/AppConstants';
import AppContext from 'components/contextProvider/AppContextProvider/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { withWidth, Box, List } from '@material-ui/core';
import CmtList from '@coremat/CmtList';
import ItemCell from './ItemCell';
import { ListItem } from '@material-ui/core';
import useStyles from '../index.style';
import { getUsersAdmin } from 'redux/actions/Users';
import { getAvailableSports } from 'redux/actions';
import GlobalVariables from 'assets/global/globalVariables';
import { setOutrightsFilters } from 'redux/actions/Outrights';

const statusFilters = [
  {
    _id: 1,
    name: 'Active',
    type: '1',
  },
  {
    _id: 2,
    name: 'Inactive',
    type: '0',
  },
  {
    _id: 3,
    name: 'Finished',
    type: '2',
  },
];

const Sidebar = ({ width }) => {
  const dispatch = useDispatch();
  const { availableSports } = useSelector(({ common }) => common);

  const { filters } = useSelector(({ outrights }) => outrights);
  const { showFooter } = useContext(AppContext);
  const [sports, setSports] = useState([]);

  useEffect(() => {
    dispatch(getUsersAdmin(null, null, ''));
    dispatch(getAvailableSports());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statusHandler = filter => {
    if (filters?.status?._id === filter._id) {
      dispatch(setOutrightsFilters({ ...filters, status: '', offset: 0 }));
    } else {
      dispatch(setOutrightsFilters({ ...filters, status: filter, offset: 0 }));
    }
  };

  const sportHandler = filter => {
    if (filters?.sport?._id === filter._id) {
      dispatch(setOutrightsFilters({ ...filters, sport: '', offset: 0 }));
    } else {
      dispatch(setOutrightsFilters({ ...filters, sport: filter, offset: 0 }));
    }
  };

  useEffect(() => {
    if (availableSports) {
      const data = availableSports.map(sport => {
        return {
          ...sport,
          slug: sport.nameLowercase,
          icon: GlobalVariables.sports.find(el => el.name === sport.name)?.icon,
        };
      });
      setSports(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableSports]);

  const classes = useStyles({
    isCollapsed: false,
    height: getAppSidebarHeight(width, showFooter),
  });

  return (
    <Box className={classes.inBuildAppSidebar}>
      <PerfectScrollbar className={classes.perfectScrollbarMatchSidebar} style={{ marginTop: 0 }}>
        <ListItem component="div" className={classes.appNavHeaderItem}>
          <Box component="span" className={classes.appNavHeaderItemText}>
            Filter by Status
          </Box>
        </ListItem>
        <List component="nav" className={classes.appNav}>
          <CmtList
            data={statusFilters}
            renderRow={(item, index) => (
              <ItemCell
                item={item}
                classes={classes}
                selectedItem={filters?.status?._id}
                onChange={() => statusHandler(item)}
                key={index}
              />
            )}
          />
        </List>
        <ListItem component="div" className={classes.appNavHeaderItem}>
          <Box component="span" className={classes.appNavHeaderItemText}>
            Filter by Sport
          </Box>
        </ListItem>
        <List component="nav" className={classes.appNav}>
          <CmtList
            data={sports}
            renderRow={(item, index) => (
              <ItemCell
                item={item}
                classes={classes}
                selectedItem={filters?.sport?._id}
                onChange={() => sportHandler(item)}
                key={index}
              />
            )}
          />
        </List>
      </PerfectScrollbar>
    </Box>
  );
};

export default withWidth()(Sidebar);
