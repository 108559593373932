import React from 'react';
import { Box, TablePagination, TableRow, TableCell, TableBody, Table } from '@material-ui/core';
import 'react-notifications/lib/notifications.css';

import TableHeader from 'assets/global/globalTableHeadingData';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import TableHeading from 'components/Table/TableHeading';
import useStyles from 'components/Table/table.styles';

const Wallet = ({ wallets, changePage, walletPage }) => {
  const classes = useStyles();

  return (
    <CmtCard>
      <CmtCardHeader
        className="pt-8"
        title="Wallet transfers"
        style={{ marginBottom: '30px' }}
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}
      />
      <Table>
        <TableHeading headingData={TableHeader.UserDetail.wallet} />
        <TableBody>
          {wallets.walletTransfers.map(row => {
            return (
              <TableRow className={classes.tableRowRoot} key={row._id}>
                <TableCell className={classes.tableCellRoot}>{row.name}</TableCell>
                <TableCell className={classes.tableCellRoot}>
                  {row.value} {row.currency}
                </TableCell>
                <TableCell className={classes.tableCellRoot}>{row.type === 0 ? 'INCOME' : 'OUTCOME'}</TableCell>
                <TableCell className={classes.tableCellRoot}>{new Date(row.created).toLocaleDateString()}</TableCell>
                <TableCell className={classes.tableCellRoot}>{new Date(row.updated).toLocaleDateString()}</TableCell>
                <TableCell className={classes.tableCellRoot}>
                  <Box>
                    <strong>Wallet Id</strong>: {row.walletId}
                  </Box>
                  <Box>
                    <strong>Transfer Id</strong>: {row._id}
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        style={{ order: '9999', width: '100%' }}
        count={wallets.count}
        page={walletPage}
        onPageChange={changePage}
        rowsPerPage={5}
        onRowsPerPageChange={() => {}}
        rowsPerPageOptions={[]}
      />
    </CmtCard>
  );
};

export default Wallet;
