import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { getMatchDetails } from 'redux/actions/MatchDetails';

import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';

import Lineups from './Lineups';
import Events from './Events';
import MatchHeader from './Header';

import useStyles from './index.style';
import OddsMatch from './Odds';

const MatchApp = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { matchDetail, matchAddEvents, matchRemoveEvents } = useSelector(({ matchDetails }) => matchDetails);
  const { id } = useParams();
  const [filter, setFilter] = useState('odds');
  useEffect(() => {
    if (matchDetail !== undefined && matchDetail.status === 'FT') {
      setFilter('events');
    }
    if (matchDetail !== undefined && matchDetail.status === 'LIVE') {
      setFilter('odds');
    }
    if (matchDetail !== undefined && (matchDetail.status === 'POSTP' || matchDetail.status === 'NS')) {
      setFilter('odds');
    }
  }, [matchDetail]);

  useEffect(() => {
    dispatch(getMatchDetails(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, matchAddEvents, matchRemoveEvents]);

  const setNewFilter = value => {
    setFilter(value);
  };
  return matchDetail !== undefined ? (
    <CmtCard>
      <CmtCardContent>
        <MatchHeader
          className="ASD"
          setNewFilter={setNewFilter}
          style={{ width: '100%', display: 'block' }}
          matchDetails={matchDetail}
        />
      </CmtCardContent>
      <CmtCardContent className={classes.cardContentRoot}>
        <PerfectScrollbar className={classes.scrollbarRoot}>
          {/*{filter === 'odds' && (!matchDetail.oddsId ? <Odds id={matchDetail.oddsId} /> : 'This match has no odds')}*/}
          {filter === 'odds' && <OddsMatch matchId={matchDetail._id} />}
          {filter === 'lineups' && <Lineups matchDetail={matchDetail} id={matchDetail._id} />}
          {filter === 'events' && <Events id={matchDetail._id} matchDetail={matchDetail} />}
        </PerfectScrollbar>
      </CmtCardContent>
    </CmtCard>
  ) : null;
};

export default MatchApp;
