import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Hidden, IconButton, Table, TableBody, TablePagination, Typography } from '@material-ui/core';
import { toggleExpandSidebar } from 'redux/actions/MatchApp';
import { Menu } from '@material-ui/icons';
import CmtSearch from '@coremat/CmtSearch';
import AppContext from 'components/contextProvider/AppContextProvider/AppContext';
import { getMatchContainerHeight } from 'constants/AppConstants';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TableHeading from 'components/Table/TableHeading';
import TableHeader from 'assets/global/globalTableHeadingData';
import TableItem from './TableItem';
import Sidebar from './Sidebar';
import EmptyResult from 'components/Table/EmptyResult';

import useStyles from './index.style';
import { getSelections } from '../../../redux/actions/Selections';
import Button from '@material-ui/core/Button';
import { GET_SELECTIONS } from '../../../constants/ActionTypes';
import { useHistory } from 'react-router-dom';

const Selections = () => {
  const history = useHistory();
  const { isSideBarCollapsed, selectionsList } = useSelector(({ selections }) => selections);
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();
  const classes = useStyles({ height: getMatchContainerHeight(showFooter) + 80 });
  const [selectionList, setSelectionList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterByGeneration, setFilterByGeneration] = useState({ id: 1, name: 'Admin', generatedBy: 0 });
  const [filterByType, setFilterByType] = useState({ id: 0, name: 'Normal', type: 0 });
  const [filterByStatus, setFilterByStatus] = useState({ id: 1, name: 'Active', type: 1 });

  const fetchSelection = () => {
    dispatch(
      getSelections(page, rowsPerPage, searchQuery, filterByGeneration.generatedBy, filterByType.type, filterByStatus.type),
    );
  };

  useEffect(() => {
    if (selectionsList) {
      setSelectionList(selectionsList.selections);
      setCount(selectionsList.count);
    }
  }, [selectionsList]);

  useEffect(() => {
    fetchSelection();
    setSelectionList(selectionsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page, filterByGeneration, filterByType, filterByStatus.type]);

  useEffect(() => {
    let timer = setTimeout(
      () => {
        fetchSelection();
      },
      searchQuery ? 500 : 0,
    );
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  return (
    <Box className={classes.inBuildAppCard}>
      <Box className={classes.inBuildAppHeader}>
        <Box className={classes.inBuildAppHeaderSidebar}>
          <Hidden>
            <IconButton onClick={() => dispatch(toggleExpandSidebar())}>
              <Menu />
            </IconButton>
          </Hidden>
          <Typography className={classes.inBuildAppHeaderTitle} component="div" variant="h1">
            Selections
          </Typography>
        </Box>
        <Box className={classes.inBuildAppHeaderContent}>
          <CmtSearch
            placeholder="Search..."
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            border={false}
          />
        </Box>
        <Button
          style={{ margin: '15px' }}
          variant="contained"
          color="primary"
          onClick={() => {
            history.push('/selection/new');
            dispatch({
              type: GET_SELECTIONS,
              payload: undefined,
            });
          }}>
          Create
        </Button>
      </Box>
      <Box className={clsx(classes.inBuildAppContainer, isSideBarCollapsed ? 'collapsed' : '')}>
        <Sidebar
          selectedSport={selectionsList}
          setFilterByGeneration={setFilterByGeneration}
          setFilterByType={setFilterByType}
          filterByGeneration={filterByGeneration}
          filterByType={filterByType}
          filterByStatus={filterByStatus}
          setFilterByStatus={setFilterByStatus}
        />
        <Box className={classes.inBuildAppMainContent}>
          <Box className="Cmt-table-responsive">
            <PerfectScrollbar className={classes.perfectScrollbarMatchCon}>
              {selectionList ? (
                !!selectionList.length ? (
                  <Table>
                    <TableHeading headingData={TableHeader.Selections} />
                    <TableBody>
                      {selectionList.map(row => (
                        <TableItem key={row._id} row={row} fetchParticipants={fetchSelection} />
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <EmptyResult />
                )
              ) : null}
            </PerfectScrollbar>
          </Box>
          <TablePagination
            component="div"
            style={{ order: '9999', width: '100%' }}
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Selections;
