import React from 'react';
import { TableCell, TableRow, makeStyles } from '@material-ui/core';
import { green, yellow, red } from '@material-ui/core/colors';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles(theme => ({
  tableCellRoot: {
    padding: 16,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.secondary,
    borderBottom: '0 none',
    position: 'relative',
    textAlign: 'center',
    '&:first-child': {
      paddingLeft: 24,
      textAlign: 'left',
    },
    '&:last-child': {
      textAlign: 'center',
    },
  },
}));

const TableItem = ({ row }) => {
  const classes = useStyles();

  const formatPoints = points => (points !== undefined ? points.toFixed(2) : '0.00');
  const formatDate = date => (date ? new Date(date).toLocaleDateString('en-GB') : 'N/A');

  const getActivityStatusIcon = () => {
    const today = new Date();
    const lastBetDate = new Date(row.mostRecentBetDate);
    const diffTime = Math.abs(today - lastBetDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays <= 7) {
      return <FiberManualRecordIcon style={{ color: green[500] }} />;
    } else if (diffDays <= 30) {
      return <FiberManualRecordIcon style={{ color: yellow[700] }} />;
    } else {
      return <FiberManualRecordIcon style={{ color: red[500] }} />;
    }
  };

  return (
    <TableRow>
      <TableCell className={classes.tableCellRoot}>{row.email}</TableCell>
      <TableCell className={classes.tableCellRoot}>{row.nickname}</TableCell>
      <TableCell className={classes.tableCellRoot}>{row.country}</TableCell>
      <TableCell className={classes.tableCellRoot}>{formatPoints(row.totalPoints)}</TableCell>
      <TableCell className={classes.tableCellRoot}>{row.totalBets}</TableCell>
      <TableCell className={classes.tableCellRoot}>{formatDate(row.mostRecentBetDate)}</TableCell>
      <TableCell className={classes.tableCellRoot}>{getActivityStatusIcon()}</TableCell>
    </TableRow>
  );
};

export default TableItem;
