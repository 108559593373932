import React from 'react';
import { Box } from '@material-ui/core';
import useStyles from './../index.style';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

const AddQuestion = ({ questionDetail, editQuestion, disableEdit, deleteQuestion }) => {
  const classes = useStyles();
  return (
    <Box className={classes.questionsListContainer}>
      <Box className={classes.questionsListContent}>
        {questionDetail.questionText.map((question, questionKey) => {
          return (
            <Box key={questionKey} className={classes.questionWrapper}>
              <Box className={classes.questionTranslation}>Question Translation {question.language.toUpperCase()}</Box>
              <Box>
                <Box className={classes.questionHead}>
                  <span className={classes.questionTitle}>{question.text}</span>
                </Box>
                <Box className={classes.questionBody}>
                  {questionDetail.possibleAnswers[questionKey].answers.map((answer, key) => {
                    return (
                      <span key={key} className={classes.questionAnswer}>
                        - {answer}
                      </span>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box className={classes.buttonsGroup} style={{ marginTop: 10 }}>
        <Button
          disabled={disableEdit}
          className={classes.rightButtons}
          variant="contained"
          color="primary"
          component="span"
          onClick={editQuestion}
          startIcon={<SaveIcon />}>
          Edit Question
        </Button>
        <Button
          disabled={disableEdit}
          className={classes.rightButtons}
          variant="contained"
          color="primary"
          component="span"
          onClick={deleteQuestion}
          startIcon={<SaveIcon />}>
          Delete Question
        </Button>
      </Box>
    </Box>
  );
};

export default AddQuestion;
