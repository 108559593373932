import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getMatchContainerHeight } from 'constants/AppConstants';
import AppContext from 'components/contextProvider/AppContextProvider/AppContext';
import { Table, TableBody, Box, TablePagination } from '@material-ui/core';
import ListHeader from './ListHeader';
import MatchCell from './MatchCell';
import CheckedListHeader from './CheckedListHeader';
import useStyles from '../../index.style';

const ListTableView = ({
  checkedMatches,
  handleCellCheckBox,
  handleHeaderCheckBox,
  updateCheckedMatches,
  onShowMatchDetail,
  onClickEditMatch,
  filterType,
  width,
  matchesData,
  pagination,
  setPagination,
}) => {
  const { showFooter } = useContext(AppContext);
  const classes = useStyles({
    height: getMatchContainerHeight(width, showFooter) + 80,
  });

  const handleChangePage = (event, newPage) => {
    setPagination(prev => {
      return {
        ...prev,
        page: newPage,
      };
    });
  };

  const handleChangeRowsPerPage = event => {
    setPagination({
      page: 0,
      rowsPerPage: event.target.value,
    });
  };
  return (
    <>
      {checkedMatches.length > 0 && (
        <CheckedListHeader
          checkedMatches={checkedMatches}
          handleHeaderCheckBox={handleHeaderCheckBox}
          updateCheckedMatches={updateCheckedMatches}
        />
      )}
      <Box className="Cmt-table-responsive">
        <PerfectScrollbar className={classes.perfectScrollbarMatchCon}>
          <Table>
            {checkedMatches.length === 0 && <ListHeader filterType={filterType} />}
            <TableBody>
              {matchesData?.matches !== undefined && matchesData?.matches?.length
                ? matchesData.matches.map(match => (
                    <MatchCell
                      key={match._id}
                      match={match}
                      filterType={filterType}
                      checkedMatches={checkedMatches}
                      handleCellCheckBox={handleCellCheckBox}
                      onShowMatchDetail={onShowMatchDetail}
                      onClickEditMatch={onClickEditMatch}
                    />
                  ))
                : null}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Box>
      <TablePagination
        component="div"
        style={{ order: '9999', width: '100%' }}
        count={matchesData?.count || 0}
        page={pagination.page}
        onPageChange={handleChangePage}
        rowsPerPage={pagination.rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default ListTableView;

ListTableView.prototype = {
  checkedMatches: PropTypes.array,
  handleCellCheckBox: PropTypes.func,
  handleHeaderCheckBox: PropTypes.func,
  updateCheckedMatches: PropTypes.func,
  onShowMatchDetail: PropTypes.func,
  onClickEditMatch: PropTypes.func,
};

ListTableView.defaultProps = {
  checkedMatches: [],
};
