import {
  ADD_OFFICIAL_CHAMPIONSHIPS,
  TOGGLE_SIDEBAR_COLLAPSED,
  GET_OFFICIAL_CHAMPIONSHIPS,
  GET_OFFICIAL_CHAMPIONSHIPS_MATCHES,
  GET_OFFICIAL_CHAMPIONSHIPS_LANG,
  GET_OFFICIAL_CHAMPIONSHIPS_DETAILS,
  PATCH_OFFICIAL_CHAMPIONSHIPS_DETAILS,
  DELETE_OFFICIAL_CHAMPIONSHIPS,
  PUBLISH_OFFICIAL_CHAMPIONSHIPS,
  SELECT_BET_TEMPLATE,
  SELECT_BET_TEMPLATE_TYPE,
  SET_CUSTOM_FINAL_BETTING_DATES_OUTRIGHTS,
  SET_INDIVIDUAL_BET_TEMPLATES,
} from 'constants/ActionTypes';

const INIT_STATE = {
  selectedBetTemplate: [],
  selectedBetTemplateType: '', // "single-match" - used for changing the bet template id for one match; "all-matches" - for all matches
  isSideBarCollapsed: false,
  labelsList: [],
  filterType: {
    status: 'future',
    competition: '',
    query: '',
  },
  matchesList: [],
  currentMatch: null,
  totalMatches: null,
  counter: null,
  customFinalBettingDatesOutrights: {},
  individualBetTemplates: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR_COLLAPSED: {
      return {
        ...state,
        isSideBarCollapsed: action.payload ? action.payload : !state.isSideBarCollapsed,
      };
    }
    case ADD_OFFICIAL_CHAMPIONSHIPS: {
      return {
        ...state,
        createOfficialChampionsip: action.payload,
        successCreate: true,
      };
    }

    case PUBLISH_OFFICIAL_CHAMPIONSHIPS: {
      return {
        ...state,
        publishOfficialChampionship: action.payload,
      };
    }

    case DELETE_OFFICIAL_CHAMPIONSHIPS: {
      return {
        ...state,
        deleteOfficialChampionship: action.payload,
      };
    }

    case GET_OFFICIAL_CHAMPIONSHIPS: {
      return {
        ...state,
        officialChampionshipList: action.payload,
      };
    }

    case GET_OFFICIAL_CHAMPIONSHIPS_DETAILS: {
      return {
        ...state,
        officialChampionshipDetail: action.payload,
      };
    }

    case PATCH_OFFICIAL_CHAMPIONSHIPS_DETAILS: {
      return {
        ...state,
        patchOfficialChampionshipDetail: action.payload,
      };
    }

    case GET_OFFICIAL_CHAMPIONSHIPS_MATCHES: {
      return {
        ...state,
        officialChampionshipMatches: action.payload,
      };
    }

    case GET_OFFICIAL_CHAMPIONSHIPS_LANG: {
      return {
        ...state,
        lang: action.payload,
      };
    }
    case SELECT_BET_TEMPLATE: {
      return {
        ...state,
        selectedBetTemplate: action.payload,
      };
    }
    case SELECT_BET_TEMPLATE_TYPE: {
      return {
        ...state,
        selectedBetTemplateType: action.payload,
      };
    }
    case SET_CUSTOM_FINAL_BETTING_DATES_OUTRIGHTS: {
      return {
        ...state,
        customFinalBettingDatesOutrights: action.payload,
      };
    }
    case SET_INDIVIDUAL_BET_TEMPLATES: {
      return {
        ...state,
        individualBetTemplates: action.payload,
      };
    }

    default:
      return state;
  }
};
