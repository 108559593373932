import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  TextareaAutosize,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const SettingDetail = ({ setting, editSetting }) => {
  const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      background: 'rgba(255,255,255, 1)',
      maxWidth: 'calc(100% - 30px)',
      margin: '10px 15px',
      width: '100%',
      padding: '5px 20px',
      borderRadius: '4px',
      '@media (max-width: 1024px)': {
        maxWidth: '100%',
      },
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      '@media (max-width: 768px)': {
        flexDirection: 'column',
      },
    },
    leftContainer: {
      width: '375px',
      '@media (max-width: 768px)': {
        width: '100%',
      },
    },
  }));

  const classes = useStyles();

  const [scope, setScope] = useState(setting.scope);
  const [visibility, setVisibility] = useState(setting.visibility);
  const [jsonValue, setJsonValue] = useState(JSON.stringify(setting.value, null, 2));
  const [jsonError, setJsonError] = useState('');

  const handleChangeScope = event => {
    setScope(event.target.value);
  };

  const handleChangeVisibility = event => {
    setVisibility(event.target.value);
  };

  const handleChangeJSON = event => {
    setJsonValue(event.target.value);
  };

  const checkValidations = () => {
    try {
      let parsedJson = JSON.parse(jsonValue);
      let body = {
        value: parsedJson,
        scope,
        visibility,
      };
      editSetting(setting?._id, body);
    } catch (e) {
      setJsonError('Enter a valid JSON');
    }
  };

  return (
    <Box component="div" className={classes.container}>
      <Accordion style={{ border: 'none', boxShadow: 'none' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="email content">
          <Box className={classes.title}>
            <h3>{setting?.name ? formatName(setting?.name) : null}</h3>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.wrapper}>
            <Box className={classes.leftContainer}>
              <FormControl className={classes.formControl}>
                <InputLabel>Scope</InputLabel>
                <Select value={scope} onChange={handleChangeScope}>
                  <MenuItem value={0}>GLOBAL</MenuItem>
                  <MenuItem value={1}>MOBILE</MenuItem>
                  <MenuItem value={2}>BACKEND</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel>Visibility</InputLabel>
                <Select value={visibility} onChange={handleChangeVisibility}>
                  <MenuItem value={0}>PUBLIC</MenuItem>
                  <MenuItem value={1}>PRIVATE</MenuItem>
                </Select>
              </FormControl>
              <Box>
                <h3 style={{ margin: '20px 0 10px 0' }}>JSON value</h3>
                <TextareaAutosize
                  value={jsonValue}
                  style={{
                    minHeight: '150px',
                    width: '100%',
                    padding: '15px',
                  }}
                  onChange={handleChangeJSON}
                />
                <h3 style={{ margin: '5px 0 10px 0', color: 'red' }}>{jsonError}</h3>
              </Box>
              <Box mt={2}>
                <Button variant="contained" color="primary" onClick={checkValidations}>
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default SettingDetail;

const formatName = name => {
  let result = name.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};
