import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Card, CardContent, Grid, Typography, CircularProgress } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import GetAppIcon from '@material-ui/icons/GetApp';
import { makeStyles } from '@material-ui/core/styles';
import CountersFilterModal from './CountersFilterModal';
import { fetchAnalyticsCounters, fetchAnalyticsCountersCSV, B2BUserType } from '../../../../redux/actions/Analytics';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardTitle: {
    fontSize: '20px',
    marginBottom: '8px',
  },
  cardValue: {
    fontSize: '50px',
    fontWeight: 'bold',
  },
  buttonContainer: {
    display: 'flex',
    marginBottom: '16px',
    alignItems: 'center',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  downloadButton: {
    marginLeft: 'auto',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  subtitle: {
    marginBottom: '20px',
  },
  gridContainer: {
    marginBottom: '30px',
  },
}));

const DEFAULT_FILTERS = {
  country: 'ALL',
  userType: 'ALL',
  dateType: null,
  selectedDate: null,
  startDate: null,
  endDate: null,
  selectedMonth: null,
};

const CountersSection = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [appliedFilters, setAppliedFilters] = useState(DEFAULT_FILTERS);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [filterOpen, setFilterOpen] = useState(false);
  const { loading, counters } = useSelector(state => state.analytics);

  useEffect(() => {
    fetchCounters();
  }, [appliedFilters]);

  const fetchCounters = (filters = appliedFilters) => {
    const { startDate, endDate } = getDateRange(filters);

    const params = {};
    if (startDate && endDate) {
      params.startDate = startDate;
      params.endDate = endDate;
    }

    const externalType = B2BUserType[filters.userType] || null;
    if (externalType) {
      params.externalType = externalType;
    }

    dispatch(fetchAnalyticsCounters(params));
  };

  const availableCountries = counters ? Array.from(new Set(counters.map(counter => counter.country))) : [];

  const fetchAvailableCountries = userType => {
    const { startDate, endDate } = filters;

    const externalType = B2BUserType[userType] || null;

    dispatch(fetchAnalyticsCounters({ externalType, startDate, endDate }));
  };

  const handleFilterChange = (filter, value) => {
    setFilters(prevFilters => ({ ...prevFilters, [filter]: value }));

    if (filter === 'userType') {
      fetchAvailableCountries(value);
    }
  };

  const handleFilterOpen = () => setFilterOpen(true);
  const handleFilterClose = () => setFilterOpen(false);

  const handleApplyFilter = () => {
    setAppliedFilters(filters);
    setFilterOpen(false);
  };

  const handleReset = () => {
    const resetFilters = {
      ...DEFAULT_FILTERS,
      startDate: null,
      endDate: null,
      selectedDate: null,
      selectedMonth: null,
    };
    setFilters(resetFilters);
    setAppliedFilters(resetFilters);
    fetchCounters(resetFilters);
  };

  const handleDownloadCSV = () => dispatch(fetchAnalyticsCountersCSV());

  const getDateRange = filters => {
    let startDate = null,
      endDate = null;

    if (filters.dateType === 'day' && filters.selectedDate) {
      const localDate = new Date(filters.selectedDate);
      localDate.setHours(0, 0, 0, 0);
      startDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();

      localDate.setHours(23, 59, 59, 999);
      endDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();
    } else if (filters.dateType === 'week' && filters.startDate && filters.endDate) {
      const localStartDate = new Date(filters.startDate);
      localStartDate.setHours(0, 0, 0, 0);
      startDate = new Date(localStartDate.getTime() - localStartDate.getTimezoneOffset() * 60000).toISOString();

      const localEndDate = new Date(filters.endDate);
      localEndDate.setHours(23, 59, 59, 999);
      endDate = new Date(localEndDate.getTime() - localEndDate.getTimezoneOffset() * 60000).toISOString();
    } else if (filters.dateType === 'month' && filters.selectedMonth) {
      const monthStart = new Date(filters.selectedMonth.getFullYear(), filters.selectedMonth.getMonth(), 1);
      monthStart.setHours(0, 0, 0, 0);
      startDate = new Date(monthStart.getTime() - monthStart.getTimezoneOffset() * 60000).toISOString();

      const monthEnd = new Date(filters.selectedMonth.getFullYear(), filters.selectedMonth.getMonth() + 1, 0);
      monthEnd.setHours(23, 59, 59, 999);
      endDate = new Date(monthEnd.getTime() - monthEnd.getTimezoneOffset() * 60000).toISOString();
    }

    return { startDate, endDate };
  };

  const renderCardValue = value => {
    if (loading || counters === null) {
      return <CircularProgress />;
    }
    return <Typography className={classes.cardValue}>{value.toLocaleString()}</Typography>;
  };

  const totalUsers =
    counters && appliedFilters.country === 'ALL'
      ? counters.reduce((sum, counter) => sum + counter.totalUsers, 0)
      : (counters &&
          counters.find(counter => counter.country === appliedFilters.country) &&
          counters.find(counter => counter.country === appliedFilters.country).totalUsers) ||
        0;

  const newUsers =
    counters && appliedFilters.country === 'ALL'
      ? counters.reduce((sum, counter) => sum + counter.newUsers, 0)
      : (counters &&
          counters.find(counter => counter.country === appliedFilters.country) &&
          counters.find(counter => counter.country === appliedFilters.country).newUsers) ||
        0;

  const totalBets =
    counters && appliedFilters.country === 'ALL'
      ? counters.reduce((sum, counter) => sum + counter.totalBets, 0)
      : (counters &&
          counters.find(counter => counter.country === appliedFilters.country) &&
          counters.find(counter => counter.country === appliedFilters.country).totalBets) ||
        0;

  const newBets =
    counters && appliedFilters.country === 'ALL'
      ? counters.reduce((sum, counter) => sum + counter.newBets, 0)
      : (counters &&
          counters.find(counter => counter.country === appliedFilters.country) &&
          counters.find(counter => counter.country === appliedFilters.country).newBets) ||
        0;

  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom className={classes.subtitle}>
        Counters
      </Typography>
      <Box className={classes.buttonContainer}>
        <Button className={classes.button} onClick={handleFilterOpen} startIcon={<FilterListIcon />}>
          Counters Filters
        </Button>
        <Button className={classes.downloadButton} onClick={handleDownloadCSV} startIcon={<GetAppIcon />}>
          Download Counters CSV
        </Button>
      </Box>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.cardTitle}>Total Users</Typography>
              {renderCardValue(totalUsers)}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.cardTitle}>New Users</Typography>
              {renderCardValue(newUsers)}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.cardTitle}>Total Bets</Typography>
              {renderCardValue(totalBets)}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.cardTitle}>New Bets</Typography>
              {renderCardValue(newBets)}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <CountersFilterModal
        open={filterOpen}
        onClose={handleFilterClose}
        handleApply={handleApplyFilter}
        filters={filters}
        handleFilterChange={handleFilterChange}
        B2BUserType={B2BUserType}
        availableCountries={availableCountries}
        onReset={handleReset}
      />
    </React.Fragment>
  );
};

export default CountersSection;
