import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import GlobalMessages from 'assets/global/globalMessages';
import { getAvailableSports } from 'redux/actions/Common';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormHelperText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  TextField,
  MenuItem,
  Select,
  Box,
} from '@material-ui/core';
import useStyles from './index.style';
import GlobalVariables from 'assets/global/globalVariables';
import { createBetTemplate } from 'redux/actions/BetTemplate';
import { getVisibleOdds } from 'redux/actions/Odds';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const AddBetTemplate = ({ open, onClose }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      type: '',
    },
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  const { availableSports } = useSelector(({ common }) => common);
  const { visibleOdds } = useSelector(({ odds }) => odds);
  const [selectedSport, setSelectedSport] = useState('');
  const [oddData, setOddData] = useState([]);
  const [order, setOrder] = useState({});

  useEffect(() => {
    dispatch(getAvailableSports());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (visibleOdds) {
      const data = visibleOdds.entities.map(el => ({
        ...el,
        visibleEventParts: el.visibleEventParts.map(eventPart => ({
          ...eventPart,
          visible: true,
        })),
      }));
      setOddData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleOdds]);

  useEffect(() => {
    dispatch(getVisibleOdds(selectedSport));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSport]);

  useEffect(() => {
    if (availableSports && availableSports.length !== 0) {
      setSelectedSport(availableSports[0]._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableSports]);

  const handleClose = () => {
    onClose();
  };

  const handleChangeSport = event => {
    setSelectedSport(event.target.value);
  };

  const handleEventPartChange = (oddId, eventPartId) => {
    const updatedData = [...oddData];
    updatedData.find(
      odd =>
        odd._id === oddId && {
          ...odd,
          visibleEventParts: odd.visibleEventParts.find(
            eventPart => eventPart.id === eventPartId && (eventPart.visible = !eventPart.visible),
          ),
        },
    );
    setOddData(updatedData);
  };

  const onSubmit = data => {
    const oddTypes = [];

    oddData.forEach(odd => {
      if (odd.visibleEventParts.length !== 0) {
        odd.visibleEventParts.forEach(el => {
          if (el.visible)
            oddTypes.push({
              bettingTypeId: odd._id,
              eventPart: el.id.toString(),
              order: order && order[odd._id] && order[odd._id][el.id] ? +order[odd._id][el.id]?.value : undefined,
            });
        });
      }
    });

    let orderIsCorrect = true;
    let orderNumbers = [];

    oddTypes.forEach(item => {
      if (orderNumbers.includes(item.order)) orderIsCorrect = false;
      orderNumbers.push(item.order);
      if (item.order === undefined) {
        orderIsCorrect = false;
      }
    });

    if (orderIsCorrect) {
      const dataForApi = {
        name: data.name,
        sportId: selectedSport,
        oddTypeSelection: oddTypes,
        type: data.type, // TODO: check
      };

      dispatch(createBetTemplate(dataForApi));
      onClose();
    } else {
      return NotificationManager.error(
        'Please check again the order. You cannot set same order number for 2 different odd types or to leave it empty.',
        'ERROR!',
        4000,
      );
    }
  };

  const orderHandler = (e, oddId, eventPartId) => {
    if (e.target.value > 0) {
      order[oddId] = {
        ...order[oddId],
        [eventPartId]: {
          value: e.target.value,
          oddId: oddId,
          eventPartId: eventPartId,
        },
      };
      setOrder(prev => {
        return {
          ...prev,
        };
      });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} scroll={'paper'}>
      <DialogTitle className={classes.titleContainer}>Create bet template</DialogTitle>
      <DialogContent dividers={true} style={{ minWidth: '600px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl className={classes.formControl}>
            <Controller
              name="name"
              rules={{ required: true }}
              control={control}
              render={({ field }) => <TextField size="small" variant="outlined" label="Name" {...field} />}
            />
            {errors.name && <FormLabel className={classes.error}>{GlobalMessages.invalidName}</FormLabel>}
          </FormControl>
          {availableSports && availableSports.length !== 0 ? (
            <FormControl style={{ marginBottom: '1.5rem', marginTop: '1.5rem' }} className={classes.formControl}>
              <InputLabel>Sport</InputLabel>
              <Select name="sport" value={selectedSport} onChange={handleChangeSport}>
                {availableSports.map(sport => {
                  return (
                    <MenuItem key={sport._id} value={sport._id}>
                      {sport.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : (
            <Box>No available sports</Box>
          )}
          <FormControl style={{ marginBottom: '1.5rem', marginTop: '1.5rem' }} className={classes.formControl}>
            <InputLabel>Select template type</InputLabel>
            <Controller
              name="type"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <Select {...field}>
                  {GlobalVariables.betTemplateType.map(templateType => (
                    <MenuItem key={templateType.id} value={templateType.id}>
                      {templateType.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors.type && <FormLabel className={classes.error}>{GlobalMessages.required}</FormLabel>}
          </FormControl>
          {!!oddData.length && (
            <Box className={classes.events}>
              {oddData.map(
                odd =>
                  !!odd.visibleEventParts.length && (
                    <Box key={odd._id} className={classes.section}>
                      <FormHelperText className={classes.title}>{odd.name}</FormHelperText>
                      {odd.visibleEventParts.map(eventPart => {
                        return (
                          <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <FormControl key={eventPart.id} className={classes.formControl}>
                              <FormControlLabel
                                onChange={() => handleEventPartChange(odd._id, eventPart.id)}
                                checked={eventPart.visible}
                                control={<Checkbox name={`${eventPart.id}-${eventPart.name}`} color="primary" />}
                                label={eventPart.name}
                              />
                            </FormControl>

                            <TextField
                              label="Order"
                              id="standard-start-adornment"
                              type="number"
                              disabled={!eventPart.visible}
                              onChange={e => orderHandler(e, odd._id, eventPart.id)}
                              value={
                                order && order[odd._id] && order[odd._id][eventPart.id]?.value
                                  ? order[odd._id][eventPart.id]?.value
                                  : undefined
                              }
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  ),
              )}
            </Box>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        {Object.keys(errors).length !== 0 ? <span className={classes.formErrors}>{GlobalMessages.formErrors}</span> : null}
        <div>
          <Button onClick={handleClose} style={{ marginLeft: '15px' }} color="primary">
            Cancel
          </Button>
          <Button
            style={{ marginLeft: '15px' }}
            type="submit"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary">
            Create
          </Button>
        </div>
      </DialogActions>
      <NotificationContainer />
    </Dialog>
  );
};

export default AddBetTemplate;
