import React from 'react';
import { TableRow, TableCell, Box } from '@material-ui/core';
import useStyles from './index.style';
import { useDispatch, useSelector } from 'react-redux';
import { setCompetitionFilters } from 'redux/actions/Competitions';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

const TableHeading = sport => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { sortType } = useSelector(({ competitions }) => competitions);

  const sortHandler = fieldName => {
    if (sortType.field === fieldName) {
      dispatch(setCompetitionFilters({ ...sortType, order: sortType.order * -1 }));
    } else {
      dispatch(
        setCompetitionFilters({
          field: fieldName,
          order: 1,
        }),
      );
    }
  };

  return (
    <TableRow>
      <TableCell className={classes.tableCellRoot} style={{ cursor: 'pointer' }}>
        <Box display="flex" alignItems="center" onClick={() => sortHandler('name')}>
          <strong>Name</strong>{' '}
          {sortType.field === 'name' ? (
            sortType.order === -1 ? (
              <ArrowDownward fontSize="small" />
            ) : (
              <ArrowUpward fontSize="small" />
            )
          ) : (
            undefined
          )}
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ cursor: 'pointer' }}>
        <Box display="flex" alignItems="center" onClick={() => sortHandler('country')}>
          <strong>Country</strong>{' '}
          {sortType.field === 'country' ? (
            sortType.order === -1 ? (
              <ArrowDownward fontSize="small" />
            ) : (
              <ArrowUpward fontSize="small" />
            )
          ) : (
            undefined
          )}
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ cursor: 'pointer' }}>
        <Box display="flex" alignItems="center" onClick={() => sortHandler('shouldSync')}>
          <strong>Should Sync</strong>{' '}
          {sortType.field === 'shouldSync' ? (
            sortType.order === -1 ? (
              <ArrowDownward fontSize="small" />
            ) : (
              <ArrowUpward fontSize="small" />
            )
          ) : (
            undefined
          )}
        </Box>
      </TableCell>
      {(sport?.sport === '62a9cb67cc530f2d6feaa8aa' ||
        sport?.sport === '61f9195bcc7e0c4b221e8683' ||
        sport?.sport === '66a786c720a6ee6f128712b2') && (
        <TableCell className={classes.tableCellRoot} style={{ cursor: 'pointer' }}>
          <Box display="flex" alignItems="center" onClick={() => sortHandler('shouldSync')}>
            <strong>Is F1 Competition</strong>{' '}
            {sortType.field === 'shouldSync' ? (
              sortType.order === -1 ? (
                <ArrowDownward fontSize="small" />
              ) : (
                <ArrowUpward fontSize="small" />
              )
            ) : (
              undefined
            )}
          </Box>
        </TableCell>
      )}
      <TableCell className={classes.tableCellRoot} style={{ cursor: 'pointer' }}>
        <Box display="flex" alignItems="center" onClick={() => sortHandler('popular')}>
          <strong>Popular</strong>{' '}
          {sortType.field === 'popular' ? (
            sortType.order === -1 ? (
              <ArrowDownward fontSize="small" />
            ) : (
              <ArrowUpward fontSize="small" />
            )
          ) : (
            undefined
          )}
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Box style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
          <strong style={{ marginRight: '15px' }}>Fetch</strong>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TableHeading;
