import React from 'react';
import { Box, TableRow, TableCell } from '@material-ui/core';
import TableHeader from './TableHeadingData';

import useStyles from './index.style';

const TableHeading = () => {
  const classes = useStyles();
  return (
    <TableRow>
      {TableHeader.AMLUsers.map(header => (
        <TableCell className={classes.tableCellRootHeader} key={header.id}>
          <Box className={classes.headerBox}>
            <strong className={classes.headerStrong}>{header.title}</strong>
          </Box>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default TableHeading;
