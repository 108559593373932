import { GET_TEAMS_DATA, SELECT_SPORT, TOGGLE_SIDEBAR_COLLAPSED } from '../../constants/ActionTypes';

// ------------------------------------
// Action Handlers
// ------------------------------------

const INIT_STATE = {
  isSideBarCollapsed: false,
  selectedSport: {},
  teamsForSelection: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TEAMS_DATA: {
      return {
        ...state,
        teamsList: action.payload.data,
      };
    }
    case SELECT_SPORT: {
      return {
        ...state,
        selectedSport: action.payload,
      };
    }
    case TOGGLE_SIDEBAR_COLLAPSED: {
      return {
        ...state,
        isSideBarCollapsed: action.payload ? action.payload : !state.isSideBarCollapsed,
      };
    }

    default:
      return state;
  }
};
