import React from 'react';
import { Box, Table, TableHead, TableBody, CircularProgress } from '@material-ui/core';
import TableHeading from './TableHeading';
import TableItem from './TableItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
}));

const RecentTable = ({ tableData }) => {
  const classes = useStyles();
  return (
    <Box className={classes.tableContainer}>
      <Box className="Cmt-table-responsive">
        <Table>
          <TableHead>
            <TableHeading />
          </TableHead>
          <TableBody>
            {Array.isArray(tableData) && tableData.length > 0
              ? tableData.map((row, index) => <TableItem row={row} key={index} index={index} />)
              : null}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default RecentTable;
