import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core';
import CmtVertical from '@coremat/CmtNavigation/Vertical';
import IntlMessages from 'utils/IntlMessages';
import {
  Assignment,
  Beenhere,
  Bookmarks,
  ConfirmationNumber,
  Contacts,
  Gavel,
  Language,
  PeopleOutline,
  PeopleOutlineRounded,
  PostAdd,
  Score,
  Settings,
  SportsSoccer,
  VerticalSplit,
  DynamicFeed,
  Translate,
  Poll,
  Business,
  Assessment,
} from '@material-ui/icons';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const SideBar = () => {
  const classes = useStyles();
  const isB2B = useSelector(state => state.auth.isB2B);

  const navigationMenus = [
    {
      name: <IntlMessages id={'sidebar.content'} />,
      type: 'section',
      children: isB2B
        ? [
            {
              name: <IntlMessages id={'pages.analytics'} />,
              type: 'item',
              icon: <Assessment />,
              link: '/analytics',
            },
          ]
        : [
            {
              name: <IntlMessages id={'pages.dashboard'} />,
              icon: <PostAdd />,
              type: 'item',
              link: '/dashboard',
            },
            {
              name: <IntlMessages id={'pages.matchPage'} />,
              type: 'item',
              icon: <SportsSoccer />,
              link: '/matches',
            },
            {
              name: <IntlMessages id={'pages.competitionsPage'} />,
              type: 'item',
              icon: <Bookmarks />,
              link: '/competitions',
            },
            {
              name: <IntlMessages id={'pages.officialChampionshipsPage'} />,
              type: 'item',
              icon: <Beenhere />,
              link: '/official-championships',
            },
            {
              name: <IntlMessages id={'pages.Selections'} />,
              type: 'item',
              icon: <Assignment />,
              link: '/selections',
            },
            {
              name: <IntlMessages id={'pages.dbTranslations'} />,
              type: 'item',
              icon: <Translate />,
              link: '/db-translations',
            },
            {
              name: <IntlMessages id={'pages.popups'} />,
              type: 'item',
              icon: <DynamicFeed />,
              link: '/pop-ups',
            },
            {
              name: <IntlMessages id={'pages.polls'} />,
              type: 'item',
              icon: <Poll />,
              link: '/polls',
            },
            {
              name: <IntlMessages id={'pages.Odds'} />,
              type: 'item',
              icon: <ConfirmationNumber />,
              link: '/odds',
            },
            {
              name: <IntlMessages id={'pages.BetTemplates'} />,
              type: 'item',
              icon: <VerticalSplit />,
              link: '/bet-templates',
            },
            {
              name: <IntlMessages id={'pages.Ecosystems'} />,
              type: 'item',
              icon: <VerticalSplit />,
              link: '/ecosystems',
            },
            {
              name: <IntlMessages id={'pages.CompetitionApp2'} />,
              type: 'item',
              icon: <VerticalSplit />,
              link: '/outrights',
            },
            {
              name: <IntlMessages id={'pages.challengesPage'} />,
              type: 'item',
              icon: <Score />,
              link: '/challenges',
            },
            {
              name: <IntlMessages id={'pages.participantsPage'} />,
              type: 'item',
              icon: <PeopleOutlineRounded />,
              link: '/participants',
            },
            {
              name: <IntlMessages id={'pages.rulesPage'} />,
              type: 'item',
              icon: <Gavel />,
              link: '/rules',
            },
            {
              name: <IntlMessages id={'pages.users'} />,
              type: 'item',
              icon: <Contacts />,
              link: '/users',
            },
            {
              name: <IntlMessages id={'pages.adminUsers'} />,
              type: 'item',
              icon: <Contacts />,
              link: '/admin-users',
            },
            {
              name: <IntlMessages id={'pages.languagesPage'} />,
              type: 'item',
              icon: <Language />,
              link: '/translations',
            },
            {
              name: <IntlMessages id={'pages.settingsPage'} />,
              type: 'item',
              icon: <Settings />,
              link: '/settings',
            },
            {
              name: <IntlMessages id={'pages.AML'} />,
              type: 'item',
              icon: <PeopleOutline />,
              link: '/aml',
            },
            {
              name: <IntlMessages id={'pages.fanClub'} />,
              type: 'item',
              icon: <Assignment />,
              link: '/fan-clubs',
            },
            {
              name: <IntlMessages id={'pages.b2bAccount'} />,
              type: 'item',
              icon: <Business />,
              link: '/b2b-account',
            },
            {
              name: <IntlMessages id={'pages.analytics'} />,
              type: 'item',
              icon: <Assessment />,
              link: '/analytics',
            },
          ],
    },
  ];

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={navigationMenus} />
    </PerfectScrollbar>
  );
};

export default SideBar;
