import React, { useState, useEffect, useRef } from 'react';
import { Box, Button } from '@material-ui/core';
import SelectCompetitions from './SelectCompetitions';
import CompetitionCell from './CompetitionCell';
import { useParams } from 'react-router';
const Outrights = ({ competitions, getCompetition, viewDetail }) => {
  const { id } = useParams();
  const [competitionsList, setCompetitionsList] = useState([]);
  const [competitionsListDetail, setCompetitionsListDetail] = useState([]);
  const [competitionsToInsert, setCompetitionsToInsert] = useState([]);
  const [competitionsToRemove, setCompetitionsToRemove] = useState([]);
  const myRef = useRef(null);

  useEffect(() => {
    if (id !== 'new' && viewDetail !== undefined) {
      let competitionsId = [];
      viewDetail.competitions.forEach(item => {
        competitionsId.push(item._id);
      });
      let competitions = [];
      viewDetail.competitions.forEach(item => {
        competitions.push(item);
      });
      setCompetitionsList(competitionsId);
      setCompetitionsListDetail(competitions);
    }
  }, [viewDetail, id]);

  const selectCompetition = competition => {
    let difCompetition = true;
    competitionsListDetail.forEach((comp, key) => {
      if (comp._id === competition._id) {
        difCompetition = false;
      }
    });
    if (difCompetition === true) {
      setCompetitionsToInsert(competitionsToInsert => [...competitionsToInsert, competition._id]);
      setCompetitionsToRemove(competitionsToRemove.filter((item, key) => item !== competition._id));
      setCompetitionsListDetail(competitionsListDetail => [...competitionsListDetail, competition]);
      setCompetitionsList(competitionsList => [...competitionsList, competition._id]);
      sendCompetitions(
        [...competitionsList, competition._id],
        [...competitionsToInsert, competition._id],
        competitionsToRemove.filter((item, key) => item !== competition._id),
      );
    }
  };
  const sendCompetitions = (currentList, toBeInserted, toBeRemoved) => {
    const tempCurrentList = [],
      tempToBeInserted = [],
      tempToBeRemoved = [];
    currentList.forEach(listItem => {
      let tempItem = competitions.find(item => item._id === listItem);
      tempCurrentList.push({ competitionId: tempItem?.competitionId, oddTypeId: tempItem?.oddTypeId });
    });
    toBeInserted.forEach(listItem => {
      let tempItem = competitions.find(item => item._id === listItem);
      tempToBeInserted.push({ competitionId: tempItem?.competitionId, oddTypeId: tempItem?.oddTypeId });
    });
    toBeRemoved.forEach(listItem => {
      let tempItem = competitions.find(item => item._id === listItem);
      tempToBeRemoved.push({ competitionId: tempItem?.competitionId, oddTypeId: tempItem?.oddTypeId });
    });
    getCompetition(tempCurrentList, tempToBeInserted, tempToBeRemoved);
  };
  const removeCompetition = id => {
    setCompetitionsList(competitionsList.filter((item, key) => item !== id));
    setCompetitionsListDetail(competitionsListDetail.filter((item, key) => item._id !== id));
    setCompetitionsToInsert(competitionsToInsert.filter((item, key) => item !== id));
    setCompetitionsToRemove(competitionsToRemove => [...competitionsToRemove, id]);
    sendCompetitions(
      competitionsList.filter((item, key) => item !== id),
      competitionsToInsert.filter((item, key) => item !== id),
      [...competitionsToRemove, id],
    );
  };
  const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  return (
    <Box>
      <Box style={{ display: 'flex', margin: ' 30px 7.5px 15px 7.5px' }}>
        <h2 style={{ marginRight: '25px' }}>Outrights</h2>
        <Button variant="contained" color="primary" onClick={executeScroll}>
          Add
        </Button>
      </Box>
      <Box>
        {competitionsListDetail !== undefined && competitionsListDetail.length > 0 ? (
          <CompetitionCell selectedCompetitions={competitionsListDetail} onRemove={removeCompetition} />
        ) : (
          <h3 style={{ marginLeft: '7.5px', marginBottom: '30px' }}>There are no selected competitions. Please add one.</h3>
        )}
      </Box>
      <Box ref={myRef}>
        <SelectCompetitions
          competition={competitions}
          selectCompetition={selectCompetition}
          selectedCompetitions={competitionsListDetail}
        />
      </Box>
    </Box>
  );
};
export default Outrights;
