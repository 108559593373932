import { GET_OUTRIGHTS_DATA, GET_ALL_OUTRIGHTS_DATA, SET_OUTRIGHTS_FILTERS } from '../../constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';

export const getOutrights = (page, rowsPerPage, status, sportId, searchQuery) => {
  return dispatch => {
    let url = `/outrights?offset=${page * rowsPerPage}&limit=${rowsPerPage}`;
    if (status !== undefined) url += `&status=${status}`;
    if (sportId !== undefined) url += `&sportId=${sportId}`;
    if (searchQuery) url += `&searchText=${searchQuery}`;
    dispatch(fetchStart());
    axios
      .get(url)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_OUTRIGHTS_DATA, payload: data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getAllOutrights = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`/outrights`)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_ALL_OUTRIGHTS_DATA, payload: data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const fetchOutrights = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/outrights/fetch')
      .then(data => {
        dispatch(fetchSuccess());
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const updateOutrights = (competitionId, oddId, data) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .patch(`/outrights/${competitionId}/${oddId}`, data)
      .then(response => {
        dispatch(fetchSuccess());
      })
      .catch(() => {
        dispatch(fetchError('Something went wrong'));
      });
  };
};

export const setOutrightsFilters = filters => {
  return dispatch => {
    dispatch({ type: SET_OUTRIGHTS_FILTERS, payload: filters });
  };
};
