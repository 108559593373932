import React, { useContext, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, getUsersAdmin, getUsersPermissions } from 'redux/actions/Users';
import { getMatchContainerHeight } from 'constants/AppConstants';
import AppContext from 'components/contextProvider/AppContextProvider/AppContext';

import { Button, makeStyles, TablePagination } from '@material-ui/core';

import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';

import RecentTable from './RecentTable';

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddUser from './AddUser';

const useStyles = makeStyles(theme => ({
  cardContentRoot: {
    padding: '0 !important',
  },
  titleRoot: {
    letterSpacing: 0.15,
  },
  popup: {
    position: 'absolute',
    top: '50px',
    maxHeight: '800px',
    left: 'calc(50% - 225px)',
    alignSelf: 'center',
    maxWidth: '500px',
    height: '100%',
    overflow: 'scroll',
    '@media screen and (max-width: 480px)': {
      maxWidth: '400px',
      maxHeight: '600px',
    },
  },
  scrollbarRoot: {
    height: props => `calc(100vh - ${props.height}px - 10px)`,
  },
  badgeRoot: {
    color: theme.palette.common.white,
    borderRadius: 30,
    fontSize: 12,
    padding: '2px 10px',
    display: 'inline-block',
  },
  margin: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const UsersAdmin = ({ width }) => {
  const { userDetail } = useSelector(({ users }) => users);
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState({});
  const [tableDataList, setTableDataList] = useState({});
  const { usersAdminList } = useSelector(({ users }) => users);

  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [open, setOpen] = useState(false);

  const classes = useStyles({
    height: getMatchContainerHeight(width, showFooter),
  });

  useEffect(() => {
    dispatch(getUsersAdmin(page, rowsPerPage, ''));
    if (usersAdminList !== undefined) {
      setTableData(usersAdminList.users);

      setCount(usersAdminList.count);
    }
    dispatch(getUsersPermissions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userDetail]);

  useEffect(() => {
    if (usersAdminList !== undefined && usersAdminList.users.length > 0) {
      setTableData(usersAdminList.users);
      setTableDataList(usersAdminList.users);
      setCount(usersAdminList.count);
    }
    if (usersAdminList !== undefined && usersAdminList.users.length === 0) {
      setTableData(usersAdminList.users);
      setTableDataList(usersAdminList.users);
      setCount(0);
    }
  }, [usersAdminList]);

  const handleChangePage = (event, newPage) => {
    dispatch(getUsers(newPage * rowsPerPage, rowsPerPage));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    setTableData(tableDataList.slice(page, parseInt(event.target.value)) || {});
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CmtCard>
      <CmtCardHeader
        className="pt-4"
        title={'Admin Users List'}
        style={{ marginBottom: '30px', zIndex: '444' }}
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}>
        <Button
          variant="contained"
          style={{ height: '100%', marginLeft: '15px', zIndex: '334' }}
          color="primary"
          onClick={handleClickOpen}>
          <PersonAddIcon style={{ marginRight: '5px' }} />
          Create Admin User
        </Button>
        <AddUser open={open} onClose={handleClose} action={'add'} />
      </CmtCardHeader>
      <CmtCardContent className={classes.cardContentRoot}>
        <PerfectScrollbar className={classes.scrollbarRoot}>
          <RecentTable tableData={tableData} />
        </PerfectScrollbar>
        <TablePagination
          component="div"
          style={{ order: '9999', width: '100%' }}
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default UsersAdmin;
