import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import axios from '../../../services/auth/jwt/config';

import { useDispatch, useSelector } from 'react-redux';
import { setCurrentMatch } from 'redux/actions/MatchApp';
import { Box } from '@material-ui/core';

import AppHeader from './AppHeader';
import Sidebar from './Sidebar';
import MatchesList from './MatchesList';
import MatchDetail from './MatchDetail';
import CreateMatch from './CreateMatch';

import useStyles from './index.style';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';

const MatchApp = () => {
  const classes = useStyles();
  const { isSideBarCollapsed, selectedSport, filterType, topTeam, sortType } = useSelector(({ matchApp }) => matchApp);
  const [viewMode, setViewMode] = useState('table');
  const [showMatchDetail, setShowMatchDetail] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const dispatch = useDispatch();

  const filters = {
    past: 0,
    live: 'popular',
    future: 2,
    'canceled-postponed': 3,
    deleted: 4,
    'past-not-ended': 5,
  };
  const [typeFilter, setTypeFilter] = useState('future');
  const [searchQuery, setSearchQuery] = useState('');
  const [matchesData, setMatchesData] = useState();
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 10,
  });

  const onChangeViewMode = mode => {
    setViewMode(mode);
  };

  const onShowMatchDetail = match => {
    dispatch(setCurrentMatch(match));
    setShowMatchDetail(true);
  };

  const onHideMatchDetail = () => {
    dispatch(setCurrentMatch(null));
    setShowMatchDetail(false);
  };

  const onClickCreateMatch = () => {
    setOpenCreateDialog(true);
  };

  const onClickEditMatch = match => {
    dispatch(setCurrentMatch(match));
    setOpenCreateDialog(true);
  };

  const onCloseComposeDialog = () => {
    dispatch(setCurrentMatch(null));
    setOpenCreateDialog(false);
  };

  useEffect(() => {
    if (selectedSport && selectedSport?._id) {
      let url = `/matches/admin?offset=${pagination.page * pagination.rowsPerPage}&limit=${pagination.rowsPerPage}`;
      if (selectedSport && selectedSport?._id) url += `&sportsIds=${selectedSport._id}`;
      if (searchQuery) url += `&name=${searchQuery}`;
      if (filterType.competition) url += `&competitionId=${filterType.competition}`;
      if (typeFilter) {
        if (typeFilter !== 'live') url += `&status=${filters[typeFilter]}`;
        else url += '&popular=true';
      }
      if (sortType) {
        url += `&sortBy=${sortType.field}&sortOrder=${sortType.order}`;
      }

      dispatch(fetchStart());
      axios
        .get(url)
        .then(data => {
          setTimeout(() => {
            setMatchesData(data.data);
            dispatch(fetchSuccess());
          }, 200);
        })
        .catch(error => {
          if (error.response?.status !== 401) {
            dispatch(fetchError('Something went wrong'));
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSport, searchQuery, filterType.competition, typeFilter, pagination, topTeam, sortType]);

  useEffect(() => {
    setPagination({
      page: 0,
      rowsPerPage: pagination.rowsPerPage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSport, typeFilter, searchQuery, sortType]);

  useEffect(() => {
    setSearchQuery('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSport, typeFilter]);

  return (
    <Box className={classes.inBuildAppCard}>
      <AppHeader
        onChangeViewMode={onChangeViewMode}
        viewMode={viewMode}
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
      />
      <Box className={clsx(classes.inBuildAppContainer, isSideBarCollapsed ? 'collapsed' : '')}>
        <Sidebar onClickCreateMatch={onClickCreateMatch} setTypeFilter={setTypeFilter} />
        <MatchesList
          viewMode={viewMode}
          onShowMatchDetail={onShowMatchDetail}
          onClickEditMatch={onClickEditMatch}
          matchesData={matchesData}
          pagination={pagination}
          setPagination={setPagination}
        />
      </Box>
      {showMatchDetail && <MatchDetail open={showMatchDetail} handleDialog={onHideMatchDetail} />}
      {openCreateDialog && <CreateMatch open={openCreateDialog} handleDialog={onCloseComposeDialog} />}
    </Box>
  );
};

export default MatchApp;
