import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addStadiumMatch } from 'redux/actions/MatchDetails';
import { getModalStyle } from 'constants/AppConstants';

import { Box, makeStyles, Button, Modal, TextField, Divider } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    outline: 'none',
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 8, 6),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const Stadiums = ({ open, handleCloseModal, competitionId, stadium }) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(open);
  const [stadiumName, setStadiumName] = useState('');
  const createNotification = (type, message) => {
    return NotificationManager.error(message, 'ERROR!', 2000);
  };

  const handleClose = () => {
    setOpenModal(false);
    handleCloseModal(false);
    setStadiumName('');
  };

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  useEffect(() => {
    if (stadium) {
      setStadiumName(stadium);
    } else {
      setStadiumName('');
    }
  }, [stadium]);

  const addStadium = () => {
    if (!stadiumName) {
      createNotification('error', 'Stadium Name should not be empty');
    }
    dispatch(addStadiumMatch(competitionId, stadiumName));
    handleClose();
  };

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <div style={modalStyle} className={classes.paper}>
        <Close
          onClick={handleClose}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            right: '20px',
            top: '20px',
          }}
        />
        <div className="stadium-list" style={{ marginBottom: '30px' }}>
          <h2>Stadium</h2>
        </div>
        <Divider />
        <div className="stadium-add" style={{ marginTop: '20px' }}>
          <h2>{!stadium ? 'Add stadium' : 'Edit stadium'}</h2>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              id="outlined-basic"
              label="stadium name"
              variant="outlined"
              value={stadiumName}
              onChange={e => setStadiumName(e.target.value)}
            />
          </form>
          <Box style={{ margin: '0 auto' }} className={classes.root}>
            <Button variant="contained" color="primary" onClick={addStadium}>
              {!stadium ? 'Add Stadium' : 'Edit Stadium'}
            </Button>
          </Box>
        </div>
        <NotificationContainer />
      </div>
    </Modal>
  );
};

export default Stadiums;
