import React, { useContext, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getAppSidebarHeight } from 'constants/AppConstants';
import AppContext from 'components/contextProvider/AppContextProvider/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { withWidth, Box, List } from '@material-ui/core';
import CmtList from '@coremat/CmtList';
import ItemCell from './ItemCell';
import { ListItem } from '@material-ui/core';
import useStyles from '../index.style';
import { getUsersAdmin } from 'redux/actions/Users';
import { setDBFilters } from 'redux/actions/DBTranslations';

const statusFilters = [
  {
    _id: 1,
    name: 'Full translated',
    type: '1',
    tooltip: "Terms without translations with 'needs update' status",
  },
  {
    _id: 2,
    name: 'Needs update',
    type: '0',
    tooltip: "Terms with tanslations with 'needs update' status",
  },
];

const Sidebar = ({ width }) => {
  const dispatch = useDispatch();
  const { usersAdminList } = useSelector(({ users }) => users);
  const { filters } = useSelector(({ DBTranslations }) => DBTranslations);
  const { showFooter } = useContext(AppContext);
  const [statusFilter, setStatusFilter] = useState(filters.status);
  const [sectionFilter, setSectionFilter] = useState();
  const [addedByFilter, setAddedByFilter] = useState(filters.addedBy);
  const [updatedByFilter, setUpdatedByFilter] = useState(filters.updatedBy);

  useEffect(() => {
    dispatch(getUsersAdmin(null, null, ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statusHandler = filter => {
    if (statusFilter?._id === filter._id) {
      dispatch(setDBFilters({ ...filters, status: '', offset: 0 }));
      setStatusFilter('');
    } else {
      dispatch(setDBFilters({ ...filters, status: filter, offset: 0 }));
      setStatusFilter(filter);
    }
  };

  const sectionHandler = section => {
    if (addedByFilter?._id === section._id) {
      dispatch(setDBFilters({ ...filters, section: '', offset: 0 }));
      setSectionFilter('');
    } else {
      dispatch(setDBFilters({ ...filters, section: section, offset: 0 }));
      setSectionFilter(section);
    }
  };

  const addedByHandler = user => {
    if (addedByFilter?._id === user._id) {
      dispatch(setDBFilters({ ...filters, addedBy: '', offset: 0 }));
      setAddedByFilter('');
    } else {
      dispatch(setDBFilters({ ...filters, addedBy: user, offset: 0 }));
      setAddedByFilter(user);
    }
  };

  const updatedByHandler = user => {
    if (updatedByFilter?._id === user._id) {
      setUpdatedByFilter('');
      dispatch(setDBFilters({ ...filters, updatedBy: '', offset: 0 }));
    } else {
      dispatch(setDBFilters({ ...filters, updatedBy: user, offset: 0 }));
      setUpdatedByFilter(user);
    }
  };
  const classes = useStyles({
    isCollapsed: false,
    height: getAppSidebarHeight(width, showFooter),
  });

  return (
    <Box className={classes.inBuildAppSidebar}>
      <PerfectScrollbar className={classes.perfectScrollbarMatchSidebar}>
        <ListItem component="div" className={classes.appNavHeaderItem}>
          <Box component="span" className={classes.appNavHeaderItemText}>
            Filter by Status
          </Box>
        </ListItem>
        <List component="nav" className={classes.appNav}>
          <CmtList
            data={statusFilters}
            renderRow={(item, index) => (
              <ItemCell
                item={item}
                classes={classes}
                selectedItem={statusFilter?._id}
                onChange={() => statusHandler(item)}
                key={index}
              />
            )}
          />
        </List>

        <ListItem component="div" className={classes.appNavHeaderItem}>
          <Box component="span" className={classes.appNavHeaderItemText}>
            Filter by Section
          </Box>
        </ListItem>

        <ListItem component="div" className={classes.appNavHeaderItem}>
          <Box component="span" className={classes.appNavHeaderItemText}>
            Filter by Added by
          </Box>
        </ListItem>
        <List component="nav" className={classes.appNav}>
          <CmtList
            data={usersAdminList?.users}
            renderRow={item => (
              <ItemCell
                key={item._id}
                item={item}
                classes={classes}
                selectedItem={addedByFilter?._id}
                onChange={() => addedByHandler(item)}
              />
            )}
          />
        </List>

        <ListItem component="div" className={classes.appNavHeaderItem}>
          <Box component="span" className={classes.appNavHeaderItemText}>
            Filter by Updated by
          </Box>
        </ListItem>
        <List component="nav" className={classes.appNav}>
          <CmtList
            data={usersAdminList?.users}
            renderRow={item => (
              <ItemCell
                key={item._id}
                item={item}
                classes={classes}
                selectedItem={updatedByFilter?._id}
                onChange={() => updatedByHandler(item)}
              />
            )}
          />
        </List>
      </PerfectScrollbar>
    </Box>
  );
};

export default withWidth()(Sidebar);
