import React from 'react';
import { Box, Button, TextField } from '@material-ui/core';
import CmtAvatar from '@coremat/CmtAvatar';
import useStyles from '../index.style';
import { useParams } from 'react-router';

const SelectionCell = ({ selectedSelections, onRemove }) => {
  const classes = useStyles();
  const { id } = useParams();
  return (
    <Box className={classes.selectedMatchesList}>
      {selectedSelections.map((selection, id) => {
        return (
          <Box key={id} className={classes.selectListMatchRow}>
            <Box>
              <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
                <Box
                  mr={4}
                  style={{
                    width: '30px',
                    overflow: 'hidden',
                    marginRight: '0',
                    borderRight: '1px solid #fff',
                  }}>
                  <CmtAvatar size={60} alt={selection.name} />
                </Box>
                <Box mr={4} style={{ width: '30px', overflow: 'hidden', marginRight: '0' }}>
                  <CmtAvatar size={60} style={{ transform: 'translateX(-30px)' }} alt={selection.name} />
                </Box>
              </Box>
            </Box>
            <Box>
              <Box>
                <h3 style={{ fontWeight: 'bold' }}>{selection.name}</h3>
              </Box>
              <Box className={classes.selectionsDate}>
                Matches: <strong>{selection.games.length}</strong>
                <br />
                Outrights: <strong>{selection.competitions.length}</strong>
              </Box>
            </Box>
            <Button
              style={{ marginLeft: 'auto' }}
              variant="contained"
              color="primary"
              disabled={selection.status === 'Ended'}
              onClick={() => onRemove(selection._id)}>
              Remove
            </Button>
          </Box>
        );
      })}
    </Box>
  );
};

export default SelectionCell;
