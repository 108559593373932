import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, TextField, Button, Box } from '@material-ui/core';
import { getUserDetail, getUserTransactions, getUserWalletTransfer, patchUser, getUserDocuments } from 'redux/actions/Users';
import Wallet from './Wallet';
import Transactions from './Transactions';
import 'react-notifications/lib/notifications.css';
import IdentityVerification from './IdentityVerification';
import GlobalMessages from 'assets/global/globalMessages';
import GlobalVariables from 'assets/global/globalVariables';

import NoProfilePicture from 'assets/images/no-profile-picture.svg';

const UserDetail = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { userDetail, userWallet, userTransactions, userDocuments } = useSelector(({ users }) => users);
  const [fullDetail, setFullDetail] = React.useState(undefined);
  const [fullWallet, setFullWallet] = React.useState(undefined);
  const [fullTransactions, setFullTransactions] = React.useState(undefined);
  const [documents, setDocuments] = React.useState();
  const [transactionCount, setTransactionCount] = React.useState(0);
  const [limitsPopup, setLimitsPopup] = React.useState(false);
  const [walletPage, setWalletPage] = React.useState(0);
  const [transPage, setTransPage] = React.useState(0);

  const [weeklyLimit, setWeeklyLimit] = React.useState(0);
  const [dailyLimit, setDailyLimit] = React.useState(0);
  const [monthlyLimit, setMonthlyLimit] = React.useState(0);

  const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
    },
    profileImage: {
      width: '150px',
      height: '150px',
      objectFit: 'cover',
    },
    textFieldHead: {
      margin: '10px 0',
      width: '100%',
    },
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        minWidth: '400px',
      },
    },
    limitsPopup: {
      position: 'absolute',
      top: '0',
      right: '0',
      zIndex: '333',
      background: 'white',
      padding: '30px 15px',
      minWidth: '300px',
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '30px',
      '& h2': {
        marginBottom: '30px',
      },
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    dispatch(getUserDetail(id));
    dispatch(getUserDetail(id));
    dispatch(getUserDocuments(id));
    dispatch(getUserWalletTransfer(id, walletPage));
    dispatch(getUserTransactions(id, transPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (userTransactions !== undefined) {
      setTransactionCount(userTransactions.count);
    }
  }, [userTransactions]);

  const changePage = (event, newPage) => {
    dispatch(getUserWalletTransfer(id, newPage * 5));
    setWalletPage(newPage);
  };

  const changePageTransaction = (event, newPage) => {
    dispatch(getUserTransactions(id, newPage * 5));
    setTransPage(newPage);
  };

  useEffect(() => {
    setFullDetail(userDetail);
    if (userDetail !== undefined && userDetail.depositLimit !== undefined) {
      userDetail.depositLimit.forEach((item, key) => {
        if (item.type === 0) {
          setDailyLimit(item.limit);
        }
        if (item.type === 1) {
          setWeeklyLimit(item.limit);
        }
        if (item.type === 2) {
          setMonthlyLimit(item.limit);
        }
      });
    }
    setLimitsPopup(false);
  }, [userDetail]);

  useEffect(() => {
    setFullWallet(userWallet);
  }, [userWallet]);

  useEffect(() => {
    setFullTransactions(userTransactions);
  }, [userTransactions]);

  useEffect(() => {
    setDocuments(documents);
  }, [documents]);

  return (
    userDocuments !== undefined &&
    fullDetail !== undefined &&
    fullWallet !== undefined &&
    fullTransactions !== undefined && (
      <Box component="div">
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <img
            alt="profile"
            className={classes.profileImage}
            src={fullDetail.profilePicture ? fullDetail.profilePicture : NoProfilePicture}
          />
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '30px',
            }}>
            <Box>
              <h1>
                {fullDetail.firstName} {fullDetail.lastName}
              </h1>
            </Box>
            <Box>
              <strong>ID</strong>: {fullDetail._id}
            </Box>
            <Box>
              <strong>Birthday</strong>: {new Date(fullDetail.birthDate).toLocaleDateString()}
            </Box>
            <Box>
              <strong>Address</strong>: {fullDetail.address ? fullDetail.address : 'N/A'}
            </Box>
            <Box>
              <strong>Country</strong>: {fullDetail.country ? fullDetail.country : 'N/A'}
            </Box>
            <Box>
              {GlobalVariables.licenses.map(license => {
                if (license.type === fullDetail.licenseType) {
                  return (
                    <Box key={license.type}>
                      <strong>License</strong>: {license.value}
                    </Box>
                  );
                }
                return false;
              })}
            </Box>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto' }}>
            <Box>
              <Button
                variant="contained"
                color="primary"
                style={{ maxHeight: '40px', marginRight: '10px' }}
                onClick={e => {
                  dispatch(
                    patchUser(fullDetail._id, {
                      accountBlocked: !fullDetail.accountBlocked,
                    }),
                  );
                }}>
                {fullDetail.accountBlocked === false ? 'Block User' : 'Unblock User'}
              </Button>
            </Box>
            <Box style={{ position: 'relative' }}>
              <Button
                variant="contained"
                color="primary"
                style={{ maxHeight: '40px' }}
                onClick={() => {
                  setLimitsPopup(!limitsPopup);
                }}>
                Change Limits
              </Button>
              {limitsPopup && (
                <Box className={classes.limitsPopup}>
                  <TextField
                    value={dailyLimit}
                    onChange={e => {
                      setDailyLimit(e.target.value);
                    }}
                    id="outlined-basic"
                    label={'Daily'}
                    className={classes.textFieldHead}
                    variant="outlined"
                  />
                  <TextField
                    value={weeklyLimit}
                    onChange={e => {
                      setWeeklyLimit(e.target.value);
                    }}
                    id="outlined-basic"
                    label={'Weekly'}
                    className={classes.textFieldHead}
                    variant="outlined"
                  />
                  <TextField
                    value={monthlyLimit}
                    onChange={e => {
                      setMonthlyLimit(e.target.value);
                    }}
                    id="outlined-basic"
                    label={'Monthly'}
                    className={classes.textFieldHead}
                    variant="outlined"
                  />
                  <Box style={{ display: 'flex' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ maxHeight: '40px' }}
                      onClick={() => {
                        dispatch(
                          patchUser(fullDetail._id, {
                            depositLimit: [
                              {
                                type: 0,
                                limit: dailyLimit,
                              },
                              {
                                type: 1,
                                limit: weeklyLimit,
                              },
                              {
                                type: 2,
                                limit: monthlyLimit,
                              },
                            ],
                          }),
                        );
                      }}>
                      Update Limits
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ maxHeight: '40px', marginLeft: 'auto' }}
                      onClick={() => {
                        setLimitsPopup(!limitsPopup);
                      }}>
                      Cancel
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box className={classes.section}>
          {userDocuments.length === 0 ? (
            <h2>{GlobalMessages.noDocuments}</h2>
          ) : (
            <>
              <IdentityVerification documents={userDocuments} />
            </>
          )}
        </Box>
        <Box className={classes.section}>
          {fullWallet.walletTransfers?.length === 0 ? (
            <h2>{GlobalMessages.noWalletTransfers}</h2>
          ) : (
            <>
              <Wallet wallets={fullWallet} changePage={changePage} walletPage={walletPage} />
            </>
          )}
        </Box>
        <Box className={classes.section}>
          {fullTransactions.transactions?.length === 0 ? (
            <h2>{GlobalMessages.noTransactions}</h2>
          ) : (
            <>
              <Transactions
                transactions={fullTransactions}
                countTransaction={transactionCount}
                changePageTransaction={changePageTransaction}
                transPage={transPage}
              />
            </>
          )}
        </Box>
      </Box>
    )
  );
};

export default UserDetail;
