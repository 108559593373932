import { Button, TextField } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import React, { useState } from 'react';
import axios from '../../../../services/auth/jwt/config';
import { fetchError } from '../../../../redux/actions';
import useStyles from '../index.style';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import copy from 'clipboard-copy';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const GenerateInviteLink = () => {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [inviteLink, setInviteLink] = useState(null);
  const handleGenerateInviteLink = () => {
    axios
      .post(`/environments/invite/${id}`)
      .then(response => {
        setInviteLink(response.data.inviteLink);
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };

  const handleCopyLink = () => {
    if (inviteLink) copy(inviteLink).then(() => NotificationManager.info('Link copied to clipboard'));
  };

  return (
    <div className={classes.inviteLinkContainer} style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
      {inviteLink && (
        <TextField
          id="outlined-basic"
          className={classes.inviteLinkInput}
          label={'Invite Link'}
          inputProps={{ readOnly: true }}
          variant="outlined"
          value={inviteLink}
          onClick={handleCopyLink}
          style={{ width: 400 }}
        />
      )}
      <Button
        onClick={handleGenerateInviteLink}
        variant="contained"
        color="primary"
        component="span"
        startIcon={<LinkIcon />}>
        Generate invite link
      </Button>
    </div>
  );
};

export default GenerateInviteLink;
