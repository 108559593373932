import React from 'react';
import { useHistory } from 'react-router-dom';
import { alpha, IconButton, Tooltip, Box, TableRow, TableCell, makeStyles } from '@material-ui/core';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import CmtAvatar from '@coremat/CmtAvatar';
import CmtMediaObject from '@coremat/CmtMediaObject';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Moment from 'moment';

const useStyles = makeStyles(theme => ({
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&:last-child': {
          color: theme.palette.error.main,
        },
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    padding: 16,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.secondary,
    borderBottom: '0 none',
    position: 'relative',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      textAlign: 'right',
      color: theme.palette.error.main,
      paddingRight: 24,
    },
    '&.success': {
      color: theme.palette.success.main,
    },
    '& .Cmt-media-object': {
      alignItems: 'center',
    },
  },
  badgeRoot: {
    color: theme.palette.common.white,
    borderRadius: 30,
    fontSize: 12,
    padding: '2px 10px',
    display: 'inline-block',
  },
}));

const actions = [
  {
    label: 'View challenge',
  },
];

const TableItem = ({ row }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <TableRow className={classes.tableRowRoot}>
      <TableCell className={classes.tableCellRoot}>
        <CmtMediaObject
          avatarPos="center"
          avatar={<CmtAvatar size={35} src={row.profilePic} alt={row.name} />}
          title={row.name}
          titleProps={{
            variant: 'h5',
            className: classes.titleRoot,
          }}
        />
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        Start: {Moment(row.startDate).format('H:mm - D MMM yyyy')}
        <br /> End: {Moment(row.endDate).format('H:mm - D MMM yyyy')}
      </TableCell>
      <TableCell className={classes.tableCellRoot}>{row.matches.length}</TableCell>
      <TableCell className={classes.tableCellRoot}>
        Invited: {row.invitedUsersCount} <br />
        Accepted: {row.acceptedUsersCount} <br />
        Rejected: {row.rejectedUsersCount}
      </TableCell>
      <TableCell className={classes.tableCellRoot}>{row.stake}</TableCell>
      <TableCell className={classes.tableCellRoot}>{row.totalPrize}</TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <CmtDropdownMenu
            TriggerComponent={
              <Tooltip title="More">
                <IconButton size="small" style={{ marginLeft: 10 }}>
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
            }
            items={actions}
            onItemClick={() => {
              history.push('/challenge/' + row._id);
            }}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TableItem;
