export const AppHeaderHeight = 72;
export const AppHeaderHeightSm = 64;
export const AppFooterHeight = 72;
export const AppFooterHeightSm = 64;
export const AppMainContentPadding = 60;
export const InBuildAppHeaderHeight = 70;

export const InBuildAppSidebarHeaderHeight = 86;
export const MailAppContentHeaderHeight = 78;

export const OfficialChampionshipDetailHeaderHeight = 72;
export const OfficialChampionshipDetailAddCommentHeight = 72;

export const ChatSidebarHeaderHeight = 124;
export const ChatSidebarTabContainerHeight = 49;
export const ChatFooterHeight = 70;
export const CurrentAuthMethod = 'jwtAuth'; //jwtAuth,firebase

export const getAppSidebarHeight = (width, visibleAppFooter) => {
  switch (width) {
    case 'xs':
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        InBuildAppSidebarHeaderHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    case 'sm':
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        InBuildAppSidebarHeaderHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    default:
      return (
        AppHeaderHeight +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        InBuildAppSidebarHeaderHeight +
        (visibleAppFooter ? AppHeaderHeight : 0)
      );
  }
};

export const getMailContainerHeight = (width, visibleAppFooter) => {
  switch (width) {
    case 'xs':
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        MailAppContentHeaderHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    case 'sm':
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        MailAppContentHeaderHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    default:
      return (
        AppHeaderHeight +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        MailAppContentHeaderHeight +
        (visibleAppFooter ? AppFooterHeight : 0)
      );
  }
};

export const getContactContainerHeight = (width, visibleAppFooter) => {
  switch (width) {
    case 'xs':
      return AppHeaderHeightSm + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeightSm : 0);
    case 'sm':
      return AppHeaderHeightSm + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeightSm : 0);
    default:
      return AppHeaderHeight + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeight : 0);
  }
};

export const getOfficialChampionshipListSidebarHeight = (width, visibleAppFooter) => {
  switch (width) {
    case 'xs':
      return AppHeaderHeightSm + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeightSm : 0);
    case 'sm':
      return AppHeaderHeightSm + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeightSm : 0);
    default:
      return AppHeaderHeight + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeight : 0);
  }
};

export const getOfficialChampionshipListContainerHeight = (width, visibleAppFooter) => {
  switch (width) {
    case 'xs':
      return AppHeaderHeightSm + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeightSm : 0);
    case 'sm':
      return AppHeaderHeightSm + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeightSm : 0);
    default:
      return AppHeaderHeight + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeight : 0);
  }
};

export const getOfficialChampionshipDetailContainerHeight = (width, visibleAppFooter) => {
  switch (width) {
    case 'xs':
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        OfficialChampionshipDetailHeaderHeight +
        OfficialChampionshipDetailAddCommentHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    case 'sm':
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        OfficialChampionshipDetailHeaderHeight +
        OfficialChampionshipDetailAddCommentHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    default:
      return (
        AppHeaderHeight +
        AppMainContentPadding +
        OfficialChampionshipDetailHeaderHeight +
        OfficialChampionshipDetailAddCommentHeight +
        (visibleAppFooter ? AppFooterHeight : 0)
      );
  }
};

export const getChatSidebarHeight = (width, visibleAppFooter) => {
  switch (width) {
    case 'xs':
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        ChatSidebarHeaderHeight +
        ChatSidebarTabContainerHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    case 'sm':
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        ChatSidebarHeaderHeight +
        ChatSidebarTabContainerHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    default:
      return (
        AppHeaderHeight +
        AppMainContentPadding +
        ChatSidebarHeaderHeight +
        ChatSidebarTabContainerHeight +
        (visibleAppFooter ? AppFooterHeight : 0)
      );
  }
};

export const getChatContainerHeight = (width, visibleAppFooter) => {
  switch (width) {
    case 'xs':
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        ChatFooterHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    case 'sm':
      return (
        AppHeaderHeightSm +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        ChatFooterHeight +
        (visibleAppFooter ? AppFooterHeightSm : 0)
      );
    default:
      return (
        AppHeaderHeight +
        AppMainContentPadding +
        InBuildAppHeaderHeight +
        ChatFooterHeight +
        (visibleAppFooter ? AppFooterHeight : 0)
      );
  }
};

export const getWallHeight = (width, visibleAppFooter) => {
  switch (width) {
    case 'xs':
      return AppHeaderHeightSm + AppMainContentPadding + (visibleAppFooter ? AppFooterHeightSm : 0);
    case 'sm':
      return AppHeaderHeightSm + AppMainContentPadding + (visibleAppFooter ? AppFooterHeightSm : 0);
    default:
      return AppHeaderHeight + AppMainContentPadding + (visibleAppFooter ? AppFooterHeight : 0);
  }
};

export const getMatchContainerHeight = (width, visibleAppFooter) => {
  switch (width) {
    case 'xs':
      return AppHeaderHeightSm + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeightSm : 0);
    case 'sm':
      return AppHeaderHeightSm + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeightSm : 0);
    default:
      return AppHeaderHeight + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeight : 0);
  }
};

export const getBarContainerHeight = (width, visibleAppFooter) => {
  switch (width) {
    case 'xs':
      return AppHeaderHeightSm + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeightSm : 0);
    case 'sm':
      return AppHeaderHeightSm + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeightSm : 0);
    default:
      return AppHeaderHeight + AppMainContentPadding + InBuildAppHeaderHeight + (visibleAppFooter ? AppFooterHeight : 0);
  }
};

export const buildSearch = (obj, disabled) => {
  let str = [];
  for (let p in obj) {
    if (obj.hasOwnProperty(p) && obj[p] !== '' && disabled[p] !== true) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }
  return str.join('&');
};

export const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

export const docVer = [
  {
    label: 'REQUIRED',
    id: '0',
  },
  {
    label: 'IN PROGRESS',
    id: '1',
  },
  {
    label: 'COMPLETE',
    id: '2',
  },
  {
    label: 'REQUESTED',
    id: '3',
  },
];

export const risk = [
  {
    label: 'LOW',
    id: '0',
  },
  {
    label: 'MEDIUM',
    id: '1',
  },
  {
    label: 'HIGH',
    id: '2',
  },
];

export const AMLrisk = [
  {
    label: 'MEDIUM',
    id: '0',
  },
  {
    label: 'HIGH',
    id: '1',
  },
];

export const licenseType = [
  {
    label: 'DEMO',
    id: '0',
  },
  {
    label: 'UK_LICENSE',
    id: '1',
  },
  {
    label: 'MALTA_LICENSE',
    id: '2',
  },
];

export const appMenuList = [
  {
    id: 0,
    menu: 'FEED',
  },
  {
    id: 1,
    menu: 'EVENTS',
  },
  {
    id: 2,
    menu: 'CHALLENGES',
  },
  {
    id: 3,
    menu: 'SOCIAL',
  },
  {
    id: 4,
    menu: 'OFFICIAL_CHALLENGE',
  },
  {
    id: 5,
    menu: 'YEPP_CLASSIC_TO_SIMPLE',
  },
  {
    id: 6,
    menu: 'YEPP_SIMPLE_TO_CLASSIC',
  },
];
