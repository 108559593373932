import { UPDATE_AUTH_USER, UPDATE_LOAD_USER } from '../../constants/ActionTypes';

export const setAuthUser = user => {
  return dispatch => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: user,
    });
  };
};

export const updateLoadUser = loading => {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAD_USER,
      payload: loading,
    });
  };
};

export const updateB2BStatus = ({ isB2B, b2bExternalType }) => {
  return dispatch => {
    dispatch({
      type: 'UPDATE_B2B_STATUS',
      payload: { isB2B, b2bExternalType },
    });
  };
};
