import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOutrights, updateOutrights } from 'redux/actions/Outrights';
import clsx from 'clsx';
import { Select, MenuItem, FormControl, alpha, TableCell, makeStyles, TableRow, TextField, Button } from '@material-ui/core';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Moment from 'moment';

const useStyles = makeStyles(theme => ({
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&:last-child': {
          color: theme.palette.error.main,
        },
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    padding: 16,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.secondary,
    borderBottom: '0 none',
    position: 'relative',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&.success': {
      color: theme.palette.success.main,
    },
    '& .Cmt-media-object': {
      alignItems: 'center',
    },
  },
  badgeRoot: {
    color: theme.palette.common.white,
    borderRadius: 30,
    fontSize: 12,
    padding: '2px 10px',
    display: 'inline-block',
  },
}));

const statusTypes = [
  { id: 0, value: 0, label: 'Inactive' },
  { id: 1, value: 1, label: 'Active' },
  { id: 2, value: 2, label: 'Finished' },
];

const TableItem = ({ row }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { filters } = useSelector(({ outrights }) => outrights);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [date, setDate] = useState();

  const handleChange = (event, row) => {
    dispatch(updateOutrights(row.competitionId, row.oddTypeId, { status: event.target.value }));
    setTimeout(() => {
      dispatch(getOutrights(filters.offset, filters.limit, filters.status?.type, filters.sport?._id, filters.searchQuery));
    }, 400);
  };

  const dateChangeHandler = event => {
    const newDate = new Date(event.target.value).toISOString();
    const currentDate = new Date().toISOString();
    if (newDate < currentDate) {
      NotificationManager.error("You cannot set a past date for outright's bet limit date", 'ERROR!', 7000);
    } else {
      if (newDate > row?.competition?.endTime) {
        NotificationManager.error(
          `The end date of the competition is ${Moment(row?.competition?.endTime).format(
            'YYYY-MM-DD hh:mm',
          )}. You can’t set a date later than that!`,
          'ERROR!',
          7000,
        );
      } else {
        setModalIsOpen(true);
        setDate(event.target.value);
      }
    }
  };

  const handleTime = () => {
    const newDate = new Date(date).toISOString();

    dispatch(updateOutrights(row.competitionId, row.oddTypeId, { betLimitDate: newDate }));
    setTimeout(() => {
      dispatch(getOutrights(filters.offset, filters.limit, filters.status?.type, filters.sport?._id, filters.searchQuery));
    }, 400);
    setModalIsOpen(false);
  };

  return (
    <>
      <TableRow className={classes.tableRowRoot}>
        <TableCell className={classes.tableCellRoot}>{row.country !== null ? row.competition.name : 'NULL'}</TableCell>
        <TableCell className={classes.tableCellRoot}>{row.country !== null ? row.oddType.name : 'NULL'}</TableCell>
        <TableCell className={classes.tableCellRoot}>
          {row.country !== null ? row.oddType.visibleEventParts[0]?.name || '-' : 'NULL'}
        </TableCell>
        <TableCell className={clsx(classes.tableCellRoot, 'success')}>
          <FormControl style={{ margin: '12.5px 20px' }} className={classes.formControl}>
            <Select name="status" value={row.status} onChange={e => handleChange(e, row)}>
              {statusTypes.map(statusType => {
                return (
                  <MenuItem key={statusType.id} value={statusType.value}>
                    {statusType.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell className={classes.tableCellRoot}>
          <TextField
            onChange={dateChangeHandler}
            id="datetime-local"
            type="datetime-local"
            value={row?.betLimitDate ? moment(row?.betLimitDate).format('YYYY-MM-DDTkk:mm') : ''}
            sx={{ width: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </TableCell>
      </TableRow>
      <Dialog
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to change the Bet Limit Date for{' '}
            <b>
              {row?.competition?.name} - {row?.oddType?.name} - {row?.oddType?.visibleEventParts[0]?.name}
            </b>
            ? <br />
            <br />
            New Bet Limit Date: {moment(date).format('DD-MM-YYYY, HH:mm')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalIsOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleTime} color="primary" variant="contained" autoFocus>
            Change Bet Limit Date
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TableItem;
