import React from 'react';
import { Box, TableRow, TableCell, TableHead, makeStyles } from '@material-ui/core';

const TableHeading = ({ headingData }) => {
  const useStyles = makeStyles(theme => ({
    tableCellRootHeader: {
      fontSize: 12,
      letterSpacing: 0.4,
      color: theme.palette.common.dark,
      borderBottom: '0 none',
      cursor: 'pointer',
      '&:first-child': {
        paddingLeft: 24,
      },
      '&:last-child': {
        textAlign: 'right',
        paddingRight: 44,
      },
    },
    headerBox: {
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center',
    },
    headerStrong: {
      marginRight: '15px',
    },
  }));
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        {headingData.map(header => (
          <TableCell className={classes.tableCellRootHeader} key={header.id}>
            <Box className={classes.headerBox}>
              <strong className={classes.headerStrong}>{header.title}</strong>
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeading;
