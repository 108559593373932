import React from 'react';
import { Badge, Box, TextField, Tooltip } from '@material-ui/core';
import CmtAvatar from '@coremat/CmtAvatar';
import useStyles from '../index.style';
import Moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomFinalBettingDatesOutrights } from 'redux/actions/OfficialChampionship';
import { NotificationManager } from 'react-notifications';

const CompetitionCell = ({ selectedCompetitions }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { customFinalBettingDatesOutrights } = useSelector(({ officialChampionship }) => officialChampionship);

  const finalBettingDateHandler = (e, id, competitionId, oddTypeId, betLimitDate, competitionEndTime) => {
    const date = new Date(e.target.value).toISOString();
    const currentDate = new Date().toISOString();
    if (date > currentDate && date <= competitionEndTime) {
      if (!betLimitDate) {
        customFinalBettingDatesOutrights[id] = {
          competitionId: competitionId,
          finalBettingDate: date,
          oddTypeId: oddTypeId,
        };
        dispatch(setCustomFinalBettingDatesOutrights({ ...customFinalBettingDatesOutrights }));
      } else {
        if (betLimitDate && date < betLimitDate) {
          customFinalBettingDatesOutrights[id] = {
            competitionId: competitionId,
            finalBettingDate: date,
            oddTypeId: oddTypeId,
          };
          dispatch(setCustomFinalBettingDatesOutrights({ ...customFinalBettingDatesOutrights }));
        } else {
          NotificationManager.error(
            `Global betting end date is set to ${Moment(betLimitDate).format(
              'YYYY-MM-DD hh:mm',
            )} in Admin/Outrights. You can’t set a date later than that!`,
            'ERROR!',
            7000,
          );
        }
      }
    } else {
      if (date < currentDate)
        NotificationManager.error("You cannot set a past date for outright's final betting date", 'ERROR!', 7000);
      else if (date > competitionEndTime) {
        NotificationManager.error(
          `The end date of the competition is ${Moment(competitionEndTime).format(
            'YYYY-MM-DD hh:mm',
          )}. You can’t set a date later than that!`,
          'ERROR!',
          7000,
        );
      }
    }
  };

  return (
    <Box className={classes.selectedMatchesList}>
      {selectedCompetitions.map((competition, id) => {
        return (
          <Box key={id} className={classes.selectListMatchRow}>
            <Box>
              <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
                <Box
                  mr={4}
                  style={{
                    width: '30px',
                    overflow: 'hidden',
                    marginRight: '0',
                    borderRight: '1px solid #fff',
                  }}>
                  <CmtAvatar size={60} alt={competition?.competition?.name} />
                </Box>
                <Box mr={4} style={{ width: '30px', overflow: 'hidden', marginRight: '0' }}>
                  <CmtAvatar size={60} style={{ transform: 'translateX(-30px)' }} alt={competition?.competition?.name} />
                </Box>
              </Box>
            </Box>
            <Badge
              badgeContent={
                competition?.betsPlaced ? (
                  <Tooltip title="Number of bets" arrow>
                    <span>{competition?.betsPlaced || 0}</span>
                  </Tooltip>
                ) : null
              }
              color="primary"
              showZero
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}>
              <Box style={{ paddingRight: '15px' }}>
                <Box>
                  <h3 style={{ fontWeight: 'bold' }}>{competition.competition.name}</h3>
                </Box>
                <Box>{Moment(competition.competition.endTime).format('H:mm - D MMM yyyy')}</Box>
                <Box>
                  {competition.oddType.name} - {competition.oddType.availableEventParts[0].name}
                </Box>
              </Box>
            </Badge>
            <Box style={{ marginLeft: 'auto' }}>
              <TextField
                style={{ padding: ' 0 30px' }}
                id="datetime-local"
                type="datetime-local"
                onChange={e =>
                  finalBettingDateHandler(
                    e,
                    competition._id,
                    competition.competitionId,
                    competition.oddTypeId,
                    competition?.betLimitDate || competition?.outright?.betLimitDate,
                    competition?.competition?.endTime,
                  )
                }
                value={
                  competition?.finalBettingDate
                    ? customFinalBettingDatesOutrights[competition._id]?.finalBettingDate
                      ? Moment(customFinalBettingDatesOutrights[competition._id]?.finalBettingDate).format(
                          'YYYY-MM-DDTkk:mm',
                        )
                      : Moment(competition?.finalBettingDate).format('YYYY-MM-DDTkk:mm')
                    : customFinalBettingDatesOutrights[competition._id]?.finalBettingDate
                    ? Moment(customFinalBettingDatesOutrights[competition._id]?.finalBettingDate).format('YYYY-MM-DDTkk:mm')
                    : Moment(competition?.betLimitDate || competition.competition.endTime).format('YYYY-MM-DDTkk:mm')
                }
                sx={{ width: 250 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default CompetitionCell;
