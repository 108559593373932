import React from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import useStyles from './index.style';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';

import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DBTranslationsDetails from './DBTranslationDetails';

const DBTranslationsCreateEdit = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  return (
    <CmtCard>
      <CmtCardHeader
        className="pt-4"
        icon={
          <ArrowBackIcon
            style={{ cursor: 'pointer' }}
            onClick={() => {
              history.push('/db-translations');
            }}
          />
        }
        title={id !== undefined ? 'Edit Translation' : 'Add Translation'}
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}
      />
      <CmtCardContent className={classes.cardContentRoot}>
        <DBTranslationsDetails />
        <NotificationContainer />
      </CmtCardContent>
    </CmtCard>
  );
};

export default DBTranslationsCreateEdit;
