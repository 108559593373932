import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Auth from './Auth';
import BarApp from './BarApp';
import Teams from './Teams';
import Languages from './Languages';
import Competitions from './Competitions';
import MatchApp from './MatchApp';
import MatchDetails from './MatchDetails';
import OfficialChampionshipApp from './OfficialChampionshipApp';
import OfficialChampionship from './OfficialChampionship';
import Rules from './Rules';
import Settings from './Settings';
import Users from './Users';
import Challenges from './Challenges';
import Odds from './Odds';
import BetTemplate from './BetTemplate';
import Ecosystems from './Ecosystems';
import Outrights from './Outrights';
import Selections from './Selections';
import FanClub from './FanClub';
import PopUps from './PopUps';
import DBTranslations from './DBTranslations';
import Polls from './Polls';
import B2BAccount from './B2BAccount';
import analytics from './Analytics';

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    matchApp: MatchApp,
    matchDetails: MatchDetails,
    barApp: BarApp,
    languages: Languages,
    teams: Teams,
    rules: Rules,
    settings: Settings,
    users: Users,
    competitions: Competitions,
    outrights: Outrights,
    challenges: Challenges,
    officialChampionshipApp: OfficialChampionshipApp,
    officialChampionship: OfficialChampionship,
    selections: Selections,
    fanClub: FanClub,
    odds: Odds,
    betTemplates: BetTemplate,
    Ecosystems: Ecosystems,
    popUps: PopUps,
    DBTranslations: DBTranslations,
    polls: Polls,
    b2bAccount: B2BAccount,
    analytics: analytics,
  });
