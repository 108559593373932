import React, { useContext, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import AppContext from 'components/contextProvider/AppContextProvider/AppContext';
import { getMatchContainerHeight } from 'constants/AppConstants';
import { getChallenges } from 'redux/actions/Challenges';
import { makeStyles, Button, InputLabel, FormControl, Input, TablePagination } from '@material-ui/core';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import RecentTable from './RecentTable';
import SearchIcon from '@material-ui/icons/Search';

const CompetitionSearch = props => {
  const [value, setValue] = useState(props.value);
  const submitVal = () => {
    if (value && value.length > 0) {
      props.submit(value);
    } else {
      props.submit('');
    }
  };
  return (
    <FormControl className={props.classes.margin}>
      <InputLabel htmlFor="input-with-icon-adornment">Search for Challenges</InputLabel>
      <Input
        style={{ marginRight: '20px' }}
        id="input-with-icon-adornment"
        defaultValue={value}
        onChange={e => {
          setValue(e.target.value);
        }}
      />
      <Button variant="contained" color="primary" onClick={submitVal} startIcon={<SearchIcon />}>
        Search
      </Button>
    </FormControl>
  );
};

const useStyles = makeStyles(theme => ({
  cardContentRoot: {
    padding: '0 !important',
  },
  titleRoot: {
    letterSpacing: 0.15,
  },
  scrollbarRoot: {
    height: props => `calc(100vh - ${props.height}px - 10px)`,
  },
  badgeRoot: {
    color: theme.palette.common.white,
    borderRadius: 30,
    fontSize: 12,
    padding: '2px 10px',
    display: 'inline-block',
  },
  margin: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const CompetitionApp = ({ width }) => {
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState({});
  const [tableDataList, setTableDataList] = useState({});
  const { challengesList } = useSelector(({ challenges }) => challenges);

  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [status, setStatus] = React.useState(false);

  const classes = useStyles({
    height: getMatchContainerHeight(width, showFooter),
  });

  const handleChangePage = (event, newPage) => {
    setTableData(tableDataList.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage) || {});
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    setTableData(tableDataList.slice(page, parseInt(event.target.value)) || {});
  };

  const submit = valueSearch => {
    const searchList = [];
    if (valueSearch.length > 0) {
      challengesList.forEach((value, key) => {
        if (valueSearch && value.name.toLowerCase().includes(valueSearch.toLowerCase())) {
          searchList.push(value);
        }
        // if (valueSearch && value.country.name.toLowerCase().includes(valueSearch.toLowerCase())) {
        //   searchList.push(value);
        // }
      });
      setTableDataList(searchList);
      setCount(searchList.length);
      setPage(0);
      setTableData(searchList.slice(0, rowsPerPage));
    } else {
      setTableDataList(challengesList);
      setCount(challengesList.length);
      setPage(0);
      setTableData(challengesList.slice(0, rowsPerPage));
    }
  };

  useEffect(() => {
    // if (viewTop === false) {
    dispatch(getChallenges(status));
    // } else {
    //   dispatch(getTopCompetitions());
    // }
    if (challengesList !== undefined) {
      setTableData(challengesList.slice(0, 10));
      setTableDataList(challengesList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (challengesList !== undefined && challengesList.length > 0) {
      setTableData(challengesList.slice(0, 10));
      setTableDataList(challengesList);
      setCount(challengesList.length);
    }
  }, [challengesList]);

  const futureChallenge = () => {
    if (status === 0) {
      setStatus(false);
      dispatch(getChallenges(false));
    } else {
      setStatus(0);
      dispatch(getChallenges(0));
    }
  };

  const ongoingChallenge = () => {
    if (status === 1) {
      setStatus(false);
      dispatch(getChallenges(false));
    } else {
      setStatus(1);
      dispatch(getChallenges(1));
    }
  };
  const finishedChallenge = () => {
    if (status === 2) {
      setStatus(false);
      dispatch(getChallenges(false));
    } else {
      setStatus(2);
      dispatch(getChallenges(2));
    }
  };

  return (
    <CmtCard>
      <CmtCardHeader
        className="pt-4"
        title={'Challenges'}
        style={{ marginBottom: '30px' }}
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}>
        <CompetitionSearch classes={classes} submit={submit} />
        <Button
          variant="contained"
          style={{ height: '100%', marginLeft: '15px' }}
          color={status === 0 ? 'secondary' : 'primary'}
          onClick={futureChallenge}>
          FUTURE
        </Button>
        <Button
          variant="contained"
          style={{ height: '100%', marginLeft: '15px' }}
          color={status === 1 ? 'secondary' : 'primary'}
          onClick={ongoingChallenge}>
          ONGOING
        </Button>
        <Button
          variant="contained"
          color={status === 2 ? 'secondary' : 'primary'}
          style={{ height: '100%', marginLeft: '15px' }}
          onClick={finishedChallenge}>
          FINISHED
        </Button>
      </CmtCardHeader>
      <CmtCardContent className={classes.cardContentRoot}>
        <PerfectScrollbar className={classes.scrollbarRoot}>
          <RecentTable tableData={tableData} />
          <TablePagination
            component="div"
            style={{ order: '9999', width: '100%' }}
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </PerfectScrollbar>
      </CmtCardContent>
    </CmtCard>
  );
};

export default CompetitionApp;
