import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, GET_SPORTS } from '../../constants/ActionTypes';
import axios from '../../services/auth/jwt/config';

export const fetchSuccess = () => {
  return dispatch => {
    dispatch({
      type: FETCH_SUCCESS,
    });
  };
};
export const fetchError = error => {
  return dispatch => {
    dispatch({
      type: FETCH_ERROR,
      payload: error,
    });
  };
};

export const fetchStart = () => {
  return dispatch => {
    dispatch({
      type: FETCH_START,
    });
  };
};

export const getAvailableSports = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/sports/available')
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_SPORTS, payload: data.data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};
