import {
  FETCH_ALL_LANGUAGES,
  FETCH_ALL_LANGUAGES_PLATFORMS,
  GET_LANGUAGE_DATA,
  UPDATE_LANGUAGE_DATA,
  CHANGE_LANGUAGE_STATUS,
  ADD_NEW_LANGUAGE,
} from '../../constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';

export const extractLanguageData = (language, platform) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`/languages/translations/${platform}/${language}`)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_LANGUAGE_DATA, payload: data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const changeLanguageStatus = (languageId, status) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`/languages/change-status/${languageId}`, { status: status })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch(fetchAllLanguages());
        dispatch({ type: CHANGE_LANGUAGE_STATUS, payload: data });
        dispatch(fetchError(`Language status changed`));
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const fetchAllLanguagesPlatforms = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/languages/platforms')
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: FETCH_ALL_LANGUAGES_PLATFORMS, payload: data });
      })
      .catch(error => {});
  };
};

export const fetchAllLanguages = () => {
  return dispatch => {
    // dispatch(fetchStart());
    axios
      .get('/languages')
      .then(data => {
        // dispatch(fetchSuccess());
        dispatch({ type: FETCH_ALL_LANGUAGES, payload: data });
      })
      .catch(error => {});
  };
};

export const addNewLanguage = params => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/languages', params)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch(fetchAllLanguages());
        dispatch({ type: ADD_NEW_LANGUAGE, payload: data });
        dispatch(fetchError(`New Language added`));
      })
      .catch(error => {
        if (error.response.data.message[0].constraints && error.response.data.message[0].constraints.isISO31661Alpha2) {
          dispatch(fetchError(error.response.data.message[0].constraints.isISO31661Alpha2));
        }
      });
  };
};

export const deleteLanguage = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete('/languages/' + id)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch(fetchAllLanguages());
        dispatch({ type: ADD_NEW_LANGUAGE, payload: data });
        dispatch(fetchError(`Language deleted`));
      })
      .catch(error => {});
  };
};

export const performUpdateLanguageData = (params, platform, language) => dispatch => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/languages/translations/${platform}/${language}`, params)
      .then(resolve => {
        const { data } = resolve;

        dispatch({
          type: UPDATE_LANGUAGE_DATA,
          data: data,
        });
        dispatch(fetchError(`New text language saved`));
      })
      .catch(reject => {
        console.log(reject);
      });
  });
};
