import React from 'react';
import StarIcon from '@material-ui/icons/Star';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';

export const foldersList = [
  { id: 2, name: 'Starred Matches', slug: 'starred', icon: <StarIcon /> },
  { id: 1, name: 'Future', slug: 'future', icon: <AccessTimeIcon /> },
  { id: 3, name: 'Live', slug: 'live', icon: <TimelapseIcon /> },
  { id: 6, name: 'Ended', slug: 'ended', icon: <AlarmOnIcon /> },
  { id: 7, name: 'Canceled-postponed', slug: 'canceled-postponed', icon: <AlarmOnIcon /> },
];

export const labelsList = [
  { id: 1, name: 'UEFA', slug: 'uefa', color: '#FF8C00' },
  { id: 2, name: 'Primera Division', slug: 'primera-division', color: '#00C4B4' },
  { id: 3, name: 'Premiere League', slug: 'premiere-league', color: '#0F9AF7' },
];

export const matches = [
  {
    id: 1457690400,
    name: 'PSV - Chelsea',
    profilePic: 'https://via.placeholder.com/150x150',
    country: 'Spain',
    dates: [{ date: '27.03.2012 10:30:30', label: 'start_date' }],
    matchType: 'Group C',
    starred: false,
    frequent: true,
    competition: 'Serie A',
    labels: [2],
    folder: 'future',
  },
  {
    id: 1457690401,
    name: 'Real Madrid - Atletico Bilbao',
    profilePic: 'https://via.placeholder.com/150x150',
    country: 'Spain',
    dates: [{ date: '27.03.2012 10:30:30', label: 'home' }],
    matchType: 'qualifications',
    starred: false,
    frequent: false,
    competition: 'UEFA Cup',
    labels: [2, 1],
    folder: 'future',
  },
  {
    id: 1457690402,
    name: 'Sport Recife vs Fortaleza',
    profilePic: 'https://via.placeholder.com/150x150',
    country: 'Spain',
    dates: [{ date: '27.03.2012 10:30:45', label: 'home' }],
    matchType: 'quarters',
    starred: false,
    frequent: false,
    competition: 'National Cup',
    labels: [3],
    folder: 'future',
  },
  {
    id: 1457690403,
    name: 'Santiago Wanderers vs Colo-Colo',
    profilePic: 'https://via.placeholder.com/150x150',
    country: 'England',
    dates: [{ date: '27.03.2012 10:30', label: 'home' }],
    matchType: 'semi finals',
    starred: false,
    frequent: true,
    competition: 'France Cup',
    labels: [],
    folder: 'future',
  },
  {
    id: 1457890404,
    name: 'Universidad Católica vs Huachipato',
    profilePic: 'https://via.placeholder.com/150x150',
    country: 'England',
    dates: [{ date: '27.03.2012 10:30', label: 'home' }],
    matchType: 'semi finals',
    starred: true,
    frequent: false,
    competition: 'Serie A',
    labels: [],
    folder: 'future',
  },
  {
    id: 1457690405,
    name: 'Southampton vs Liverpool',
    profilePic: 'https://via.placeholder.com/150x150',
    country: 'England',
    dates: [{ date: '27.03.2012 10:30', label: 'home' }],
    matchType: 'Group F',
    starred: false,
    frequent: false,
    competition: 'France Cup',
    labels: [],
    folder: 'future',
  },
  {
    id: 1457690406,
    name: 'Porto - Valencia',
    profilePic: 'https://via.placeholder.com/150x150',
    country: 'England',
    dates: [{ date: '27.03.2012 10:30', label: 'home' }],
    matchType: 'Group D',
    starred: true,
    frequent: true,
    competition: 'England Cup',
    labels: [],
    folder: 'ended',
  },
  {
    id: 1457690407,
    name: 'Bragantino vs São Paulo',
    profilePic: 'https://via.placeholder.com/150x150',
    country: 'England',
    dates: [{ date: '27.03.2012 10:30', label: 'home' }],
    matchType: 'Group D',
    starred: false,
    frequent: false,
    competition: 'Portugal Cup',
    labels: [],
    folder: 'future',
  },
  {
    id: 1457690408,
    name: 'Flamengo vs Fluminense',
    profilePic: 'https://via.placeholder.com/150x150',
    country: 'Spain',
    dates: [{ date: '27.03.2012 10:30', label: 'home' }],
    matchType: 'championship',
    starred: true,
    frequent: false,
    competition: 'Europa League',
    labels: [],
    folder: 'live',
  },
  {
    id: 1457690409,
    name: 'West Bromwich Albion vs Arsenal',
    profilePic: 'https://via.placeholder.com/150x150',
    country: 'Spain',
    dates: [{ date: '27.03.2012 10:30', label: 'home' }],
    matchType: 'championship',
    starred: false,
    frequent: false,
    competition: 'UEFA Cup',
    labels: [],
    folder: 'live',
  },
  {
    id: 1457690410,
    name: 'Stuttgart vs RB Leipzig',
    profilePic: 'https://via.placeholder.com/150x150',
    country: 'Spain',
    dates: [{ date: '27.03.2012 10:30', label: 'home' }],
    matchType: 'championship',
    starred: false,
    frequent: false,
    competition: 'Maruti',
    labels: [],
    folder: 'future',
  },
  {
    id: 1457690500,
    name: 'Hertha BSC vs Schalke 04',
    profilePic: 'https://via.placeholder.com/150x150',
    country: 'Spain',
    dates: [{ date: '27.03.2012 10:30', label: 'home' }],
    matchType: 'championship',
    starred: false,
    frequent: true,
    competition: 'Primera Division',
    labels: [],
    folder: 'future',
  },
  {
    id: 1457690501,
    name: 'Crystal Palace vs Sheffield United\n',
    profilePic: 'https://via.placeholder.com/150x150',
    country: 'Germany',
    dates: [{ date: '27.03.2012 10:30:30', label: 'home' }],
    matchType: 'championship',
    starred: false,
    frequent: true,
    competition: 'Bundesliga',
    labels: [],
    folder: 'future',
  },
  {
    id: 1457690502,
    name: 'Köln vs Augsburge',
    profilePic: 'https://via.placeholder.com/150x150',
    country: 'Germany',
    dates: [{ date: '27.03.2012 10:30', label: 'home' }],
    matchType: 'Designer',
    starred: false,
    frequent: false,
    competition: 'Bundesliga',
    labels: [],
    folder: 'live',
  },
  {
    id: 1457690503,
    name: 'Eintracht Frankfurt vs Bayer Leverkusen',
    profilePic: 'https://via.placeholder.com/150x150',
    country: 'Germany',
    dates: [{ date: '27.03.2012 10:30', label: 'home' }],
    matchType: 'quarters',
    starred: false,
    frequent: true,
    competition: 'Premiere League',
    labels: [],
    folder: 'future',
  },
  {
    id: 14576352304,
    name: 'Kerala Blasters vs Odisha FC',
    profilePic: 'https://via.placeholder.com/150x150',
    country: 'Spain',
    dates: [{ date: '27.03.2012 10:30', label: 'home' }],
    matchType: 'qualifications',
    starred: true,
    frequent: false,
    competition: 'Premiere League',
    labels: [],
    folder: 'future',
  },
  {
    id: 1457690505,
    name: 'Leeds United vs Burnley',
    profilePic: 'https://via.placeholder.com/150x150',
    country: 'Spain',
    dates: [{ date: '27.03.2012 10:30', label: 'home' }],
    matchType: 'Marketing Head',
    starred: false,
    frequent: false,
    competition: 'Premiere League',
    labels: [],
    folder: 'future',
  },
  {
    id: 1457690506,
    name: 'Fluminense vs São Paulo',
    profilePic: 'https://via.placeholder.com/150x150',
    country: 'Spain',
    dates: [{ date: '27.03.2012 10:30', label: 'home' }],
    matchType: 'optims',
    starred: true,
    frequent: false,
    competition: 'Premiere League',
    labels: [],
    folder: 'ended',
  },
  {
    id: 1457690507,
    name: "Santiago Wanderers vs O'Higgins",
    profilePic: 'https://via.placeholder.com/150x150',
    country: 'Spain',
    dates: [{ date: '27.03.2012 10:30', label: 'home' }],
    matchType: 'final',
    starred: false,
    frequent: false,
    competition: 'Bundesliga',
    labels: [],
    folder: 'future',
  },
  {
    id: 1457690508,
    name: 'Hertha BSC vs Schalke 04',
    profilePic: 'https://via.placeholder.com/150x150',
    country: 'Spain',
    dates: [{ date: '27.03.2012 10:30', label: 'home' }],
    matchType: 'final',
    starred: true,
    frequent: false,
    competition: 'Serie A',
    labels: [],
    folder: 'ended',
  },
  {
    id: 1457690509,
    name: 'Real Betis vs Sevilla',
    profilePic: 'https://via.placeholder.com/150x150',
    country: 'Spain',
    dates: [{ date: '27.03.2012 10:30', label: 'home' }],
    matchType: 'championship',
    starred: false,
    frequent: false,
    competition: 'Serie A',
    labels: [],
    folder: 'live',
  },
  {
    id: 1457690510,
    name: 'Audax Italiano vs Unión Española',
    profilePic: 'https://via.placeholder.com/150x150',
    country: 'Spain',
    dates: [{ date: '27.03.2012 10:30', label: 'home' }],
    matchType: 'championship',
    starred: false,
    frequent: false,
    competition: 'Serie A',
    labels: [],
    folder: 'future',
  },
];
