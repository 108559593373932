import React, { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import SelectModal from './SelectModal';
import { useParams } from 'react-router';

const BetTemplates = ({ betTemplatesData }) => {
  const [open, setOpen] = useState(false);
  const { id } = useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box style={{ display: 'flex', margin: ' 30px 7.5px 15px 7.5px' }}>
        <h2 style={{ marginRight: '25px' }}>Bet Templates</h2>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          {id === 'new' ? 'Add' : 'Update'}
        </Button>
      </Box>
      <SelectModal open={open} onClose={handleClose} betTemplatesData={betTemplatesData} />
    </>
  );
};

export default BetTemplates;
