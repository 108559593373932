import React, { useContext, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import AppContext from 'components/contextProvider/AppContextProvider/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { getMatchContainerHeight } from 'constants/AppConstants';
import {
  fetchCompetitions,
  getCompetitions,
  getTopCompetitions,
  fetchCompetitionsDetails,
  toggleExpandSidebar,
} from 'redux/actions/Competitions';
import { TablePagination, Button, Box, Hidden, IconButton, Typography } from '@material-ui/core';
import RecentTable from './RecentTable';
import { Menu } from '@material-ui/icons';
import CmtSearch from '@coremat/CmtSearch';
import Sidebar from './Sidebar';
import useStyles from './index.style';

const CompetitionApp = () => {
  const { showFooter } = useContext(AppContext);
  const classes = useStyles({ height: getMatchContainerHeight(showFooter) + 80 });
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const { competitionsList, selectedSport, isSideBarCollapsed, sortType } = useSelector(({ competitions }) => competitions);

  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showTopCompetitions, setShowTopCompetitions] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const viewTopCompetitions = () => {
    if (!showTopCompetitions) {
      dispatch(getTopCompetitions(selectedSport._id, page, rowsPerPage, searchQuery, sortType));
    } else {
      dispatch(getCompetitions(selectedSport._id, page, rowsPerPage, searchQuery, sortType));
    }
    setShowTopCompetitions(!showTopCompetitions);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    if (selectedSport) setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSport, sortType]);

  useEffect(() => {
    if (selectedSport) {
      dispatch(getCompetitions(selectedSport._id, page, rowsPerPage, searchQuery, sortType));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSport, rowsPerPage, page, sortType]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (selectedSport) dispatch(getCompetitions(selectedSport._id, page, rowsPerPage, searchQuery, sortType));
    }, 1500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (competitionsList) {
      setTableData(competitionsList.competitions);
      setCount(competitionsList.count ?? 0);
    }
  }, [competitionsList, showTopCompetitions]);

  const fetchAllCompetitions = () => {
    dispatch(fetchCompetitions());
  };

  const fetchAllDetailsCompetitions = () => {
    let futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 14);
    let backDate = new Date();
    backDate.setDate(backDate.getDate() - 14);

    dispatch(fetchCompetitionsDetails(backDate.toISOString(), futureDate.toISOString()));
  };

  return (
    <Box className={classes.inBuildAppCard}>
      <Box className={classes.inBuildAppHeader}>
        <Box className={classes.inBuildAppHeaderSidebar}>
          <Hidden>
            <IconButton onClick={() => dispatch(toggleExpandSidebar())}>
              <Menu />
            </IconButton>
          </Hidden>
          <Typography className={classes.inBuildAppHeaderTitle} component="div" variant="h1">
            Competitions
          </Typography>
        </Box>
        <Box className={classes.inBuildAppHeaderContent}>
          <CmtSearch
            placeholder="Search..."
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            border={false}
          />
          <Button
            variant="contained"
            style={{ height: '100%', marginLeft: 'auto' }}
            color="primary"
            onClick={fetchAllCompetitions}>
            FETCH
          </Button>
          <Button
            variant="contained"
            style={{ height: '100%', marginLeft: '15px' }}
            color="primary"
            onClick={fetchAllDetailsCompetitions}>
            FETCH DETAILS
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ height: '100%', marginLeft: '15px' }}
            onClick={viewTopCompetitions}>
            {showTopCompetitions ? 'ALL COMPETITIONS' : 'TOP COMPETITIONS'}
          </Button>
        </Box>
      </Box>
      <Box className={clsx(classes.inBuildAppContainer, isSideBarCollapsed ? 'collapsed' : '')}>
        <Sidebar selectedSport={selectedSport} />
        <Box className={classes.inBuildAppMainContent}>
          <Box className="Cmt-table-responsive">
            <PerfectScrollbar className={classes.perfectScrollbarMatchCon}>
              <RecentTable tableData={tableData} />
            </PerfectScrollbar>
          </Box>
          <TablePagination
            component="div"
            style={{ order: '9999', width: '100%' }}
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CompetitionApp;
