import {
  ADD_SELECTION,
  GET_SELECTIONS,
  GET_SELECTION_DETAILS,
  PATCH_SELECTION,
  GET_FANCLUBS,
  GET_FANCLUB_DETAILS,
  ADD_FANCLUB,
  PATCH_FANCLUB,
} from 'constants/ActionTypes';

const INIT_STATE = {};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SELECTIONS: {
      return {
        ...state,
        selectionsList: action.payload,
      };
    }

    case GET_FANCLUBS: {
      return {
        ...state,
        fanClubsList: action.payload,
      };
    }

    case GET_FANCLUB_DETAILS: {
      return {
        ...state,
        fanClubDetails: action.payload,
      };
    }

    case ADD_FANCLUB: {
      return {
        ...state,
        addFanClub: action.payload,
      };
    }

    case PATCH_FANCLUB: {
      return {
        ...state,
        patchFanClub: action.payload,
      };
    }

    case GET_SELECTION_DETAILS: {
      return {
        ...state,
        selectionDetails: action.payload,
      };
    }

    case ADD_SELECTION: {
      return {
        ...state,
        createSelection: action.payload,
      };
    }

    case PATCH_SELECTION: {
      return {
        ...state,
        patchSelection: action.payload,
      };
    }

    default:
      return state;
  }
};
