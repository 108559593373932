import React from 'react';
import clsx from 'clsx';
import { TableCell, TableRow, Switch } from '@material-ui/core';
import EditCellSystem from '../Ecosystem/EcosystemDetail/EditCellSystem.js';
import useStyles from 'components/Table/table.styles';
import { updateEcosystem } from 'redux/actions/Ecosystems';
import { useDispatch } from 'react-redux';

const TableItem = ({ row }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleFieldChange = field => event => {
    const updatedRow = {
      ...row,
      [field]: event.target.checked,
    };
    const formData = new FormData();
    formData.append('name', updatedRow.name);
    formData.append('challengeId', updatedRow.challengeId);
    row.competitionsIds.forEach(id => {
      formData.append('competitionsIds[]', id);
    });
    formData.append('backgroundColor', updatedRow.backgroundColor);
    formData.append('cardBackgroundColor', updatedRow.cardBackgroundColor);
    formData.append('isMotorRacing', updatedRow.isMotorRacing);
    formData.append('contrastColor', updatedRow.contrastColor);
    formData.append('file', updatedRow.cover);
    formData.append('published', updatedRow.published);

    dispatch(updateEcosystem(formData, updatedRow._id));
  };

  return (
    <TableRow className={classes.tableRowRoot}>
      <TableCell className={classes.tableCellRoot}>{row?.name}</TableCell>
      <TableCell className={classes.tableCellRoot}>{row?.challenge?.name}</TableCell>
      <TableCell className={clsx(classes.tableCellRoot)}>
        {row?.competitions?.map(comp => (
          <div key={comp._id}>
            {comp.name} - {comp.country.name}{' '}
          </div>
        ))}
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Switch checked={row?.isMotorRacing} onChange={handleFieldChange('isMotorRacing')} />
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Switch checked={row?.published} onChange={handleFieldChange('published')} />
      </TableCell>
      <TableCell className={clsx(classes.tableCellRoot, classes.tableCellRootSmall)}>
        <EditCellSystem betTemplate={row} />
      </TableCell>
    </TableRow>
  );
};

export default TableItem;
