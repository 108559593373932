import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAnalyticsCounters,
  fetchAnalyticsCountersCSV,
  fetchAnalyticsUsers,
  fetchAnalyticsUsersCSV,
  fetchGames,
  fetchGamesCSV,
  B2BUserType,
} from '../../../../redux/actions/Analytics';

export const useDataFetching = (filters, dataType) => {
  const dispatch = useDispatch();
  const { counters, users, games, loading, error } = useSelector(state => state.analytics);

  useEffect(() => {
    const fetchData = () => {
      const { startDate, endDate } = getDateRange(filters);
      const externalType = B2BUserType[filters.userType] || null;

      switch (dataType) {
        case 'counters':
          dispatch(fetchAnalyticsCounters({ externalType, startDate, endDate }));
          break;
        case 'users':
          dispatch(fetchAnalyticsUsers({ externalType, startDate, endDate }));
          break;
        case 'games':
          dispatch(fetchGames({ startDate, endDate }));
          break;
      }
    };

    fetchData();
  }, [dispatch, filters, dataType]);

  const handleDownloadCSV = () => {
    switch (dataType) {
      case 'counters':
        dispatch(fetchAnalyticsCountersCSV());
        break;
      case 'users':
        dispatch(fetchAnalyticsUsersCSV());
        break;
      case 'games':
        dispatch(fetchGamesCSV());
        break;
    }
  };

  const getDateRange = filters => {
    let startDate, endDate;
    const today = new Date();

    if (filters.dateType === 'day') {
      const localDate = new Date(filters.selectedDate || new Date());

      localDate.setHours(0, 0, 0, 0);
      startDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();

      localDate.setHours(23, 59, 59, 999);
      endDate = new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString();
    } else if (filters.dateType === 'week') {
      const start = new Date(filters.startDate || new Date());

      start.setHours(0, 0, 0, 0);
      startDate = new Date(start.getTime() - start.getTimezoneOffset() * 60000).toISOString();

      const end = new Date(start);
      end.setDate(end.getDate() + 6);
      end.setHours(23, 59, 59, 999);

      if (end > today) {
        end.setDate(today.getDate());
        end.setHours(23, 59, 59, 999);
      }

      endDate = new Date(end.getTime() - end.getTimezoneOffset() * 60000).toISOString();
    } else if (filters.dateType === 'month') {
      const monthStart = new Date(filters.selectedMonth.getFullYear(), filters.selectedMonth.getMonth(), 1);

      monthStart.setHours(0, 0, 0, 0);
      startDate = new Date(monthStart.getTime() - monthStart.getTimezoneOffset() * 60000).toISOString();

      const monthEnd = new Date(filters.selectedMonth.getFullYear(), filters.selectedMonth.getMonth() + 1, 0);

      monthEnd.setHours(23, 59, 59, 999);
      endDate = new Date(monthEnd.getTime() - monthEnd.getTimezoneOffset() * 60000).toISOString();
    }

    return { startDate, endDate };
  };

  return {
    counters,
    users,
    games,
    loading,
    error,
    handleDownloadCSV,
  };
};
