import React, { useState, useEffect, useRef } from 'react';
import { Box, Button } from '@material-ui/core';
import SelectMatches from './SelectMatches';
import MatchCell from './MatchCell';
import { useParams } from 'react-router';

const Matches = ({ matches, getMatches, viewDetail }) => {
  const { id } = useParams();
  const [matchList, setMatchList] = useState([]);
  const [matchListDetail, setMatchListDetail] = useState([]);
  const [matchesToInsert, setMatchesToInsert] = useState([]);
  const [matchesToRemove, setMatchesToRemove] = useState([]);
  const myRef = useRef(null);

  useEffect(() => {
    if (id !== 'new' && viewDetail) {
      let matchesId = [];
      viewDetail.games.forEach(item => {
        matchesId.push(item._id);
      });
      let matches = [];
      viewDetail.games.forEach(item => {
        matches.push(item);
      });
      setMatchList(matchesId);
      setMatchListDetail(matches);
    }
  }, [viewDetail, id]);

  const selectMatch = (id, headline, date, homeEmblem, awayEmblem, competition, sportId) => {
    let newMatch = {
      _id: id,
      headline: headline,
      scheduledDate: date,
      homeTeamEmblemUrl: homeEmblem,
      awayTeamEmblemUrl: awayEmblem,
      competitionName: competition,
      sportId: sportId,
    };
    let difMatch = true;

    matchListDetail.forEach((match, key) => {
      if (match._id === id) {
        difMatch = false;
      }
    });

    if (difMatch === true) {
      setMatchesToInsert(matchesToInsert => [...matchesToInsert, id]);
      setMatchesToRemove(matchesToRemove.filter((item, key) => item !== id));
      setMatchListDetail(matchListDetail => [...matchListDetail, newMatch]);
      setMatchList(matchList => [...matchList, id]);
      sendMatches(
        [...matchList, id],
        [...matchesToInsert, id],
        matchesToRemove.filter((item, key) => item !== id),
      );
    }
  };

  const sendMatches = (currentList, toBeInserted, toBeRemoved) => {
    getMatches(currentList, toBeInserted, toBeRemoved);
  };

  const removeMatch = id => {
    setMatchList(matchList.filter((item, key) => item !== id));
    setMatchListDetail(matchListDetail.filter((item, key) => item._id !== id));
    setMatchesToInsert(matchesToInsert.filter((item, key) => item !== id));
    setMatchesToRemove(matchesToRemove => [...matchesToRemove, id]);
    sendMatches(
      matchList.filter((item, key) => item !== id),
      matchesToInsert.filter((item, key) => item !== id),
      [...matchesToRemove, id],
    );
  };

  const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  return (
    <Box>
      <Box style={{ display: 'flex', margin: ' 30px 7.5px 15px 7.5px' }}>
        <h2 style={{ marginRight: '25px' }}>Matches</h2>
        <Button variant="contained" color="primary" onClick={executeScroll}>
          Add
        </Button>
      </Box>
      <Box>
        {matchListDetail !== undefined && matchListDetail.length > 0 ? (
          <MatchCell selectedMatches={matchListDetail} onRemove={removeMatch} />
        ) : (
          <h3 style={{ marginLeft: '7.5px', marginBottom: '30px' }}>There are no selected matches. Please add one.</h3>
        )}
      </Box>

      <Box ref={myRef}>
        <SelectMatches match={matches} selectMatch={selectMatch} selectedMatches={matchListDetail} />
      </Box>
    </Box>
  );
};

export default Matches;
