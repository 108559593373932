import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  fullBoxwidth: {
    display: 'flex',
    flexDirection: 'row',
    margin: '7.5px',
    flexWrap: 'wrap',
  },
  titleRoot: {
    fontSize: '30px',
    padding: '10px',
  },

  fakeBorder: {
    width: '150px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    padding: '4px 10px',
    borderRadius: '4px',
    marginTop: '7.5px',
    marginRight: '10px',
    marginLeft: '5px',
    position: 'relative',
    height: '58px',
    '@media screen and (max-width: 768px)': {
      marginTop: '10px',
    },
  },
  label: {
    color: 'rgba(0, 0, 0, 0.6)',
    width: 'auto',
    display: 'inline-block',
    padding: '3px',
    fontSize: '0.75rem',
    textAlign: 'left',
    visibility: 'visible',
    position: 'absolute',
    background: '#fff',
    zIndex: '99',
    top: '-11px',
    left: '10px',
  },

  headOfficialLeft: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '40%',
    marginBottom: '30px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },

  headOfficialRight: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '60%',
    marginBottom: '30px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      flexDirection: 'column',
    },
  },

  addPrizeHalf: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '50%',
    marginBottom: '30px',
    width: '100%',
  },

  imageLogo: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  imagePlaceholder: {
    maxWidth: '150px',
    width: '150px',
    maxHeight: '120px',
    height: '120px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    marginTop: '7.5px',
    marginBottom: '15px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100px',
      width: '100px',
      maxHeight: '80px',
      height: '80px',
    },
  },

  logoCover: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '50%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },

  headOfficial: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '100%',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },

  prizeOfficial: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 'calc(100% + 48px)',
    padding: '24px',
    margin: '0 -24px 25px -24px',
    width: 'calc(100% + 48px)',
    background: 'rgba(0,0,0,0.01)',
    border: '1px solid rgba(0,0,0,0.1)',
    boxShadow: 'inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC',
    '& .notification-container': {
      top: '100px',
    },
  },

  prizeOfficialRow: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '100%',
    width: '100%',
    marginTop: '15px',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },

  prizePhoto: {
    maxWidth: '150px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },

  prizeDescription: {
    maxWidth: 'calc(100% - 150px)',
    marginLeft: '7.5px',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },

  hiddenInput: {
    display: 'none',
  },

  textFieldHead: {
    margin: '12.5px 7.5px',
    maxWidth: 'calc(100% - 15px)',
    width: '100%',
  },

  Textarea25: {
    maxWidth: 'calc(25% - 15px)',
    width: '100%',
    margin: '7.5px',
    '& textarea': {
      minHeight: '0',
      height: '19px',
      maxHeight: '19px',
      transition: 'min-height 0.4s ease-in-out',
    },
    '& .Mui-focused': {
      '& textarea': {
        minHeight: '120px',
        maxHeight: '999px',
        transition: 'min-height 0.4s ease-in-out, max-height 0.8s ease-in-out',
      },
    },
  },
  TextareaNumber: {
    margin: '7.5px',
  },
  Textarea50: {
    maxWidth: 'calc(50% - 15px)',
    width: '100%',
    margin: '7.5px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
    '& textarea': {
      minHeight: '0',
      height: '19px',
      maxHeight: '19px',
      transition: 'min-height 0.4s ease-in-out',
    },
    '& .Mui-focused': {
      '& textarea': {
        minHeight: '120px',
        maxHeight: '999px',
        transition: 'min-height 0.4s ease-in-out, max-height 0.8s ease-in-out',
      },
    },
  },

  prizeCell: {
    display: 'flex',
    flexDirection: 'row',
    padding: '24px',
    borderBottom: '1px solid rgba(0,0,0,0.25)',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: '15px 0',
    },
  },
  prizeCellImage: {
    maxWidth: '150px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  prizeCellDetails: {
    maxWidth: 'calc(100% - 150px)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  prizeCellDescription: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  prizeCellTitle: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '25px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0',
      paddingTop: '10px',
    },
  },
  prizeCellTranslation: {
    maxWidth: '50%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 25px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      padding: '10px 0',
    },
  },
  prizeCellLabel: {
    fontWeight: 'bold',
  },
  prizeCellValue: {
    background: 'rgba(0,0,0,0.05)',
    padding: '10px',
    borderRadius: '5px',
  },

  questionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 'calc(100% + 48px)',
    padding: '24px',
    margin: '0 -24px 25px -24px',
    width: 'calc(100% + 48px)',
    background: 'rgba(0,0,0,0.01)',
    border: '1px solid rgba(0,0,0,0.1)',
    boxShadow: 'inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 0',
    },
    '& .notification-container': {
      top: '100px',
    },
  },
  cardContentRoot: {
    '& .notification-container': {
      top: '100px',
    },
  },

  addQuestionCell: {
    maxWidth: 'calc(33% - 20px)',
    margin: '10px',
    padding: '10px',
    width: '100%',
    background: '#fff',
    border: '1px solid rgba(0,0,0,0.05)',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  questionsContent: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  questionsActions: {
    margin: '15px 7.5px',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },

  rightButtons: {
    marginLeft: '15px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
      marginBottom: '10px',
    },
  },
  leftButtons: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
      marginBottom: '10px',
    },
  },

  questionWrapper: {
    maxWidth: 'calc(33% - 20px)',
    margin: '10px',
    padding: '10px',
    width: '100%',
    background: 'rgba(255,255,255,1)',
    border: '1px solid rgba(0,0,0,0.05)',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  questionTranslation: {
    fontWeight: 'bold',
  },
  questionHead: {},
  questionTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
    margin: '4px 0',
  },
  questionBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  questionAnswer: {
    margin: '2px 0 0px 8px',
  },
  questionsListContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    padding: '24px',
    margin: '0',
    width: 'calc(100% + 48px)',
    background: '#fff',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    [theme.breakpoints.down('xs')]: {
      padding: '15px 0',
    },
  },

  questionsListContent: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  selectedMatchesList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: '100%',
    padding: '24px',
    margin: '0',
    width: '100%',
    background: '#fff',
  },
  selectedCountriesList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: '100%',
    padding: '24px',
    margin: '0',
    width: '100%',
    background: '#fff',
  },

  selectListMatch: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    maxWidth: 'calc(100% + 48px)',
    padding: '24px',
    margin: '0 -24px 25px -24px',
    width: 'calc(100% + 48px)',
    background: 'rgba(0,0,0,0.01)',
    border: '1px solid rgba(0,0,0,0.1)',
    boxShadow: 'inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC',
  },
  selectListCountry: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    maxWidth: 'calc(100% + 48px)',
    padding: '24px',
    margin: '0 -24px 25px -24px',
    width: 'calc(100% + 48px)',
    background: 'rgba(0,0,0,0.01)',
    border: '1px solid rgba(0,0,0,0.1)',
    boxShadow: 'inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC',
  },
  selectListMatchContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  selectListCountryContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  selectListMatchRow: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '50%',
    width: '100%',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0,0,0, 0.05)',
    padding: '10px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  selectListCountriesRow: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 'calc(25% - 5px)',
    width: '100%',
    alignItems: 'center',
    marginRight: '5px',
    borderBottom: '1px solid rgba(0,0,0, 0.05)',
    borderRight: '1px solid rgba(0,0,0, 0.05)',
    padding: '10px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  TextFieldStyle: {
    margin: '7.5px',
  },
}));
export default useStyles;
