import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import CmtAvatar from '../../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import useStyles from '../MatchCell.style';
import PropTypes from 'prop-types';
import MatchCellOptions from '../ListTableView/MatchCellOptions';
import Moment from 'moment';
import { setMatchSetting } from '../../../../../redux/actions/MatchApp';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const MatchCell = ({ filterType, match }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [matchSettings, setMatchSettings] = useState({
    popular: match.popular,
    matchOfTheDay: match.matchOfTheDay,
    topMatch: match.topMatch,
  });

  const onClickChangeSettings = (e, settingToChange) => {
    console.log('valoare intrare', e, settingToChange);

    let updatedValue = { ...matchSettings };

    switch (settingToChange) {
      case 'popular':
        updatedValue = { ...matchSettings, popular: e.target.value };
        break;
      case 'matchOfTheDay':
        updatedValue = { ...matchSettings, matchOfTheDay: e.target.value };
        break;

      case 'topMatch':
        updatedValue = { ...matchSettings, topMatch: e.target.value };
        break;

      default:
        return null;
    }
    console.log('valoarea de iesire', updatedValue);
    setMatchSettings(updatedValue);
    dispatch(setMatchSetting(updatedValue));
  };

  const { headline, homeTeamEmblemUrl, awayTeamEmblemUrl, scheduledDate, competitionName } = match;
  return (
    <Box
      className={classes.gridMatchCell}
      onClick={() => {
        history.push('/match/' + match._id);
      }}>
      <Box className={classes.gridMatchCellHeader} display="flex" mb={3}>
        <Box width={{ sm: 'calc(100% - 56px)' }} display="flex" alignItems="center">
          <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
            <Box
              mr={4}
              style={{
                width: '30px',
                overflow: 'hidden',
                marginRight: '0',
                borderRight: '1px solid #fff',
              }}>
              <CmtAvatar size={60} src={homeTeamEmblemUrl} alt={headline} />
            </Box>
            <Box mr={4} style={{ width: '30px', overflow: 'hidden', marginRight: '0' }}>
              <CmtAvatar size={60} style={{ transform: 'translateX(-30px)' }} src={awayTeamEmblemUrl} alt={headline} />
            </Box>
          </Box>

          <Box width="calc(100% - 56px)">
            <Typography className={classes.titleRoot} component="div" variant="h4">
              {headline}
            </Typography>
            {competitionName && (
              <Box mb={2} component="p" className={classes.textTruncate}>
                {competitionName}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box ml={{ sm: 'auto' }} onClick={e => e.stopPropagation()}>
        <MatchCellOptions
          filterType={filterType}
          popularType={matchSettings.topMatch}
          match={match}
          onClickTopMatch={e => onClickChangeSettings(e.target.value, 'topMatch')}
          onClickEditMatch={() => {
            history.push('/match/' + match._id);
          }}
        />
      </Box>
      <Box display="flex" flexDirection="column">
        <Box mb={2} component="p">
          {Moment(scheduledDate).format('H:mm - D MMM yyyy')}
        </Box>
      </Box>
    </Box>
  );
};

export default MatchCell;

MatchCell.prototype = {
  match: PropTypes.object.isRequired,
  onShowMatchDetail: PropTypes.func,
  onClickEditMatch: PropTypes.func,
};
