import React, { useContext, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AppContext from 'components/contextProvider/AppContextProvider/AppContext';
import { AMLrisk, getMatchContainerHeight, buildSearch } from 'constants/AppConstants';

import { useDispatch, useSelector } from 'react-redux';
import { getAMLUsers } from 'redux/actions/Users';

import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';

import RecentTable from './RecentTable';

import { Box, Button, Input, MenuItem, Select, TablePagination } from '@material-ui/core';
import useStyles from './index.style';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ClearIcon from '@material-ui/icons/Clear';

const AMLUsers = ({ width }) => {
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState({});
  const [tableDataList, setTableDataList] = useState({});
  const { usersList } = useSelector(({ users }) => users);

  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [searchName, setSearchName] = React.useState('');
  const [nameDisabled, setNameDisabled] = React.useState(true);

  const [searchEmail, setSearchEmail] = React.useState('');
  const [emailDisabled, setEmailDisabled] = React.useState(true);

  const [searchPhone, setSearchPhone] = React.useState('');
  const [phoneDisabled, setPhoneDisable] = React.useState(true);

  const [riskLevel, setRiskLevel] = React.useState(0);
  const [riskLevelDisabled, setRiskLevelDisabled] = React.useState(true);

  const [playedEUR24H, setPlayedEUR24H] = React.useState(1);
  const [playedGBP24H, setPlayedGBP24H] = React.useState(1);
  const [playedUSD24H, setPlayedUSD24H] = React.useState(1);
  const [playedEUR24HDisabled, setPlayedEUR24HDisabled] = React.useState(true);
  const [playedGBP24HDisabled, setPlayedGBP24HDisabled] = React.useState(true);
  const [playedUSD24HDisabled, setPlayedUSD24HDisabled] = React.useState(true);

  const [playedEUR7D, setPlayedEUR7D] = React.useState(1);
  const [playedGBP7D, setPlayedGBP7D] = React.useState(1);
  const [playedUSD7D, setPlayedUSD7D] = React.useState(1);
  const [playedEUR7DDisabled, setPlayedEUR7DDisabled] = React.useState(true);
  const [playedGBP7DDisabled, setPlayedGBP7DDisabled] = React.useState(true);
  const [playedUSD7DDisabled, setPlayedUSD7DDisabled] = React.useState(true);

  const [playedEUR30D, setPlayedEUR30D] = React.useState(1);
  const [playedGBP30D, setPlayedGBP30D] = React.useState(1);
  const [playedUSD30D, setPlayedUSD30D] = React.useState(1);
  const [playedEUR30DDisabled, setPlayedEUR30DDisabled] = React.useState(true);
  const [playedGBP30DDisabled, setPlayedGBP30DDisabled] = React.useState(true);
  const [playedUSD30DDisabled, setPlayedUSD30DDisabled] = React.useState(true);

  const [popupActive, setPopupActive] = React.useState(false);

  let searchParams = {
    fullname: searchName,
    email: searchEmail,
    phoneNumber: searchPhone,
    riskLevel: riskLevel,
    totalAmountPlayedEUR24HOrder: playedEUR24H,
    totalAmountPlayedUSD24HOrder: playedUSD24H,
    totalAmountPlayedGBP24HOrder: playedGBP24H,

    totalAmountPlayedEUR7DOrder: playedEUR7D,
    totalAmountPlayedUSD7DOrder: playedUSD7D,
    totalAmountPlayedGBP7DOrder: playedGBP7D,

    totalAmountPlayedEUR30DOrder: playedEUR30D,
    totalAmountPlayedUSD30DOrder: playedUSD30D,
    totalAmountPlayedGBP30DOrder: playedGBP30D,
  };

  let searchDisabled = {
    fullname: nameDisabled,
    email: emailDisabled,
    phoneNumber: phoneDisabled,
    riskLevel: riskLevelDisabled,
    totalAmountPlayedEUR24HOrder: playedEUR24HDisabled,
    totalAmountPlayedUSD24HOrder: playedUSD24HDisabled,
    totalAmountPlayedGBP24HOrder: playedGBP24HDisabled,

    totalAmountPlayedEUR7DOrder: playedEUR7DDisabled,
    totalAmountPlayedUSD7DOrder: playedUSD7DDisabled,
    totalAmountPlayedGBP7DOrder: playedGBP7DDisabled,

    totalAmountPlayedEUR30DOrder: playedEUR30DDisabled,
    totalAmountPlayedUSD30DOrder: playedUSD30DDisabled,
    totalAmountPlayedGBP30DOrder: playedGBP30DDisabled,
  };

  const handleSearch = () => {
    let queryString = buildSearch(searchParams, searchDisabled);
    dispatch(getAMLUsers(0, 10, queryString));

    setPopupActive(false);
  };

  const handleReset = () => {
    setSearchName('');
    setNameDisabled(true);

    setSearchEmail('');
    setEmailDisabled(true);

    setSearchPhone('');
    setPhoneDisable(true);

    setRiskLevel(false);
    setRiskLevelDisabled(true);

    setPlayedEUR24H(1);
    setPlayedGBP24H(1);
    setPlayedUSD24H(1);
    setPlayedEUR24HDisabled(true);
    setPlayedGBP24HDisabled(true);
    setPlayedUSD24HDisabled(true);

    setPlayedEUR7D(1);
    setPlayedGBP7D(1);
    setPlayedUSD7D(1);
    setPlayedEUR7DDisabled(true);
    setPlayedGBP7DDisabled(true);
    setPlayedUSD7DDisabled(true);

    setPlayedEUR30D(1);
    setPlayedGBP30D(1);
    setPlayedUSD30D(1);
    setPlayedEUR30DDisabled(true);
    setPlayedGBP30DDisabled(true);
    setPlayedUSD30DDisabled(true);

    let searchParams = {
      fullname: '',
      email: '',
      phoneNumber: '',
      verified: false,
      riskLevel: 1,

      totalAmountPlayedEUR24HOrder: 1,
      totalAmountPlayedUSD24HOrder: 1,
      totalAmountPlayedGBP24HOrder: 1,

      totalAmountPlayedEUR7DOrder: 1,
      totalAmountPlayedUSD7DOrder: 1,
      totalAmountPlayedGBP7DOrder: 1,

      totalAmountPlayedEUR30DOrder: 1,
      totalAmountPlayedUSD30DOrder: 1,
      totalAmountPlayedGBP30DOrder: 1,
    };

    let searchDisabled = {
      fullname: true,
      email: true,
      phoneNumber: true,
      verified: true,
      riskLevel: true,
      totalAmountPlayedEUR24HOrder: true,
      totalAmountPlayedUSD24HOrder: true,
      totalAmountPlayedGBP24HOrder: true,

      totalAmountPlayedEUR7DOrder: true,
      totalAmountPlayedUSD7DOrder: true,
      totalAmountPlayedGBP7DOrder: true,

      totalAmountPlayedEUR30DOrder: true,
      totalAmountPlayedUSD30DOrder: true,
      totalAmountPlayedGBP30DOrder: true,
    };
    let queryString = buildSearch(searchParams, searchDisabled);
    dispatch(getAMLUsers(0, 10, queryString));
    setPopupActive(false);
  };

  const classes = useStyles({
    height: getMatchContainerHeight(width, showFooter),
  });

  const handleChangePage = (event, newPage) => {
    let queryString = buildSearch(searchParams, searchDisabled);
    dispatch(getAMLUsers(newPage * rowsPerPage, rowsPerPage, queryString));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    setTableData(tableDataList.slice(page, parseInt(event.target.value)) || {});
  };

  useEffect(() => {
    dispatch(getAMLUsers(page, rowsPerPage, ''));
    if (usersList !== undefined) {
      setTableData(usersList.users);
      setTableDataList(usersList.users);
      setCount(usersList.users.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (usersList !== undefined && usersList.users.length > 0) {
      setTableData(usersList.users);
      setTableDataList(usersList.users);
      setCount(usersList.users.length);
    }
    if (usersList !== undefined && usersList.users.length === 0) {
      setTableData(usersList.users);
      setTableDataList(usersList.users);
      setCount(0);
    }
  }, [usersList]);

  return (
    <CmtCard>
      <CmtCardHeader
        className="pt-4"
        title={'AML - Users List'}
        style={{ marginBottom: '30px', zIndex: '444' }}
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}>
        <Button
          variant="contained"
          style={{ height: '100%', marginLeft: '15px', zIndex: '334' }}
          color="primary"
          onClick={() => {
            setPopupActive(!popupActive);
          }}>
          FILTERS
        </Button>
      </CmtCardHeader>
      <CmtCardContent className={classes.cardContentRoot}>
        <PerfectScrollbar className={classes.scrollbarRoot}>
          <RecentTable tableData={tableData} />
        </PerfectScrollbar>
        <TablePagination
          component="div"
          style={{ order: '9999', width: '100%' }}
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CmtCardContent>
      {popupActive === true && (
        <Box className={classes.popupDark}>
          <Box className={classes.popup}>
            <Box>
              <Input
                style={{ marginBottom: '20px' }}
                id="input-with-icon-adornment"
                value={searchName}
                placeholder="Search after name"
                onChange={e => {
                  setSearchName(e.target.value);
                  setNameDisabled(e.target.value.length <= 0);
                }}
              />
            </Box>
            <Box>
              <Input
                style={{ marginBottom: '20px' }}
                id="input-with-icon-adornment"
                value={searchEmail}
                placeholder="Search after email"
                onChange={e => {
                  setSearchEmail(e.target.value.toString());
                  setEmailDisabled(e.target.value.length <= 0);
                }}
              />
            </Box>
            <Box>
              <Input
                style={{ marginBottom: '20px' }}
                id="input-with-icon-adornment"
                value={searchPhone}
                placeholder="Search after phone"
                onChange={e => {
                  if (/^[0-9 +]+$/.test(e.target.value)) {
                    setSearchPhone(e.target.value);
                    setPhoneDisable(e.target.value.length <= 0);
                  }
                }}
              />
            </Box>
            <Box>
              <h3 style={{ marginTop: '20px', marginBottom: '10px' }}>Risk Level</h3>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: riskLevelDisabled === true ? 0.5 : 1,
                }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={riskLevel}
                  onChange={e => {
                    setRiskLevel(e.target.value);
                    setRiskLevelDisabled(false);
                  }}>
                  {AMLrisk.map((row, key) => (
                    <MenuItem key={key} value={row.id}>
                      {row.label}
                    </MenuItem>
                  ))}
                </Select>
                {riskLevelDisabled === false && (
                  <ClearIcon
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      setRiskLevel(0);
                      setRiskLevelDisabled(true);
                    }}
                  />
                )}
              </Box>
            </Box>

            <Box>
              <h3 style={{ marginTop: '20px', marginBottom: '10px' }}>Amount Played 24H</h3>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: playedEUR24HDisabled === true ? 0.5 : 1,
                }}>
                EUR{' '}
                {playedEUR24H === 1 ? (
                  <ArrowUpwardIcon
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedEUR24H(-1);
                      setPlayedEUR24HDisabled(false);
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedEUR24H(1);
                      setPlayedEUR24HDisabled(false);
                    }}
                  />
                )}
                {playedEUR24HDisabled === false && (
                  <ClearIcon
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      setPlayedEUR24H(1);
                      setPlayedEUR24HDisabled(true);
                    }}
                  />
                )}
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: playedGBP24HDisabled === true ? 0.5 : 1,
                }}>
                GBP{' '}
                {playedGBP24H === 1 ? (
                  <ArrowUpwardIcon
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedGBP24H(-1);
                      setPlayedGBP24HDisabled(false);
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedGBP24H(1);
                      setPlayedGBP24HDisabled(false);
                    }}
                  />
                )}
                {playedGBP24HDisabled === false && (
                  <ClearIcon
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      setPlayedGBP24H(1);
                      setPlayedGBP24HDisabled(true);
                    }}
                  />
                )}
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: playedUSD24HDisabled === true ? 0.5 : 1,
                }}>
                USD{' '}
                {playedUSD24H === 1 ? (
                  <ArrowUpwardIcon
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedUSD24H(-1);
                      setPlayedUSD24HDisabled(false);
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedUSD24H(1);
                      setPlayedUSD24HDisabled(false);
                    }}
                  />
                )}
                {playedUSD24HDisabled === false && (
                  <ClearIcon
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      setPlayedUSD24H(1);
                      setPlayedUSD24HDisabled(true);
                    }}
                  />
                )}
              </Box>
            </Box>

            <Box>
              <h3 style={{ marginTop: '20px', marginBottom: '10px' }}>Amount Played 7D</h3>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: playedEUR7DDisabled === true ? 0.5 : 1,
                }}>
                EUR{' '}
                {playedEUR7D === 1 ? (
                  <ArrowUpwardIcon
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedEUR7D(-1);
                      setPlayedEUR7DDisabled(false);
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedEUR7D(1);
                      setPlayedEUR7DDisabled(false);
                    }}
                  />
                )}
                {playedEUR7DDisabled === false && (
                  <ClearIcon
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      setPlayedEUR7D(1);
                      setPlayedEUR7DDisabled(true);
                    }}
                  />
                )}
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: playedGBP7DDisabled === true ? 0.5 : 1,
                }}>
                GBP{' '}
                {playedGBP7D === 1 ? (
                  <ArrowUpwardIcon
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedGBP7D(-1);
                      setPlayedGBP7DDisabled(false);
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedGBP7D(1);
                      setPlayedGBP7DDisabled(false);
                    }}
                  />
                )}
                {playedGBP7DDisabled === false && (
                  <ClearIcon
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      setPlayedGBP7D(1);
                      setPlayedGBP7DDisabled(true);
                    }}
                  />
                )}
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: playedUSD7DDisabled === true ? 0.5 : 1,
                }}>
                USD{' '}
                {playedUSD7D === 1 ? (
                  <ArrowUpwardIcon
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedUSD7D(-1);
                      setPlayedUSD7DDisabled(false);
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedUSD7D(1);
                      setPlayedUSD7DDisabled(false);
                    }}
                  />
                )}
                {playedUSD7DDisabled === false && (
                  <ClearIcon
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      setPlayedUSD7D(1);
                      setPlayedUSD7DDisabled(true);
                    }}
                  />
                )}
              </Box>
            </Box>

            <Box>
              <h3 style={{ marginTop: '20px', marginBottom: '10px' }}>Amount Played 30D</h3>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: playedEUR30DDisabled === true ? 0.5 : 1,
                }}>
                EUR{' '}
                {playedEUR30D === 1 ? (
                  <ArrowUpwardIcon
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedEUR30D(-1);
                      setPlayedEUR30DDisabled(false);
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedEUR30D(1);
                      setPlayedEUR30DDisabled(false);
                    }}
                  />
                )}
                {playedEUR30DDisabled === false && (
                  <ClearIcon
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      setPlayedEUR30D(1);
                      setPlayedEUR30DDisabled(true);
                    }}
                  />
                )}
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: playedGBP30DDisabled === true ? 0.5 : 1,
                }}>
                GBP{' '}
                {playedGBP30D === 1 ? (
                  <ArrowUpwardIcon
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedGBP30D(-1);
                      setPlayedGBP30DDisabled(false);
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedGBP30D(1);
                      setPlayedGBP30DDisabled(false);
                    }}
                  />
                )}
                {playedGBP30DDisabled === false && (
                  <ClearIcon
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      setPlayedGBP30D(1);
                      setPlayedGBP30DDisabled(true);
                    }}
                  />
                )}
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: playedUSD30DDisabled === true ? 0.5 : 1,
                }}>
                USD{' '}
                {playedUSD30D === 1 ? (
                  <ArrowUpwardIcon
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedUSD30D(-1);
                      setPlayedUSD30DDisabled(false);
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedUSD30D(1);
                      setPlayedUSD30DDisabled(false);
                    }}
                  />
                )}
                {playedUSD30DDisabled === false && (
                  <ClearIcon
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      setPlayedUSD30D(1);
                      setPlayedUSD30DDisabled(true);
                    }}
                  />
                )}
              </Box>
            </Box>

            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px',
              }}>
              <Button variant="contained" style={{ height: '100%', marginLeft: '15px' }} onClick={handleReset}>
                RESET
              </Button>
              <Button
                variant="contained"
                style={{ height: '100%', marginLeft: '15px' }}
                color="primary"
                onClick={handleSearch}>
                APPLY
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </CmtCard>
  );
};

export default AMLUsers;
