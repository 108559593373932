import React, { useContext, useState } from 'react';
import ListTableView from './ListTableView';
import ListGridView from './ListGridView';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import useStyles from '../index.style';
import { getMatchContainerHeight } from 'constants/AppConstants';
import AppContext from 'components/contextProvider/AppContextProvider/AppContext';
import EmptyMatchResult from './EmptyMatchResult';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';

export const foldersList = [
  { id: 1, name: 'Future', slug: 'future', icon: <AccessTimeIcon /> },
  { id: 2, name: 'Live', slug: 'live', icon: <TimelapseIcon /> },
  { id: 3, name: 'Ended', slug: 'past', icon: <AlarmOnIcon /> },
  { id: 4, name: 'Canceled-postponed', slug: 'canceled-postponed', icon: <AlarmOnIcon /> },
];

export const counter = {
  folders: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
  },
  labels: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
  },
};
const MatchesList = ({ width, viewMode, onShowMatchDetail, onClickEditMatch, matchesData, pagination, setPagination }) => {
  const { showFooter } = useContext(AppContext);
  const { filterType, matchesList, sortType } = useSelector(({ matchApp }) => matchApp);
  const { loading } = useSelector(({ common }) => common);
  const [checkedMatches, setCheckedMatches] = useState([]);

  const handleCellCheckBox = (isChecked, id) => {
    if (isChecked) {
      setCheckedMatches(checkedMatches.concat(id));
    } else {
      setCheckedMatches(checkedMatches.filter(matchId => matchId !== id));
    }
  };

  const handleHeaderCheckBox = isChecked => {
    if (isChecked) {
      const ids = matchesList.map(match => match.id);
      updateCheckedMatches(ids);
    } else {
      updateCheckedMatches([]);
    }
  };

  const updateCheckedMatches = matchIds => {
    setCheckedMatches(matchIds);
  };

  const classes = useStyles({
    height: getMatchContainerHeight(width, showFooter),
  });
  return matchesData !== undefined && matchesData?.matches?.length > 0 && !loading ? (
    <Box className={classes.inBuildAppMainContent}>
      {viewMode === 'table' ? (
        <ListTableView
          checkedMatches={checkedMatches}
          handleCellCheckBox={handleCellCheckBox}
          handleHeaderCheckBox={handleHeaderCheckBox}
          updateCheckedMatches={updateCheckedMatches}
          onShowMatchDetail={onShowMatchDetail}
          onClickEditMatch={onClickEditMatch}
          filterType={filterType.status}
          width={width}
          matchesData={matchesData}
          pagination={pagination}
          setPagination={setPagination}
        />
      ) : (
        <ListGridView
          width={width}
          filterType={filterType.status}
          onShowMatchDetail={onShowMatchDetail}
          onClickEditMatch={onClickEditMatch}
          matchesData={matchesData}
          pagination={pagination}
          setPagination={setPagination}
        />
      )}
    </Box>
  ) : (
    !loading && (
      <Box className={classes.inBuildAppMainContent}>
        <EmptyMatchResult />
      </Box>
    )
  );
};

export default MatchesList;

MatchesList.prototype = {
  viewMode: PropTypes.string,
  onShowMatchDetail: PropTypes.func,
  onClickEditMatch: PropTypes.func,
};

MatchesList.defaultProps = {
  viewMode: 'table',
};
