import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchError } from 'redux/actions';
import { makeStyles, Box, Switch, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const LanguagePublish = ({ lang, deleteLanguage, languages, handleChangeStatus, ...props }) => {
  const useStyles = makeStyles(theme => ({
    margin: {
      padding: '0 10px;',
      display: 'flex',
      maxWidth: '50%',
      width: '100%',
    },
    fullWidth: {
      width: '100%',
    },
    fullWidthFlex: {
      maxWidth: '170px',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'nowrap',
      borderRadius: '4px',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      marginRight: '15px',
      marginTop: '15px',
      order: '1',
      '&:last-of-type': {},
    },

    fullWidthFlexFirst: {
      maxWidth: '170px',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'nowrap',
      borderRadius: '4px',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      marginRight: '15px',
      marginTop: '15px',
      order: '0',
      background: 'rgba(0,0,0,0.05)',
      '&:last-of-type': {},
    },
  }));

  const [status, setStatus] = useState(lang.status === 1);

  const dispatch = useDispatch();
  const classes = useStyles();

  const handleChange = () => {
    if (lang.code === 'en') {
      dispatch(fetchError(`English language can't be disabled`));
    } else {
      setStatus(!status);
      handleChangeStatus(lang._id, !status === false ? 0 : 1);
    }
  };

  useEffect(() => {}, []);

  return (
    <Box component="div" className={lang.code === 'en' ? classes.fullWidthFlexFirst : classes.fullWidthFlex}>
      <Box>
        <span
          style={{
            textTransform: 'uppercase',
            fontWeight: 'bold',
            margin: '0 15px 0 15px',
          }}>
          {lang.code}
        </span>
      </Box>
      <Box>
        <Switch
          checked={status}
          onChange={handleChange}
          disabled={lang.code === 'en'}
          name="checkedA"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </Box>
      <Box>
        <IconButton
          aria-label="delete"
          disabled={lang.code === 'en'}
          onClick={e => {
            deleteLanguage(lang._id);
          }}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default LanguagePublish;
