import React from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useStyles from './index.style';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';

import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PopupDetails from './PopupDetails';
import { getAllPopUps } from 'redux/actions/PopUps';

const PopupsCreateEdit = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <CmtCard>
      <CmtCardHeader
        className="pt-4"
        icon={
          <ArrowBackIcon
            style={{ cursor: 'pointer' }}
            onClick={() => {
              dispatch(getAllPopUps());
              setTimeout(() => {
                history.push('/pop-ups');
              }, 100);
            }}
          />
        }
        title={id !== undefined ? 'Edit Pop-up' : 'Create Pop-up'}
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}
      />
      <CmtCardContent className={classes.cardContentRoot}>
        <PopupDetails />
        <NotificationContainer />
      </CmtCardContent>
    </CmtCard>
  );
};

export default PopupsCreateEdit;
