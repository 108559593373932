import React from 'react';
import useStyles from './index.style';

import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import PollsListing from './PollsListing';

const PollsPage = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <CmtCard style={{ minHeight: '80vh' }}>
      <CmtCardHeader
        className="pt-4"
        title="Polls"
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push('/polls/new');
          }}>
          Create
        </Button>
      </CmtCardHeader>
      <CmtCardContent className={classes.cardContentRoot} style={{ overflow: 'auto' }}>
        <PollsListing />
      </CmtCardContent>
    </CmtCard>
  );
};

export default PollsPage;
