import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addBroadcastCompetition } from 'redux/actions/Competitions';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { getModalStyle } from 'constants/AppConstants';

import {
  Box,
  Divider,
  makeStyles,
  Button,
  Modal,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
} from '@material-ui/core';
import { Close, Edit, Delete } from '@material-ui/icons';

import 'react-notifications/lib/notifications.css';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    outline: 'none',
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 8, 6),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 233,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const Broadcasts = ({ open, handleCloseModal, broadcasters, competitionId }) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(open);
  const [broadcastersList, setBroadcastersList] = useState(broadcasters === null ? [] : broadcasters);

  const [uuid, setUuid] = useState('');
  const [country, setCountry] = useState('');
  const [broadcasterName, setBroadcasterName] = useState('');
  const [broadcasterStream, setBroadcasterStream] = useState('');
  const [editing, setEditing] = useState(false);
  const { getData } = require('country-list');

  const createNotification = (type, message) => {
    return NotificationManager.error(message, 'ERROR!', 2000);
  };

  const handleClose = () => {
    setOpenModal(false);
    handleCloseModal(false);
  };

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  useEffect(() => {
    broadcasters !== null && setBroadcastersList(broadcasters);
  }, [broadcasters]);

  const addBroadcast = () => {
    let body;
    if (broadcasterName.length === 0) {
      createNotification('error', 'Broadcaster Name should not be empty');
      return false;
    }
    if (country.length === 0) {
      createNotification('error', 'country should not be empty');
      return false;
    }
    if (editing === true) {
      if (broadcasterStream.length === 0) {
        body = {
          stations: [
            {
              uuid: uuid,
              country: country,
              broadcasterName: broadcasterName,
              delete: false,
            },
          ],
        };
      } else {
        let pattern = new RegExp(
          '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
          'i',
        ); // fragment locator
        if (pattern.test(broadcasterStream) === false) {
          createNotification('error', 'Broadcaster Stream is invalid');
          return false;
        }
        body = {
          stations: [
            {
              uuid: uuid,
              country: country,
              broadcasterName: broadcasterName,
              broadcasterStream: broadcasterStream,
              delete: false,
            },
          ],
        };
      }
    } else {
      if (broadcasterStream.length === 0) {
        body = {
          stations: [
            {
              country: country,
              broadcasterName: broadcasterName,
              delete: false,
            },
          ],
        };
      } else {
        let pattern = new RegExp(
          '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
          'i',
        ); // fragment locator
        if (pattern.test(broadcasterStream) === false) {
          createNotification('error', 'Broadcaster Stream is invalid');
          // alert('Broadcaster Stream is invalid');
          return false;
        }
        body = {
          stations: [
            {
              country: country,
              broadcasterName: broadcasterName,
              broadcasterStream: broadcasterStream,
              delete: false,
            },
          ],
        };
      }
    }
    dispatch(addBroadcastCompetition(competitionId, body));
    setCountry('');
    setBroadcasterName('');
    setBroadcasterStream('');
    setUuid('');
    setEditing(false);
  };

  const editBroadcast = (country, name, stream, uuid) => {
    setCountry(country);
    setBroadcasterName(name);
    setBroadcasterStream(stream);
    setUuid(uuid);
    setEditing(true);
  };
  const deleteBroadcast = (country, name, stream, uuid) => {
    let body = {
      stations: [
        {
          uuid: uuid,
          country: country,
          broadcasterName: name,
          delete: true,
        },
      ],
    };
    dispatch(addBroadcastCompetition(competitionId, body));
    setCountry('');
    setBroadcasterName('');
    setBroadcasterStream('');
    setUuid('');
    setEditing(false);
  };
  const cancelEditing = () => {
    setCountry('');
    setBroadcasterName('');
    setBroadcasterStream('');
    setUuid('');
    setEditing(false);
  };

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <div style={modalStyle} className={classes.paper}>
        <Close
          onClick={handleClose}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            right: '20px',
            top: '20px',
          }}
        />
        <div className="broadcaster-list" style={{ marginBottom: '30px' }}>
          <h2>Broadcasters</h2>
          <div className="broadcast-wrapper">
            {broadcastersList !== undefined &&
              // eslint-disable-next-line array-callback-return
              broadcastersList.map((item, key) => {
                if (item.stations.length !== undefined && item.stations.length > 0) {
                  return (
                    <div key={key}>
                      <h4
                        style={{
                          marginTop: '10px',
                          marginBottom: '5px',
                        }}>
                        country {item.country}
                      </h4>
                      <div
                        className="broadcasterWrapper"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                        }}>
                        {item.stations.map((station, id) => {
                          return (
                            <div key={id} className="broadcaster">
                              <Button
                                variant="contained"
                                style={{ margin: '5px' }}
                                color="primary"
                                endIcon={
                                  <Box
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}>
                                    <Edit
                                      style={{
                                        marginRight: '10px',
                                        marginLeft: '10px',
                                      }}
                                      onClick={() =>
                                        editBroadcast(
                                          item.country,
                                          station.broadcasterName,
                                          station.broadcasterStream,
                                          station.uuid,
                                          competitionId,
                                        )
                                      }
                                    />
                                    <Delete
                                      onClick={() =>
                                        deleteBroadcast(
                                          item.country,
                                          station.broadcasterName,
                                          station.broadcasterStream,
                                          station.uuid,
                                          competitionId,
                                        )
                                      }
                                    />
                                  </Box>
                                }>
                                <a
                                  style={{ marginRight: '0', color: '#fff' }}
                                  href={station.broadcasterStream}
                                  target="_blank"
                                  rel="noopener noreferrer">
                                  {station.broadcasterName}
                                </a>
                              </Button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </div>
        <Divider />
        <div
          className="broadcaster-add"
          style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h2>{editing === false ? 'Add Broadcaster' : 'Edit Broadcaster'}</h2>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-filled-label">Country</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={country}
              onChange={e => {
                setCountry(e.target.value);
              }}
              label="Country">
              {getData().map(country => {
                return (
                  <MenuItem key={country.code} value={country.code}>
                    {country.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              id="outlined-basic"
              label="Broadcaster Name"
              variant="outlined"
              value={broadcasterName}
              onChange={e => setBroadcasterName(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Broadcaster Stream"
              variant="outlined"
              value={broadcasterStream}
              onChange={e => setBroadcasterStream(e.target.value)}
            />
          </form>
          <Box className={classes.root}>
            <Button variant="contained" color="primary" onClick={addBroadcast}>
              {editing === false ? 'Add Broadcast' : 'Edit Broadcast'}
            </Button>
            {editing === true && (
              <Button variant="contained" color="secondary" onClick={cancelEditing}>
                Cancel
              </Button>
            )}
          </Box>
        </div>
        <NotificationContainer />
      </div>
    </Modal>
  );
};

export default Broadcasts;
