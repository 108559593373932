import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import OddCellOptions from './OddCellOptions';
import useStyles from 'components/Table/table.styles';
import clsx from 'clsx';
import CommonName from './CommonName';

const OddCell = ({ odd, fetchOdds, commonNames, commonNamesEvent, handleChangeCommonName, handleChangeCommonNameEvent }) => {
  const classes = useStyles();
  return (
    <TableRow className={classes.tableRowRoot}>
      <TableCell className={classes.tableCellRoot} style={{ width: '20%' }}>
        {odd.name}
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <CommonName
          oddOptionBettingSelectorTypeValue={odd.oddOptionBettingSelectorType}
          buttonAvailable={true}
          commonNames={commonNames}
          id={odd._id}
          selectedName={odd.commonName}
          nameSimple={odd.commonNameSimple}
          handleChangeCommonName={handleChangeCommonName}
        />
      </TableCell>
      <TableCell className={clsx(classes.tableCellRoot, classes.tableCellRootSmall)}>
        {odd.visible ? <Visibility /> : <VisibilityOff color="disabled" />}
      </TableCell>
      <TableCell className={clsx(classes.tableCellRoot, classes.tableCellRootSmall)}>
        <OddCellOptions
          odd={odd}
          fetchOdds={fetchOdds}
          commonNames={commonNamesEvent}
          handleChangeCommonNameEvent={handleChangeCommonNameEvent}
          nameSimple={odd.commonNameSimple}
        />
      </TableCell>
    </TableRow>
  );
};

export default OddCell;

OddCell.prototype = {
  odd: PropTypes.object.isRequired,
};
