import React, { useState, useEffect } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import useStyles from '../index.style';
import ImageIcon from '@material-ui/icons/Image';
import { useParams } from 'react-router';

const statusTypes = [
  { id: 0, value: 0, label: 'Inactive' },
  { id: 1, value: 1, label: 'Active' },
  { id: 2, value: 2, label: 'Finished' },
];

const designTypes = [
  { id: 0, value: 0, label: 'Top' },
  { id: 1, value: 1, label: 'Middle' },
];

const generatedSelections = [
  { id: 1, label: 'Admin', generatedBy: 0, disabled: false },
  { id: 2, label: 'Script', generatedBy: 1, disabled: true },
];

const typesSelections = [
  { id: 0, label: 'Normal', type: 0, disabled: false },
  { id: 1, label: 'Team', type: 1, disabled: true },
  { id: 2, label: 'Competition', type: 2, disabled: true },
];

const SelectionDetails = ({
  sendCover,
  sendName,
  sendPriority,
  viewDetail,
  sendStatus,
  sendDesign,
  sendFilterByGeneration,
  sendFilterByType,
}) => {
  const { id } = useParams();
  const classes = useStyles();
  const [editDetail, setEditDetail] = useState();
  const [previewCover, setPreviewCover] = useState(null);
  const [status, setStatus] = useState(0);
  const [design, setDesign] = useState(0);
  const [filterByGeneration, setFilterByGeneration] = useState(0);
  const [filterByType, setFilterByType] = useState(0);

  useEffect(() => {
    setEditDetail(viewDetail);
    if (viewDetail !== undefined && id !== 'new') {
      setPreviewCover(viewDetail.bgImage);
      setStatus(viewDetail.status);
      setDesign(viewDetail.designType);
      setFilterByGeneration(viewDetail.generatedBy);
      setFilterByType(viewDetail.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewDetail]);

  const handleCover = e => {
    setPreviewCover(URL.createObjectURL(e.target.files[0]));
    sendCover(e.target.files[0]);
  };

  const handleChangeStatus = e => {
    const selectedStatus = statusTypes.find(statusType => statusType.value === e.target.value);
    setStatus(selectedStatus.value);
    sendStatus(selectedStatus.value);
  };

  const handleChangeDesign = e => {
    const selectedDesign = designTypes.find(designType => designType.value === e.target.value);
    setDesign(selectedDesign.value);
    sendDesign(selectedDesign.value);
  };

  const handleChangeFilterByGeneration = e => {
    const selectedFilterByGeneration = generatedSelections.find(
      generatedSelection => generatedSelection.generatedBy === e.target.value,
    );
    setFilterByGeneration(selectedFilterByGeneration.generatedBy);
    sendFilterByGeneration(selectedFilterByGeneration.generatedBy);
  };

  const handleChangeFilterByType = e => {
    const selectedFilterByType = typesSelections.find(typesSelection => typesSelection.type === e.target.value);
    setFilterByType(selectedFilterByType.type);
    sendFilterByType(selectedFilterByType.type);
  };

  return (
    <Box>
      <Box className={classes.headOfficial}>
        <Box className={classes.headOfficialLeft}>
          {editDetail !== undefined && (
            <TextField
              id="outlined-basic"
              onChange={e => sendName(e.target.value)}
              defaultValue={editDetail.name}
              className={classes.textFieldHead}
              label="Selection Name"
              variant="outlined"
            />
          )}
          {editDetail === undefined && (
            <TextField
              id="outlined-basic"
              onChange={e => sendName(e.target.value)}
              className={classes.textFieldHead}
              label="Selection Name"
              variant="outlined"
            />
          )}

          {editDetail !== undefined && (
            <TextField
              id="outlined-basic"
              onChange={e => sendPriority(e.target.value)}
              defaultValue={editDetail.priority}
              label="Priority"
              type="number"
              inputProps={{ min: 1 }}
              className={classes.textFieldHead}
              variant="outlined"
            />
          )}
          {editDetail === undefined && (
            <TextField
              id="outlined-basic"
              onChange={e => sendPriority(e.target.value)}
              label="Priority"
              type="number"
              inputProps={{ min: 1 }}
              className={classes.textFieldHead}
              variant="outlined"
            />
          )}

          {editDetail !== undefined && (
            <FormControl style={{ margin: '12.5px 20px' }} className={classes.formControl}>
              <InputLabel>Design Type</InputLabel>
              <Select name="designType" defaultValue={editDetail?.designType} value={design} onChange={handleChangeDesign}>
                {designTypes.map(designType => {
                  return (
                    <MenuItem key={designType.id} value={designType.value}>
                      {designType.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
          {editDetail === undefined && (
            <FormControl style={{ margin: '12.5px 20px' }} className={classes.formControl}>
              <InputLabel>Design Type</InputLabel>
              <Select name="designType" value={design} onChange={handleChangeDesign}>
                {designTypes.map(designType => {
                  return (
                    <MenuItem key={designType.id} value={designType.value}>
                      {designType.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}

          {editDetail !== undefined && (
            <FormControl style={{ margin: '12.5px 20px' }} className={classes.formControl}>
              <InputLabel>Status</InputLabel>
              <Select name="status" defaultValue={editDetail?.status} value={status} onChange={handleChangeStatus}>
                {statusTypes.map(statusType => {
                  return (
                    <MenuItem key={statusType.id} value={statusType.value}>
                      {statusType.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
          {editDetail === undefined && (
            <FormControl style={{ margin: '12.5px 20px' }} className={classes.formControl}>
              <InputLabel>Status</InputLabel>
              <Select name="status" value={status} onChange={handleChangeStatus}>
                {statusTypes.map(statusType => {
                  return (
                    <MenuItem key={statusType.id} value={statusType.value}>
                      {statusType.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}

          <FormControl style={{ margin: '12.5px 20px' }} className={classes.formControl}>
            <InputLabel>Generated By</InputLabel>
            <Select
              name="filterByGeneration"
              defaultValue={editDetail?.generatedBy}
              value={filterByGeneration}
              disabled={id !== 'new' ? true : false}
              onChange={handleChangeFilterByGeneration}>
              {generatedSelections.map(generatedSelection => {
                return (
                  <MenuItem
                    key={generatedSelection.id}
                    value={generatedSelection.generatedBy}
                    disabled={generatedSelection.disabled}>
                    {generatedSelection.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl style={{ margin: '12.5px 20px' }} className={classes.formControl}>
            <InputLabel>Type</InputLabel>
            <Select
              name="filterByType"
              defaultValue={editDetail?.type}
              value={filterByType}
              disabled={id !== 'new' ? true : false}
              onChange={handleChangeFilterByType}>
              {typesSelections.map(typesSelection => {
                return (
                  <MenuItem key={typesSelection.id} value={typesSelection.type} disabled={typesSelection.disabled}>
                    {typesSelection.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        <Box className={classes.headOfficialRight}>
          <Box className={classes.logoCover}>
            <Box className={classes.imagePlaceholder}>
              {previewCover !== null ? <img alt="cover" src={previewCover} className={classes.imageLogo} /> : null}
            </Box>
            <input
              accept="image/*"
              onChange={handleCover}
              className={classes.hiddenInput}
              id="contained-button-cover"
              multiple
              type="file"
            />
            <label htmlFor="contained-button-cover">
              <Button variant="contained" color="primary" component="span" startIcon={<ImageIcon />}>
                Upload Cover
              </Button>
            </label>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SelectionDetails;
