import {
  GET_USERS,
  GET_AML_USERS,
  GET_USERS_ADMIN,
  GET_USER_DETAIL,
  GET_USER_WALLET,
  GET_USER_TRANSACTIONS,
  CREATE_USER,
  PATCH_USER,
  BLOCK_USER,
  UNBLOCK_USER,
  CHANGE_PASSWORD,
  BLOCK_USER_AML,
  GET_USERS_PERMISSIONS,
  POST_USERS_PERMISSIONS,
  GET_USER_DOCUMENTS,
  PATCH_DOCUMENT_STATUS,
} from '../../constants/ActionTypes';

// ------------------------------------
// Action Handlers
// ------------------------------------

const INIT_STATE = {
  isSideBarCollapsed: false,
  labelsList: [],
  filterType: {
    selectedFolder: 'accepted',
    selectedLabel: '',
    searchText: '',
  },
  languageList: [],
  languages: [],
  languagePlatforms: [],
  currentLanguage: null,
  totalBars: null,
  counter: null,
  page: 0,
  rowsPerPage: 50,
  params: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS: {
      return {
        ...state,
        usersList: action.payload.data,
      };
    }

    case GET_USERS_ADMIN: {
      return {
        ...state,
        usersAdminList: action.payload.data,
      };
    }

    case GET_AML_USERS: {
      return {
        ...state,
        usersList: action.payload.data,
        page: action.payload.page,
        rowsPerPage: action.payload.rowsPerPage,
        params: action.payload.params,
      };
    }

    case GET_USER_DETAIL: {
      return {
        ...state,
        userDetail: action.payload.data,
      };
    }

    case GET_USER_WALLET: {
      return {
        ...state,
        userWallet: action.payload.data,
      };
    }

    case GET_USER_DOCUMENTS: {
      return {
        ...state,
        userDocuments: action.payload.data,
      };
    }

    case PATCH_DOCUMENT_STATUS: {
      return {
        ...state,
        documentStatus: action.payload.data,
      };
    }

    case GET_USER_TRANSACTIONS: {
      return {
        ...state,
        userTransactions: action.payload.data,
      };
    }

    case PATCH_USER: {
      return {
        ...state,
        userDetail: action.payload.data,
      };
    }

    case CREATE_USER: {
      return {
        ...state,
      };
    }

    case BLOCK_USER: {
      return {
        ...state,
      };
    }

    case UNBLOCK_USER: {
      return {
        ...state,
      };
    }

    case CHANGE_PASSWORD: {
      return {
        ...state,
        changePassword: action.payload.data,
      };
    }

    case BLOCK_USER_AML: {
      return {
        ...state,
        blockUser: action.payload.data,
      };
    }

    case GET_USERS_PERMISSIONS: {
      return {
        ...state,
        usersPermissions: action.payload.data,
      };
    }

    case POST_USERS_PERMISSIONS: {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};
