const AMLUsers = [
  {
    id: 1,
    title: 'Name',
  },
  {
    id: 2,
    title: 'Email',
  },
  {
    id: 3,
    title: 'Risk Level',
  },
  {
    id: 4,
    title: 'Total Placed Bets 24H',
  },
  {
    id: 5,
    title: 'Total Placed Bets 7D',
  },
  {
    id: 6,
    title: 'Total Placed Bets 30D',
  },
  {
    id: 7,
    title: 'Action',
  },
];

const TableHeader = {
  AMLUsers,
};

export default TableHeader;
