import {
  SportsSoccer,
  SportsTennis,
  SportsBaseball,
  SportsBasketball,
  SportsCricket,
  SportsHockey,
  SportsRugby,
  SportsMotorsports,
  SportsFootball,
} from '@material-ui/icons';
import React from 'react';

const licenses = [
  { value: 'DEMO', type: 0 },
  { value: 'UK_LICENSE', type: 1 },
  { value: 'MALTA_LICENSE', type: 2 },
];

const documentStatus = [
  { id: 0, value: 'approved', label: 'Approved' },
  { id: 1, value: 'rejected', label: 'Rejected' },
];

const weekDays = [
  { id: 0, name: 'monday', label: 'Monday' },
  { id: 1, name: 'tuesday', label: 'Tuesday' },
  { id: 2, name: 'wednesday', label: 'Wednesday' },
  { id: 3, name: 'thursday', label: 'Thursday' },
  { id: 4, name: 'friday', label: 'Friday' },
  { id: 5, name: 'saturday', label: 'Saturday' },
  { id: 6, name: 'sunday', label: 'Sunday' },
];

const sports = [
  { id: 0, name: 'Football', icon: <SportsSoccer /> },
  { id: 1, name: 'Tennis', icon: <SportsTennis /> },
  { id: 2, name: 'Baseball', icon: <SportsBaseball /> },
  { id: 3, name: 'Basketball', icon: <SportsBasketball /> },
  { id: 4, name: 'Cricket', icon: <SportsCricket /> },
  { id: 5, name: 'Hockey', icon: <SportsHockey /> },
  { id: 6, name: 'Rugby', icon: <SportsRugby /> },
  { id: 7, name: 'Motor Racing', icon: <SportsMotorsports /> },
  { id: 8, name: 'Am. Football', icon: <SportsFootball /> },
];

const addEventTypes = {
  football: [
    {
      value: 'goal',
      label: 'Goal',
      id: 0,
    },
    {
      value: 'ownGoal',
      label: 'Own Goal',
      id: 1,
    },
    {
      value: 'penalty',
      label: 'Penalty',
      id: 2,
    },
  ],
  tennis: [
    {
      value: 'goalTennis',
      label: 'Goal Tennis',
      id: 0,
    },
    {
      value: 'ownGoalTennis',
      label: 'Own Goal Tennis',
      id: 1,
    },
    {
      value: 'penaltyTennis',
      label: 'Penalty Tennis',
      id: 2,
    },
  ],
  baseball: [
    {
      value: 'goalBaseball',
      label: 'Goal Baseball',
      id: 0,
    },
    {
      value: 'ownGoalBaseball',
      label: 'Own Goal Baseball',
      id: 1,
    },
    {
      value: 'penaltyBaseball',
      label: 'Penalty Baseball',
      id: 2,
    },
  ],
  'am. football': [
    {
      value: 'goalAmFootball',
      label: 'Goal Am Football',
      id: 0,
    },
    {
      value: 'ownAmFootball',
      label: 'Own Goal Am Football',
      id: 1,
    },
    {
      value: 'penaltyAmFootball',
      label: 'Penalty Am Football',
      id: 2,
    },
  ],
  basketball: [
    {
      value: 'goalBasketball',
      label: 'Goal Basketball',
      id: 0,
    },
    {
      value: 'ownGoalBasketball',
      label: 'Own Goal Basketball',
      id: 1,
    },
    {
      value: 'penaltyBasketball',
      label: 'Penalty Basketball',
      id: 2,
    },
  ],
  'motor racing': [
    {
      value: 'goalMotorSport',
      label: 'Goal Motor Sport',
      id: 0,
    },
    {
      value: 'ownGoalMotorSport',
      label: 'Own Goal Motor Sport',
      id: 1,
    },
    {
      value: 'penaltyMotorSport',
      label: 'Penalty Motor Sport',
      id: 2,
    },
  ],
};

const betTemplateType = [
  { id: 3, name: 'Simple template', value: 'simple' },
  { id: 0, name: 'Combo template', value: 'global' },
  { id: 4, name: 'Mix template', value: 'mix' },
];

const GlobalVariables = {
  licenses,
  documentStatus,
  weekDays,
  sports,
  betTemplateType,
  addEventTypes,
};

export default GlobalVariables;
