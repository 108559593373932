import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { setSortType } from 'redux/actions/MatchApp';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  tableCellRoot: {
    color: '#020202',
    cursor: 'pointer',
    letterSpacing: '0.4px',
    fontSize: '16px',
    fontWeight: 'bold',
    padding: '16px',
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 15,
    },
    '&:last-child': {
      paddingRight: 15,
    },
  },
}));

const ListHeader = ({ filterType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { sortType } = useSelector(({ matchApp }) => matchApp);

  const sortHandler = fieldName => {
    if (sortType.field === fieldName) {
      dispatch(setSortType({ ...sortType, order: sortType.order * -1 }));
    } else {
      dispatch(
        setSortType({
          field: fieldName,
          order: 1,
        }),
      );
    }
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tableCellRoot}>
          <Box display="flex" alignItems="center" onClick={() => sortHandler('name')}>
            Name {sortType.field === 'name' ? sortType.order === -1 ? <ArrowDownward /> : <ArrowUpward /> : undefined}
          </Box>
        </TableCell>
        {filterType === 'past' || filterType === 'live' ? (
          <TableCell className={classes.tableCellRoot}>Score</TableCell>
        ) : null}
        <TableCell display="flex" alignItems="center" className={classes.tableCellRoot} onClick={() => sortHandler('date')}>
          <Box display="flex" alignItems="center" onClick={() => sortHandler('date')}>
            Date {sortType.field === 'date' ? sortType.order === -1 ? <ArrowDownward /> : <ArrowUpward /> : undefined}
          </Box>
        </TableCell>
        <TableCell display="flex" alignItems="center" className={classes.tableCellRoot}>
          <Box display="flex" alignItems="center" onClick={() => sortHandler('competition')}>
            Competition{' '}
            {sortType.field === 'competition' ? sortType.order === -1 ? <ArrowDownward /> : <ArrowUpward /> : undefined}
          </Box>
        </TableCell>
        {filterType === 'past' || filterType === 'live' || filterType === 'future' ? (
          <>
            <TableCell className={classes.tableCellRoot}>
              <Box display="flex" alignItems="center" onClick={() => sortHandler('popular')}>
                Popular{' '}
                {sortType.field === 'popular' ? sortType.order === -1 ? <ArrowDownward /> : <ArrowUpward /> : undefined}
              </Box>
            </TableCell>
            <TableCell className={classes.tableCellRoot}>Match Of The Day</TableCell>
            <TableCell className={classes.tableCellRoot}>Top Match</TableCell>
          </>
        ) : null}
        {filterType === 'canceled-postponed' ? (
          <>
            <TableCell className={classes.tableCellRoot}>Sport Name</TableCell>
            <TableCell className={classes.tableCellRoot}>Status matches</TableCell>
          </>
        ) : null}
      </TableRow>
    </TableHead>
  );
};

export default ListHeader;

ListHeader.prototype = {
  checkedMatches: PropTypes.array,
  matchesList: PropTypes.array,
  handleHeaderCheckBox: PropTypes.func,
};

ListHeader.defaultProps = {
  checkedMatches: [],
  matchList: [],
};
