import React from 'react';
import { Box, Button, TablePagination } from '@material-ui/core';
import CmtAvatar from '@coremat/CmtAvatar';

import useStyles from './../index.style';
import { useState } from 'react';
import { useEffect } from 'react';

const CountryCell = ({ selectedCountries }) => {
  const classes = useStyles();

  const rowsPerPage = 32;
  const [page, setPage] = useState(0);
  const [slicedCountries, setSlicedCountries] = useState(selectedCountries.slice(0, rowsPerPage));

  useEffect(() => {
    setSlicedCountries(selectedCountries.slice(0, rowsPerPage));
  }, [selectedCountries]);

  const handleChangePage = (event, newPage) => {
    setSlicedCountries(selectedCountries.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage) || []);
    setPage(newPage);
  };

  return (
    <Box className={classes.selectedCountriesList}>
      {slicedCountries.map((country, id) => {
        return (
          <Box key={id} className={classes.selectListCountriesRow}>
            <Box>
              <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
                <Box mr={4} style={{ width: '30px', overflow: 'hidden', marginRight: '0' }}>
                  <CmtAvatar
                    size={30}
                    src={'http://www.geognos.com/api/en/countries/flag/' + country.code + '.png'}
                    alt={country.name}
                  />
                </Box>
              </Box>
            </Box>
            <Box>
              <Box>
                <h4 style={{ fontWeight: 'bold' }}>{country.name}</h4>
              </Box>
            </Box>
          </Box>
        );
      })}
      <TablePagination
        component="div"
        style={{ order: '9999', width: '100%' }}
        count={selectedCountries?.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[30]}
        rowsPerPage={rowsPerPage}
      />
    </Box>
  );
};

export default CountryCell;
