import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import useStyles from './../index.style';
import ImageIcon from '@material-ui/icons/Image';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

const stakeTypes = [
  { id: 0, value: 0, label: 'Fun' },
  { id: 1, value: 1, label: 'Money' },
  { id: 2, value: 2, label: 'Yepp Coins' },
];

const externalChallengeTypes = [
  { value: 6, label: 'SPORTWORLD' },
  { value: 7, label: 'TSC' },
  { value: 9, label: 'PBC' },
];

const CustomColorCheckbox = withStyles({
  root: {
    color: '#000000de',
    '&$checked': {
      color: '#30178e',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const ChampionshipDetails = ({
  langList,
  sendDaily,
  sendWeekly,
  sendAllTime,
  sendQr,
  sendIsB2B,
  sendB2BchallengeType,
  sendLogo,
  sendCover,
  getHowItWorks,
  sendName,
  sendTerms,
  sendCustomEndDate,
  sendStake,
  viewDetail,
  sendStakeType,
  sendRankingFilterType,
  sendAvailableRankingFilterType,
  order,
  setOrder,
  showTCScreen,
  setShowTCScreen,
  regulationURI,
  setRegulationURI,
  showChallengeTC,
  setShowChallengeTC,
  showChallengeInfo,
  setShowChallengeInfo,
  showPrizes,
  setShowPrizes,
}) => {
  const classes = useStyles();
  const lang = langList;
  const [editDetail, setEditDetail] = useState();
  const [previewLogo, setPreviewLogo] = useState(null);
  const [previewCover, setPreviewCover] = useState(null);
  const [allTime, setAllTime] = useState(1);
  const [weekly, setWeekly] = useState(0);
  const [daily, setDaily] = useState(0);
  const [qrCodeEligible, setQrCodeEligible] = useState(false);
  const [stakeType, setStakeType] = useState(0);
  const [isB2B, setIsB2B] = useState(false);
  const [B2BchallengeType, setB2BchallengeType] = useState(externalChallengeTypes[0]);
  const [rankingFilterType, setRankingFilterType] = useState([
    { id: 0, value: false, label: 'All time', transKey: 'all_time', type: 0 },
    { id: 1, value: false, label: 'Weekly', transKey: 'weekly', type: 1 },
    { id: 2, value: false, label: 'Daily', transKey: 'daily', type: 2 },
    { id: 3, value: false, label: 'Monthly', transKey: 'monthly', type: 3 },
  ]);
  const [availableRankingFilterType, setAvailableRankingFilterType] = useState({});
  const [terms, setTerms] = useState('');
  const [customEndDate, setCustomEndDate] = useState();
  const [description, setDescription] = useState(() => {
    let state = [];
    lang.forEach(item => {
      let array = {
        text: '',
        language: item,
      };
      state.push(array);
    });
    return state;
  });
  useEffect(() => {
    setEditDetail(viewDetail);
    if (viewDetail !== undefined) {
      setDaily(viewDetail.prizeTypesCounts === undefined ? 0 : viewDetail.prizeTypesCounts.daily);
      sendDaily(viewDetail.prizeTypesCounts === undefined ? 0 : viewDetail.prizeTypesCounts.daily);
      setWeekly(viewDetail.prizeTypesCounts === undefined ? 0 : viewDetail.prizeTypesCounts.weekly);
      sendWeekly(viewDetail.prizeTypesCounts === undefined ? 0 : viewDetail.prizeTypesCounts.weekly);
      setAllTime(viewDetail.prizeTypesCounts === undefined ? 0 : viewDetail.prizeTypesCounts.allTime);
      sendAllTime(viewDetail.prizeTypesCounts === undefined ? 0 : viewDetail.prizeTypesCounts.allTime);
      setQrCodeEligible(viewDetail.qrCodeEligible === undefined ? false : viewDetail.qrCodeEligible);
      sendQr(viewDetail.qrCodeEligible === undefined ? false : viewDetail.qrCodeEligible);
      setPreviewLogo(viewDetail.logo);
      setPreviewCover(viewDetail.cover);
      setTerms(viewDetail.challengeInfo);
      setCustomEndDate(viewDetail.customEndDate);
      setIsB2B(!!viewDetail.isExternalType);
      setB2BchallengeType(viewDetail.externalType === undefined ? externalChallengeTypes[0].value : viewDetail.externalType);
      let newHowItWorks = [];
      viewDetail.howItWorks.forEach((langH, key) => {
        newHowItWorks.push(langH);
      });
      let newLangHowItWorks = [];

      lang.forEach((langH, key) => {
        newLangHowItWorks.push({
          text: '',
          language: langH,
        });
      });

      let newArr = newLangHowItWorks.reduce(
        (acc, eachArr2Elem) => {
          if (
            newHowItWorks.findIndex(
              eachArr1Elem => eachArr1Elem.language === eachArr2Elem.language && eachArr1Elem.text.length >= 1,
            ) === -1
          ) {
            acc.push(eachArr2Elem);
          }
          return acc;
        },
        [...newHowItWorks],
      );
      setDescription(newArr);
      setStakeType(viewDetail.stakeType);
      const allValues = [
        { id: 0, value: false, label: 'All time', transKey: 'all_time', type: 0 },
        { id: 1, value: false, label: 'Weekly', transKey: 'weekly', type: 1 },
        { id: 2, value: false, label: 'Daily', transKey: 'daily', type: 2 },
        { id: 3, value: false, label: 'Monthly', transKey: 'monthly', type: 3 },
      ];

      const mapRankings = viewDetail?.availableRankingFilterTypes?.map(value => {
        const index = allValues?.findIndex(item => item.transKey === value.transKey);

        if (index >= 0) allValues[index].value = true;
      });

      setRankingFilterType(
        allValues?.length
          ? allValues
          : [
              { id: 0, value: false, label: 'All time', transKey: 'all_time', type: 0 },
              { id: 1, value: false, label: 'Weekly', transKey: 'weekly', type: 1 },
              { id: 2, value: false, label: 'Daily', transKey: 'daily', type: 2 },
              { id: 3, value: false, label: 'Monthly', transKey: 'monthly', type: 3 },
            ],
      );
      let rankingFilter;
      if (viewDetail?.rankingFilterType === 0) {
        rankingFilter = { id: 0, value: true, label: 'All time', transKey: 'all_time', type: 0 };
      }
      if (viewDetail?.rankingFilterType === 1) {
        rankingFilter = { id: 1, value: true, label: 'Weekly', transKey: 'weekly', type: 1 };
      }
      if (viewDetail?.rankingFilterType === 2) {
        rankingFilter = { id: 2, value: true, label: 'Daily', transKey: 'daily', type: 2 };
      }
      if (viewDetail?.rankingFilterType === 3) {
        rankingFilter = { id: 3, value: true, label: 'Monthly', transKey: 'monthly', type: 3 };
      }
      setAvailableRankingFilterType(rankingFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewDetail]);

  useEffect(() => {
    if (viewDetail === undefined) {
      if (langList.length > 0) {
        setDescription(() => {
          let state = [];
          lang.forEach((item, key) => {
            let array = {
              text: '',
              language: item,
            };
            state.push(array);
          });
          return state;
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langList]);

  const handleChange = (e, lang, key) => {
    let descriptionNew = {
      text: e.target.value,
      language: lang,
    };
    setDescription(description, (description[key] = descriptionNew));
    getHowItWorks(description);
  };

  const handleChangeAvailableFilter = e => {
    const newFilterType = rankingFilterType.find(rankingFilterType => rankingFilterType.id === e.target.value);

    setAvailableRankingFilterType(newFilterType);
    sendAvailableRankingFilterType(newFilterType);
  };

  const handleLogo = e => {
    setPreviewLogo(URL.createObjectURL(e.target.files[0]));
    sendLogo(e.target.files[0]);
  };

  const handleCover = e => {
    setPreviewCover(URL.createObjectURL(e.target.files[0]));
    sendCover(e.target.files[0]);
  };

  const handleDaily = e => {
    setDaily(e);
    sendDaily(e);
  };

  const handleWeekly = e => {
    setWeekly(e);
    sendWeekly(e);
  };

  const handleAllTime = e => {
    setAllTime(e);
    sendAllTime(e);
  };

  const handleQr = e => {
    setQrCodeEligible(e);
    sendQr(e);
  };

  const handleIsB2B = e => {
    setIsB2B(e);
    sendIsB2B(e);
  };
  const handleB2BchallengeType = e => {
    setB2BchallengeType(e.target.value);
    sendB2BchallengeType(e.target.value);
  };

  const handleTerms = e => {
    setTerms(e);
    sendTerms(e);
  };

  const handleCustomEndDate = e => {
    const newDate = new Date(e.target.value).toISOString();
    setCustomEndDate(newDate);
    sendCustomEndDate(newDate);
  };

  const handleChangeStakeType = e => {
    const selectedStakeType = stakeTypes.find(stakeType => stakeType.value === e.target.value);
    setStakeType(selectedStakeType.value);
    sendStakeType(selectedStakeType.value);
  };

  const handleChangeRankingFilterType = e => {
    const otherRankingFilterTypes = rankingFilterType.filter(
      rankingFilterType => rankingFilterType.id !== Number(e.target.name),
    );
    const selectedRankingFilterType = rankingFilterType.find(
      rankingFilterType => rankingFilterType.id === Number(e.target.name),
    );
    selectedRankingFilterType.value = !selectedRankingFilterType.value;

    const updatedValues = [...otherRankingFilterTypes, selectedRankingFilterType];
    updatedValues.sort((a, b) => a.id - b.id);
    setRankingFilterType(updatedValues);
    const filteredValues = updatedValues.filter(value => value.value);
    sendRankingFilterType(filteredValues);
  };

  const handleOrder = e => {
    if (e.target.value > 0) {
      setOrder(e.target.value);
    }
  };

  const TCScreenHandler = () => {
    if (showTCScreen) {
      setShowChallengeInfo(false);
      setShowChallengeTC(false);
    }
    setShowTCScreen(prev => !prev);
  };

  return (
    <Box>
      <Box className={classes.headOfficial}>
        <Box className={classes.headOfficialLeft}>
          {editDetail !== undefined && (
            <TextField
              id="outlined-basic"
              onChange={e => sendName(e.target.value)}
              defaultValue={editDetail.name}
              className={classes.textFieldHead}
              label="Championship Name"
              variant="outlined"
            />
          )}
          {editDetail === undefined && (
            <TextField
              id="outlined-basic"
              onChange={e => sendName(e.target.value)}
              className={classes.textFieldHead}
              label="Championship Name"
              variant="outlined"
            />
          )}
          {editDetail !== undefined && (
            <TextField
              id="outlined-basic"
              onChange={e => sendStake(e.target.value)}
              defaultValue={editDetail.stake}
              className={classes.textFieldHead}
              label="Official stake"
              variant="outlined"
            />
          )}
          {editDetail === undefined && (
            <TextField
              id="outlined-basic"
              onChange={e => sendStake(e.target.value)}
              className={classes.textFieldHead}
              label="Official stake"
              variant="outlined"
              inputProps={{ inputProps: { min: 0, max: 10 } }}
            />
          )}

          <TextField
            type="number"
            id="outlined-basic"
            onChange={handleOrder}
            value={order}
            className={classes.textFieldHead}
            label="Feed Order"
            variant="outlined"
          />

          <Box style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
            <h2>Is B2B</h2>
            <Switch
              checked={isB2B}
              onChange={e => {
                handleIsB2B(!isB2B);
              }}
              name="isB2B"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Box>
          {isB2B &&
            (editDetail !== undefined ? (
              <FormControl style={{ margin: '12.5px 20px' }} className={classes.formControl}>
                <InputLabel>External Championship Type</InputLabel>
                <Select
                  name="External Championship Type"
                  defaultValue={editDetail?.externalType}
                  value={B2BchallengeType}
                  onChange={handleB2BchallengeType}>
                  {externalChallengeTypes.map((externalChallengeType, index) => {
                    return (
                      <MenuItem key={index} value={externalChallengeType.value}>
                        {externalChallengeType.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : (
              <FormControl style={{ margin: '12.5px 20px' }} className={classes.formControl}>
                <InputLabel>External Championship Type</InputLabel>
                <Select name="External Championship Type" value={B2BchallengeType} onChange={handleB2BchallengeType}>
                  {externalChallengeTypes.map((externalChallengeType, index) => {
                    return (
                      <MenuItem key={index} value={externalChallengeType.value}>
                        {externalChallengeType.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ))}

          {editDetail !== undefined && (
            <FormControl style={{ margin: '12.5px 20px' }} className={classes.formControl}>
              <InputLabel>Stake Type</InputLabel>
              <Select
                name="stakeType"
                defaultValue={editDetail?.stakeType}
                value={stakeType}
                onChange={handleChangeStakeType}>
                {stakeTypes.map(stakeType => {
                  return (
                    <MenuItem key={stakeType.id} value={stakeType.value}>
                      {stakeType.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
          {editDetail === undefined && (
            <FormControl style={{ margin: '12.5px 20px' }} className={classes.formControl}>
              <InputLabel>Stake Type</InputLabel>
              <Select name="stakeType" value={stakeType} onChange={handleChangeStakeType}>
                {stakeTypes.map(stakeType => {
                  return (
                    <MenuItem key={stakeType.id} value={stakeType.value}>
                      {stakeType.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}

          <Box style={{ display: 'block', margin: '10px 7.5px' }}>
            <h2>Ranking Filter Type</h2>
          </Box>
          <FormControl required component="fieldset" sx={{ m: 3 }} variant="standard" style={{ margin: '10px 7.5px' }}>
            {rankingFilterType.map(rankingType => {
              return (
                <FormControlLabel
                  key={rankingType.id}
                  control={
                    <CustomColorCheckbox
                      checked={rankingType.value}
                      name={String(rankingType.id)}
                      onChange={handleChangeRankingFilterType}
                    />
                  }
                  label={rankingType.label}
                />
              );
            })}
          </FormControl>
          <FormControl style={{ margin: '12.5px 20px' }} className={classes.formControl}>
            <InputLabel>Available filter type</InputLabel>
            <Select
              name="rankingFilterType"
              defaultValue={''}
              value={availableRankingFilterType.id ?? ''}
              onChange={handleChangeAvailableFilter}>
              {rankingFilterType.map(rankingType => {
                if (rankingType.value) {
                  return (
                    <MenuItem key={rankingType.id} value={rankingType.id}>
                      {rankingType.label}
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </FormControl>
        </Box>

        <Box className={classes.headOfficialRight}>
          <Box className={classes.logoCover}>
            <Box className={classes.imagePlaceholder}>
              {previewLogo !== null ? <img alt="logo" src={previewLogo} className={classes.imageLogo} /> : null}
            </Box>
            <input
              accept="image/*"
              onChange={handleLogo}
              className={classes.hiddenInput}
              id="contained-button-logo"
              multiple
              type="file"
            />
            <label htmlFor="contained-button-logo">
              <Button variant="contained" color="primary" component="span" startIcon={<ImageIcon />}>
                Upload Logo
              </Button>
            </label>
          </Box>
          <Box className={classes.logoCover}>
            <Box className={classes.imagePlaceholder}>
              {previewCover !== null ? <img alt="cover" src={previewCover} className={classes.imageLogo} /> : null}
            </Box>
            <input
              accept="image/*"
              onChange={handleCover}
              className={classes.hiddenInput}
              id="contained-button-cover"
              multiple
              type="file"
            />
            <label htmlFor="contained-button-cover">
              <Button variant="contained" color="primary" component="span" startIcon={<ImageIcon />}>
                Upload Cover
              </Button>
            </label>
          </Box>
        </Box>
      </Box>
      <Box>
        {editDetail !== undefined &&
          description !== undefined &&
          description.map((item, key) => {
            return (
              <TextField
                id="outlined-basic"
                key={key}
                defaultValue={item.text}
                onChange={e => handleChange(e, item.language, key)}
                multiline={true}
                className={classes.Textarea50}
                label={'How it works ' + item.language.toUpperCase()}
                variant="outlined"
              />
            );
          })}
      </Box>
      <Box>
        {editDetail === undefined &&
          description !== undefined &&
          description.map((item, key) => {
            return (
              <TextField
                id="outlined-basic"
                key={key}
                onChange={e => handleChange(e, item.language, key)}
                multiline={true}
                className={classes.Textarea50}
                label={'How it works ' + item.language.toUpperCase()}
                variant="outlined"
              />
            );
          })}
      </Box>
      <Box style={{ display: 'block', margin: '10px 7.5px' }}>
        <h2>Type of Prizes</h2>
      </Box>
      <Box className={classes.fullBoxwidth}>
        <Box>
          <TextField
            id="outlined-basic"
            style={{ maxWidth: '170px', width: '100%' }}
            onChange={e => {
              handleDaily(e.target.value);
            }}
            InputProps={{ inputProps: { min: 0 } }}
            className={classes.TextareaNumber}
            value={daily}
            type="number"
            label="Prizes per day"
            variant="outlined"
          />
        </Box>
        <Box>
          <TextField
            id="outlined-basic"
            style={{ maxWidth: '170px', width: '100%' }}
            onChange={e => {
              handleWeekly(e.target.value);
            }}
            InputProps={{ inputProps: { min: 0 } }}
            className={classes.TextareaNumber}
            value={weekly}
            type="number"
            label="Prizes per week"
            variant="outlined"
          />
        </Box>
        <Box>
          <TextField
            id="outlined-basic"
            style={{ maxWidth: '170px', width: '100%' }}
            onChange={e => {
              handleAllTime(e.target.value);
            }}
            InputProps={{ inputProps: { min: 1 } }}
            className={classes.TextareaNumber}
            value={allTime}
            type="number"
            label="All time prizes"
            variant="outlined"
          />
        </Box>
        <Box className={classes.fakeBorder}>
          <Box className={classes.label}>qrCodeEligible</Box>
          <p>
            {' '}
            <Switch
              checked={qrCodeEligible}
              onChange={e => {
                handleQr(!qrCodeEligible);
              }}
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </p>
        </Box>
      </Box>
      <Box style={{ display: 'block', margin: '10px 7.5px' }}>
        <h2>Terms and conditions</h2>
      </Box>
      <Box>
        <TextField
          id="outlined-basic"
          onChange={e => handleTerms(e.target.value)}
          value={terms}
          multiline={true}
          className={classes.textFieldHeadDown}
          label="TS & Cs"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
      </Box>

      <Box style={{ display: 'flex', flexDirection: 'row', margin: 10, alignItems: 'center' }}>
        <h2>Display T&C Screen</h2>
        <Switch
          checked={showTCScreen}
          onChange={() => {
            TCScreenHandler();
          }}
          name="displayTAndC"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </Box>

      <Box style={{ display: 'flex', flexDirection: 'row', margin: 10, alignItems: 'center' }}>
        <h2>Display T&C</h2>
        <Switch
          checked={showChallengeTC}
          onChange={() => {
            setShowChallengeTC(prev => !prev);
            !showTCScreen && setShowTCScreen(true);
          }}
          name="displayTAndC"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </Box>

      <Box style={{ display: 'flex', flexDirection: 'row', margin: 10, alignItems: 'center' }}>
        <h2>Display Challenge Info </h2>
        <Switch
          checked={showChallengeInfo}
          onChange={() => {
            setShowChallengeInfo(prev => !prev);
            !showTCScreen && setShowTCScreen(true);
          }}
          name="displayTAndC"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </Box>

      <Box style={{ display: 'flex', flexDirection: 'row', margin: 10, alignItems: 'center' }}>
        <h2>Show Prizes</h2>
        <Switch
          checked={showPrizes}
          onChange={() => {
            setShowPrizes(prev => !prev);
          }}
          name="showPrizes"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </Box>

      <Box style={{ maxWidth: 700 }}>
        <TextField
          id="outlined-basic"
          onChange={e => setRegulationURI(e.target.value)}
          value={regulationURI}
          label="Regulation URI"
          className={classes.textFieldHeadDown}
          variant="outlined"
        />
      </Box>
      <Box style={{ display: 'flex', margin: '10px 7.5px', alignItems: 'center' }}>
        <h2>Set custom date</h2>
        <TextField
          style={{ display: 'block', margin: '10px 20px' }}
          id="datetime-local"
          type="datetime-local"
          onChange={handleCustomEndDate}
          value={customEndDate ? moment(customEndDate).format('YYYY-MM-DDTkk:mm') : null}
          sx={{ width: 250 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
    </Box>
  );
};

export default ChampionshipDetails;
