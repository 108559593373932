import React from 'react';
import { Box, Table, TableBody, TableCell, TableRow, TablePagination } from '@material-ui/core';
import 'react-notifications/lib/notifications.css';

import TableHeader from 'assets/global/globalTableHeadingData';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCard from '@coremat/CmtCard';

import TableHeading from 'components/Table/TableHeading';
import useStyles from 'components/Table/table.styles';

const Transactions = ({ transactions, changePageTransaction, countTransaction, transPage }) => {
  const classes = useStyles();

  return (
    <CmtCard>
      <CmtCardHeader
        className="pt-8"
        title="Transactions"
        style={{ marginBottom: '30px' }}
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}
      />
      <Table>
        <TableHeading headingData={TableHeader.UserDetail.transactions} />
        <TableBody>
          {transactions.transactions.map((row, key) => {
            return (
              <TableRow className={classes.tableRowRoot} key={key}>
                <TableCell className={classes.tableCellRoot}>{row.status}</TableCell>
                <TableCell className={classes.tableCellRoot}>
                  {row.value} {row.currency}
                </TableCell>
                <TableCell className={classes.tableCellRoot}>{row.type === 0 ? 'DEPOSIT' : 'WITHDRAWAL'}</TableCell>
                <TableCell className={classes.tableCellRoot}>{new Date(row.created).toLocaleDateString()}</TableCell>
                <TableCell className={classes.tableCellRoot}>{new Date(row.updated).toLocaleDateString()}</TableCell>
                <TableCell className={classes.tableCellRoot}>
                  <Box>
                    <strong>Wallet Id</strong>: {row.walletId}
                  </Box>
                  <Box>
                    <strong>Payment Id</strong>: {row.paymentMethodId}
                  </Box>
                  <Box />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        style={{ order: '9999', width: '100%' }}
        count={transactions.count}
        page={transPage}
        onPageChange={changePageTransaction}
        rowsPerPage={5}
        onRowsPerPageChange={() => {}}
        rowsPerPageOptions={[]}
      />
    </CmtCard>
  );
};

export default Transactions;
