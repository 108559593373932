import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Box, Button, TablePagination, FormControl, InputLabel, Input } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CmtAvatar from '@coremat/CmtAvatar';
import useStyles from '../index.style';
import ClearIcon from '@material-ui/icons/Clear';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Moment from 'moment';
const CompetitionSearch = props => {
  const useStyles = makeStyles(theme => ({
    clearIcon: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }));
  const classes = useStyles();
  const [value, setValue] = useState('');
  useEffect(() => {
    setValue('');
    props.setCompetition(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.added]);
  const submitVal = () => {
    if (value && value.length > 0) {
      props.submit(value);
    } else {
      props.submit('');
    }
  };
  const clearSearchField = () => {
    setValue('');
    props.submit('');
  };
  return (
    <FormControl style={{ width: '100%', display: 'block' }}>
      <Box style={{ display: 'inline-block' }}>
        <InputLabel htmlFor="input-with-icon-adornment">Search for Outrights</InputLabel>
        <Input
          style={{ marginRight: '20px' }}
          endAdornment={value?.length >= 1 && <ClearIcon className={classes.clearIcon} onClick={clearSearchField} />}
          id="input-with-icon-adornment"
          value={value || ''}
          onChange={e => {
            setValue(e.target.value);
          }}
          onKeyPress={event => {
            if (event.key === 'Enter') submitVal();
          }}
        />
      </Box>
      <Button variant="contained" color="primary" onClick={submitVal} startIcon={<SearchIcon />}>
        Search
      </Button>
    </FormControl>
  );
};
const rowsPerPage = 10;
const SelectCompetitions = ({ competition, selectCompetition, selectedCompetitions }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [competitionFull, setCompetitionFull] = useState(competition.filter(item => item.status === 1));
  const [competitionPagination, setCompetitionPagination] = useState(competitionFull.slice(0, rowsPerPage));
  const [competitionAdded, setCompetitionAdded] = useState(false);
  const handleChangePage = (event, newPage) => {
    setCompetitionPagination(competitionFull.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage) || {});
    setPage(newPage);
  };

  useEffect(() => {
    // filter selected matches
    let updatedCompetitions = competition.filter(
      match => match.status === 1 && !selectedCompetitions.find(({ _id }) => match._id === _id),
    );
    // correct the page number by number of matches
    if (page && count) {
      if (rowsPerPage * page + 1 === count) setPage(page - 1);
    }
    setCount(updatedCompetitions.length);
    setCompetitionFull(updatedCompetitions);
    let updatedCompetitionsPagination =
      updatedCompetitions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || {};
    if (!updatedCompetitionsPagination.length) {
      setCompetitionPagination(
        updatedCompetitions.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage) || {},
      );
    } else {
      setCompetitionPagination(updatedCompetitionsPagination);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompetitions, competition]);
  const submit = valueSearch => {
    const searchList = [];
    let newCompetitionList = competition.filter(competition => competition.status === 1);
    if (valueSearch.length > 0) {
      newCompetitionList
        .filter(competition => !selectedCompetitions.find(({ _id }) => competition._id === _id))
        .forEach(value => {
          if (valueSearch && value.competition.name.toLowerCase().includes(valueSearch.toLowerCase())) {
            searchList.push(value);
          } else if (
            valueSearch &&
            value.oddType.availableEventParts[0].name.toLowerCase().includes(valueSearch.toLowerCase())
          ) {
            searchList.push(value);
          }
        });
      setCompetitionFull(searchList);
      setCount(searchList.length);
      setCompetitionPagination(searchList.slice(0, rowsPerPage));
    } else {
      setCompetitionFull(newCompetitionList);
      setCount(newCompetitionList.length);
      setCompetitionPagination(newCompetitionList.slice(0, rowsPerPage));
    }
    setPage(0);
  };
  const offset = new Date().getTimezoneOffset() * 1000 * 60;
  const DateTextField = value => {
    const offsetDate = new Date(value).valueOf() - offset;
    const date = new Date(offsetDate).toISOString();
    return date.substring(0, 16);
  };
  return (
    <Box className={classes.selectListMatch}>
      <Box style={{ marginBottom: '20px', display: 'flex' }}>
        <CompetitionSearch classes={classes} setCompetition={setCompetitionAdded} added={competitionAdded} submit={submit} />
      </Box>
      <Box className={classes.selectListMatchContainer}>
        {competitionPagination?.map((competition, id) => {
          return (
            <Box key={id} className={classes.selectListMatchRow}>
              <Box>
                <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
                  <Box
                    mr={4}
                    style={{
                      width: '30px',
                      overflow: 'hidden',
                      marginRight: '0',
                      borderRight: '1px solid #fff',
                    }}>
                    <CmtAvatar size={60} alt={competition.competition.name} />
                  </Box>
                  <Box
                    mr={4}
                    style={{
                      width: '30px',
                      overflow: 'hidden',
                      marginRight: '0',
                    }}>
                    <CmtAvatar size={60} style={{ transform: 'translateX(-30px)' }} alt={competition.competition.name} />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box>
                  <h3 style={{ fontWeight: 'bold' }}>{competition.competition.name}</h3>
                </Box>
                <Box>{Moment(competition.competition.endTime).format('H:mm - D MMM yyyy')}</Box>
                <Box>
                  {competition.oddType.name} - {competition.oddType.availableEventParts[0].name}
                </Box>
              </Box>
              {/*<Box style={{ marginLeft: 'auto' }}>*/}
              {/*  <TextField*/}
              {/*    style={{ padding: ' 0 30px' }}*/}
              {/*    id="datetime-local"*/}
              {/*    onChange={e => {*/}
              {/*      competition.dateTimeField = e.target.value;*/}
              {/*    }}*/}
              {/*    type="datetime-local"*/}
              {/*    disabled={pageId !== 'new'}*/}
              {/*    defaultValue={pageId === 'new' ? DateTextField : competition.dateTimeField}*/}
              {/*    sx={{ width: 250 }}*/}
              {/*    InputLabelProps={{*/}
              {/*      shrink: true,*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</Box>*/}
              <Button
                style={{ marginLeft: 'auto' }}
                variant="contained"
                color="primary"
                onClick={() => {
                  selectCompetition(competition);
                  setCompetitionAdded(true);
                }}>
                Add
              </Button>
            </Box>
          );
        })}
        <TablePagination
          component="div"
          style={{ order: '9999', width: '100%' }}
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[rowsPerPage]}
          rowsPerPage={rowsPerPage}
        />
      </Box>
    </Box>
  );
};
export default SelectCompetitions;
