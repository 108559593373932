import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GET_SELECTION_DETAILS } from 'constants/ActionTypes';
import {
  getSelectionDetails,
  createSelection,
  patchSelection,
  addEvents,
  removeEvents,
  getTemplates,
} from 'redux/actions/Selections';

import { Box, Button } from '@material-ui/core';
import useStyles from './index.style';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';

import { getMatchesForOfficialChampionship } from 'redux/actions/OfficialChampionship';
import { getAllOutrights } from 'redux/actions/Outrights';

import SelectionDetails from './SelectionDetails';
import Matches from './Matches';
import Outrights from './Outrights';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Teams from './Teams';

const Selection = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectionDetails } = useSelector(({ selections }) => selections);
  const { officialChampionshipMatches } = useSelector(({ officialChampionship }) => officialChampionship);
  const { outrightsListAll } = useSelector(({ outrights }) => outrights);

  const [viewDetail, setViewDetail] = useState(selectionDetails);
  const [matchesList, setMatchesList] = useState(undefined);
  const [competitionList, setCompetitionList] = useState(undefined);
  const [matches, setMatches] = useState([]);
  const [matchesInsert, setMatchesInsert] = useState([]);
  const [matchesRemove, setMatchesRemove] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const [competitionsInsert, setCompetitionsInsert] = useState([]);
  const [competitionsRemove, setCompetitionsRemove] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedCompetitions, setSelectedCompetitions] = useState([]);
  const [cover, setCover] = useState();
  const [name, setName] = useState();
  const [priority, setPriority] = useState();
  const [status, setStatus] = useState(0);
  const [design, setDesign] = useState(0);
  const [filterByGeneration, setFilterByGeneration] = useState(0);
  const [filterByType, setFilterByType] = useState(0);
  const [automationDays, setAutomationDays] = useState(0);

  useEffect(() => {
    dispatch(getTemplates(1));
    dispatch(getTemplates(2));
    dispatch(getAllOutrights());
    dispatch(getMatchesForOfficialChampionship());
    if (id !== 'new') {
      dispatch(getSelectionDetails(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (id === 'new') {
      setViewDetail(undefined);
    } else {
      setViewDetail(selectionDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionDetails]);

  useEffect(() => {
    if (viewDetail !== undefined && id !== 'new') {
      setCover(viewDetail.bgImage);
      setName(viewDetail.name);
      setPriority(viewDetail.priority);
      setStatus(viewDetail.status);
      setDesign(viewDetail.designType);
      setMatches(viewDetail.games);
      setFilterByGeneration(viewDetail.generatedBy);
      setFilterByType(viewDetail.type);
      setSelectedTeams(viewDetail?.teams);
      setSelectedCompetitions(viewDetail?.teamCompetitions);
      setAutomationDays(viewDetail?.autoDays);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewDetail]);

  useEffect(() => {
    setMatchesList(officialChampionshipMatches);
  }, [officialChampionshipMatches]);

  useEffect(() => {
    setCompetitionList(outrightsListAll);
  }, [outrightsListAll]);

  const createNotification = (type, message) => {
    return NotificationManager.error(message, 'ERROR!', 2000);
  };

  const getCover = cover => {
    setCover(cover);
  };

  const getName = newName => {
    setName(newName);
  };

  const getPriority = newPriority => {
    setPriority(newPriority);
  };

  const getStatus = newStatus => {
    setStatus(newStatus);
  };

  const getDesign = newDesign => {
    setDesign(newDesign);
  };

  const getFilterByGeneration = newFilterByGeneration => {
    setFilterByGeneration(newFilterByGeneration);
  };

  const getFilterByType = newFilterByType => {
    setFilterByType(newFilterByType);
  };

  const create = async type => {
    let nameEmpty = false;
    let priorityEmpty = false;
    let coverEmpty = false;
    let filterByGenerationEmpty = false;
    let filterByTypeEmpty = false;

    if (name === undefined || name.length === 0) {
      createNotification('error', 'Selection Name should not be empty');
      nameEmpty = true;
    }

    if (priority === undefined || priority < 1) {
      createNotification('error', 'Priority should not be empty and the value should be greater than 0');
      priorityEmpty = true;
    }

    if (cover === undefined) {
      createNotification('error', 'Cover Image should not be empty');
      coverEmpty = true;
    }

    if (filterByGeneration === undefined) {
      createNotification('error', 'Filter by generation should not be empty');
      filterByGenerationEmpty = true;
    }

    if (filterByType === undefined) {
      createNotification('error', 'Filter by type should not be empty');
      filterByTypeEmpty = true;
    }

    if (
      nameEmpty !== true &&
      priorityEmpty !== true &&
      coverEmpty !== true &&
      filterByGenerationEmpty !== true &&
      filterByTypeEmpty !== true
    ) {
      let formData = new FormData();
      if (type === 'create') {
        formData.append('name', name);
        formData.append('priority', priority);
        formData.append('autoDays', automationDays);
        formData.append('status', status);
        formData.append('designType', design);
        formData.append('file', cover);
        formData.append('games', JSON.stringify(matches));
        formData.append('competitions', JSON.stringify(competitions));
        formData.append('generatedBy', filterByGeneration);
        formData.append('type', filterByType);
        formData.append('teamsIds', JSON.stringify(selectedTeams.map(item => item._id)));
        formData.append('teamCompetitionsIds', JSON.stringify(selectedCompetitions.map(item => item._id)));
        dispatch(createSelection(formData));
      } else {
        formData.append('name', name);
        formData.append('priority', priority);
        formData.append('autoDays', automationDays);
        formData.append('status', status);
        formData.append('designType', design);
        formData.append('file', cover);
        formData.append('generatedBy', filterByGeneration);
        formData.append('type', filterByType);
        formData.append('teamsIds', JSON.stringify(selectedTeams.map(item => item._id)));
        formData.append('teamCompetitionsIds', JSON.stringify(selectedCompetitions.map(item => item._id)));
        if (matchesInsert.length) {
          let formDataInsert = new FormData();
          formDataInsert.append('games', JSON.stringify(matchesInsert));
          await dispatch(addEvents(id, formDataInsert));
        }
        if (matchesRemove.length) {
          let formDataRemove = new FormData();
          formDataRemove.append('games', JSON.stringify(matchesRemove));
          await dispatch(removeEvents(id, formDataRemove));
        }
        if (competitionsInsert.length) {
          let formDataInsert = new FormData();
          formDataInsert.append('competitions', JSON.stringify(competitionsInsert));
          await dispatch(addEvents(id, formDataInsert));
        }
        if (competitionsRemove.length) {
          let formDataRemove = new FormData();
          formDataRemove.append('competitions', JSON.stringify(competitionsRemove));
          await dispatch(removeEvents(id, formDataRemove));
        }
        dispatch(patchSelection(id, formData));
      }
    }
  };

  const getMatches = (currentList, toBeInserted, toBeRemoved) => {
    setMatches(currentList);
    setMatchesInsert(toBeInserted);
    setMatchesRemove(toBeRemoved);
  };

  const getCompetition = (currentList, toBeInserted, toBeRemoved) => {
    setCompetitions(currentList);
    setCompetitionsInsert(toBeInserted);
    setCompetitionsRemove(toBeRemoved);
  };

  return (
    <CmtCard>
      <CmtCardHeader
        className="pt-4"
        icon={
          <ArrowBackIcon
            style={{ cursor: 'pointer' }}
            onClick={() => {
              history.push('/selections');
              dispatch({
                type: GET_SELECTION_DETAILS,
                payload: undefined,
              });
            }}
          />
        }
        title={viewDetail !== undefined ? 'Edit Selection' : 'Create Selection'}
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}
      />
      <CmtCardContent className={classes.cardContentRoot}>
        <SelectionDetails
          viewDetail={viewDetail}
          sendName={getName}
          sendPriority={getPriority}
          sendStatus={getStatus}
          sendDesign={getDesign}
          sendCover={getCover}
          sendFilterByGeneration={getFilterByGeneration}
          sendFilterByType={getFilterByType}
        />

        <Box>
          {matchesList !== undefined && <Matches viewDetail={viewDetail} matches={matchesList} getMatches={getMatches} />}
        </Box>
        <Box>
          {competitionList !== undefined && (
            <Outrights viewDetail={viewDetail} competitions={competitionList} getCompetition={getCompetition} />
          )}
        </Box>
        <Box>
          <Teams
            setSelectedTeams={setSelectedTeams}
            selectedTeams={selectedTeams}
            setSelectedCompetitions={setSelectedCompetitions}
            selectedCompetitions={selectedCompetitions}
            automationDays={automationDays}
            setAutomationDays={setAutomationDays}
          />
        </Box>
        {id !== 'new' ? (
          <Button style={{ marginBottom: '25px' }} variant="contained" color="primary" onClick={() => create('update')}>
            Update Selection
          </Button>
        ) : (
          <Button style={{ marginBottom: '25px' }} variant="contained" color="primary" onClick={() => create('create')}>
            Create Selection
          </Button>
        )}
        <NotificationContainer />
      </CmtCardContent>
    </CmtCard>
  );
};

export default Selection;
