import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddQuestion from './AddQuestion';
import QuestionCell from './QuestionCell';
import { NotificationManager } from 'react-notifications';
import Alert from 'utils/InfoAlertMessage';

const Questions = ({ langList, getQuestions, viewDetail, showTCScreen }) => {
  const [editDetail, setEditDetail] = useState();
  const [toggleAdd, setToggleAdd] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAddNew, setIsAddNew] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [editDataQuestion, setEditDataQuestion] = useState(undefined);

  const toggleAddContent = () => {
    setToggleAdd(!toggleAdd);
    setIsAddNew(!toggleAdd);
    setEditDataQuestion(undefined);
  };

  const saveQuestion = newQuestion => {
    setQuestions(questions => [...questions, newQuestion]);
    setToggleAdd(false);
    setIsEditing(false);
    setIsAddNew(false);
    let a = [...questions, newQuestion];
    sendQuestions(a);
  };

  useEffect(() => {
    setEditDetail(viewDetail);
    if (viewDetail !== undefined) {
      let newQuestionsList = [];
      viewDetail.questions.forEach((item, key) => {
        newQuestionsList.push({ ...item, deleted: false });
      });
      setQuestions(newQuestionsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewDetail]);

  const sendQuestions = a => {
    getQuestions(a);
  };

  const editQuestion = (key, question) => {
    let newAddedQuestions = [];
    langList.forEach((lang, item) => {
      newAddedQuestions.push({
        text: '',
        language: lang,
      });
    });

    const newArrQ = newAddedQuestions.reduce(
      (acc, eachArr2Elem) => {
        if (
          question.questionText.findIndex(
            eachArr1Elem => eachArr1Elem.language === eachArr2Elem.language && eachArr1Elem.text.length >= 1,
          ) === -1
        ) {
          acc.push(eachArr2Elem);
        }
        return acc;
      },
      [...question.questionText],
    );

    let newAddedAnswers = [];
    langList.forEach((lang, item) => {
      newAddedAnswers.push({
        answers: ['', ''],
        language: lang,
      });
    });

    const newArrA = newAddedAnswers.reduce(
      (acc, eachArr2Elem) => {
        if (
          question.possibleAnswers.findIndex(
            eachArr1Elem => eachArr1Elem.language === eachArr2Elem.language && eachArr1Elem.answers[0].length >= 1,
          ) === -1
        ) {
          acc.push(eachArr2Elem);
        }
        return acc;
      },
      [...question.possibleAnswers],
    );

    question.questionText = newArrQ;
    question.possibleAnswers = newArrA;
    setEditDataQuestion(question);
    setToggleAdd(true);
    setIsEditing(true);
    setIsAddNew(true);
    setQuestions(questions.filter((item, index) => index !== key));
  };

  const deleteQuestion = (key, newQuestion) => {
    let removeQuestionList = [];
    if (newQuestion._id === null) {
      setQuestions(questions.filter((item, keyList) => keyList !== key));
      sendQuestions(questions.filter((item, keyList) => keyList !== key));
    } else {
      removeQuestionList = questions.filter((item, key) => item._id !== newQuestion._id);
      removeQuestionList.push({ ...newQuestion, deleted: true });
      setQuestions(removeQuestionList);
      sendQuestions(removeQuestionList);
    }
  };

  useEffect(() => {
    if (!showTCScreen && questions?.length > 0) {
      NotificationManager.info(
        'Questions will not be displayed if "Display T&C Screen" toggle is set as false!',
        'INFO!',
        5000,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTCScreen]);

  return (
    <Box>
      <Box>
        <Box style={{ display: 'flex', margin: ' 30px 7.5px 15px 7.5px' }}>
          <h2 style={{ marginRight: '25px' }}>Questions</h2>
          <Button disabled={isEditing} variant="contained" color="primary" onClick={toggleAddContent}>
            Add
          </Button>
        </Box>
        {!showTCScreen && questions?.length > 0 && (
          <Alert
            style={{
              paddingTop: '10px',
              border: 'none',
            }}>
            Questions will not be displayed because the "Display T&C Screen" toggle is set as false!
          </Alert>
        )}
        {toggleAdd !== false && (
          <AddQuestion
            langList={langList}
            editDetail={editDetail}
            editNow={editDataQuestion}
            onSaveQuestion={saveQuestion}
          />
        )}
        {questions.length > 0 ? (
          questions.map((question, key) => {
            if (question.deleted === false) {
              return (
                <QuestionCell
                  disableEdit={isEditing || isAddNew}
                  key={key}
                  questionDetail={question}
                  deleteQuestion={() => deleteQuestion(key, question)}
                  editQuestion={() => editQuestion(key, question)}
                />
              );
            } else return <></>;
          })
        ) : (
          <h3 style={{ marginLeft: '7.5px', marginBottom: '30px' }}>There are no questions. Please add one.</h3>
        )}
      </Box>
    </Box>
  );
};

export default Questions;
