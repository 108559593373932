import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getAppSidebarHeight } from 'constants/AppConstants';
import AppContext from 'components/contextProvider/AppContextProvider/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { getLabelsList, setFilterType } from 'redux/actions/MatchApp';

import { withWidth, Box, List, ListItem } from '@material-ui/core';
import { AccessTime, Timelapse, AlarmOn, DeleteOutline } from '@material-ui/icons';

import CmtList from '@coremat/CmtList';

import ItemCell from './ItemCell';
import LabelCell from './LabelCell';

import useStyles from '../index.style';
import { getAvailableSports } from '../../../../redux/actions';
import GlobalVariables from '../../../../assets/global/globalVariables';
import { selectSport } from '../../../../redux/actions/Odds';

export const foldersList = [
  { id: 1, name: 'Future', slug: 'future', icon: <AccessTime /> },
  { id: 2, name: 'Popular', slug: 'live', icon: <Timelapse /> },
  { id: 3, name: 'Ended', slug: 'past', icon: <AlarmOn /> },
  {
    id: 4,
    name: (
      <>
        Canceled and <br /> Postponed Matches
      </>
    ),
    slug: 'canceled-postponed',
    icon: <AlarmOn />,
  },
  { id: 5, name: 'Deleted', slug: 'deleted', icon: <DeleteOutline /> },
  { id: 6, name: 'Past Not Ended', slug: 'past-not-ended', icon: <AlarmOn /> },
];

const Sidebar = ({ width, setTypeFilter }) => {
  const { labelsList, filterType, selectedSport } = useSelector(({ matchApp }) => matchApp);
  const { showFooter } = useContext(AppContext);
  const { availableSports } = useSelector(({ common }) => common);
  const dispatch = useDispatch();
  const [sports, setSports] = useState([]);
  const [sport, setSport] = useState('');

  useEffect(() => {
    dispatch(
      setFilterType({
        status: foldersList[0].slug,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getLabelsList());
    dispatch(getAvailableSports());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (availableSports) {
      const data = availableSports.map(sport => {
        return {
          ...sport,
          slug: sport.nameLowercase,
          icon: GlobalVariables.sports.find(el => el.name === sport.name)?.icon,
        };
      });
      setSports(data);
      if (selectedSport) {
        if (Object.keys(selectedSport).length === 0) dispatch(selectSport(data[0]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableSports]);

  const onChangeSport = sportName => {
    const sport = sports.find(el => el.nameLowercase === sportName);
    dispatch(selectSport(sport));
    setSport(sport._id);
  };

  const onChangeFolder = folder => {
    setTypeFilter(folder);
    dispatch(
      setFilterType({
        status: folder,
      }),
    );
  };

  const onChangeLabel = label => {
    const selectedLabelSport = labelsList.find(lab => lab._id === label).sport;
    dispatch(selectSport(selectedLabelSport));
    // If competition is already selected, deselect it
    if (filterType.competition === label) {
      dispatch(
        setFilterType({
          status: filterType.status,
          competition: '',
          query: filterType.query,
        }),
      );
    } else {
      dispatch(
        setFilterType({
          status: filterType.status,
          competition: label,
          query: filterType.query,
        }),
      );
    }
  };

  const classes = useStyles({
    isCollapsed: false,
    height: getAppSidebarHeight(width, showFooter),
  });

  return (
    <Box className={classes.inBuildAppSidebar}>
      <Box className={classes.inBuildAppSidebarHeader}></Box>

      <PerfectScrollbar className={classes.perfectScrollbarMatchSidebar}>
        <ListItem component="div" className={classes.appNavHeaderItem}>
          <Box component="span" className={classes.appNavHeaderItemText}>
            Filter by sport
          </Box>
        </ListItem>
        <List component="nav" className={classes.appNav}>
          <CmtList
            data={sports}
            renderRow={(item, index) => (
              <ItemCell
                key={index}
                item={item}
                classes={classes}
                selectedItem={selectedSport.nameLowercase}
                onChange={onChangeSport}
              />
            )}
          />
        </List>

        <ListItem component="div" className={classes.appNavHeaderItem}>
          <Box component="span" className={classes.appNavHeaderItemText}>
            Filter by type
          </Box>
        </ListItem>

        <List component="nav" className={classes.appNav}>
          <CmtList
            data={foldersList}
            renderRow={(item, index) => (
              <ItemCell
                key={index}
                item={item}
                classes={classes}
                selectedItem={filterType.status}
                onChange={onChangeFolder}
              />
            )}
          />
        </List>
        <ListItem component="div" className={classes.appNavHeaderItem}>
          <Box component="span" className={classes.appNavHeaderItemText}>
            Filter by competition
          </Box>
        </ListItem>
        <List component="nav" className={classes.appNav}>
          <CmtList
            data={labelsList.filter(item => item.sport._id === sport)}
            renderRow={(item, index) => (
              <LabelCell
                key={index}
                item={item}
                classes={classes}
                selectedItem={filterType.competition}
                onChange={onChangeLabel}
              />
            )}
          />
        </List>
      </PerfectScrollbar>
    </Box>
  );
};

export default withWidth()(Sidebar);

Sidebar.prototype = {
  onClickCreateMatch: PropTypes.func,
};
