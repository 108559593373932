import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMatch, updateMatchesLabel } from 'redux/actions/MatchApp';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CmtList from '@coremat/CmtList';
import { Box, Tooltip, IconButton, Menu, MenuItem, makeStyles } from '@material-ui/core';
import ExportMatches from '../../ExportMatches';
import EditIcon from '@material-ui/icons/Edit';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import LabelIcon from '@material-ui/icons/Label';

const useStyles = makeStyles(theme => ({
  matchCellOptionsRoot: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    overflow: 'hidden',
  },
  starViewRoot: {
    transition: 'all 0.3s ease',
    transform: 'translateX(0)',
  },
  actionOptionRoot: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    transition: 'all 0.3s ease',
    opacity: 0,
    visibility: 'hidden',
    transform: 'translateX(0)',
  },
  menuItemsRoot: {
    width: 200,
    fontSize: 14,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },
  },
  iconBlock: {
    display: 'block',
  },
  titleLabelsRoot: {
    fontSize: 10,
    letterSpacing: 1.5,
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    padding: '16px 16px 8px',
  },
}));

const MatchCellOptions = ({ filterType, match, popularType, onClickEditMatch, onClickTopMatch }) => {
  const dispatch = useDispatch();
  const { labelsList } = useSelector(({ matchApp }) => matchApp);
  const [showMoreOptions, setShowMoreOptions] = useState(null);

  const classes = useStyles();

  // const onShowMoreOptions = event => {
  //   setShowMoreOptions(event.currentTarget);
  // };

  const onHideMoreOptions = () => {
    setShowMoreOptions(null);
  };

  // const onClickStarredIcon = status => {
  //   dispatch(updateStarredStatus([match.id], status));
  // };

  const onClickDeleteOption = () => {
    dispatch(deleteMatch([match.id]));
    onHideMoreOptions();
  };

  const onClickLabelOption = label => {
    dispatch(updateMatchesLabel([match.id], label));
    onHideMoreOptions();
  };
  const { labels } = match;

  return (
    <Box className={classes.matchCellOptionsRoot} onClick={e => e.stopPropagation()}>
      {/*{*/}
      {/*  filterType !== 'past' ?*/}
      {/*    <Box className={clsx(classes.starViewRoot, 'star-view')}>*/}
      {/*    <Tooltip title="Set As Popular Match">*/}
      {/*      <IconButton size="small" onClick={(e) => onClickTopMatch(e)}>*/}
      {/*        {popularType ? <StarIcon />*/}
      {/*          :*/}
      {/*          <StarBorderIcon />*/}
      {/*        }*/}

      {/*      </IconButton>*/}
      {/*    </Tooltip>*/}
      {/*  </Box> : null*/}
      {/*}*/}

      <Box className={clsx(classes.actionOptionRoot, 'action-option')}>
        <Box ml={1}>
          <Tooltip title="Edit Match">
            <IconButton size="small" onClick={() => onClickEditMatch()}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {/*<Box ml={1}>*/}
        {/*  <Tooltip title="More Options">*/}
        {/*    <IconButton size="small" onClick={onShowMoreOptions}>*/}
        {/*      <MoreVertIcon />*/}
        {/*    </IconButton>*/}
        {/*  </Tooltip>*/}
        {/*</Box>*/}

        <Menu anchorEl={showMoreOptions} open={Boolean(showMoreOptions)} onClose={onHideMoreOptions}>
          <MenuItem onClick={onHideMoreOptions} className={classes.menuItemsRoot}>
            <ExportMatches data={[{ ...match }]}>
              <Box component="span" display="flex" alignItems="center">
                <CloudUploadIcon />
                <Box component="span" ml={4}>
                  Export
                </Box>
              </Box>
            </ExportMatches>
          </MenuItem>
          <MenuItem onClick={onClickDeleteOption} className={classes.menuItemsRoot}>
            <DeleteIcon />
            <Box component="span" ml={4}>
              Delete
            </Box>
          </MenuItem>
          <Box className={classes.titleLabelsRoot}>Labels</Box>
          <CmtList
            data={labelsList}
            renderRow={(item, index) => (
              <MenuItem key={index} onClick={() => onClickLabelOption(item.slug)} className={classes.menuItemsRoot}>
                <Box display="flex" alignItems="center" width={1}>
                  <Box>
                    <LabelIcon className={classes.iconBlock} style={{ color: item.color }} />
                  </Box>
                  <Box ml={4} component="span">
                    {item.name}
                  </Box>
                  {labels.includes(item.slug) && (
                    <Box ml="auto">
                      <DoneIcon className={classes.iconBlock} />
                    </Box>
                  )}
                </Box>
              </MenuItem>
            )}
          />
        </Menu>
      </Box>
    </Box>
  );
};

export default MatchCellOptions;

MatchCellOptions.prototype = {
  match: PropTypes.object.isRequired,
  onClickEditMatch: PropTypes.func,
};
