import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { alpha } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { useHistory } from 'react-router-dom';
import { appMenuList } from 'constants/AppConstants';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { deletePopUp, getAllPopUps, getPopUp } from 'redux/actions/PopUps';
import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
const useStyles = makeStyles(theme => ({
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&:last-child': {
          color: theme.palette.error.main,
        },
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    padding: 2,
    paddingLeft: 14,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.secondary,
    borderBottom: '0 none',
    position: 'relative',
  },
  url: {
    width: '45%',
    wordBreak: 'break-word',
    [theme.breakpoints.down('sm')]: {
      minWidth: '300px',
    },
  },
}));

const TableItem = ({ row, index }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleModalIsOpen = () => {
    setModalIsOpen(prev => !prev);
  };
  return (
    <TableRow className={classes.tableRowRoot}>
      <TableCell className={classes.tableCellRoot}>{index + 1}</TableCell>
      <TableCell className={[classes.tableCellRoot, classes.url].join(' ')}>{row.webPageLink}</TableCell>
      <TableCell className={classes.tableCellRoot}>{appMenuList[row.menu].menu}</TableCell>
      <TableCell className={classes.tableCellRoot}>
        {moment(row.startDate).format('DD.MM.YYYY')} - {moment(row.endDate).format('DD.MM.YYYY')}
      </TableCell>
      <TableCell className={classes.tableCellRoot}>{row.redirectSection}</TableCell>

      <TableCell className={classes.tableCellRoot}>
        <IconButton
          color="primary"
          aria-label="edit selection"
          component="span"
          onClick={() => {
            dispatch(getPopUp(row._id));
            history.push('/pop-ups/' + row._id);
          }}>
          <EditIcon />
        </IconButton>
        <IconButton
          color="primary"
          aria-label="edit selection"
          component="span"
          onClick={() => {
            handleModalIsOpen();
            dispatch(getAllPopUps());
          }}>
          <DeleteOutline />
        </IconButton>
      </TableCell>
      <Dialog
        open={modalIsOpen}
        onClose={handleModalIsOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{`Delete Pop-up #${index + 1}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Do you want to delete this pop-up ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalIsOpen} color="primary">
            Cancel
          </Button>
          <Button onClick={() => dispatch(deletePopUp(row._id))} color="primary" variant="contained" autoFocus>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  );
};

export default TableItem;
