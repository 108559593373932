import {
  GET_USERS,
  GET_USERS_ADMIN,
  GET_USER_DETAIL,
  GET_USER_DOCUMENTS,
  GET_USER_WALLET,
  GET_USER_TRANSACTIONS,
  CREATE_USER,
  PATCH_USER,
  BLOCK_USER,
  UNBLOCK_USER,
  CHANGE_PASSWORD,
  GET_AML_USERS,
  GET_USERS_PERMISSIONS,
  POST_USERS_PERMISSIONS,
  PATCH_DOCUMENT_STATUS,
} from '../../constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';

export const getUsers = (page, rowsPerPage, params) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/users/admin?offset=' + page + '&limit=' + rowsPerPage + '&' + params)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_USERS, payload: data });
      })
      .catch(error => {
        if (error.response && error.response.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getUsersAdmin = (page, rowsPerPage, params) => {
  let url = `/users/admins?`;
  if (parseInt(page) >= 0 && rowsPerPage) url += `offset=${page}&limit=${rowsPerPage}&`;
  if (params) url += params;
  return dispatch => {
    // dispatch(fetchStart());
    axios
      .get(url)
      .then(data => {
        // dispatch(fetchSuccess());
        dispatch({ type: GET_USERS_ADMIN, payload: data });
      })
      .catch(error => {
        if (error.response && error.response.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getUserDetail = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/users/' + id)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_USER_DETAIL, payload: data });
      })
      .catch(error => {
        if (error.response && error.response.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getUserDocuments = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/documents/user/' + id)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_USER_DOCUMENTS, payload: data });
      })
      .catch(error => {
        if (error.response && error.response.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getAMLUsers = (page, rowsPerPage, params) => {
  return (dispatch, getState) => {
    dispatch(fetchStart());

    if (page === undefined) {
      page = getState().users.page;
      rowsPerPage = getState().users.rowsPerPage;
      params = getState().users.params;
    }
    axios
      .get('/users/admin?offset=' + page + '&limit=' + rowsPerPage + '&' + params + '&riskLevelExceeded=true')
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_AML_USERS,
          payload: { data: data.data, page, rowsPerPage, params },
        });
      })
      .catch(error => {
        if (error.response && error.response.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getUserWalletTransfer = (id, page) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/wallet-transfers/user/' + id + '?offset=' + page + '&limit=5')
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_USER_WALLET, payload: data });
      })
      .catch(error => {
        if (error.response && error.response.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getUserTransactions = (id, page) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/transactions/user/' + id + '?offset=' + page + '&limit=5')
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_USER_TRANSACTIONS, payload: data });
      })
      .catch(error => {
        if (error.response && error.response.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const patchUser = (id, body) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .patch('/users/' + id, body)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: PATCH_USER, payload: data });
      })
      .catch(error => {
        if (error.response && error.response.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const createUser = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/users/admin/', body)
      .then(data => {
        return dispatch({ type: CREATE_USER, payload: data });
      })
      .then(() => {
        dispatch(fetchSuccess());
        dispatch(getUsersAdmin(0, 50, ''));
      })
      .catch(error => {
        if (error.response && error.response.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const blockUser = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/users/block-user', { userId: id })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: BLOCK_USER, payload: data });
      })
      .catch(error => {
        if (error.response && error.response.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const unblockUser = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/users/unblock-user/', { userId: id })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: UNBLOCK_USER, payload: data });
      })
      .catch(error => {
        if (error.response && error.response.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const changePasswordAdmin = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/users/change-password', body)
      .then(data => {
        dispatch(fetchError('Password Changed'));
        dispatch({ type: CHANGE_PASSWORD, payload: data });
        localStorage.removeItem('token');
        localStorage.removeItem('login');
        window.location.href = '/login';
      })
      .catch(error => {
        if (
          error.response &&
          error.response.status === 400 &&
          (error.response.data.message === 'auth.WRONG_CREDENTIALS' ||
            error.response.data.message === 'You provide wrong credentials!' ||
            error.response.data.message === 'Ai introdus gresit datele de autentificare!')
        ) {
          dispatch(fetchError('Invalid old password'));
        } else {
          if (error.response && error.response.status !== 401) {
            dispatch(fetchError('Something went wrong'));
          }
        }
      });
  };
};

export const blockUserAML = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/users/admin/change-aml-status', body)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch(getAMLUsers());
      })
      .catch(error => {
        if (error.response && error.response.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getUsersPermissions = () => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get('/users-permissions')
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_USERS_PERMISSIONS, payload: data });
      })
      .catch(error => {
        if (error && error.response && error.response.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const addUsersPermissions = (id, body) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/users-permissions', { userId: id, routes: body })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: POST_USERS_PERMISSIONS, payload: data });
      })
      .catch(error => {
        if (error.response && error.response.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const changeDocumentStatus = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .patch(`/documents/${body.id}`, { status: body.status })
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: PATCH_DOCUMENT_STATUS, payload: data });
      })
      .catch(error => {
        if (error.response && error.response.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};
