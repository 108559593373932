import React from 'react';
import useStyles from './index.style';
import { useSelector } from 'react-redux';

import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import PopUpsListing from './PopUpsListing';

const PopupsPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { popUps } = useSelector(({ popUps }) => popUps);

  return (
    <CmtCard style={{ minHeight: '80vh' }}>
      <CmtCardHeader
        className="pt-4"
        title="Pop-ups"
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push('/pop-ups/new');
          }}>
          Create
        </Button>
      </CmtCardHeader>
      <CmtCardContent className={classes.cardContentRoot} style={{ overflow: 'auto' }}>
        <PopUpsListing popUps={popUps} />
      </CmtCardContent>
    </CmtCard>
  );
};

export default PopupsPage;
