import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { ListItemIcon, ListItemText, ListItem, Tooltip } from '@material-ui/core';
import LabelIcon from '@material-ui/icons/Label';

const ItemCell = ({ classes, item, selectedItem, onChange }) => {
  return (
    <Tooltip title={item.tooltip || ''} arrow>
      <ListItem
        button
        className={clsx(classes.appNavItem, {
          active: item._id === selectedItem,
        })}
        onClick={() => onChange(item.slug)}>
        <ListItemIcon className="Cmt-icon-root">
          <LabelIcon style={{ color: item.color }} />
        </ListItemIcon>
        <ListItemText className="Cmt-nav-text" primary={item?.name || item?.username || item?.email} />
      </ListItem>
    </Tooltip>
  );
};

export default ItemCell;

ItemCell.prototype = {
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  selectedItem: PropTypes.string,
};

ItemCell.defaultProps = {
  selectedItem: '',
};
