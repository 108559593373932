import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
  Typography,
  Box,
  InputAdornment,
  IconButton,
  alpha,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { createB2BAccount, updateB2BAccount } from '../../../redux/actions/B2BAccount';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import 'react-notifications/lib/notifications.css';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    paddingTop: theme.spacing(2),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  errorText: {
    fontSize: '12px',
    color: 'red',
  },
  dialogTitle: {
    fontSize: '20px',
  },
  passwordInput: {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
}));

const B2BUserType = {
  SPORTWORLD: 6,
  TSC: 7,
  PBC: 9,
};

const B2BAccountModal = ({ open, onClose, initialData = {} }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { allUsers: b2bUsers } = useSelector(({ b2bAccount }) => b2bAccount);
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [b2bExternalType, setB2bExternalType] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);

  useEffect(() => {
    if (open) {
      if (initialData && initialData._id) {
        setFullname(initialData.fullname || '');
        setEmail(initialData.email || '');
        setPhoneNumber(initialData.phoneNumber || '');
        setB2bExternalType(initialData.b2bExternalType || '');
        setPassword('');
        setConfirmPassword('');
      } else {
        resetFields();
      }
      resetErrors();
    }
  }, [open, initialData]);

  const resetFields = () => {
    setFullname('');
    setEmail('');
    setPhoneNumber('');
    setPassword('');
    setConfirmPassword('');
    setB2bExternalType('');
  };

  const resetErrors = () => {
    setError({});
  };

  const validateFullname = value => {
    const namePattern = /^[a-zA-Z\s-]+$/;

    if (!value) {
      return 'Full name is required.';
    }

    if (typeof value !== 'string' || value.length < 2) {
      return 'Full name must be at least 2 characters long.';
    }

    if (!namePattern.test(value)) {
      return 'Full name should only contain letters, spaces, and hyphens.';
    }

    if (Array.isArray(b2bUsers)) {
      const lowerCaseName = value.toLowerCase();
      const existingUser = b2bUsers.find(user => user.fullname && user.fullname.toLowerCase() === lowerCaseName);

      if (existingUser) {
        return 'A user with this full name already exists.';
      }
    }

    return '';
  };

  const validateEmail = value => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value) {
      return 'Email is required.';
    } else if (!emailPattern.test(value)) {
      return 'Please enter a valid email.';
    } else if (initialData && initialData._id) {
      return '';
    } else if (b2bUsers && b2bUsers.some(user => user.email.toLowerCase() === value.toLowerCase())) {
      return 'A user with this email already exists.';
    }
    return '';
  };

  const validateUserType = value => {
    if (!value) {
      return 'B2B User Type is required.';
    }
    return '';
  };

  const validatePhoneNumber = value => {
    const phonePattern = /^\+?[1-9]\d{9,14}$/;
    if (!value) {
      return 'Phone number is required.';
    } else if (!phonePattern.test(value)) {
      return 'Please enter a valid phone number. It should include a country code and at least 10 digits.';
    } else if ((!initialData || !initialData._id) && b2bUsers && b2bUsers.some(user => user.phoneNumber === value)) {
      return 'A user with this phone number already exists.';
    }
    return '';
  };

  const validatePassword = value => {
    const basicPasswordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    const symbolPattern = /[^A-Za-z\d]/;
    if (!value) {
      return 'Password is required.';
    } else if (!basicPasswordPattern.test(value)) {
      return 'Password must be at least 8 characters long and include both uppercase and lowercase letters, and at least one number.';
    } else if (symbolPattern.test(value)) {
      return 'Password must not contain any symbols.';
    }
    return '';
  };

  const validateConfirmPassword = value => {
    if (!value) {
      return 'Confirm password is required.';
    } else if (value !== password) {
      return 'Passwords do not match.';
    }
    return '';
  };

  const handleFieldChange = (field, value) => {
    switch (field) {
      case 'fullname':
        setFullname(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'phoneNumber':
        setPhoneNumber(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        break;
      case 'b2bExternalType':
        setB2bExternalType(value);
        break;
      default:
        break;
    }

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const newTimeout = setTimeout(() => {
      let errorText = '';
      switch (field) {
        case 'fullname':
          if (!initialData || !initialData._id) errorText = validateFullname(value);
          break;
        case 'email':
          if (!initialData || !initialData._id) errorText = validateEmail(value);
          break;
        case 'phoneNumber':
          if (!initialData || !initialData._id) errorText = validatePhoneNumber(value);
          break;
        case 'password':
          errorText = validatePassword(value);
          break;
        case 'confirmPassword':
          errorText = validateConfirmPassword(value);
          break;
        case 'b2bExternalType':
          if (!initialData || !initialData._id) errorText = validateUserType(value);
          break;
        default:
          break;
      }
      setError(prev => ({ ...prev, [field]: errorText }));
    }, 1000);

    setTypingTimeout(newTimeout);
  };

  const handleClickShowPassword = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(prevShowConfirmPassword => !prevShowConfirmPassword);
  };

  const handleSubmit = () => {
    let formErrors = {};

    if (!initialData || !initialData._id) {
      const fullnameError = validateFullname(fullname);
      const emailError = validateEmail(email);
      const phoneNumberError = validatePhoneNumber(phoneNumber);
      const userTypeError = validateUserType(b2bExternalType);

      formErrors = {
        fullname: fullnameError,
        email: emailError,
        phoneNumber: phoneNumberError,
        b2bExternalType: userTypeError,
      };
    }

    const passwordError = validatePassword(password);
    const confirmPasswordError = validateConfirmPassword(confirmPassword);

    formErrors = {
      ...formErrors,
      password: passwordError,
      confirmPassword: confirmPasswordError,
    };

    setError(formErrors);

    const isFormValid = Object.values(formErrors).every(error => !error);

    if (!isFormValid) {
      NotificationManager.error('Please fix the errors before submitting.');
      return;
    }

    const body = { fullname, email, password, phoneNumber, b2bExternalType };

    if (initialData && initialData._id) {
      // Update existing account
      dispatch(updateB2BAccount(initialData._id, { password }));
      NotificationManager.success('B2B Account updated successfully.');
    } else {
      // Create new account
      dispatch(createB2BAccount(body));
      NotificationManager.success('B2B Account created successfully.');
    }

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography className={classes.dialogTitle}>
          {initialData && initialData._id ? 'Edit B2B Account' : 'Create B2B Account'}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required={!initialData || !initialData._id}
            fullWidth
            id="fullname"
            label="Full Name"
            name="fullname"
            autoComplete="off"
            autoFocus
            value={fullname}
            onChange={e => handleFieldChange('fullname', e.target.value)}
            helperText={error.fullname}
            error={!!error.fullname}
            disabled={!!initialData && initialData._id}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="off"
            value={email}
            onChange={e => handleFieldChange('email', e.target.value)}
            helperText={error.email}
            error={!!error.email}
            disabled={!!initialData && initialData._id}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phoneNumber"
            label="Phone Number"
            name="phoneNumber"
            autoComplete="off"
            value={phoneNumber}
            onChange={e => handleFieldChange('phoneNumber', e.target.value)}
            helperText={error.phoneNumber}
            error={!!error.phoneNumber}
            disabled={!!initialData && initialData._id}
          />
          <FormControl variant="outlined" fullWidth margin="normal" error={!!error.b2bExternalType}>
            <InputLabel id="b2bExternalType-label">B2B User Type</InputLabel>
            <Select
              labelId="b2bExternalType-label"
              id="b2bExternalType"
              value={b2bExternalType}
              onChange={e => handleFieldChange('b2bExternalType', e.target.value)}
              label="B2B User Type">
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {Object.keys(B2BUserType).map(key => (
                <MenuItem key={key} value={B2BUserType[key]}>
                  {key}
                </MenuItem>
              ))}
            </Select>
            {error.b2bExternalType && <Typography className={classes.errorText}>{error.b2bExternalType}</Typography>}
          </FormControl>

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="new-password"
            value={password}
            onChange={e => handleFieldChange('password', e.target.value)}
            helperText={error.password}
            error={!!error.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              className: initialData && initialData._id ? classes.passwordInput : '',
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type={showConfirmPassword ? 'text' : 'password'}
            id="confirmPassword"
            autoComplete="new-password"
            value={confirmPassword}
            onChange={e => handleFieldChange('confirmPassword', e.target.value)}
            helperText={error.confirmPassword}
            error={!!error.confirmPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={handleClickShowConfirmPassword}
                    edge="end">
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!Object.values(error).every(err => !err)}>
          Submit
        </Button>
      </DialogActions>
      <NotificationContainer />
    </Dialog>
  );
};

export default B2BAccountModal;
