import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Avatar,
  TablePagination,
} from '@material-ui/core';
import useStyles from '../index.style';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { appMenuList } from 'constants/AppConstants';
import { addPopUp, getPopUp, updatePopUp } from 'redux/actions/PopUps';
import { getOfficialChampionshipList } from 'redux/actions/OfficialChampionship';
import { getEcosystems } from 'redux/actions/Ecosystems';

const countryList = require('country-list');

const PopupDetails = () => {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [embedURL, setEmbedURL] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [menu, setMenu] = useState('no-option');
  const [redirectSection, setRedirectSection] = useState('');
  const countries = countryList.getData();
  const [slicedCountries, setSlicedCountries] = useState([]);
  const [slicedSelectedCountries, setSlicedSelectedCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSelected, setPageSelected] = useState(0);
  const [countriesSectionIsDisplayed, setCountriesSectionIsDisplayed] = useState(false);
  const itemsPerPage = 40;
  const { popUps } = useSelector(({ popUps }) => popUps);
  const { officialChampionshipList } = useSelector(({ officialChampionship }) => officialChampionship);
  const { Ecosystems } = useSelector(({ Ecosystems }) => Ecosystems);
  const [officialChampionship, setOfficialChampionship] = useState({});
  const [ecosystem, setEcosystem] = useState({});

  useEffect(() => {
    dispatch(getOfficialChampionshipList({ published: true, final: false }));
    dispatch(getEcosystems());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getPopUp(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (popUps && id) {
      setEmbedURL(popUps?.webPageLink || '');
      setButtonText(popUps?.actionText || '');
      setStartDate(popUps?.startDate);
      setEndDate(popUps?.endDate);
      setRedirectSection(popUps?.redirectSection || '');
      setMenu(popUps.menu === undefined ? 'no-option' : popUps.menu);
      const selectedCountriesForPopup = countries.filter(item => popUps?.countries?.includes(item.code));
      setSelectedCountries(selectedCountriesForPopup);
    } else {
      setMenu('no-option');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popUps]);

  useEffect(() => {
    if (popUps?.challengeId && officialChampionshipList?.length) {
      setOfficialChampionship(officialChampionshipList?.find(item => item._id === popUps?.challengeId));
    }
    if (popUps?.environmentId && Ecosystems?.length) {
      setEcosystem(Ecosystems?.find(item => item._id === popUps?.environmentId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popUps, officialChampionshipList, Ecosystems]);

  useEffect(() => {
    setSlicedSelectedCountries(
      selectedCountries.slice(pageSelected * itemsPerPage, pageSelected * itemsPerPage + itemsPerPage),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountries]);

  const isValidUrl = urlString => {
    var urlPattern = new RegExp(
      '^(https?:\\/\\/)?' +
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
        '(\\?[;&a-z\\d%_.~+=-]*)?' +
        '(\\#[-a-z\\d_]*)?$',
      'i',
    );
    return !!urlPattern.test(urlString);
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangePageSelected = (e, newPage) => {
    setPageSelected(newPage);
  };

  useEffect(() => {
    setSlicedCountries(countries.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    setSlicedSelectedCountries(
      selectedCountries.slice(pageSelected * itemsPerPage, pageSelected * itemsPerPage + itemsPerPage),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSelected]);

  const handleStartDate = e => {
    const newDate = new Date(e.target.value).toISOString();
    setStartDate(newDate);
  };

  const handleEndDate = e => {
    const newDate = new Date(e.target.value).toISOString();
    setEndDate(newDate);
  };

  const handleMenuChange = e => {
    setMenu(e.target.value);
  };
  const handleRedirectSectionChange = e => {
    setRedirectSection(e.target.value);
  };

  const addHandler = country => {
    setSelectedCountries(prev => [...prev, country]);
  };

  const removeHandler = country => {
    const filteredCountries = selectedCountries.filter(item => item.code !== country.code);
    setSelectedCountries(filteredCountries);
  };

  const addAllHandler = () => {
    setSelectedCountries(countries);
  };

  const removeAllHandler = () => {
    setSelectedCountries([]);
  };

  const createNotification = message => {
    return NotificationManager.error(message, 'ERROR!', 3000);
  };

  const toggleCountriesSection = () => {
    setCountriesSectionIsDisplayed(prev => !prev);
  };

  const createPopupHandler = () => {
    const canBeSubmitted = validateField();
    if (canBeSubmitted) {
      const selectedCountriesCodes = selectedCountries.map(country => {
        return country.code;
      });
      const data = {
        webPageLink: embedURL,
        menu,
        redirectSection,
        actionText: buttonText,
        startDate,
        endDate,
        countries: selectedCountriesCodes,
        challengeId: officialChampionship?._id,
      };
      if (menu === 5 || menu === 6) {
        data.environmentId = ecosystem?._id;
      }
      dispatch(addPopUp(data));
    }
  };

  const updatePopupHandler = () => {
    const canBeSubmitted = validateField();
    if (canBeSubmitted) {
      const selectedCountriesCodes = selectedCountries.map(country => {
        return country.code;
      });
      const data = {
        webPageLink: embedURL,
        menu,
        redirectSection,
        actionText: buttonText,
        startDate,
        endDate,
        countries: selectedCountriesCodes,
        challengeId: officialChampionship?._id,
      };
      if (menu === 5 || menu === 6) {
        data.environmentId = ecosystem?._id;
      }
      dispatch(updatePopUp(popUps._id, data));
    }
  };

  const validateField = () => {
    let canBeSubmitted = true;
    if (!embedURL) {
      createNotification('Pop-up embed URL should not be empty!');
      canBeSubmitted = false;
    } else {
      if (isValidUrl(embedURL) === false) {
        createNotification('Please add a valid URL!');
        canBeSubmitted = false;
      }
    }
    if (!buttonText) {
      createNotification('Button text should not be empty!');
      canBeSubmitted = false;
    }
    if (!startDate) {
      createNotification('Start date should not be empty!');
      canBeSubmitted = false;
    }
    if (!endDate) {
      createNotification('End date should not be empty!');
      canBeSubmitted = false;
    }
    if (startDate > endDate) {
      createNotification('Start date should be before the end date!');
      canBeSubmitted = false;
    }
    if (menu === 'no-option') {
      createNotification('Menu should not be empty!');
      canBeSubmitted = false;
    }
    if (menu === 4 && !officialChampionship?._id) {
      createNotification('Please select an official championship!');
      canBeSubmitted = false;
    }
    if ((menu === 5 || menu === 6) && !ecosystem?._id) {
      createNotification('Please select an ecosystem!');
      canBeSubmitted = false;
    }
    if (!redirectSection) {
      createNotification('Redirect section should not be empty!');
      canBeSubmitted = false;
    }
    if (!selectedCountries.length) {
      createNotification('Countries should not be empty!');
      setCountriesSectionIsDisplayed(true);
      canBeSubmitted = false;
    }
    return canBeSubmitted;
  };

  return (
    <Box>
      <Box>
        <Box className={classes.row}>
          <TextField
            id="outlined-basic"
            onChange={e => setEmbedURL(e.target.value)}
            value={embedURL}
            className={classes.textFieldHead}
            label="Pop-up embed URL"
            variant="outlined"
          />
          <TextField
            id="outlined-basic"
            onChange={e => setButtonText(e.target.value)}
            value={buttonText}
            className={classes.textFieldHead}
            label="Button Text"
            variant="outlined"
          />
        </Box>
        <Box className={classes.row}>
          <FormControl style={{ margin: '12.5px 10px', flex: '1' }} className={classes.formControl}>
            <InputLabel>App Menu</InputLabel>
            <Select name="stakeType" value={menu} onChange={handleMenuChange} style={{ height: '100%' }}>
              <MenuItem key="0" value="no-option">
                Select Menu
              </MenuItem>
              {appMenuList.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.id}>
                    {item.menu}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            id="outlined-basic"
            onChange={handleRedirectSectionChange}
            value={redirectSection}
            className={classes.textFieldHead}
            label="Redirect Section"
            variant="outlined"
            style={{ flex: '1' }}
          />
        </Box>
        {menu === 4 && (
          <Box className={classes.row}>
            <FormControl style={{ margin: '12.5px 10px', width: '49%' }} className={classes.formControl}>
              <InputLabel>Official Championship</InputLabel>
              <Select value={officialChampionship} onChange={e => setOfficialChampionship(e.target.value)}>
                {officialChampionshipList?.map(item => {
                  return (
                    <MenuItem key={item._id} value={item}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        )}
        {(menu === 5 || menu === 6) && Ecosystems?.length && (
          <Box className={classes.row}>
            <FormControl style={{ margin: '12.5px 10px', width: '49%' }} className={classes.formControl}>
              <InputLabel>Ecosystem</InputLabel>
              <Select value={ecosystem} onChange={e => setEcosystem(e.target.value)}>
                {Ecosystems?.map(item => {
                  return (
                    <MenuItem key={item._id} value={item}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        )}
        <Box className={classes.row}>
          <Box className={classes.date}>
            <h2>Start date</h2>
            <TextField
              id="datetime-local"
              type="datetime-local"
              onChange={handleStartDate}
              value={moment(startDate).format('YYYY-MM-DDTkk:mm')}
              className={classes.dateInput}
              sx={{ width: 200 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box className={classes.date}>
            <h2>End date</h2>
            <TextField
              id="datetime-local"
              type="datetime-local"
              onChange={handleEndDate}
              value={moment(endDate).format('YYYY-MM-DDTkk:mm')}
              className={classes.dateInput}
              sx={{ width: 250 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Box>

        <Box>
          <FormControl
            component="fieldset"
            className={classes.formControl}
            style={{
              width: '100%',
              margin: '5px 0',
              borderBottom: '1px solid black',
              borderTop: '1px solid black',
              padding: '40px 12.5px',
            }}>
            <Box mb={10} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h2 style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                Selected Countries
                {!countriesSectionIsDisplayed ? (
                  <AddCircleOutlineIcon style={{ cursor: 'pointer' }} onClick={toggleCountriesSection} />
                ) : (
                  <RemoveCircleOutlineIcon style={{ cursor: 'pointer' }} onClick={toggleCountriesSection} />
                )}
              </h2>
              <Box style={{ display: 'flex', gap: '10px' }}>
                {selectedCountries?.length !== countries?.length && (
                  <Button variant="contained" color="primary" onClick={addAllHandler}>
                    ADD ALL COUNTRIES
                  </Button>
                )}
                {selectedCountries?.length > 0 && (
                  <Button variant="contained" color="primary" onClick={removeAllHandler}>
                    REMOVE ALL COUNTRIES
                  </Button>
                )}
              </Box>
            </Box>
            <FormGroup className={classes.countries}>
              {!selectedCountries.length && (
                <Box>
                  <p style={{ fontWeight: 'bold' }}>No countries selected.</p>
                </Box>
              )}
              {slicedSelectedCountries.map((country, index) => {
                return (
                  <Box
                    mr={4}
                    mb={5}
                    p={2}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      border: '1px solid rgba(0,0,0,0.06)',
                    }}
                    key={index}>
                    <Box>
                      <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
                        <Box>
                          <Avatar
                            src={'https://flagsapi.com/' + country.code + '/flat/64.png'}
                            variant="square"
                            style={{ width: '30px', height: '20px' }}></Avatar>
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Box>
                        <h4 style={{ fontWeight: 'bold' }}>{country.name}</h4>
                      </Box>
                    </Box>
                    <Button
                      style={{ marginLeft: 'auto' }}
                      variant="contained"
                      color="primary"
                      onClick={() => removeHandler(country)}>
                      REMOVE
                    </Button>
                  </Box>
                );
              })}
            </FormGroup>
            <TablePagination
              component="div"
              style={{ order: '9999', width: '100%', marginBottom: '20px' }}
              count={selectedCountries.length}
              page={pageSelected}
              onPageChange={handleChangePageSelected}
              rowsPerPageOptions={[]}
              rowsPerPage={itemsPerPage}
            />
          </FormControl>
        </Box>
        {countriesSectionIsDisplayed && (
          <Box className={classes.card}>
            <FormControl
              component="fieldset"
              className={classes.formControl}
              style={{ width: '100%', margin: '40px 12.5px' }}>
              <Box mb={5}>
                <h2 style={{ fontWeight: 'bold' }}>Choose countries</h2>
              </Box>
              <FormGroup className={classes.countries}>
                {slicedCountries.map((country, index) => {
                  return (
                    <Box
                      mr={4}
                      mb={5}
                      p={2}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        border: '1px solid rgba(0,0,0,0.06)',
                      }}
                      key={index}>
                      <Box>
                        <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
                          <Box>
                            <Avatar
                              src={'https://flagsapi.com/' + country.code + '/flat/64.png'}
                              variant="square"
                              style={{ width: '30px', height: '20px' }}></Avatar>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Box>
                          <h4 style={{ fontWeight: 'bold' }}>{country.name}</h4>
                        </Box>
                      </Box>
                      <Button
                        style={{ marginLeft: 'auto' }}
                        variant="contained"
                        color="primary"
                        onClick={() => addHandler(country)}
                        disabled={selectedCountries.filter(item => item.code === country.code)?.length ? true : false}>
                        Add
                      </Button>
                    </Box>
                  );
                })}
              </FormGroup>
            </FormControl>
            <TablePagination
              component="div"
              style={{ order: '9999', width: '100%', marginBottom: '20px' }}
              count={countries.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[]}
              rowsPerPage={itemsPerPage}
            />
          </Box>
        )}
      </Box>
      {id && id !== 'new' ? (
        <Button style={{ margin: '25px 0 ' }} variant="contained" color="primary" onClick={updatePopupHandler}>
          Update Pop-up
        </Button>
      ) : (
        <Button style={{ margin: '25px 0 ' }} variant="contained" color="primary" onClick={createPopupHandler}>
          Create Pop-up
        </Button>
      )}
      <NotificationContainer />
    </Box>
  );
};

export default PopupDetails;
