import React from 'react';
import { TableRow, TableCell, Box, TableHead, Tooltip } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import useStyles from './../index.style';
import { useDispatch, useSelector } from 'react-redux';
import { setDBFilters } from 'redux/actions/DBTranslations';

const TableHeading = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { filters } = useSelector(({ DBTranslations }) => DBTranslations);

  const addedDateSortHandler = () => {
    if (filters.addedDateSort === 0) {
      dispatch(setDBFilters({ ...filters, offset: 0, addedDateSort: 1, updatedDateSort: 0 }));
    } else if (filters.addedDateSort === 1) {
      dispatch(setDBFilters({ ...filters, offset: 0, addedDateSort: -1, updatedDateSort: 0 }));
    } else {
      dispatch(setDBFilters({ ...filters, offset: 0, addedDateSort: 0, updatedDateSort: 0 }));
    }
  };

  const updatedDateSortHandler = () => {
    if (filters.updatedDateSort === 0) {
      dispatch(setDBFilters({ ...filters, offset: 0, updatedDateSort: 1, addedDateSort: 0 }));
    } else if (filters.updatedDateSort === 1) {
      dispatch(setDBFilters({ ...filters, offset: 0, updatedDateSort: -1, addedDateSort: 0 }));
    } else {
      dispatch(setDBFilters({ ...filters, offset: 0, updatedDateSort: 0, addedDateSort: 0 }));
    }
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <strong>Term ID</strong>
        </TableCell>
        {/* <TableCell className={classes.tableCellRoot}>
          <strong>Section</strong>
        </TableCell> */}
        <TableCell className={classes.tableCellRoot}>
          <Box>
            <strong>Translation</strong>
          </Box>
        </TableCell>

        <TableCell className={classes.tableCellRoot}>
          <Box>
            <strong>Added By</strong>
          </Box>
        </TableCell>

        <TableCell className={classes.tableCellRoot} style={{ cursor: 'pointer' }}>
          <Box style={{ display: 'flex', alignItems: 'center' }} onClick={addedDateSortHandler}>
            <strong style={{ marginRight: '15px' }}>Added Date</strong>
            {filters.addedDateSort === 1 && <ArrowDropUpIcon />}
            {filters.addedDateSort === -1 && <ArrowDropDownIcon />}
          </Box>
        </TableCell>

        <TableCell className={classes.tableCellRoot} style={{ cursor: 'pointer' }}>
          <Box style={{ display: 'flex', alignItems: 'center' }} onClick={updatedDateSortHandler}>
            <strong style={{ marginRight: '15px' }}>Updated Date</strong>
            {filters.updatedDateSort === 1 && <ArrowDropUpIcon />}
            {filters.updatedDateSort === -1 && <ArrowDropDownIcon />}
          </Box>
        </TableCell>

        <TableCell className={classes.tableCellRoot}>
          <Box style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
            <strong>Missing Translations</strong>
          </Box>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeading;
