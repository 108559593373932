import axios from '../../services/auth/jwt/config';
import { fetchError, fetchStart, fetchSuccess } from './Common';

export const CREATE_B2B_ACCOUNT = 'CREATE_B2B_ACCOUNT';
export const FETCH_B2B_USERS_SUCCESS = 'FETCH_B2B_USERS_SUCCESS';
export const FETCH_B2B_USERS_FAILURE = 'FETCH_B2B_USERS_FAILURE';
export const UPDATE_B2B_ACCOUNT = 'UPDATE_B2B_ACCOUNT';

export const createB2BAccount = body => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/authentication/sign-up-b2b', body)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: CREATE_B2B_ACCOUNT, payload: data.data });
      })
      .catch(error => {
        let errorMessage = 'Error creating B2B account';

        if (error.response && error.response.data && error.response.data.errorCode === 1003) {
          errorMessage = 'An account with this email already exists.';
        }

        dispatch(fetchError(errorMessage));
      });
  };
};

export const fetchB2BUsers = (page, rowsPerPage) => {
  return dispatch => {
    dispatch(fetchStart());

    let url = `/users/b2b-clients?offset=${page * rowsPerPage}&limit=${rowsPerPage}`;

    axios
      .get(url)
      .then(response => {
        dispatch(fetchSuccess());
        dispatch({ type: FETCH_B2B_USERS_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch(fetchError('Error fetching B2B users'));
        dispatch({ type: FETCH_B2B_USERS_FAILURE, payload: error });
      });
  };
};

export const updateB2BAccount = (id, body) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .patch(`/users/b2b-client/${id}`, body)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: UPDATE_B2B_ACCOUNT, payload: data.data });
        dispatch(fetchB2BUsers(0, 25));
      })
      .catch(error => {
        dispatch(fetchError('Error updating B2B account'));
      });
  };
};
