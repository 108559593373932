import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { useDispatch } from 'react-redux';
import { setMatchSetting } from 'redux/actions/MatchApp';
import { useHistory } from 'react-router-dom';
import { TableCell, TableRow, Box, Typography, Switch, Button } from '@material-ui/core';
import CmtAvatar from '@coremat/CmtAvatar';
import useStyles from '../MatchCell.style';
import MatchCellOptions from './MatchCellOptions';
import OutdatedTemplate from './OutdatedTemplate';

const MatchCell = ({ filterType, match }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    _id,
    status,
    headline,
    stats: wholeGame,
    homeTeamEmblemUrl,
    awayTeamEmblemUrl,
    scheduledDate,
    competitionName,
  } = match;
  const [matchSettings, setMatchSettings] = useState({
    popular: match.popular,
    matchOfTheDay: match.matchOfTheDay,
    topMatch: match.topMatch,
  });

  const [open, setOpen] = useState(false);
  const onClickChangeSettings = (e, settingToChange) => {
    let updatedValue = { ...matchSettings };
    switch (settingToChange) {
      case 'popular':
        updatedValue = { ...matchSettings, popular: e.target.checked };
        break;

      case 'matchOfTheDay':
        updatedValue = { ...matchSettings, matchOfTheDay: e.target.checked };
        break;

      case 'topMatch':
        updatedValue = { ...matchSettings, topMatch: e.target.checked };
        break;

      default:
        return null;
    }
    setMatchSettings(updatedValue);
    dispatch(
      setMatchSetting(match._id, {
        popular:
          settingToChange === 'popular'
            ? e.target.checked
            : matchSettings.popular !== undefined
            ? matchSettings.popular
            : false,
        matchOfTheDay:
          settingToChange === 'matchOfTheDay'
            ? e.target.checked
            : matchSettings.matchOfTheDay !== undefined
            ? matchSettings.matchOfTheDay
            : false,
        topMatch:
          settingToChange === 'topMatch'
            ? e.target.checked
            : matchSettings.topMatch !== undefined
            ? matchSettings.topMatch
            : false,
      }),
    );
  };

  useEffect(() => {
    setMatchSettings({ popular: match.popular, matchOfTheDay: match.matchOfTheDay, topMatch: match.topMatch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <TableRow className={classes.tableRowRoot}>
      <TableCell className={classes.tableCellRoot}>
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center">
            <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
              <Box
                mr={4}
                style={{
                  width: '30px',
                  overflow: 'hidden',
                  marginRight: '0',
                  borderRight: '1px solid #fff',
                }}>
                <CmtAvatar size={60} src={homeTeamEmblemUrl} alt={headline} />
              </Box>
              <Box mr={4} style={{ width: '30px', overflow: 'hidden', marginRight: '0' }}>
                <CmtAvatar size={60} style={{ transform: 'translateX(-30px)' }} src={awayTeamEmblemUrl} alt={headline} />
              </Box>
            </Box>

            <Box>
              <Typography className={classes.titleRoot} component="div" variant="h4">
                {headline}{' '}
                <Typography style={{ display: 'inline-block' }} className={classes.subTitleRoot}>
                  ({status})
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Box>
      </TableCell>
      {filterType === 'past' || filterType === 'live' ? (
        <TableCell className={classes.tableCellRoot}>
          {wholeGame ? (
            <span>
              {wholeGame.wholeGame?.paramFloat1} - {wholeGame.wholeGame?.paramFloat2}
            </span>
          ) : (
            <span>0 - 0</span>
          )}
        </TableCell>
      ) : null}
      <TableCell className={classes.tableCellRoot}>{Moment(scheduledDate).format('H:mm - D MMM yyyy')}</TableCell>
      <TableCell className={classes.tableCellRoot}>{competitionName}</TableCell>
      {filterType === 'past' || filterType === 'live' || filterType === 'future' ? (
        <>
          <TableCell className={['classes.tableCellRoot test'].join(' ')}>
            <Switch
              checked={matchSettings.popular}
              onChange={e => onClickChangeSettings(e, 'popular')}
              name="isPublished"
              color="primary"
            />
          </TableCell>
          <TableCell className={classes.tableCellRoot}>
            <Switch
              checked={matchSettings.matchOfTheDay}
              onChange={e => onClickChangeSettings(e, 'matchOfTheDay')}
              name="isPublished"
              color="primary"
            />
          </TableCell>
          <TableCell className={classes.tableCellRoot}>
            <Switch
              checked={matchSettings.topMatch}
              onChange={e => onClickChangeSettings(e, 'topMatch')}
              name="isPublished"
              color="primary"
            />
          </TableCell>
        </>
      ) : null}
      {filterType === 'canceled-postponed' || filterType === 'deleted' || filterType === 'past-not-ended' ? (
        <>
          <TableCell className={classes.tableCellRoot}>
            <span>{match.sportName}</span>
          </TableCell>
          <TableCell className={classes.tableCellRoot}>
            <Button
              variant="contained"
              style={{ height: '100%', marginLeft: '15px', zIndex: '334' }}
              color="primary"
              onClick={handleClickOpen}>
              Status
            </Button>
            <OutdatedTemplate open={open} onClose={handleClose} action={'add'} match={match} filterType={filterType} />
          </TableCell>
        </>
      ) : null}
      <TableCell className={classes.tableCellRoot}>
        <MatchCellOptions
          match={match}
          onClickEditMatch={() => {
            history.push('/match/' + _id);
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default MatchCell;

MatchCell.defaultProps = {
  checkedMatches: [],
};
