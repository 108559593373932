import { GET_TEAMS_DATA, SELECT_SPORT, TOGGLE_SIDEBAR_COLLAPSED } from 'constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from 'services/auth/jwt/config';

export const getTeams = ({ limit, offset, name, sportsIds }) => {
  return dispatch => {
    dispatch(fetchStart());
    let url = '/teams?';
    if (limit >= 0 && offset >= 0) {
      url += `offset=${offset}&limit=${limit}&`;
    }
    if (name) {
      url += `name=${name}&`;
    }
    if (sportsIds?.length) {
      url += '[';
      sportsIds.forEach(item => (url += item));
      url += ']&';
    }
    axios
      .get(url)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_TEAMS_DATA, payload: data });
      })
      .catch(error => {
        if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const selectSport = sport => {
  return {
    type: SELECT_SPORT,
    payload: sport,
  };
};

export const toggleExpandSidebar = value => {
  return dispatch => {
    dispatch({
      type: TOGGLE_SIDEBAR_COLLAPSED,
      payload: value,
    });
  };
};
