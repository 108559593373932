import {
  ADD_OFFICIAL_CHAMPHIONSHIP,
  ADD_OFFICIAL_CHAMPHIONSHIP_LIST,
  DELETE_OFFICIAL_CHAMPHIONSHIP,
  DELETE_OFFICIAL_CHAMPHIONSHIP_LIST,
  GET_OFFICIAL_CHAMPIONSHIPS_COUNTS,
  SEND_MESSAGE,
  SET_FILTER_DATA,
  SET_OFFICIAL_CHAMPHIONSHIP_CURRENT_USER,
  SET_OFFICIAL_CHAMPHIONSHIP_DETAIL,
  SET_OFFICIAL_CHAMPHIONSHIP_LIST_DATA,
  SET_OFFICIAL_CHAMPIONSHIPS_DATA,
  TOGGLE_SIDEBAR_COLLAPSED,
  UPDATE_OFFICIAL_CHAMPHIONSHIP,
  UPDATE_OFFICIAL_CHAMPHIONSHIP_LIST,
} from '../../constants/ActionTypes';
import { users } from '../../@fake-db/officialChampionship';

const INIT_STATE = {
  currentUser: null,
  officialChampionships: [],
  officialChampionshipLists: [],
  currentOfficialChampionship: null,
  isSideBarCollapsed: false,
  filterData: {
    filterType: 1,
    officialChampionshipList: 0,
  },
  counter: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_OFFICIAL_CHAMPHIONSHIP_CURRENT_USER: {
      return { ...state, currentUser: action.payload };
    }
    case SET_OFFICIAL_CHAMPIONSHIPS_DATA: {
      return { ...state, officialChampionships: action.payload };
    }
    case SET_OFFICIAL_CHAMPHIONSHIP_LIST_DATA: {
      return { ...state, officialChampionshipLists: action.payload };
    }
    case SET_OFFICIAL_CHAMPHIONSHIP_DETAIL: {
      return {
        ...state,
        currentOfficialChampionship: action.payload,
        isSideBarCollapsed: true,
      };
    }
    case SEND_MESSAGE: {
      return {
        ...state,
        currentOfficialChampionship: {
          ...state.currentOfficialChampionship,
          comments: state.currentOfficialChampionship.comments.concat({
            id: new Date().getTime(),
            user: users[0],
            message: action.payload,
            createdAt: '',
          }),
        },
      };
    }
    case SET_FILTER_DATA: {
      return { ...state, filterData: { ...state.filterData, ...action.payload } };
    }
    case DELETE_OFFICIAL_CHAMPHIONSHIP_LIST: {
      return {
        ...state,
        officialChampionshipLists: state.officialChampionshipLists.filter(item => item.id !== action.payload.id),
      };
    }
    case ADD_OFFICIAL_CHAMPHIONSHIP_LIST: {
      return { ...state, officialChampionshipLists: state.officialChampionshipLists.concat(action.payload) };
    }
    case ADD_OFFICIAL_CHAMPHIONSHIP: {
      return { ...state, officialChampionships: state.officialChampionships.concat(action.payload) };
    }
    case UPDATE_OFFICIAL_CHAMPHIONSHIP: {
      return {
        ...state,
        officialChampionships: state.officialChampionships.map(item =>
          item.id === action.payload.id ? action.payload : item,
        ),
      };
    }
    case DELETE_OFFICIAL_CHAMPHIONSHIP: {
      return {
        ...state,
        officialChampionships: state.officialChampionships.filter(item => item.id !== action.payload.id),
        currentOfficialChampionship: null,
        isSideBarCollapsed: false,
      };
    }
    case UPDATE_OFFICIAL_CHAMPHIONSHIP_LIST: {
      return {
        ...state,
        officialChampionshipLists: state.officialChampionshipLists.map(item =>
          item.id === action.payload.id ? action.payload : item,
        ),
      };
    }
    case TOGGLE_SIDEBAR_COLLAPSED: {
      return { ...state, isSideBarCollapsed: !state.isSideBarCollapsed };
    }

    case GET_OFFICIAL_CHAMPIONSHIPS_COUNTS: {
      return {
        ...state,
        counter: action.payload,
      };
    }

    default:
      return state;
  }
};
