import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import GlobalMessages from 'assets/global/globalMessages';
import { getCompetitions } from 'redux/actions/Competitions';
import { getChallenges } from 'redux/actions/Challenges';
import { useHistory } from 'react-router-dom';
import {
  Button,
  DialogActions,
  FormControl,
  FormLabel,
  InputLabel,
  TextField,
  MenuItem,
  Select,
  Box,
  Switch,
  OutlinedInput,
  Chip,
} from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import DeleteIcon from '@material-ui/icons/Delete';
import useStyles from './index.style';
import { createEcosystems } from 'redux/actions/Ecosystems';
import { NotificationContainer } from 'react-notifications';
import ColorPickerPopup from 'components/Common/ColorPickerPopup';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const AddecosystemTemplate = ({ open }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      challange: '',
      competition: '',
    },
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedCompetitions, setSelectedCompetitions] = useState('');

  const [page, setPage] = React.useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const history = useHistory();
  const filterType = {
    field: 'name',
    order: '1',
  };
  const { competitionsList } = useSelector(({ competitions }) => competitions);
  const rowsPerPage = 999;

  const [previewCover, setPreviewCover] = useState(null);
  const [cover, setCover] = useState();
  const [showChallengeTC, setShowChallengeTC] = useState(false);
  const [showPublished, setShowPublished] = useState(false);

  const { challengesList } = useSelector(({ challenges }) => challenges);
  const [bgColor, setBgColor] = useState('#b9b9b9');
  const [buttonsColor, setButtonsColor] = useState('#504e4e');
  const [contrastColor, setContrastColor] = useState('#666666');
  const [selectedIds, setSelectedIds] = useState([]);

  const handleSelectionChange = event => {
    const value = event.target.value;
    // Creating a new array of selected items based on the selected IDs
    setSelectedIds(value);
  };

  useEffect(() => {
    setPage(0);
    dispatch(getCompetitions(null, page, rowsPerPage, searchQuery, filterType));
    dispatch(getChallenges(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (competitionsList && competitionsList.length !== 0) {
      setSelectedCompetitions(competitionsList?.competitions[0]._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitionsList]);

  const handleCover = e => {
    setPreviewCover(URL.createObjectURL(e.target.files[0]));
    setCover(e.target.files[0]);
  };

  const setNewBgColor = event => {
    setBgColor(event);
  };
  const setNewButtonsColor = event => {
    setButtonsColor(event);
  };

  const setNewContrastColor = event => {
    setContrastColor(event);
  };

  const onSubmit = data => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('challengeId', data.challange);
    selectedIds.forEach(id => {
      formData.append('competitionsIds[]', id);
    });
    formData.append('backgroundColor', bgColor);
    formData.append('cardBackgroundColor', buttonsColor);
    formData.append('isMotorRacing', showChallengeTC);
    formData.append('contrastColor', contrastColor);
    formData.append('file', cover);
    formData.append('published', showPublished);
    dispatch(createEcosystems(formData));
  };

  return (
    <CmtCard open={open} scroll={'paper'}>
      <CmtCardHeader
        icon={
          <ArrowBackIcon
            style={{ cursor: 'pointer' }}
            onClick={() => {
              history.push('/ecosystems');
            }}
          />
        }
        title="Create Ecosystems"
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}></CmtCardHeader>
      <CmtCardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '50px' }}>
            <Box sx={{ width: '40%' }}>
              <FormControl className={classes.formControl}>
                <Controller
                  name="name"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => <TextField size="small" variant="outlined" label="Name" {...field} />}
                />
                {errors.name && <FormLabel className={classes.error}>{GlobalMessages.invalidName}</FormLabel>}
              </FormControl>
              <Box style={{ display: 'flex', flexDirection: 'row', margin: 10, alignItems: 'center', gap: '15px' }}>
                <p>Background color:</p>
                <ColorPickerPopup color={bgColor} setColor={setNewBgColor} />
              </Box>
              <Box style={{ display: 'flex', flexDirection: 'row', margin: 10, alignItems: 'center', gap: '15px' }}>
                <p>Card Background color:</p>
                <ColorPickerPopup color={buttonsColor} setColor={setNewButtonsColor} />
              </Box>
              <Box style={{ display: 'flex', flexDirection: 'row', margin: 10, alignItems: 'center', gap: '15px' }}>
                <p>Contrast color:</p>
                <ColorPickerPopup color={contrastColor} setColor={setNewContrastColor} />
              </Box>
              <Box style={{ display: 'flex', flexDirection: 'row', margin: 10, alignItems: 'center' }}>
                <p>Is Moto racing?</p>
                <Switch
                  checked={showChallengeTC}
                  onChange={() => {
                    setShowChallengeTC(prev => !prev);
                  }}
                />
              </Box>
              {competitionsList && competitionsList.length !== 0 ? (
                <FormControl style={{ marginBottom: '1.5rem', marginTop: '1.5rem' }} className={classes.formControl}>
                  <InputLabel>Competitions</InputLabel>
                  <Controller
                    name="competition"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        multiple
                        value={selectedIds}
                        onChange={handleSelectionChange}
                        input={<OutlinedInput label="Multiple Select" />}
                        renderValue={selected => (
                          <Box gap={1} direction="row" flexWrap="wrap" display="flex">
                            {selected.map(id => {
                              const { name } = competitionsList?.competitions.find(name => name._id === id) || {};
                              const countryName =
                                competitionsList?.competitions.find(name => name._id === id).country.name || {};
                              return (
                                <Chip
                                  key={id}
                                  label={`${name} - ${countryName}`}
                                  onDelete={() => setSelectedIds(selectedIds.filter(itemId => itemId !== id))}
                                  deleteIcon={<DeleteIcon onMouseDown={event => event.stopPropagation()} />}
                                />
                              );
                            })}
                          </Box>
                        )}>
                        {competitionsList?.competitions.map(competition => {
                          return (
                            <MenuItem key={competition._id} value={competition._id}>
                              {competition.name} - {competition.country.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  />

                  {errors.competition && <FormLabel className={classes.error}>{GlobalMessages.required}</FormLabel>}
                </FormControl>
              ) : (
                <Box>No available competitions</Box>
              )}
              {challengesList && challengesList.length !== 0 ? (
                <FormControl style={{ marginBottom: '1.5rem', marginTop: '1.5rem' }} className={classes.formControl}>
                  <InputLabel>Select Challenges</InputLabel>
                  <Controller
                    name="challange"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <Select {...field}>
                        {challengesList
                          .filter(templateType => templateType.type === 1)
                          .map(templateType => (
                            <MenuItem key={templateType._id} value={templateType._id}>
                              {templateType.name}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />

                  {errors.challange && <FormLabel className={classes.error}>{GlobalMessages.required}</FormLabel>}
                </FormControl>
              ) : (
                <Box>No available competitions</Box>
              )}
              <Box style={{ display: 'flex', flexDirection: 'row', margin: 10, alignItems: 'center' }}>
                <p>Is published?</p>
                <Switch
                  checked={showPublished}
                  onChange={() => {
                    setShowPublished(prev => !prev);
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{ width: '30%' }}
              style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <Box className={classes.imagePlaceholder}>
                {previewCover !== null ? <img alt="cover" src={previewCover} className={classes.imageLogo} /> : null}
              </Box>
              <input
                accept="image/*"
                onChange={handleCover}
                className={classes.hiddenInput}
                id="contained-button-cover"
                multiple
                type="file"
              />
              <label htmlFor="contained-button-cover">
                <Button variant="contained" color="primary" component="span" startIcon={<ImageIcon />}>
                  Upload Cover
                </Button>
              </label>
            </Box>
          </Box>
        </form>
      </CmtCardContent>
      <DialogActions>
        {Object.keys(errors).length !== 0 ? <span className={classes.formErrors}>{GlobalMessages.formErrors}</span> : null}
        <div>
          <Button
            onClick={() => {
              history.push('/ecosystems');
            }}
            style={{ marginLeft: '15px' }}
            color="primary">
            Cancel
          </Button>
          <Button
            style={{ marginLeft: '15px' }}
            type="submit"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary">
            Create
          </Button>
        </div>
      </DialogActions>
      <NotificationContainer />
    </CmtCard>
  );
};

export default AddecosystemTemplate;
