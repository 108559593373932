import React, { useContext, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { getMatchContainerHeight } from 'constants/AppConstants';
import AppContext from 'components/contextProvider/AppContextProvider/AppContext';

import { Box, Button, Table, TableBody, TablePagination } from '@material-ui/core';

import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';

import AddIcon from '@material-ui/icons/Add';
import TableHeading from 'components/Table/TableHeading';
import TableHeader from 'assets/global/globalTableHeadingData';

import useStyles from 'components/Table/table.styles';
import { getBetTemplates } from 'redux/actions/BetTemplate';

const Brackets = () => {
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();

  const { betTemplates } = useSelector(({ betTemplates }) => betTemplates);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);

  const classes = useStyles({
    height: getMatchContainerHeight(showFooter),
  });

  useEffect(() => {
    if (betTemplates.count) setCount(betTemplates.count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betTemplates]);

  useEffect(() => {
    dispatch(getBetTemplates(page, rowsPerPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CmtCard>
      <CmtCardHeader
        className="pt-4"
        title={'Brackets'}
        style={{ marginBottom: '30px', zIndex: '444' }}
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}>
        <Button
          variant="contained"
          style={{ height: '100%', marginLeft: '15px', zIndex: '334' }}
          color="primary"
          onClick={handleClickOpen}>
          <AddIcon style={{ marginRight: '5px' }} />
          Create Bracket
        </Button>
        {/*<OutdatedTemplate open={open} onClose={handleClose} action={'add'} />*/}
      </CmtCardHeader>
      <CmtCardContent className={classes.cardContentRoot}>
        <PerfectScrollbar className={classes.scrollbarRoot}>
          <Box className="Cmt-table-responsive">
            {betTemplates?.entities ? (
              <Table>
                <TableHeading headingData={TableHeader.Brackets} />
                <TableBody>
                  {/*{betTemplates.entities.map(template => (*/}
                  {/*  <TableItem row={template} key={template._id} />*/}
                  {/*))}*/}
                </TableBody>
              </Table>
            ) : (
              <Box className={classes.flexCenter}>No bet templates found</Box>
            )}
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          style={{ order: '9999', width: '100%' }}
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default Brackets;
