import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import useStyles from './../index.style';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import SaveIcon from '@material-ui/icons/Save';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const AddQuestion = ({ langList, onSaveQuestion, editNow, editDetail }) => {
  const classes = useStyles();
  const lang = langList;

  const [questionsText, setQuestionsText] = useState(
    editNow === undefined
      ? () => {
          let state = [];
          lang.forEach((item, key) => {
            let array = {
              text: '',
              language: item,
            };
            state.push(array);
          });
          return state;
        }
      : editNow.questionText,
  );

  const [possibleAnswers, setPossibleAnswers] = useState(
    editNow === undefined
      ? () => {
          let state = [];
          lang.forEach((item, key) => {
            let array = {
              answers: [{}, {}],
              language: item,
            };
            state.push(array);
          });
          return state;
        }
      : editNow.possibleAnswers,
  );

  const [addAnswerToggle, setAnswerToggle] = useState(false);

  const handleQuestion = (e, lang, key) => {
    let description = {
      text: e.target.value,
      language: lang,
    };
    setQuestionsText(questionsText, (questionsText[key] = description));
  };

  const handleAnsswer = (e, lang, questionKey, answKey, length) => {
    setPossibleAnswers(possibleAnswers, (possibleAnswers[questionKey].answers[answKey] = e.target.value));
  };

  const createNotification = (type, message) => {
    return NotificationManager.error(message, 'ERROR!', 3000, () => {
      alert('callback');
    });
  };

  const addAnswer = () => {
    lang.forEach((lang, key) => {
      setPossibleAnswers(possibleAnswers, possibleAnswers[key].answers.push({}));
      setQuestionsText(questionsText, []);
    });

    setAnswerToggle(!addAnswerToggle);
  };

  const removeAnswer = () => {
    let answersLength = true;
    lang.forEach((lang, key) => {
      if (possibleAnswers[key].answers.length > 2) {
        setPossibleAnswers(possibleAnswers, possibleAnswers[key].answers.pop());
        setQuestionsText(questionsText, []);
      } else {
        answersLength = false;
      }
    });

    if (answersLength === false) {
      createNotification('error', 'Question should have at least 2 answers');
    }

    setAnswerToggle(!addAnswerToggle);
  };

  const saveQuestion = () => {
    let questionId = null;
    let challengeId = null;
    if (editNow !== undefined && (editNow._id !== null || editNow._id !== undefined)) {
      questionId = editNow._id;
      challengeId = editDetail._id;
    }
    let question = {
      _id: questionId,
      deleted: false,
      type: 0,
      possibleAnswers: possibleAnswers,
      questionText: questionsText,
      challengeId: challengeId,
    };
    let answersEmpty = false;
    let questionEmpty = false;
    possibleAnswers.forEach((answers, key) => {
      answers.answers.forEach((answer, key) => {
        if (answer.length === undefined || answer.length === 0) {
          answersEmpty = true;
        }
      });
    });

    questionsText.forEach((question, key) => {
      if (question.text.length === undefined || question.text.length === 0) {
        questionEmpty = true;
      }
    });

    if (questionEmpty === true) {
      createNotification('error', 'Questions title should not be empty');
    } else {
      if (answersEmpty === true) {
        createNotification('error', 'Answers should not be empty');
      } else {
        onSaveQuestion(question);
      }
    }
  };

  return (
    <Box className={classes.questionsContainer}>
      <Box className={classes.questionsContent}>
        {addAnswerToggle === true || addAnswerToggle === false
          ? questionsText.map((question, questionKey) => {
              return (
                <Box key={questionKey} className={classes.addQuestionCell}>
                  <TextField
                    key={questionKey}
                    defaultValue={question.text}
                    onChange={e => handleQuestion(e, question.language, questionKey)}
                    id="outlined-basic"
                    className={classes.textFieldHead}
                    label={'Question ' + question.language}
                    variant="outlined"
                  />
                  {possibleAnswers[questionKey].answers.map((answer, key) => {
                    return (
                      <TextField
                        key={key}
                        defaultValue={editNow !== undefined ? answer : ''}
                        style={{
                          marginLeft: '30px',
                          maxWidth: 'calc(100% - 37.5px)',
                        }}
                        onChange={e =>
                          handleAnsswer(e, question.language, questionKey, key, possibleAnswers[questionKey].length)
                        }
                        id="outlined-basic"
                        label={'answer for question - ' + question.language}
                        className={classes.textFieldHead}
                        variant="outlined"
                      />
                    );
                  })}
                </Box>
              );
            })
          : null}
      </Box>
      <Box className={classes.questionsActions}>
        <Button
          className={classes.leftButtons}
          variant="contained"
          color="primary"
          component="span"
          onClick={addAnswer}
          startIcon={<AddIcon />}>
          Add Answer
        </Button>
        <Button
          className={classes.rightButtons}
          variant="contained"
          component="span"
          onClick={removeAnswer}
          startIcon={<RemoveIcon />}>
          Remove Answer
        </Button>
        <Button
          className={classes.rightButtons}
          variant="contained"
          color="primary"
          component="span"
          onClick={saveQuestion}
          startIcon={<SaveIcon />}>
          Save Question
        </Button>
      </Box>
      <NotificationContainer />
    </Box>
  );
};

export default AddQuestion;
