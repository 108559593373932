import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  fetchCompetitionsDetails,
  fetchCompetitionsSeason,
  setCompetitionAsRecommended,
  syncCompetitions,
  syncF1Competitions,
} from 'redux/actions/Competitions';
import clsx from 'clsx';
import { alpha, IconButton, Tooltip, TableCell, makeStyles, Box, Switch, Button, TableRow } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import CmtAvatar from '@coremat/CmtAvatar';
import CmtMediaObject from '@coremat/CmtMediaObject';

const useStyles = makeStyles(theme => ({
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&:last-child': {
          color: theme.palette.error.main,
        },
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    padding: 16,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.secondary,
    borderBottom: '0 none',
    position: 'relative',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      textAlign: 'right',
      color: theme.palette.error.main,
      paddingRight: 24,
    },
    '&.success': {
      color: theme.palette.success.main,
    },
    '& .Cmt-media-object': {
      alignItems: 'center',
    },
  },
  badgeRoot: {
    color: theme.palette.common.white,
    borderRadius: 30,
    fontSize: 12,
    padding: '2px 10px',
    display: 'inline-block',
  },
}));

const actions = [
  {
    label: 'View competition',
  },
];

function getBgColor(status) {
  const color = {
    cancelled: '#E00930',
    completed: '#0795F4',
    delayed: '#03DAC5',
    onHold: '#FF8C00',
  };
  return color[status];
}

const TableItem = ({ row }) => {
  const history = useHistory();
  const classes = useStyles();
  const [sync, setSync] = useState(row.shouldSync);
  const [isPopular, setIsPopular] = useState(row.recommended !== undefined ? row?.recommended : false);
  const dispatch = useDispatch();
  const [syncMoto, setSyncMoto] = useState(row?.isF1Competition || false);

  const handleChange = event => {
    setSync(event.target.checked);
    dispatch(syncCompetitions(row._id, event.target.checked));
    row.shouldSync = !row.shouldSync;
  };

  const handleChangeMoto = event => {
    setSyncMoto(event.target.checked);
    dispatch(syncF1Competitions(row._id, event.target.checked));
    row.isF1Competition = !row.isF1Competition;
  };

  const handlePopular = event => {
    setIsPopular(event.target.checked);
    dispatch(setCompetitionAsRecommended(row._id, event.target.checked));
    row.recommended = !row.recommended;
  };

  const handleFetchCompetition = () => {
    let futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 14);
    let pastDate = new Date();
    pastDate.setDate(pastDate.getDate() - 14);

    dispatch(fetchCompetitionsDetails(pastDate.toISOString(), futureDate.toISOString(), { competitionId: row._id }));
  };

  const handleFetch = () => {
    dispatch(fetchCompetitionsSeason(row._id));
  };

  useEffect(() => {
    setSync(row.shouldSync);
  }, [row]);

  return (
    <TableRow className={classes.tableRowRoot}>
      <TableCell className={classes.tableCellRoot}>
        <CmtMediaObject
          avatarPos="center"
          avatar={<CmtAvatar size={35} src={row.profilePic} alt={row.name} />}
          title={row.name}
          titleProps={{
            variant: 'h5',
            className: classes.titleRoot,
          }}
        />
      </TableCell>
      <TableCell className={classes.tableCellRoot}>{row.country !== null ? row.country.name : 'NULL'}</TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Switch checked={sync} onChange={handleChange} name="checkedA" inputProps={{ 'aria-label': 'secondary checkbox' }} />
      </TableCell>
      {(row?.sportId === '62a9cb67cc530f2d6feaa8aa' || row?.sportId === '61f9195bcc7e0c4b221e8683') && (
        <TableCell className={classes.tableCellRoot}>
          <Switch
            checked={syncMoto}
            onChange={handleChangeMoto}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </TableCell>
      )}
      <TableCell className={classes.tableCellRoot}>
        <Switch
          checked={isPopular}
          name="isPopular"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          onChange={handlePopular}
        />
      </TableCell>
      <TableCell className={clsx(classes.tableCellRoot, 'success')}>
        <Box className={classes.badgeRoot} component="span" bgcolor={getBgColor(row.status)}>
          <Button variant="contained" color="primary" onClick={handleFetch}>
            FETCH&nbsp;SEASON
          </Button>
        </Box>
      </TableCell>
      <TableCell className={clsx(classes.tableCellRoot, 'success')}>
        <Box className={classes.badgeRoot} component="span" bgcolor={getBgColor(row.status)}>
          <Button variant="contained" color="primary" onClick={handleFetchCompetition}>
            FETCH&nbsp;COMPETITION
          </Button>
        </Box>
      </TableCell>
      <TableCell className={clsx(classes.tableCellRoot, 'success')}>
        <Box display="flex" alignItems="center" justifyContent="flex-end"></Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <CmtDropdownMenu
            TriggerComponent={
              <Tooltip title="More">
                <IconButton size="small" style={{ marginLeft: 10 }}>
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
            }
            items={actions}
            onItemClick={() => {
              history.push('/competitions/' + row._id);
            }}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TableItem;
