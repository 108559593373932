import React from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import useStyles from 'components/Table/table.styles';
import OddsOptionCompetition from './OddsOptions';

const OddsCompetition = ({ tableDataOdds }) => {
  const classes = useStyles();
  return (
    <Box className="Cmt-table-responsive">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Odd Name</TableCell>
            <TableCell>Odd Option</TableCell>
            <TableCell>Validate</TableCell>
            <TableCell>Invalidated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableDataOdds !== undefined && tableDataOdds.length > 0 ? (
            tableDataOdds.map((rows, index) => (
              <TableRow key={index} className={classes.tableRowRoot}>
                <TableCell className={classes.tableCellRoot}>{rows.name}</TableCell>
                <OddsOptionCompetition rows={rows} key={index} />
              </TableRow>
            ))
          ) : (
            <TableRow className={classes.tableRowRoot}>
              <TableCell className={classes.tableCellRoot} align="center">
                no Odds
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default OddsCompetition;
