import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getUsers } from 'redux/actions/Users';
import { getMatchContainerHeight, buildSearch } from 'constants/AppConstants';
import AppContext from 'components/contextProvider/AppContextProvider/AppContext';
import { docVer, risk, licenseType } from 'constants/AppConstants';

import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';

import {
  FormControlLabel,
  TablePagination,
  Input,
  Button,
  Box,
  Select,
  MenuItem,
  Switch,
  makeStyles,
} from '@material-ui/core';
import { ArrowUpward, ArrowDownward, Clear } from '@material-ui/icons';

import RecentTable from './RecentTable';

const useStyles = makeStyles(theme => ({
  cardContentRoot: {
    padding: '0 !important',
  },
  titleRoot: {
    letterSpacing: 0.15,
  },
  popupDark: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: '332',
    background: 'rgba(0,0,0,0.3)',
    top: '0',
    left: '0',
  },
  popup: {
    position: 'absolute',
    top: '0',
    right: '0',
    padding: '80px 30px 30px 30px',
    background: '#fff',
    zIndex: '333',
    minWidth: '300px',
    minHeight: '400px',
    height: '100%',
    overflow: 'scroll',
    boxShadow: '0px 1px 3px rgb(0 0 0 / 20%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 1px rgb(0 0 0 / 14%)',
    borderRadius: '4px',
  },
  scrollbarRoot: {
    height: props => `calc(100vh - ${props.height}px - 10px)`,
  },
  badgeRoot: {
    color: theme.palette.common.white,
    borderRadius: 30,
    fontSize: 12,
    padding: '2px 10px',
    display: 'inline-block',
  },
  margin: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const CompetitionApp = ({ width }) => {
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState({});
  const [tableDataList, setTableDataList] = useState({});
  const { usersList } = useSelector(({ users }) => users);

  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const [searchName, setSearchName] = React.useState('');
  const [nameDisabled, setNameDisabled] = React.useState(true);

  const [searchEmail, setSearchEmail] = React.useState('');
  const [emailDisabled, setEmailDisabled] = React.useState(true);

  const [searchPhone, setSearchPhone] = React.useState('');
  const [phoneDisabled, setPhoneDisable] = React.useState(true);

  const [verified, setVerified] = React.useState(false);
  const [verifiedDisabled, setVerifiedDisabled] = React.useState(true);

  const [riskLevel, setRiskLevel] = React.useState(0);
  const [riskLevelDisabled, setRiskLevelDisabled] = React.useState(true);

  const [license, setLicense] = React.useState(0);
  const [licenseDisabled, setLicenseDisabled] = React.useState(true);

  const [docStatus, setDocStatus] = React.useState(0);
  const [docStatusDisabled, setDocStatusDisabled] = React.useState(true);

  const [playedEUR, setPlayedEUR] = React.useState(1);
  const [playedGBP, setPlayedGBP] = React.useState(1);
  const [playedUSD, setPlayedUSD] = React.useState(1);
  const [playedEURDisabled, setPlayedEURDisabled] = React.useState(true);
  const [playedGBPDisabled, setPlayedGBPDisabled] = React.useState(true);
  const [playedUSDDisabled, setPlayedUSDDisabled] = React.useState(true);

  const [walletEUR, setWalletEUR] = React.useState(1);
  const [walletGBP, setWalletGBP] = React.useState(1);
  const [walletUSD, setWalletUSD] = React.useState(1);
  const [walletEURDisabled, setWalletEURDisabled] = React.useState(true);
  const [walletGBPDisabled, setWalletGBPDisabled] = React.useState(true);
  const [walletUSDDisabled, setWalletUSDDisabled] = React.useState(true);

  const [popupActive, setPopupActive] = React.useState(false);

  let searchParams = {
    fullname: searchName,
    email: searchEmail,
    phoneNumber: searchPhone,
    licenseType: license,
    verified: verified,
    riskLevel: riskLevel,
    identityVerificationStatus: docStatus,
    walletAmountEUROrder: walletEUR,
    walletAmountUSDOrder: walletUSD,
    walletAmountGBPOrder: walletGBP,
    totalAmountPlayedEUROrder: playedEUR,
    totalAmountPlayedUSDOrder: playedUSD,
    totalAmountPlayedGBPOrder: playedGBP,
  };

  let searchDisabled = {
    fullname: nameDisabled,
    email: emailDisabled,
    phoneNumber: phoneDisabled,
    licenseType: licenseDisabled,
    verified: verifiedDisabled,
    riskLevel: riskLevelDisabled,
    identityVerificationStatus: docStatusDisabled,
    walletAmountEUROrder: walletEURDisabled,
    walletAmountUSDOrder: walletUSDDisabled,
    walletAmountGBPOrder: walletGBPDisabled,
    totalAmountPlayedEUROrder: playedEURDisabled,
    totalAmountPlayedUSDOrder: playedUSDDisabled,
    totalAmountPlayedGBPOrder: playedGBPDisabled,
  };

  const handleSearch = () => {
    let queryString = buildSearch(searchParams, searchDisabled);
    dispatch(getUsers(0, 50, queryString));
    setPopupActive(false);
  };

  const handleReset = () => {
    setSearchName('');
    setNameDisabled(true);

    setSearchEmail('');
    setEmailDisabled(true);

    setSearchPhone('');
    setPhoneDisable(true);

    setVerified(false);
    setVerifiedDisabled(true);

    setRiskLevel(false);
    setRiskLevelDisabled(true);

    setLicense(0);
    setLicenseDisabled(true);

    setPlayedEUR(1);
    setPlayedGBP(1);
    setPlayedUSD(1);
    setPlayedEURDisabled(true);
    setPlayedGBPDisabled(true);
    setPlayedUSDDisabled(true);

    setWalletEUR(1);
    setWalletGBP(1);
    setWalletUSD(1);
    setWalletEURDisabled(true);
    setWalletGBPDisabled(true);
    setWalletUSDDisabled(true);

    let searchParams = {
      fullname: '',
      email: '',
      phoneNumber: '',
      licenseType: 0,
      verified: false,
      riskLevel: 0,
      identityVerificationStatus: false,
      walletAmountEUROrder: 1,
      walletAmountUSDOrder: 1,
      walletAmountGBPOrder: 1,
      totalAmountPlayedEUROrder: 1,
      totalAmountPlayedUSDOrder: 1,
      totalAmountPlayedGBPOrder: 1,
    };

    let searchDisabled = {
      fullname: true,
      email: true,
      phoneNumber: true,
      licenseType: true,
      verified: true,
      riskLevel: true,
      identityVerificationStatus: true,
      walletAmountEUROrder: true,
      walletAmountUSDOrder: true,
      walletAmountGBPOrder: true,
      totalAmountPlayedEUROrder: true,
      totalAmountPlayedUSDOrder: true,
      totalAmountPlayedGBPOrder: true,
    };
    let queryString = buildSearch(searchParams, searchDisabled);
    dispatch(getUsers(0, 50, queryString));
    setPopupActive(false);
  };

  const classes = useStyles({
    height: getMatchContainerHeight(width, showFooter),
  });

  const handleChangePage = (event, newPage) => {
    let queryString = buildSearch(searchParams, searchDisabled);
    dispatch(getUsers(newPage * rowsPerPage, rowsPerPage, queryString));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    setTableData(tableDataList.slice(page, parseInt(event.target.value)) || {});
  };

  useEffect(() => {
    dispatch(getUsers(page, rowsPerPage, ''));
    if (usersList !== undefined) {
      setTableData(usersList.users);
      setTableDataList(usersList.users);
      setCount(usersList.count[0].count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (usersList !== undefined && usersList.users.length > 0) {
      setTableData(usersList.users);
      setTableDataList(usersList.users);
      setCount(usersList.count[0].count);
    }
    if (usersList !== undefined && usersList.users.length === 0) {
      setTableData(usersList.users);
      setTableDataList(usersList.users);
      setCount(0);
    }
  }, [usersList]);

  return (
    <CmtCard>
      <CmtCardHeader
        className="pt-4"
        title={'Users List'}
        style={{ marginBottom: '30px', zIndex: '444' }}
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}>
        <Button
          variant="contained"
          style={{ height: '100%', marginLeft: '15px', zIndex: '334' }}
          color="primary"
          onClick={() => {
            setPopupActive(!popupActive);
          }}>
          FILTERS
        </Button>
      </CmtCardHeader>
      <CmtCardContent className={classes.cardContentRoot}>
        <PerfectScrollbar className={classes.scrollbarRoot}>
          <RecentTable tableData={tableData} />
        </PerfectScrollbar>
        <TablePagination
          component="div"
          style={{ order: '9999', width: '100%' }}
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CmtCardContent>
      {popupActive === true && (
        <Box className={classes.popupDark}>
          <Box className={classes.popup}>
            <Box>
              <Input
                style={{ marginBottom: '20px' }}
                id="input-with-icon-adornment"
                value={searchName}
                placeholder="Search after name"
                onChange={e => {
                  setSearchName(e.target.value);

                  setNameDisabled(e.target.value.length <= 0);
                }}
              />
            </Box>
            <Box>
              <Input
                style={{ marginBottom: '20px' }}
                id="input-with-icon-adornment"
                value={searchEmail}
                placeholder="Search after email"
                onChange={e => {
                  setSearchEmail(e.target.value.toString());
                  setEmailDisabled(e.target.value.length <= 0);
                }}
              />
            </Box>
            <Box>
              <Input
                style={{ marginBottom: '20px' }}
                id="input-with-icon-adornment"
                value={searchPhone}
                placeholder="Search after phone"
                onChange={e => {
                  if (/^[0-9 +]+$/.test(e.target.value)) {
                    setSearchPhone(e.target.value);
                    setPhoneDisable(e.target.value.length <= 0);
                  }
                }}
              />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                style={{ opacity: verifiedDisabled === true ? 0.5 : 1 }}
                control={
                  <Box>
                    <Switch
                      checked={verified}
                      onChange={e => {
                        setVerified(!verified);
                        setVerifiedDisabled(false);
                      }}
                      name="checkedA"
                    />
                  </Box>
                }
                label="Verified"
              />
              {verifiedDisabled === false && (
                <Clear
                  style={{ color: 'red', cursor: 'pointer' }}
                  onClick={() => {
                    setVerified(verified);
                    setVerifiedDisabled(true);
                  }}
                />
              )}
            </Box>
            <Box>
              <h3 style={{ marginTop: '20px', marginBottom: '10px' }}>Risk Level</h3>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: riskLevelDisabled === true ? 0.5 : 1,
                }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={riskLevel}
                  onChange={e => {
                    setRiskLevel(e.target.value);
                    setRiskLevelDisabled(false);
                  }}>
                  {risk.map((row, key) => (
                    <MenuItem key={key} value={row.id}>
                      {row.label}
                    </MenuItem>
                  ))}
                </Select>
                {riskLevelDisabled === false && (
                  <Clear
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      setRiskLevel(0);
                      setRiskLevelDisabled(true);
                    }}
                  />
                )}
              </Box>
            </Box>
            <Box>
              <h3 style={{ marginTop: '20px', marginBottom: '10px' }}>License Type</h3>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: riskLevelDisabled === true ? 0.5 : 1,
                }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={license}
                  onChange={e => {
                    setLicense(e.target.value);
                    setLicenseDisabled(false);
                  }}>
                  {licenseType.map((row, key) => (
                    <MenuItem key={key} value={row.id}>
                      {row.label}
                    </MenuItem>
                  ))}
                </Select>
                {licenseDisabled === false && (
                  <Clear
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      setLicense(0);
                      setLicenseDisabled(true);
                    }}
                  />
                )}
              </Box>
            </Box>
            <Box>
              <h3 style={{ marginTop: '20px', marginBottom: '10px' }}>Document Status</h3>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: docStatusDisabled === true ? 0.5 : 1,
                }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={docStatus}
                  onChange={e => {
                    setDocStatus(e.target.value);
                    setDocStatusDisabled(false);
                  }}>
                  {docVer.map((row, key) => (
                    <MenuItem key={key} value={row.id}>
                      {row.label}
                    </MenuItem>
                  ))}
                </Select>
                {docStatusDisabled === false && (
                  <Clear
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      setDocStatus(0);
                      setDocStatusDisabled(true);
                    }}
                  />
                )}
              </Box>
            </Box>
            <Box>
              <h3 style={{ marginTop: '20px', marginBottom: '10px' }}>Amount Played</h3>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: playedEURDisabled === true ? 0.5 : 1,
                }}>
                EUR{' '}
                {playedEUR === 1 ? (
                  <ArrowUpward
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedEUR(-1);
                      setPlayedEURDisabled(false);
                    }}
                  />
                ) : (
                  <ArrowDownward
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedEUR(1);
                      setPlayedEURDisabled(false);
                    }}
                  />
                )}
                {playedEURDisabled === false && (
                  <Clear
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      setPlayedEUR(1);
                      setPlayedEURDisabled(true);
                    }}
                  />
                )}
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: playedGBPDisabled === true ? 0.5 : 1,
                }}>
                GBP{' '}
                {playedGBP === 1 ? (
                  <ArrowUpward
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedGBP(-1);
                      setPlayedGBPDisabled(false);
                    }}
                  />
                ) : (
                  <ArrowDownward
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedGBP(1);
                      setPlayedGBPDisabled(false);
                    }}
                  />
                )}
                {playedGBPDisabled === false && (
                  <Clear
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      setPlayedGBP(1);
                      setPlayedGBPDisabled(true);
                    }}
                  />
                )}
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: playedUSDDisabled === true ? 0.5 : 1,
                }}>
                USD{' '}
                {playedUSD === 1 ? (
                  <ArrowUpward
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedUSD(-1);
                      setPlayedUSDDisabled(false);
                    }}
                  />
                ) : (
                  <ArrowDownward
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setPlayedUSD(1);
                      setPlayedUSDDisabled(false);
                    }}
                  />
                )}
                {playedUSDDisabled === false && (
                  <Clear
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      setPlayedUSD(1);
                      setPlayedUSDDisabled(true);
                    }}
                  />
                )}
              </Box>
            </Box>

            <Box>
              <h3 style={{ marginTop: '20px', marginBottom: '10px' }}>Wallet Amount</h3>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: walletEURDisabled === true ? 0.5 : 1,
                }}>
                EUR{' '}
                {walletEUR === 1 ? (
                  <ArrowUpward
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setWalletEUR(-1);
                      setWalletEURDisabled(false);
                    }}
                  />
                ) : (
                  <ArrowDownward
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setWalletEUR(1);
                      setWalletEURDisabled(false);
                    }}
                  />
                )}
                {walletEURDisabled === false && (
                  <Clear
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      setWalletEUR(1);
                      setWalletEURDisabled(true);
                    }}
                  />
                )}
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: walletGBPDisabled === true ? 0.5 : 1,
                }}>
                GBP{' '}
                {walletGBP === 1 ? (
                  <ArrowUpward
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setWalletGBP(-1);
                      setWalletGBPDisabled(false);
                    }}
                  />
                ) : (
                  <ArrowDownward
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setWalletGBP(1);
                      setWalletGBPDisabled(false);
                    }}
                  />
                )}
                {walletGBPDisabled === false && (
                  <Clear
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      setWalletGBP(1);
                      setWalletGBPDisabled(true);
                    }}
                  />
                )}
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: walletUSDDisabled === true ? 0.5 : 1,
                }}>
                USD{' '}
                {walletUSD === 1 ? (
                  <ArrowUpward
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setWalletUSD(-1);
                      setWalletUSDDisabled(false);
                    }}
                  />
                ) : (
                  <ArrowDownward
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      setWalletUSD(1);
                      setWalletUSDDisabled(false);
                    }}
                  />
                )}
                {walletUSDDisabled === false && (
                  <Clear
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => {
                      setWalletUSD(1);
                      setWalletUSDDisabled(true);
                    }}
                  />
                )}
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px',
              }}>
              <Button variant="contained" style={{ height: '100%', marginLeft: '15px' }} onClick={handleReset}>
                RESET
              </Button>
              <Button
                variant="contained"
                style={{ height: '100%', marginLeft: '15px' }}
                color="primary"
                onClick={handleSearch}>
                APPLY
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </CmtCard>
  );
};

export default CompetitionApp;
