import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addUsersPermissions, getUserDetail, patchUser } from 'redux/actions/Users';
import { alpha, Box, Button, IconButton, makeStyles, TableCell, TableRow, Tooltip } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import CmtMediaObject from '@coremat/CmtMediaObject';
import AddUser from './AddUser';

const useStyles = makeStyles(theme => ({
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&:last-child': {
          color: theme.palette.error.main,
        },
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    padding: 16,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.secondary,
    borderBottom: '0 none',
    position: 'relative',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      textAlign: 'right',
      color: theme.palette.error.main,
      paddingRight: 24,
    },
    '&.success': {
      color: theme.palette.success.main,
    },
    '& .Cmt-media-object': {
      alignItems: 'center',
    },
  },
  popup: {
    position: 'absolute',
    top: '50px',
    maxHeight: '800px',
    left: 'calc(50% - 225px)',
    alignSelf: 'center',
    maxWidth: '500px',
    height: '100%',
    overflow: 'scroll',
    '@media screen and (max-width: 480px)': {
      maxWidth: '400px',
      maxHeight: '600px',
    },
  },
}));

const actions = [
  {
    label: 'View User',
    id: 'view',
  },
];

const TableItem = ({ row, onClose }) => {
  const { userDetail } = useSelector(({ users }) => users);
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const editUser = body => {
    dispatch(addUsersPermissions(row._id, body));
  };

  const handleClickOpen = () => {
    setOpen(true);
    dispatch(getUserDetail(row._id));
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <TableRow className={classes.tableRowRoot}>
      <TableCell className={classes.tableCellRoot}>
        <CmtMediaObject
          title={
            <Box>
              <Box>{row.fullname}</Box>
              <Box style={{ opacity: 0.6, fontSize: '0.8em' }}>{row._id}</Box>
            </Box>
          }
          titleProps={{
            variant: 'h5',
            className: classes.titleRoot,
          }}
        />
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ textAlign: 'center' }}>
        {row.username}
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ textAlign: 'center' }}>
        {row.email}
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ textAlign: 'center' }}>
        {row.status === 'blocked' && <span style={{ color: 'red', fontWeight: 'bold' }}>BLOCKED</span>}
        {row.status === 'unblocked' && <span style={{ color: 'green', fontWeight: 'bold' }}>UNBLOCKED</span>}
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          style={{ height: '100%', marginRight: '35', zIndex: '334' }}
          color="primary"
          onClick={() =>
            row.status === 'blocked'
              ? dispatch(patchUser(row._id, { accountBlocked: false }))
              : dispatch(patchUser(row._id, { accountBlocked: true }))
          }>
          {row.status === 'blocked' ? 'UNBLOCK' : 'BLOCK'}
        </Button>
      </TableCell>
      <TableCell className={classes.tableCellRoot} style={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          style={{ height: '100%', marginRight: '35', zIndex: '334' }}
          color="primary"
          onClick={handleClickOpen}>
          Edit
        </Button>
        <AddUser open={open} onClose={handleClose} action={'edit'} editUser={editUser} userDetail={userDetail} />
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <CmtDropdownMenu
            TriggerComponent={
              <Tooltip title="More">
                <IconButton size="small" style={{ marginLeft: 10 }}>
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
            }
            items={actions}
            onItemClick={() => {
              history.push('/user/' + row._id);
            }}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TableItem;
