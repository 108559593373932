import { GET_TRANSLATION, GET_ALL_TRANSLATIONS, SET_DB_FILTERS } from 'constants/ActionTypes';

import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';

export const addTranslation = (body, terms) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post('/languages/terms', JSON.stringify(body))
      .then(data => {
        // eslint-disable-next-line
        const a = Object.keys(terms)?.forEach(key => {
          let term = {
            translation: terms[key].text,
            languageId: terms[key].id,
          };
          axios
            .post(`/languages/terms/${data.data._id}/translations`, JSON.stringify(term))
            .then(data => {})
            .catch(error => {
              dispatch(fetchError('Something went wrong'));
            });
        });
        dispatch(fetchSuccess());
        dispatch(fetchError('TRANSLATION ADDED'));
        setTimeout(() => {
          window.location.href = '/db-translations';
        }, 200);
      })
      .catch(error => {
        if (error.response?.status === 400) {
          dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
        } else if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const getAllTranslations = filters => {
  return dispatch => {
    if (!filters.searchQuery) {
      let url = `languages/terms?`;
      if (filters.offset || filters.limit) url += `offset=${filters.offset}&limit=${filters.limit}&`;
      if (filters.updatedBy) url += `last_update_by=${filters.updatedBy._id}&`;
      if (filters.addedBy) url += `added_by=${filters.addedBy._id}&`;
      if (filters.status) url += `status=${filters.status.type}&`;
      if (filters.addedDateSort) url += `date_added=${filters.addedDateSort}`;
      if (filters.updatedDateSort) url += `last_update=${filters.updatedDateSort}`;
      dispatch(fetchStart());
      axios
        .get(url)
        .then(data => {
          dispatch(fetchStart());
          dispatch({ type: GET_ALL_TRANSLATIONS, payload: data.data });
          setTimeout(() => {
            dispatch(fetchSuccess());
          }, 350);
        })
        .catch(error => {
          if (error.response?.status === 400) {
            dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
          } else if (error.response?.status !== 401) {
            dispatch(fetchError('Something went wrong'));
          }
        });
    } else {
      let url = `languages/search/${filters.searchQuery}?`;
      if (filters.offset || filters.limit) url += `offset=${filters.offset}&limit=${filters.limit}`;
      dispatch(fetchStart());
      axios
        .get(url)
        .then(data => {
          dispatch(fetchStart());
          dispatch({ type: GET_ALL_TRANSLATIONS, payload: data.data });
          setTimeout(() => {
            dispatch(fetchSuccess());
          }, 350);
        })
        .catch(error => {
          if (error.response?.status === 400) {
            dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
          } else if (error.response?.status !== 401) {
            dispatch(fetchError('Something went wrong'));
          }
        });
    }
  };
};

export const getTranslation = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .get(`/languages/terms/${id}/translations`)
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_TRANSLATION, payload: data.data });
      })
      .catch(error => {
        if (error.response?.status === 400) {
          dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
        } else if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const updateTranslation = body => {
  return dispatch => {
    dispatch(fetchStart());
    if (body) {
      axios
        .patch(`/languages/terms`, JSON.stringify(body))
        .then(data => {
          dispatch(fetchError('TRANSLATION UPDATED'));
          setTimeout(() => {
            window.location.href = '/db-translations';
          }, 200);
        })
        .catch(error => {
          if (error.response?.status === 400) {
            dispatch(fetchError(error.response?.data?.message || "Term details couldn'n been updated"));
          } else if (error.response?.status !== 401) {
            dispatch(fetchError("Term details couldn'n been updated"));
          }
        });
    }

    dispatch(fetchSuccess());
  };
};

export const createLanguage = (termId, body) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .post(`/languages/terms/${termId}/translations`, JSON.stringify(body))
      .then(data => {
        dispatch(fetchSuccess());
      })
      .catch(error => {
        if (error.response?.status === 400) {
          dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
        } else if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const updateLanguage = (termId, body) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .patch(`/languages/terms/${termId}/translations`, JSON.stringify(body))
      .then(data => {
        dispatch(fetchSuccess());
      })
      .catch(error => {
        if (error.response?.status === 400) {
          dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
        } else if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const deleteTranslation = id => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(`/languages/terms/${id}`)
      .then(data => {
        dispatch(fetchSuccess());
        setTimeout(() => {
          window.location.href = '/db-translations';
        }, 200);
        dispatch(fetchError('TERM DELETED'));
      })
      .catch(error => {
        if (error.response?.status === 400) {
          dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
        } else if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const deleteLanguage = (termId, translationId, body) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete(`/languages/terms/${termId}/translations/${translationId}`, JSON.stringify(body))
      .then(data => {
        dispatch(fetchSuccess());
        setTimeout(() => {
          dispatch(fetchError('Language deleted'));
        }, 200);
      })
      .catch(error => {
        if (error.response?.status === 400) {
          dispatch(fetchError(error.response?.data?.message || 'Something went wrong'));
        } else if (error.response?.status !== 401) {
          dispatch(fetchError('Something went wrong'));
        }
      });
  };
};

export const setDBFilters = filters => {
  return dispatch => {
    dispatch({ type: SET_DB_FILTERS, payload: filters });
  };
};
