import React, { useEffect, useState } from 'react';
import PlayerDetail from './PlayerDetail';

const Players = ({ teamPlayers, team, selectTeam, teamId, positions, matchDetail }) => {
  const [players, setPlayers] = useState(teamPlayers || {});

  useEffect(() => {
    if (teamPlayers.length !== undefined && teamPlayers.length > 0) {
      setPlayers(teamPlayers.filter((item, key) => item.fullname !== 'Other'));
    }
  }, [teamPlayers]);

  return (
    players.length > 0 &&
    players.map(player => {
      return (
        <PlayerDetail
          key={player._id}
          playerDetail={player}
          selectTeam={selectTeam}
          matchDetail={matchDetail}
          positions={positions}
          team={team}
          teamId={teamId}
        />
      );
    })
  );
};

export default Players;
