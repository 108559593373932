import React, { useContext, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { getMatchContainerHeight } from 'constants/AppConstants';
import AppContext from 'components/contextProvider/AppContextProvider/AppContext';

import { Box, Button, Table, TableBody, TablePagination } from '@material-ui/core';

import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';

import AddIcon from '@material-ui/icons/Add';
import TableHeading from 'components/Table/TableHeading';
import TableHeader from 'assets/global/globalTableHeadingData';
import TableItem from './TableItem';

import useStyles from 'components/Table/table.styles';
import { getEcosystems } from 'redux/actions/Ecosystems';
import { useHistory } from 'react-router-dom';
import { ECOSYSTEMS } from '../../../constants/ActionTypes';

const UsersAdmin = () => {
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();

  const { Ecosystems } = useSelector(({ Ecosystems }) => Ecosystems);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const classes = useStyles({
    height: getMatchContainerHeight(showFooter),
  });

  useEffect(() => {
    if (Ecosystems?.count) setCount(Ecosystems?.count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Ecosystems]);

  useEffect(() => {
    dispatch(getEcosystems(page, rowsPerPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const history = useHistory();

  return (
    <CmtCard>
      <CmtCardHeader
        className="pt-4"
        title={'Ecosystems'}
        style={{ marginBottom: '30px', zIndex: '444' }}
        titleProps={{
          variant: 'h1',
          component: 'div',
          className: classes.titleRoot,
        }}>
        <Button
          variant="contained"
          style={{ height: '100%', marginLeft: '15px', zIndex: '334' }}
          color="primary"
          onClick={() => {
            history.push('/ecosystem/new');
            dispatch({
              type: ECOSYSTEMS,
              payload: undefined,
            });
          }}>
          <AddIcon style={{ marginRight: '5px' }} />
          Create Ecosystems
        </Button>
      </CmtCardHeader>
      <CmtCardContent className={classes.cardContentRoot}>
        <PerfectScrollbar className={classes.scrollbarRoot}>
          <Box className="Cmt-table-responsive">
            {Ecosystems && Ecosystems.length > 0 ? (
              <Table>
                <TableHeading headingData={TableHeader.Ecosystems} />
                <TableBody>
                  {Ecosystems.map(template => (
                    <TableItem row={template} key={template._id} />
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Box className={classes.flexCenter}>No ecosystem templates found</Box>
            )}
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          style={{ order: '9999', width: '100%' }}
          count={count || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default UsersAdmin;
