import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchError } from 'redux/actions';
import { GET_OFFICIAL_CHAMPIONSHIPS_DETAILS } from 'constants/ActionTypes';
import { createOfficialChampionship } from 'redux/actions/OfficialChampionship';
import {
  getLangForOfficialChampionship,
  patchOfficialChampionshipDetail,
  getOfficialChampionshipDetail,
} from 'redux/actions/OfficialChampionship';
import OutrightsOfficial from './OutrightsOfficial';

import { Box } from '@material-ui/core';
import useStyles from './index.style';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';

import ChampionshipDetails from './ChampionshipDetails';
import Prizes from './Prizes';
import Questions from './Questions';
import Matches from './Matches';
import Countries from './Countries';
import GenerateInviteLink from './GenerateInviteLink';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ListVIew from '../OfficialChampionshipList/ListVIew';
import { getSelectionDetails, getSelections } from 'redux/actions/Selections';
import { selectBetTemplate } from 'redux/actions/OfficialChampionship';
const countryList = require('country-list');

const OfficialChampionship = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    lang,
    officialChampionshipMatches,
    officialChampionshipDetail,
    successCreate,
    selectedBetTemplate,
    customFinalBettingDatesOutrights,
    individualBetTemplates,
  } = useSelector(({ officialChampionship }) => officialChampionship);
  const { challengeMatches } = useSelector(({ challenges }) => challenges);
  const { challengeOutrights } = useSelector(({ challenges }) => challenges);
  const { selectionDetails } = useSelector(({ selections }) => selections);
  const { availableSports } = useSelector(({ common }) => common);

  const countries = countryList.getData();

  const [viewDetail, setViewDetail] = useState(officialChampionshipDetail);
  const [langChamp, setLangChamp] = useState(
    lang !== undefined
      ? () => {
          let languageArray = [];
          lang.forEach(item => {
            languageArray.push(item.code);
          });
          return languageArray;
        }
      : [],
  );

  const [created, setCreated] = useState(false);
  const [countriesCurrentList, setCountriesCurrentList] = useState(undefined);
  const [countriesInvitedList, setCountriesInvitedList] = useState(undefined);
  const [challengeId, setChallengeId] = useState(null);

  const [logo, setLogo] = useState();
  const [cover, setCover] = useState();
  const [name, setName] = useState();
  const [terms, setTerms] = useState();
  const [customEndDate, setCustomEndDate] = useState();
  const [stake, setStake] = useState();
  const [stakeType, setStakeType] = useState(0);
  const [rankingFilterType, setRankingFilterType] = useState([]);
  const [availableRankingFilterType, setAvailableRankingFilterType] = useState({});
  const [questions, setQuestions] = useState([]);

  const [daily, setDaily] = useState(0);
  const [weekly, setWeekly] = useState(0);
  const [allTime, setAllTime] = useState(1);
  const [qr, setQr] = useState(false);
  const [isB2B, setIsB2B] = useState(false);
  const [B2BchallengeType, setB2BchallengeType] = useState(null);

  const [prizes, setPrizes] = useState([]);
  const [uuidPhotos, setUuidPhotos] = useState([]);
  const [howItWorks, setHowItWorks] = useState([]);

  const [open, setOpen] = React.useState(false);

  const [championshipSelection, setChampionshipSelection] = useState();
  const [invitedCountries, setInvitedCountries] = useState([]);
  const [allowedCountries, setAllowedCountries] = useState([]);
  const [initialGlobalBetTemplates, setInitialGlobalBetTemplates] = useState([]);
  const [lastEvent, setLastEvent] = useState({ event: null, type: undefined });
  const [order, setOrder] = useState('');
  const [showTCScreen, setShowTCScreen] = useState(false);
  const [showChallengeTC, setShowChallengeTC] = useState(false);
  const [showChallengeInfo, setShowChallengeInfo] = useState(false);
  const [showPrizes, setShowPrizes] = useState(false);
  const [regulationURI, setRegulationURI] = useState('');

  useEffect(() => {
    dispatch(getLangForOfficialChampionship());
    if (id !== 'new') {
      dispatch(getOfficialChampionshipDetail(id));
    }
    dispatch(getSelections(-1, -1, null, 0, -1, 1));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (id !== 'new' && viewDetail?.betTemplates?.length) {
      setInitialGlobalBetTemplates(viewDetail?.betTemplates.map(object => ({ ...object })));
      dispatch(selectBetTemplate(viewDetail?.betTemplates.map(object => ({ ...object }))));
    }
    setOrder(viewDetail?.officialChallengeFeedOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewDetail?._id]);

  useEffect(() => {
    if (id !== 'new' && viewDetail?.yeppSelectionId) {
      dispatch(getSelectionDetails(viewDetail?.yeppSelectionId));
    }
    if (id === 'new' || !viewDetail?.yeppSelectionId) setChampionshipSelection(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewDetail, viewDetail?.yeppSelectionId, id]);

  useEffect(() => {
    setChampionshipSelection(selectionDetails);
    if (id === 'new') setChampionshipSelection(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionDetails?._id]);

  useEffect(() => {
    if (created === true) {
      dispatch(fetchError('Official championship created.'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successCreate]);

  useEffect(() => {
    let langCodes = [];
    if (lang !== undefined) {
      lang.forEach((item, key) => {
        langCodes.push(item.code);
      });
    }
    setLangChamp(langCodes);
    setViewDetail(officialChampionshipDetail);
    if (officialChampionshipDetail !== undefined) {
      setCountriesCurrentList(officialChampionshipDetail.allowedCountries);
      setCountriesInvitedList(officialChampionshipDetail.invitedCountries);
    }
  }, [officialChampionshipMatches, officialChampionshipDetail, lang]);

  const removeChampionship = id => {
    setOpen(true);
  };

  useEffect(() => {
    if (viewDetail !== undefined) {
      setLogo(viewDetail.logo);
      setCover(viewDetail.cover);
      setName(viewDetail.name);
      setTerms(viewDetail.challengeInfo);
      setCustomEndDate(viewDetail.customEndDate);
      setStake(viewDetail.stake);
      setIsB2B(viewDetail.isExternalType);
      setB2BchallengeType(viewDetail.externalType);

      setChallengeId(id);

      let newQuestionsList = [];
      viewDetail.questions.forEach((item, key) => {
        newQuestionsList.push({ ...item, deleted: false });
      });
      setQuestions(newQuestionsList);

      let newPrizesList = [];
      viewDetail.prizes.forEach((item, key) => {
        newPrizesList.push({ ...item, deleted: false });
      });
      setPrizes(newPrizesList);
      setHowItWorks(viewDetail.howItWorks);
      const allValues = [
        { id: 0, value: false, label: 'All time', transKey: 'all_time', type: 0 },
        { id: 1, value: false, label: 'Weekly', transKey: 'weekly', type: 1 },
        { id: 2, value: false, label: 'Daily', transKey: 'daily', type: 2 },
        { id: 3, value: false, label: 'Monthly', transKey: 'monthly', type: 3 },
      ];

      const mapRankings = viewDetail?.availableRankingFilterTypes?.map(value => {
        const index = allValues?.findIndex(item => item.transKey === value.transKey);

        if (index >= 0) allValues[index].value = true;
      });

      setRankingFilterType(
        allValues?.length
          ? allValues
          : [
              { id: 0, value: false, label: 'All time', transKey: 'all_time', type: 0 },
              { id: 1, value: false, label: 'Weekly', transKey: 'weekly', type: 1 },
              { id: 2, value: false, label: 'Daily', transKey: 'daily', type: 2 },
              { id: 3, value: false, label: 'Monthly', transKey: 'monthly', type: 3 },
            ],
      );
      let rankingFilter;
      if (viewDetail?.rankingFilterType === 0) {
        rankingFilter = { id: 0, value: true, label: 'All time', transKey: 'all_time', type: 0 };
      }
      if (viewDetail?.rankingFilterType === 1) {
        rankingFilter = { id: 1, value: true, label: 'Weekly', transKey: 'weekly', type: 1 };
      }
      if (viewDetail?.rankingFilterType === 2) {
        rankingFilter = { id: 2, value: true, label: 'Daily', transKey: 'daily', type: 2 };
      }
      if (viewDetail?.rankingFilterType === 3) {
        rankingFilter = { id: 3, value: true, label: 'Monthly', transKey: 'monthly', type: 3 };
      }
      setAvailableRankingFilterType(rankingFilter);
      setRegulationURI(viewDetail?.regulationUri);
      setShowTCScreen(viewDetail?.showTCScreen);
      setShowChallengeTC(viewDetail?.showChallengeTC);
      setShowChallengeInfo(viewDetail?.showChallengeInfo);
      setShowPrizes(viewDetail?.showPrizes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewDetail, challengeMatches, challengeOutrights]);

  const createNotification = (type, message) => {
    return NotificationManager.error(message, 'ERROR!', 2000);
  };

  const getPrizes = (getPrizesList, uuidList) => {
    setPrizes(getPrizesList);
    setUuidPhotos(uuidList);
  };

  const getHowItWorks = data => {
    setHowItWorks(data);
  };

  const getLogo = logo => {
    setLogo(logo);
  };

  const getCover = cover => {
    setCover(cover);
  };

  const getQuestions = question => {
    setQuestions(question);
  };

  const getName = newName => {
    setName(newName);
  };

  const getStake = newStake => {
    setStake(newStake);
  };

  const getStakeType = newStakeType => {
    setStakeType(newStakeType);
  };

  const getIsB2B = newIsB2B => {
    setIsB2B(newIsB2B);
  };
  const getB2BchallengeType = newB2BchallengeType => {
    console.log('get', newB2BchallengeType, B2BchallengeType);
    setB2BchallengeType(newB2BchallengeType);
    console.log('get - set', B2BchallengeType);
  };
  useEffect(() => {
    console.log('get - set2', B2BchallengeType);
    setB2BchallengeType(B2BchallengeType);
  }, [B2BchallengeType]);

  const getRankingFilterType = newRankingFilterType => {
    setRankingFilterType(newRankingFilterType);
  };

  const getAvailableRankingFilterType = newRankingFilterType => {
    setAvailableRankingFilterType(newRankingFilterType);
  };

  const getCountries = (allowedCountries, invitedCountries) => {
    const formattedAddedCountries = allowedCountries?.map(item => item.code);
    const formattedInvitedCountries = invitedCountries?.map(item => item.code);
    if (id === 'new') {
      setAllowedCountries(formattedAddedCountries);
      setInvitedCountries(formattedInvitedCountries);
    } else {
      const filteredFormattedAddedCountries = formattedAddedCountries?.filter(item => !countriesCurrentList.includes(item));
      const filteredFormattedInvitedCountries = formattedInvitedCountries?.filter(
        item => !countriesInvitedList.includes(item),
      );
      setAllowedCountries(filteredFormattedAddedCountries);
      setInvitedCountries(filteredFormattedInvitedCountries);
    }
  };

  const getDaily = newDaily => {
    setDaily(Number(newDaily));
  };

  const getWeekly = newWeekly => {
    setWeekly(Number(newWeekly));
  };

  const getAllTime = newAllTime => {
    setAllTime(Number(newAllTime));
  };

  const getQr = newQr => {
    setQr(newQr);
  };

  const getTerms = newTerms => {
    setTerms(newTerms);
  };

  const getCustomEndDate = newCustomEndDate => {
    setCustomEndDate(newCustomEndDate);
  };

  const checkMatchesDateAfterCustomEndDate = () => {
    let selectionHasMatchesAfterCustomEndDate = false;
    let selectionHasOutrightsAfterCustomEndDate = false;
    let lastMatch = undefined;
    let lastOutright = undefined;
    if (customEndDate && championshipSelection?._id !== viewDetail?.yeppSelectionId) {
      // eslint-disable-next-line
      const a = championshipSelection?.games.forEach(match => {
        let date1 = new Date(match.scheduledDate).getTime();
        let date2 = new Date(customEndDate).getTime();
        let date3 = new Date(lastMatch?.scheduledDate).getTime();
        if (date1 > date2) selectionHasMatchesAfterCustomEndDate = true;
        if (!lastMatch) lastMatch = match;
        if (date3 < date1 && date1 > date2) lastMatch = match;
      });
      // eslint-disable-next-line
      const b = championshipSelection?.competitions.forEach(competition => {
        let date1 = new Date(competition.competition.endTime).getTime();
        let date2 = new Date(customEndDate).getTime();
        let date3 = new Date(lastOutright?.competition.endTime).getTime();
        if (date1 > date2) selectionHasOutrightsAfterCustomEndDate = true;
        if (!lastOutright) lastOutright = competition;
        if (date3 < date1 && date1 > date2) lastOutright = competition;
      });
    } else if (customEndDate) {
      // eslint-disable-next-line
      const a = championshipSelection?.games.forEach(match => {
        let date1 = new Date(match.scheduledDate).getTime();
        let date2 = new Date(customEndDate).getTime();
        let date3 = new Date(lastMatch?.scheduledDate).getTime();
        if (date1 > date2) selectionHasMatchesAfterCustomEndDate = true;
        if (!lastMatch) lastMatch = match;
        if (date3 < date1 && date1 > date2) lastMatch = match;
      });
      // eslint-disable-next-line
      const b = championshipSelection?.competitions.forEach(competition => {
        let date1 = new Date(competition.competition.endTime).getTime();
        let date2 = new Date(customEndDate).getTime();
        let date3 = new Date(lastOutright?.competition.endTime).getTime();
        if (date1 > date2) selectionHasOutrightsAfterCustomEndDate = true;
        if (!lastOutright) lastOutright = competition;
        if (date3 < date1 && date1 > date2) lastOutright = competition;
      });
    }

    if (selectionHasMatchesAfterCustomEndDate || selectionHasOutrightsAfterCustomEndDate) {
      setOpen(true);
      const lastMatchDate = new Date(lastMatch?.scheduledDate).getTime();
      const lastOutrightDate = new Date(lastOutright?.competition?.endTime).getTime();
      if (lastMatchDate < lastOutrightDate) {
        setLastEvent({ event: lastOutright, type: 'outright' });
      } else {
        setLastEvent({ event: lastMatch, type: 'match' });
      }
    }

    return { status: selectionHasMatchesAfterCustomEndDate || selectionHasOutrightsAfterCustomEndDate };
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    const { status } = checkMatchesDateAfterCustomEndDate();
    if (status) {
      if (lastEvent.type === 'outright') {
        setCustomEndDate(lastEvent?.event.competition?.endTime);
      } else if (lastEvent.type === 'match') {
        setCustomEndDate(lastEvent?.event.scheduledDate);
      }
      setOpen(false);
    }
  };

  const create = async type => {
    let nameEmpty = false;
    let stakeEmpty = false;
    let logoEmpty = false;
    let coverEmpty = false;
    let prizeEmpty = false;
    let questionEmpty = false;
    let howItWorksEmpty = false;
    let betTemplatesEmpty = false;
    let stakeTypeEmpty = false;
    let rankingFilterTypeEmpty = false;
    let termsEmpty = false;
    let availableRankingFilterTypeEmpty = false;
    let orderEmpty = false;
    let selectionIsEmpty = false;
    let hasError = false;

    if (howItWorks.length === 0) {
      createNotification('error', 'How it works should not be empty');
      howItWorksEmpty = true;
    } else {
      howItWorks.forEach((item, key) => {
        if (item.text.length === 0) {
          howItWorksEmpty = true;
        }
      });
      if (howItWorksEmpty === true) {
        createNotification('error', 'How it works should not be empty');
      }
    }
    if (name === undefined || name.length === 0) {
      createNotification('error', 'Championship Name should not be empty');
      nameEmpty = true;
    }

    if (stake === undefined || stake.length === 0) {
      createNotification('error', 'Championship Stake should not be empty');
      stakeEmpty = true;
    }

    if (!order) {
      createNotification('error', 'Championship order should not be empty');
      stakeEmpty = true;
    }

    if (logo === undefined) {
      createNotification('error', 'Championship Logo should not be empty');
      logoEmpty = true;
    }

    if (cover === undefined) {
      createNotification('error', 'Championship Cover should not be empty');
      coverEmpty = true;
    }

    if (prizes.length === 0) {
      createNotification('error', 'Prizes should not be empty');
      prizeEmpty = true;
    }

    if (prizes.length === 1) {
      if (prizes[0].deleted === true) {
        createNotification('error', 'Prizes should not be empty');
        prizeEmpty = true;
      }
    }

    if (terms === undefined || terms.length === 0) {
      createNotification('error', 'Championship TS & Cs should not be empty');
      termsEmpty = true;
    }

    if (rankingFilterType === undefined || rankingFilterType.length === 0) {
      createNotification('error', 'A ranking filter type needs to be selected');
      rankingFilterTypeEmpty = true;
    }

    if (availableRankingFilterType === undefined || availableRankingFilterType.length === 0) {
      createNotification('error', 'A available filter type needs to be selected');
      availableRankingFilterTypeEmpty = true;
    }

    if (stakeType === undefined) {
      createNotification('error', 'A stake type needs to be selected');
      stakeTypeEmpty = true;
    }

    if (selectedBetTemplate?.length !== availableSports?.length) {
      createNotification('error', 'Please complete the global bet template for all sports');
      betTemplatesEmpty = true;
    }

    if (id === 'new' && !championshipSelection?._id) {
      createNotification('error', 'Please select a selection');
      selectionIsEmpty = true;
    }

    if (
      type === 'update' &&
      (!championshipSelection?.games || championshipSelection?.games.length === 0) &&
      (!championshipSelection?.competitions || championshipSelection?.competitions.length === 0)
    ) {
      createNotification('error', 'You can’t update a championship with no matches or specials');
      return;
    }

    const { status } = checkMatchesDateAfterCustomEndDate();

    if (
      howItWorksEmpty !== true &&
      nameEmpty !== true &&
      stakeEmpty !== true &&
      logoEmpty !== true &&
      coverEmpty !== true &&
      prizeEmpty !== true &&
      questionEmpty !== true &&
      betTemplatesEmpty !== true &&
      stakeTypeEmpty !== true &&
      rankingFilterTypeEmpty !== true &&
      availableRankingFilterTypeEmpty !== true &&
      termsEmpty !== true &&
      status === false &&
      orderEmpty === false &&
      selectionIsEmpty === false
    ) {
      let prizeTypesCount = {
        daily: daily,
        weekly: weekly,
        allTime: allTime,
      };
      let formData = new FormData();
      if (challengeId !== null) {
        formData.append('challengeId', challengeId);
      }
      formData.append('name', name);
      formData.append('stake', stake);
      if (id === 'new') formData.append('stakeType', stakeType);

      const filteredRanking = rankingFilterType.filter(ranking => ranking.value);
      formData.append('rankingFilterType', availableRankingFilterType.type);
      formData.append('availableRankingFilterTypes', JSON.stringify(filteredRanking.map(item => item.type)));

      if (typeof logo !== 'string') formData.append('logo', logo);

      formData.append('cover', cover);
      formData.append('prizeTypesCounts', JSON.stringify(prizeTypesCount));
      formData.append('qrCodeEligible', qr);
      formData.append('isExternalType', isB2B);
      if (isB2B) formData.append('externalType', B2BchallengeType);
      formData.append('howItWorks', JSON.stringify(howItWorks));
      formData.append('questions', JSON.stringify(questions));
      formData.append('challengeInfo', terms);
      formData.append('officialChallengeFeedOrder', order);
      formData.append('showTCScreen', showTCScreen);
      formData.append('showChallengeTC', showChallengeTC);
      formData.append('showChallengeInfo', showChallengeInfo);
      formData.append('showPrizes', showPrizes);
      formData.append('regulationUri', regulationURI);
      formData.append('yeppSelectionId', championshipSelection?._id);

      if (allowedCountries.length) {
        if (id === 'new') formData.append('allowedCountries', JSON.stringify(allowedCountries));
        else formData.append('allowedCountriesToInsert', JSON.stringify(allowedCountries));
      }
      if (invitedCountries.length > 0) {
        if (id === 'new') {
          formData.append('invitedCountries', JSON.stringify(invitedCountries));
        } else {
          formData.append('invitedCountriesToInsert', JSON.stringify(invitedCountries));
        }
      }

      if ((id === 'new' && customEndDate) || (id !== 'new' && customEndDate)) {
        formData.append('customEndDate', customEndDate);
      }

      if (id === 'new') {
        const challengeOutrights = championshipSelection?.competitions?.map(item => {
          return {
            competitionId: item?.competitionId,
            oddTypeId: item.oddTypeId,
            finalBettingDate: customFinalBettingDatesOutrights[item?._id]?.finalBettingDate
              ? customFinalBettingDatesOutrights[item?._id]?.finalBettingDate
              : item?.betLimitDate || item?.endDate,
          };
        });

        const challengeGames = championshipSelection?.games?.map(item => {
          return {
            gameId: item._id,
            betTemplateId: individualBetTemplates[item._id]?.betTemplateId
              ? individualBetTemplates[item._id]?.betTemplateId
              : selectedBetTemplate.filter(betTemplate => betTemplate.sportId === item.sportId)[0]?.betTemplateId,
          };
        });

        formData.append('challengeOutrights', JSON.stringify(challengeOutrights));
        formData.append('challengeGames', JSON.stringify(challengeGames));
        formData.append('challengeCompetitionsFacts', JSON.stringify([])); // se va modifica ulterior
      }

      // UPDATE CUSTOM END DATE FOR OUTRIGHTS
      if (id !== 'new') {
        let challengeOutrights = [];
        Object.keys(customFinalBettingDatesOutrights).forEach(key => {
          challengeOutrights.push(customFinalBettingDatesOutrights[key]);
        });
        if (challengeOutrights.length > 0) {
          formData.append('challengeOutrightsToUpdate', JSON.stringify(challengeOutrights));
        }
      }

      // UPDATE - INDIVIDUAL BET TEMPLATE AND GLOBAL WHEN YEPP SELECTION IS NOT CHANGED
      let updatedGlobalBetTemplates = [];
      if (selectedBetTemplate?.length > 0) {
        selectedBetTemplate.forEach((item, index) => {
          if (item.betTemplateId !== initialGlobalBetTemplates[index]?.betTemplateId) updatedGlobalBetTemplates.push(item);
        });
      }

      if (id === 'new' && selectedBetTemplate?.length > 0)
        formData.append('betTemplates', JSON.stringify(selectedBetTemplate));
      if (id !== 'new' && updatedGlobalBetTemplates?.length > 0)
        formData.append('betTemplates', JSON.stringify(selectedBetTemplate));

      if (id !== 'new' && championshipSelection?._id?.toString() === viewDetail?.yeppSelectionId?.toString()) {
        // CHECK IF GLOBAL BET TEMPLATES HAVE BEEN UPDATED
        if (updatedGlobalBetTemplates?.length === 0) {
          // ONLY INDIVIDUAL BET TEMPLATES
          if (Object.keys(individualBetTemplates)?.length) {
            let challengeGames = [];
            Object.keys(individualBetTemplates).forEach(key => {
              challengeGames.push(individualBetTemplates[key]);
            });
            formData.append('challengeGamesToUpdate', JSON.stringify(challengeGames));
          }
        } else {
          // GLOBAL AND INDIVIDUAL BET TEMPLATES
          const sportsFromBetTemplates = updatedGlobalBetTemplates?.map(item => item.sportId);
          const gamesIdsFromIndividual = Object.keys(individualBetTemplates);
          const championshipMatchesToUpdate = challengeMatches?.filter(
            item =>
              sportsFromBetTemplates?.includes(item.game.sportId) &&
              item.game.status === 'Pending' &&
              !item.game.userBets?.length &&
              !gamesIdsFromIndividual?.includes(item.gameId),
          );

          let challengeGames = championshipMatchesToUpdate?.map(item => {
            return {
              gameId: item.gameId,
              betTemplateId: updatedGlobalBetTemplates?.filter(betTemplate => betTemplate.sportId === item.game.sportId)[0]
                ? updatedGlobalBetTemplates?.filter(betTemplate => betTemplate.sportId === item.game.sportId)[0]
                    ?.betTemplateId
                : undefined,
            };
          });
          gamesIdsFromIndividual.forEach(key => {
            challengeGames.push(individualBetTemplates[key]);
          });
          if (challengeGames?.length > 0) formData.append('challengeGamesToUpdate', JSON.stringify(challengeGames));
        }
      } else if (id !== 'new' && championshipSelection?._id?.toString() !== viewDetail?.yeppSelectionId?.toString()) {
        // THE SELECTION HAS BEEN CHANGED

        const gamesIdsFromIndividual = Object.keys(individualBetTemplates);
        const filteredMatches = championshipSelection?.games?.filter(
          item => item.status !== 'Ended' && !gamesIdsFromIndividual?.includes(item._id),
        );

        let challengeGames = filteredMatches?.map(item => {
          return {
            gameId: item._id,
            betTemplateId: selectedBetTemplate.filter(betTemplate => betTemplate.sportId === item.sportId)[0]?.betTemplateId,
          };
        });
        if (gamesIdsFromIndividual?.length > 0)
          gamesIdsFromIndividual.forEach(key => {
            challengeGames.push(individualBetTemplates[key]);
          });
        if (challengeGames?.length > 0) formData.append('challengeGamesToInsert', JSON.stringify(challengeGames));

        const challengeOutrights = championshipSelection?.competitions?.map(item => {
          return {
            competitionId: item?.competitionId,
            oddTypeId: item.oddTypeId,
            finalBettingDate: customFinalBettingDatesOutrights[item?._id]?.finalBettingDate
              ? customFinalBettingDatesOutrights[item?._id]?.finalBettingDate
              : championshipSelection?.endDate,
          };
        });

        if (challengeOutrights?.length > 0)
          formData.append('challengeOutrightsToInsert', JSON.stringify(challengeOutrights));
      }

      if (id === 'new') {
        // prizes
        uuidPhotos.forEach(item => {
          let fileName = Object.keys(item);
          const blob = item[fileName[0]].slice(0, item[fileName[0]].size, item[fileName[0]].type);
          const newFile = new File([blob], `${fileName[0]}`, { type: item[fileName[0]].type });
          formData.append('prizesPhotos', newFile);
        });

        const newPrizes = prizes.map(({ photo: photoUUID, ...rest }) => ({
          ...rest,
          photoUUID,
        }));
        formData.append('prizes', JSON.stringify(newPrizes));
      } else {
        if (!Array.isArray(uuidPhotos[0])) {
          uuidPhotos.forEach(item => {
            let fileName = Object.keys(item);
            const blob = item[fileName[0]]?.slice(0, item[fileName[0]].size, item[fileName[0]]?.type);
            if (typeof blob === 'string') {
              formData.append('prizesPhotos', blob);
            } else {
              const newFile = new File([blob], `${fileName[0]}`, { type: item[fileName[0]]?.type });
              formData.append('prizesPhotos', newFile);
            }
          });
        }

        const updatedPrizes = prizes.map(p => ({ ...p, photoUUID: p.uuid ? p.uuid : p.photo }));
        formData.append('prizes', JSON.stringify(updatedPrizes));
      }

      if (type === 'update') {
        await dispatch(patchOfficialChampionshipDetail(formData));
      } else {
        setCreated(true);
        await dispatch(createOfficialChampionship(formData));
      }
    }
  };

  return (
    <>
      <CmtCard>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <CmtCardHeader
            className="pt-4"
            icon={
              <ArrowBackIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push('/official-championships');
                  dispatch({
                    type: GET_OFFICIAL_CHAMPIONSHIPS_DETAILS,
                    payload: undefined,
                  });
                }}
              />
            }
            title={viewDetail !== undefined ? 'Edit Official Championship' : 'Create Official Championship'}
            titleProps={{
              variant: 'h1',
              component: 'div',
              className: classes.titleRoot,
            }}
          />
          {id !== 'new' && <GenerateInviteLink />}
        </div>
        <CmtCardContent className={classes.cardContentRoot}>
          {langChamp !== undefined && (
            <ChampionshipDetails
              langList={langChamp || {}}
              viewDetail={viewDetail}
              sendDaily={getDaily}
              sendWeekly={getWeekly}
              sendAllTime={getAllTime}
              sendQr={getQr}
              sendName={getName}
              sendStake={getStake}
              sendIsB2B={getIsB2B}
              sendB2BchallengeType={getB2BchallengeType}
              sendStakeType={getStakeType}
              sendLogo={getLogo}
              sendCover={getCover}
              getHowItWorks={getHowItWorks}
              sendRankingFilterType={getRankingFilterType}
              sendAvailableRankingFilterType={getAvailableRankingFilterType}
              sendTerms={getTerms}
              sendCustomEndDate={getCustomEndDate}
              order={order}
              setOrder={setOrder}
              showTCScreen={showTCScreen}
              setShowTCScreen={setShowTCScreen}
              regulationURI={regulationURI}
              setRegulationURI={setRegulationURI}
              showChallengeTC={showChallengeTC}
              setShowChallengeTC={setShowChallengeTC}
              showChallengeInfo={showChallengeInfo}
              setShowChallengeInfo={setShowChallengeInfo}
              showPrizes={showPrizes}
              setShowPrizes={setShowPrizes}
            />
          )}
          <Box>
            <Prizes
              langList={langChamp || {}}
              viewDetail={viewDetail}
              httpImages={viewDetail !== undefined}
              getPrizes={getPrizes}
            />
          </Box>
          <Box>
            <Questions
              langList={langChamp || {}}
              viewDetail={viewDetail}
              getQuestions={getQuestions}
              showTCScreen={showTCScreen}
            />
          </Box>
          <hr />
          <Box>
            <Countries
              viewDetail={countries}
              createChampionship={create}
              allowedCountriesChampionship={countriesCurrentList}
              invitedCountriesChampionship={countriesInvitedList}
              disabled={true}
              getCountries={getCountries}
            />
          </Box>
          <Box>
            <Matches
              viewDetail={viewDetail}
              matches={challengeMatches}
              championshipSelection={championshipSelection}
              setChampionshipSelection={setChampionshipSelection}
            />
          </Box>
          <Box>
            <OutrightsOfficial viewDetail={viewDetail} championshipSelection={championshipSelection} />
          </Box>
          {id !== 'new' ? (
            <Button style={{ marginTop: '40px' }} variant="contained" color="primary" onClick={() => create('update')}>
              Update Championship
            </Button>
          ) : (
            <Button style={{ marginTop: '40px' }} variant="contained" color="primary" onClick={() => create('create')}>
              Create Championship
            </Button>
          )}
          <NotificationContainer />
        </CmtCardContent>
        <CmtCardContent className={classes.cardContentRoot}>
          <ListVIew removeChampionship={removeChampionship} />
        </CmtCardContent>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
          <DialogContent>
            {lastEvent?.type === 'match' && (
              <DialogContentText id="alert-dialog-description">
                YEPP Selection has matches or outrights with scheduled date / end date after the custom end date of the
                championship.
                <br />
                <br />
                You need to change the custom end date with the date of the last match or to remove it from the selection.
                <br />
                <br /> {lastEvent?.event?.headline} - {Moment(lastEvent?.event?.scheduledDate).format('LLL')}
              </DialogContentText>
            )}

            {lastEvent?.type === 'outright' && (
              <DialogContentText id="alert-dialog-description">
                YEPP Selection has matches or outrights with scheduled date / end date after the custom end date of the
                championship.
                <br />
                <br />
                You need to change the custom end date with the date of the last outright or to remove it from the selection.
                <br />
                <br /> {lastEvent?.event?.competition?.name} - {Moment(lastEvent?.event?.competition?.endTime).format('LLL')}
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" variant="contained" autoFocus>
              Change Custom End Date
            </Button>
          </DialogActions>
        </Dialog>
      </CmtCard>
    </>
  );
};

export default OfficialChampionship;
