import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Hidden, IconButton, Table, TableBody, TablePagination, Typography } from '@material-ui/core';
import axios from 'services/auth/jwt/config';
import { fetchError } from 'redux/actions';
import { toggleExpandSidebar } from 'redux/actions/MatchApp';
import { Menu } from '@material-ui/icons';
import CmtSearch from '@coremat/CmtSearch';
import AppContext from 'components/contextProvider/AppContextProvider/AppContext';
import { getMatchContainerHeight } from 'constants/AppConstants';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TableHeading from 'components/Table/TableHeading';
import TableHeader from 'assets/global/globalTableHeadingData';
import TableItem from './TableItem';
import Sidebar from './Sidebar';
import EmptyResult from 'components/Table/EmptyResult';

import useStyles from './index.style';

const Participants = () => {
  const { isSideBarCollapsed, selectedSport } = useSelector(({ teams }) => teams);
  const { showFooter } = useContext(AppContext);
  const dispatch = useDispatch();
  const classes = useStyles({ height: getMatchContainerHeight(showFooter) + 80 });
  const [selectedSportParticipants, setSelectedSportParticipants] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchParticipants = () => {
    if (Object.keys(selectedSport).length !== 0) {
      axios
        .get(`/teams?&offset=${page * rowsPerPage}&limit=${rowsPerPage}&name=${searchQuery}&sportsIds=${selectedSport._id}`)
        .then(response => {
          setSelectedSportParticipants(response.data.teams);
          setCount(response.data.count);
        })
        .catch(() => {
          dispatch(fetchError('Something went wrong'));
        });
    }
  };

  useEffect(() => {
    fetchParticipants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSport, rowsPerPage, page]);

  useEffect(() => {
    let timer = setTimeout(() => {
      fetchParticipants();
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    setPage(0);
    setRowsPerPage(10);
  }, [selectedSport]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  return (
    <Box className={classes.inBuildAppCard}>
      <Box className={classes.inBuildAppHeader}>
        <Box className={classes.inBuildAppHeaderSidebar}>
          <Hidden>
            <IconButton onClick={() => dispatch(toggleExpandSidebar())}>
              <Menu />
            </IconButton>
          </Hidden>
          <Typography className={classes.inBuildAppHeaderTitle} component="div" variant="h1">
            Participants
          </Typography>
        </Box>
        <Box className={classes.inBuildAppHeaderContent}>
          <CmtSearch
            placeholder="Search..."
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            border={false}
          />
        </Box>
      </Box>
      <Box className={clsx(classes.inBuildAppContainer, isSideBarCollapsed ? 'collapsed' : '')}>
        <Sidebar selectedSport={selectedSport} />
        <Box className={classes.inBuildAppMainContent}>
          <Box className="Cmt-table-responsive">
            <PerfectScrollbar className={classes.perfectScrollbarMatchCon}>
              {selectedSportParticipants ? (
                !!selectedSportParticipants.length ? (
                  <Table>
                    <TableHeading headingData={TableHeader.Participants} />
                    <TableBody>
                      {selectedSportParticipants.map(row => (
                        <TableItem key={row._id} row={row} fetchParticipants={fetchParticipants} />
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <EmptyResult />
                )
              ) : null}
            </PerfectScrollbar>
          </Box>
          <TablePagination
            component="div"
            style={{ order: '9999', width: '100%' }}
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Participants;
