import React from 'react';
import { TableRow, TableCell, Box } from '@material-ui/core';
import useStyles from './index.style';

const TableHeading = () => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell className={classes.tableCellRoot}>
        <Box style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
          <strong style={{ marginRight: '15px' }}>Name</strong>
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Box style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
          <strong style={{ marginRight: '15px' }}>OddType Name</strong>
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Box style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
          <strong style={{ marginRight: '15px' }}>OddType visibleEventParts</strong>
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Box style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
          <strong style={{ marginRight: '15px' }}>Status</strong>
        </Box>
      </TableCell>
      <TableCell className={classes.tableCellRoot}>
        <Box style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
          <strong style={{ marginRight: '15px' }}>Bet Limit Date</strong>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TableHeading;
