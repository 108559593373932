import { GET_OUTRIGHTS_DATA, GET_ALL_OUTRIGHTS_DATA, SET_OUTRIGHTS_FILTERS } from 'constants/ActionTypes';

const INIT_STATE = {
  outrightsList: [],
  filters: {
    offset: 0,
    limit: 10,
    sport: '',
    status: '',
    searchQuery: '',
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_OUTRIGHTS_DATA: {
      return {
        ...state,
        outrightsList: action.payload.data,
      };
    }

    case GET_ALL_OUTRIGHTS_DATA: {
      return {
        ...state,
        outrightsListAll: action.payload.data,
      };
    }

    case SET_OUTRIGHTS_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }

    default:
      return state;
  }
};
