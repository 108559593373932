import React from 'react';
import { Box, Button } from '@material-ui/core';
import CmtAvatar from '@coremat/CmtAvatar';
import useStyles from '../index.style';
import Moment from 'moment';

const CompetitionCell = ({ selectedCompetitions, onRemove }) => {
  const classes = useStyles();
  return (
    <Box className={classes.selectedMatchesList}>
      {selectedCompetitions.map((competition, id) => {
        return (
          <Box key={id} className={classes.selectListMatchRow}>
            <Box>
              <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
                <Box
                  mr={4}
                  style={{
                    width: '30px',
                    overflow: 'hidden',
                    marginRight: '0',
                    borderRight: '1px solid #fff',
                  }}>
                  <CmtAvatar size={60} alt={competition?.competition?.name} />
                </Box>
                <Box mr={4} style={{ width: '30px', overflow: 'hidden', marginRight: '0' }}>
                  <CmtAvatar size={60} style={{ transform: 'translateX(-30px)' }} alt={competition?.competition?.name} />
                </Box>
              </Box>
            </Box>
            <Box>
              <Box>
                <h3 style={{ fontWeight: 'bold' }}>{competition.competition.name}</h3>
              </Box>
              <Box>{Moment(competition.competition.endTime).format('H:mm - D MMM yyyy')}</Box>
              <Box>
                {competition.oddType.name} - {competition.oddType.availableEventParts[0].name}
              </Box>
            </Box>
            {/*<Box style={{ marginLeft: 'auto' }}>*/}
            {/*  <TextField*/}
            {/*    style={{ padding: ' 0 30px' }}*/}
            {/*    id="datetime-local"*/}
            {/*    type="datetime-local"*/}
            {/*    disabled={pageId !== 'new'}*/}
            {/*    value={competition.dateTimeField}*/}
            {/*    sx={{ width: 250 }}*/}
            {/*    InputLabelProps={{*/}
            {/*      shrink: true,*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</Box>*/}
            <Button
              style={{ marginLeft: 'auto' }}
              variant="contained"
              color="primary"
              disabled={competition.status === 'Ended'}
              onClick={() => onRemove(competition._id)}>
              Remove
            </Button>
          </Box>
        );
      })}
    </Box>
  );
};

export default CompetitionCell;
