import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { alpha } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { getPoll } from 'redux/actions/Polls';
const useStyles = makeStyles(theme => ({
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&:last-child': {
          color: theme.palette.error.main,
        },
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    padding: 2,
    paddingLeft: 14,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.text.secondary,
    borderBottom: '0 none',
    position: 'relative',
  },
  url: {
    width: '45%',
    wordBreak: 'break-word',
    [theme.breakpoints.down('sm')]: {
      minWidth: '300px',
    },
  },
}));

const TableItem = ({ row, index }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <TableRow className={classes.tableRowRoot}>
      <TableCell className={classes.tableCellRoot}>{index + 1}</TableCell>
      <TableCell className={[classes.tableCellRoot, classes.url].join(' ')}>
        {row?.questionText?.find(item => item.language === 'en')?.text}
      </TableCell>
      <TableCell className={classes.tableCellRoot}>{moment(row.startDate).format('DD.MM.YYYY')}</TableCell>
      <TableCell className={classes.tableCellRoot}>{moment(row.answerLimitDate).format('DD.MM.YYYY')}</TableCell>
      <TableCell className={classes.tableCellRoot}>{moment(row.endDate).format('DD.MM.YYYY')}</TableCell>

      <TableCell className={classes.tableCellRoot}>
        <IconButton
          color="primary"
          aria-label="edit selection"
          component="span"
          onClick={() => {
            dispatch(getPoll(row._id));
            history.push('/polls/' + row._id);
          }}>
          <EditIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default TableItem;
