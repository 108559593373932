import { GET_TRANSLATION, GET_ALL_TRANSLATIONS, SET_DB_FILTERS } from 'constants/ActionTypes';

const INIT_STATE = {
  translation: {},
  translations: [],
  filters: {
    status: '',
    section: '',
    addedBy: '',
    updatedBy: '',
    addedDateSort: 0,
    updatedDateSort: 0,
    offset: '0',
    limit: '10',
    searchQuery: '',
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_TRANSLATIONS:
      return {
        ...state,
        translations: action.payload,
      };
    case GET_TRANSLATION:
      return {
        ...state,
        translation: action.payload,
      };
    case SET_DB_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    default:
      return state;
  }
};
