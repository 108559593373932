const UserDetail = {
  wallet: [
    {
      id: 1,
      title: 'Name',
    },
    {
      id: 2,
      title: 'Value',
    },
    {
      id: 3,
      title: 'Type',
    },
    {
      id: 4,
      title: 'Created',
    },
    {
      id: 5,
      title: 'Updated',
    },
    {
      id: 6,
      title: 'ID',
    },
  ],
  transactions: [
    {
      id: 1,
      title: 'Status',
    },
    {
      id: 2,
      title: 'Value',
    },
    {
      id: 3,
      title: 'Type',
    },
    {
      id: 4,
      title: 'Created',
    },
    {
      id: 5,
      title: 'Updated',
    },
    {
      id: 6,
      title: 'ID',
    },
  ],
  identityVerification: [
    {
      id: 1,
      title: 'Document',
    },
    {
      id: 2,
      title: 'Status',
    },
    {
      id: 3,
      title: 'View document',
    },
    {
      id: 4,
      title: 'Change status',
    },
  ],
};

const Odds = [
  {
    id: 0,
    title: 'Name',
  },
  {
    id: 1,
    title: 'Common name',
  },
  {
    id: 2,
    title: 'Visible',
  },
];

const BetTemplates = [
  {
    id: 1,
    title: 'Name',
  },
  {
    id: 2,
    title: 'Sport',
  },
];

const Brackets = [
  {
    id: 1,
    title: 'Name',
  },
  {
    id: 2,
    title: 'Sport',
  },
];

const Participants = [
  {
    id: 1,
    title: '',
  },
  {
    id: 2,
    title: 'Name',
  },
  {
    id: 3,
    title: 'Top Participant',
  },
  {
    id: 4,
    title: 'Logo',
  },
];

const Selections = [
  {
    id: 1,
    title: '',
  },
  {
    id: 2,
    title: 'Name',
  },
  {
    id: 3,
    title: 'Matches and Outrights',
  },
];

const PopUps = [
  {
    id: 1,
    title: '#',
  },
  {
    id: 2,
    title: 'URL',
  },
  {
    id: 3,
    title: 'Menu',
  },
  {
    id: 4,
    title: 'Period',
  },
  {
    id: 5,
    title: 'Redirect Section',
  },
];

const Polls = [
  {
    id: 1,
    title: '#',
  },
  {
    id: 2,
    title: 'English title',
  },
  {
    id: 3,
    title: 'Start Date',
  },
  {
    id: 4,
    title: 'Limit Time',
  },
  {
    id: 5,
    title: 'End Time',
  },
];

const Ecosystems = [
  {
    id: 1,
    title: 'Ecosystem name',
  },
  {
    id: 2,
    title: 'Challenge name',
  },
  {
    id: 3,
    title: 'Competition name',
  },
  {
    id: 4,
    title: 'Is Motor Racing',
  },
  {
    id: 5,
    title: 'Is Published',
  },
];

const TableHeader = {
  UserDetail,
  Odds,
  BetTemplates,
  Participants,
  Brackets,
  Selections,
  PopUps,
  Polls,
  Ecosystems,
};

export default TableHeader;
