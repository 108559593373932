import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, TextField, InputLabel, Select, MenuItem, FormControl, TextareaAutosize } from '@material-ui/core';

const AddSetting = ({ addSetting, toggleCreate }) => {
  const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      background: 'rgba(255,255,255, 1)',
      maxWidth: 'calc(100% - 30px)',
      margin: '10px 15px',
      width: '100%',
      padding: '5px 20px',
      borderRadius: '4px',
      '@media (max-width: 1024px)': {
        maxWidth: '100%',
      },
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      '@media (max-width: 768px)': {
        flexDirection: 'column',
      },
    },
    leftContainer: {
      width: '375px',
      '@media (max-width: 768px)': {
        width: '100%',
      },
    },
  }));

  const classes = useStyles();

  const [settingName, setSettingName] = useState('');
  const [scope, setScope] = useState(0);
  const [visibility, setVisibility] = useState(0);
  const [jsonValue, setJsonValue] = useState('');
  const [jsonError, setJsonError] = useState('');

  const handleChangeName = event => {
    setSettingName(event.target.value);
  };

  const handleChangeScope = event => {
    setScope(event.target.value);
  };

  const handleChangeVisibility = event => {
    setVisibility(event.target.value);
  };

  const handleChangeJSON = event => {
    setJsonValue(event.target.value);
  };

  const checkValidations = () => {
    try {
      let parsedJson = JSON.parse(jsonValue);
      let body = {
        name: camelcaseName(settingName),
        value: parsedJson,
        scope,
        visibility,
      };
      addSetting(body);
    } catch (e) {
      setJsonError('Enter a valid JSON');
    }
  };

  return (
    <Box component="div" className={classes.container}>
      <Box className={classes.wrapper}>
        <Box className={classes.leftContainer}>
          <FormControl className={classes.formControl}>
            <TextField label="Name" value={settingName} onChange={handleChangeName} />
          </FormControl>
          <Box>
            <FormControl className={classes.formControl}>
              <InputLabel>Scope</InputLabel>
              <Select value={scope} onChange={handleChangeScope}>
                <MenuItem value={0}>GLOBAL</MenuItem>
                <MenuItem value={1}>MOBILE</MenuItem>
                <MenuItem value={2}>BACKEND</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel>Visibility</InputLabel>
              <Select value={visibility} onChange={handleChangeVisibility}>
                <MenuItem value={0}>PUBLIC</MenuItem>
                <MenuItem value={1}>PRIVATE</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box>
            <h3 style={{ margin: '20px 0 10px 0' }}>JSON value</h3>
            <TextareaAutosize
              value={jsonValue}
              style={{
                minHeight: '150px',
                width: '100%',
                padding: '15px',
              }}
              onChange={handleChangeJSON}
            />
            <h3 style={{ margin: '5px 0 10px 0', color: 'red' }}>{jsonError}</h3>
          </Box>
          <Box mt={2}>
            <Button
              disabled={!settingName.length || !jsonValue.length}
              variant="contained"
              color="primary"
              onClick={checkValidations}>
              Save
            </Button>
          </Box>
          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={toggleCreate}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddSetting;

const camelcaseName = name => {
  return name
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};
