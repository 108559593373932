import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toggleExpandSidebar } from 'redux/actions/MatchApp';

import { Box, Typography, IconButton, Hidden } from '@material-ui/core';
import { GridOn, List, Menu } from '@material-ui/icons';

import CmtSearch from '@coremat/CmtSearch';
import useStyles from './index.style';

const AppHeader = ({ onChangeViewMode, viewMode, setSearchQuery }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');

  const handleSearchText = e => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      setSearchQuery(searchValue);
    }, 500);
    return () => clearTimeout(delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <Box className={classes.inBuildAppHeader}>
      <Box className={classes.inBuildAppHeaderSidebar}>
        <Hidden>
          <IconButton onClick={() => dispatch(toggleExpandSidebar())}>
            <Menu />
          </IconButton>
        </Hidden>
        <Typography className={classes.inBuildAppHeaderTitle} component="div" variant="h1">
          Matches
        </Typography>
      </Box>

      <Box className={classes.inBuildAppHeaderContent}>
        <CmtSearch placeholder="Search Match..." value={searchValue} onChange={handleSearchText} border={false} />
        <Box ml="auto" display="flex" alignItems="center">
          <Box ml={1}>
            <IconButton
              className="icon-btn active"
              color={viewMode === 'table' ? 'primary' : 'default'}
              onClick={() => onChangeViewMode('table')}>
              <List />
            </IconButton>
          </Box>
          <Box ml={1}>
            <IconButton
              className="icon-btn"
              color={viewMode === 'grid' ? 'primary' : 'default'}
              onClick={() => onChangeViewMode('grid')}>
              <GridOn />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AppHeader;

AppHeader.prototype = {
  onChangeViewMode: PropTypes.func,
};
