import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
import { ListItemIcon, ListItemText, ListItem } from '@material-ui/core';
import LabelIcon from '@material-ui/icons/Label';

const ItemCell = ({ classes, item, selectedItem, onChange }) => {
  // const { counter } = useSelector(({ matchApp }) => matchApp);

  return (
    <ListItem
      button
      className={clsx(classes.appNavItem, {
        active: item.slug === selectedItem,
      })}
      onClick={() => onChange(item.slug)}>
      <ListItemIcon className="Cmt-icon-root">
        {item.icon ? item.icon : <LabelIcon style={{ color: item.color }} />}
      </ListItemIcon>
      <ListItemText className="Cmt-nav-text" primary={item.name} />
      {/*{counter && counter[item.slug] !== 0 && (*/}
      {/*  <Box component="span" className="Cmt-nav-count">*/}
      {/*    {counter.folders[item.id]}*/}
      {/*  </Box>*/}
      {/*)}*/}
    </ListItem>
  );
};

export default ItemCell;

ItemCell.prototype = {
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  selectedItem: PropTypes.string,
};

ItemCell.defaultProps = {
  selectedItem: '',
};
