import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMatchEvents, addMatchEvents, removeMatchEvents } from 'redux/actions/MatchDetails';

import { Box, Button, TableCell, Table, TableBody, TableHead, TableRow } from '@material-ui/core';

import Event from './Event';
import AddEvent from './AddEvent';

import useStyles from '../index.style';

const Odds = ({ id, matchDetail }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { matchEvents, playersDetail, matchAddEvents, matchRemoveEvents } = useSelector(({ matchDetails }) => matchDetails);
  const [players, setPlayers] = useState({});
  const [eventsList, setEventsList] = useState({});

  const [showAddEvent, setShowAddEvent] = useState(false);

  useEffect(() => {
    dispatch(getMatchEvents(id));
    // dispatch(getMatchPlayers(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, matchAddEvents, matchRemoveEvents]);

  useEffect(() => {
    if (matchEvents !== undefined) {
      setEventsList(matchEvents);
    }
  }, [matchEvents]);

  useEffect(() => {
    if (playersDetail !== undefined) {
      setPlayers(playersDetail);
    }
  }, [playersDetail]);

  const addNewEvent = body => {
    dispatch(addMatchEvents(body));
    toggleAddEvent();
  };

  const deleteEvent = id => {
    dispatch(removeMatchEvents(id));
  };

  const toggleAddEvent = () => {
    setShowAddEvent(!showAddEvent);
  };

  return (
    matchEvents !== undefined && (
      <Box className={classes.headerContent} style={{ display: 'flex', flexDirection: 'column' }}>
        <Button variant="contained" className={classes.addEventsMobile} color="primary" onClick={toggleAddEvent}>
          {showAddEvent === false ? 'Add Event' : 'Cancel'}
        </Button>
        <Box className={classes.headerContent} style={{ display: 'flex', flexDirection: 'row' }}>
          <Table className={classes.tableResponsive}>
            <TableHead>
              <TableRow>
                <TableCell>Team</TableCell>
                <TableCell>Player Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Extra Time</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={toggleAddEvent}>
                    {showAddEvent === false ? 'Add Event' : 'Cancel'}
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {showAddEvent === true && (
                <AddEvent
                  players={players}
                  matchDetail={matchDetail}
                  addNewEvent={addNewEvent}
                  toggleAdd={showAddEvent}
                  toggleAddEvent={toggleAddEvent}
                />
              )}
              <Event eventsList={eventsList} deleteEvent={deleteEvent} />
            </TableBody>
          </Table>
        </Box>
      </Box>
    )
  );
};

export default Odds;
