import React, { useEffect, useState } from 'react';
import { Box, Button, TablePagination, FormControl, InputLabel, Input, Avatar } from '@material-ui/core';
import useStyles from '../index.style';
import { useDispatch, useSelector } from 'react-redux';
import { getCompetitions } from 'redux/actions/Competitions';

const CompetitionSearch = ({ setSearchQuery, searchQuery }) => {
  const { loading } = useSelector(({ common }) => common);
  return (
    <FormControl style={{ width: '100%', display: 'block' }}>
      <Box style={{ display: 'inline-block' }}>
        <InputLabel htmlFor="input-with-icon-adornment">Search for Competitions</InputLabel>
        <Input
          style={{ marginRight: '20px' }}
          id="input-with-icon-adornment"
          value={searchQuery}
          onChange={e => {
            setSearchQuery(e.target.value);
          }}
          disabled={loading}
        />
      </Box>
    </FormControl>
  );
};
const rowsPerPage = 10;
const filterType = {
  field: 'name',
  order: '1',
};

const SelectCompetitions = ({ setSelectedCompetitions, selectedCompetitions }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const { competitionsList } = useSelector(({ competitions }) => competitions);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getCompetitions(null, newPage, rowsPerPage, searchQuery, filterType));
  };

  useEffect(() => {
    const interval = setTimeout(() => {
      setPage(0);
      dispatch(getCompetitions(null, page, rowsPerPage, searchQuery, filterType));
    }, 800);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <Box className={classes.selectListMatch}>
      <Box style={{ marginBottom: '20px', display: 'flex' }}>
        <CompetitionSearch classes={classes} setSearchQuery={setSearchQuery} searchQuery={searchQuery} />
      </Box>
      <Box className={classes.selectListMatchContainer}>
        {competitionsList?.competitions?.length > 0 &&
          competitionsList?.competitions?.map(competition => {
            return (
              <Box key={competition._id} className={classes.selectListMatchRow}>
                <Box>
                  <Box mr={4} style={{ display: 'flex', flexDirection: 'row' }}>
                    <Avatar
                      alt={competition.name}
                      src={`https://flagsapi.com/${competition?.country?.code}/flat/64.png`}
                      variant="square"
                    />
                  </Box>
                </Box>
                <Box>
                  <Box>
                    <h3 style={{ fontWeight: 'bold' }}>{competition.name}</h3>
                  </Box>
                  <Box>
                    {competition.sport.name} | {competition?.country?.name}
                  </Box>
                </Box>
                <Button
                  style={{ marginLeft: 'auto' }}
                  variant="contained"
                  color="primary"
                  onClick={() => setSelectedCompetitions(prev => [...prev, competition])}
                  disabled={selectedCompetitions?.find(item => item._id === competition._id)}>
                  ADD
                </Button>
              </Box>
            );
          })}
        {competitionsList?.count && (
          <TablePagination
            component="div"
            style={{ order: '9999', width: '100%' }}
            count={competitionsList?.count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[rowsPerPage]}
            rowsPerPage={rowsPerPage}
          />
        )}
      </Box>
    </Box>
  );
};
export default SelectCompetitions;
