import React, { useState } from 'react';
import { Button, TableRow, TableCell, InputLabel, MenuItem, FormControl, Select, TextField, Box } from '@material-ui/core';
import GlobalVariables from 'assets/global/globalVariables';

import useStyles from '../index.style';

const Players = ({ addNewEvent, matchDetail, toggleAdd, toggleAddEvent }) => {
  const classes = useStyles();
  const [playersList, setPlayersList] = useState(
    matchDetail.homeTeamPlayers.concat(
      matchDetail.homeTeamPlayersBench,
      matchDetail.awayTeamPlayers,
      matchDetail.awayTeamPlayersBench,
    ),
  );

  const [selectedTeam, setSelectedTeam] = React.useState('');
  const [selectedTeamID, setSelectedTeamID] = React.useState('');
  const [selectedPlayer, setSelectedPlayer] = React.useState('');
  const [selectedType, setSelectedType] = React.useState('');
  const [selectedTime, setSelectedTime] = React.useState(0);
  const [selectedExtraTime, setSelectedExtraTime] = React.useState(0);
  const handleTeam = e => {
    if (e.target.value === 'home') {
      setSelectedTeam('home');
      setSelectedTeamID(matchDetail.homeTeam.teamId);
      setPlayersList(matchDetail.homeTeamPlayers.concat(matchDetail.homeTeamPlayersBench));
    } else if (e.target.value === 'away') {
      setSelectedTeam('away');
      setSelectedTeamID(matchDetail.awayTeam.teamId);
      setPlayersList(matchDetail.awayTeamPlayers.concat(matchDetail.awayTeamPlayersBench));
    }
  };

  const handlePlayer = e => {
    setSelectedPlayer(e.target.value);
  };

  const handleType = e => {
    setSelectedType(e.target.value);
  };
  const handleTime = e => {
    setSelectedTime(e.target.value);
  };
  const handleExtraTime = e => {
    setSelectedExtraTime(e.target.value);
  };

  const submitNewEvent = e => {
    let body = {
      matchId: matchDetail._id.toString(),
      teamId: selectedTeamID.toString(),
      playerId: selectedPlayer.toString(),
      type: selectedType.toString(),
      matchTime: parseInt(selectedTime),
      extraTime: parseInt(selectedExtraTime),
    };
    addNewEvent(body);
  };
  return (
    <TableRow className={classes.addEventTableCell}>
      <TableCell>
        <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
          <InputLabel id="demo-simple-select-outlined-label">Select Team</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={selectedTeam}
            onChange={handleTeam}
            label="Select Team">
            <MenuItem value="">
              <em>Select Team</em>
            </MenuItem>
            <MenuItem value="home">{matchDetail.homeTeam.teamName}</MenuItem>
            <MenuItem value="away">{matchDetail.awayTeam.teamName}</MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
          <InputLabel id="demo-simple-select-outlined-label">Select Player</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={selectedPlayer}
            onChange={handlePlayer}
            label="Select Player">
            <MenuItem value="">
              <em>Select Player</em>
            </MenuItem>
            {playersList !== undefined &&
              playersList.length > 0 &&
              playersList.map(player => {
                return (
                  <MenuItem key={player.playerName} value={player.playerId}>
                    {player.playerName}
                  </MenuItem>
                );
              })}
            ;
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
          <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={selectedType}
            onChange={handleType}
            label="Type">
            <MenuItem value="">
              <em>Select Type</em>
            </MenuItem>
            {GlobalVariables.addEventTypes[matchDetail.sport.nameLowercase].map(sportEventType => {
              return (
                <MenuItem key={sportEventType.id} value={sportEventType.value}>
                  {sportEventType.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            InputProps={{ inputProps: { min: 0, max: 90 } }}
            id="outlined-basic"
            label="Time"
            type="number"
            defaultValue={selectedTime}
            variant="outlined"
            onChange={handleTime}
            style={{ width: '90px' }}
          />
        </form>
      </TableCell>
      <TableCell>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            InputProps={{ inputProps: { min: 0, max: 30 } }}
            id="outlined-basic"
            label="Extra Time"
            defaultValue={selectedExtraTime}
            type="number"
            variant="outlined"
            onChange={handleExtraTime}
            style={{ width: '140px' }}
          />
        </form>
      </TableCell>
      <TableCell>
        <Button variant="contained" color="primary" onClick={submitNewEvent}>
          Add
        </Button>
        <Button variant="contained" className={classes.cancelAddEvent} color="secondary" onClick={toggleAddEvent}>
          Cancel
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default Players;
